<h4>Classifications</h4>
<hr />
<div class="mb-3">
  <label>Segment</label>
  <select class="form-select" name="segment" [(ngModel)]="segment" (change)="resetGenres()">
    <option value="" selected>Select Segment</option>
    <option *ngFor="let segment of segments" [ngValue]="segment._id">
      {{ segment.name }}
    </option>
  </select>
</div>
<div class="mb-3">
  <label>Genres</label>
  <select
    class="form-select"
    [disabled]="!segment"
    name="genre"
    multiple="true"
    [(ngModel)]="genre"
    (change)="change()"
  >
    <option *ngFor="let genre of filteredGenres()" [ngValue]="genre._id">
      {{ genre.name }}
    </option>
  </select>
</div>
<div class="mb-3">
  <label>Sub-Genre</label>
  <select
    class="form-select"
    [disabled]="!genre.length"
    name="sub-genre"
    multiple="true"
    [(ngModel)]="subGenre"
    (change)="change()"
  >
    <option *ngFor="let subGenre of filteredSubGenres()" [ngValue]="subGenre._id">
      {{ subGenre.name }}
    </option>
  </select>
</div>
