/* eslint-disable no-array-constructor */
import { ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';

export abstract class ValueAccessorBase<T> implements ControlValueAccessor {
  private innerValue: T;

  private changed = new Array<(value: T) => void>();
  private touched = new Array<() => void>();

  public get value(): T {
    return this.innerValue;
  }

  public set value(value: T) {
    if (this.innerValue !== value) {
      this.innerValue = value;

      this.value$.next(value);
      this.valueChange.next(value);
      this.changed.forEach(f => f(value));

      if (this._cd) {
        this._cd.detectChanges();
      }
    }
  }

  public value$ = new Subject<T>();
  public valueChange = new Subject<T>();

  constructor(private _cd?: ChangeDetectorRef) {}

  touch() {
    this.touched.forEach(f => f());
  }

  // #region ControlValueAccessor Members

  writeValue(value: T): void {
    this.innerValue = value;
    this.value$.next(value);
    if (this._cd) {
      this._cd.detectChanges();
    }
  }

  registerOnChange(fn: (value: T) => void): void {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.touched.push(fn);
  }

  // #endregion
}
