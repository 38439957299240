import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { BaseComponent } from '../base.component';
import { ApiResources } from '../../../enums';

@Component({
  selector: 'app-location-search-typeahead',
  templateUrl: './location-search-typeahead.component.html',
  styleUrls: ['./location-search-typeahead.component.scss'],
})
export class LocationSearchTypeaheadComponent extends BaseComponent implements OnChanges {
  @Input() locationId: any;
  @Input() value: string;
  @Input() clearOnSelect: boolean;

  @Output() selected = new EventEmitter<any>();
  @Output() locationChange = new EventEmitter<any>();

  public selectedItem = null;

  constructor() {
    super();
    this.apiClient.setResource(ApiResources.RESTAURANTS);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.locationId) {
      this.setSelected();
    }
  }

  private async setSelected() {
    const result = await this.apiClient.findById<any>(this.locationId).toPromise();
    this.selectedItem = result;
    this.value = result.name;
  }

  public selectLocation(selected: any) {
    if (selected) {
      this.selectedItem = selected.item;
      this.selected.emit(selected.item);
      this.locationChange.emit(selected.item);

      if (this.clearOnSelect) {
        this.value = '';
      }
    } else if (this.selectedItem) {
      this.selectedItem = null;
      this.selected.emit(null);
      this.locationChange.emit(null);
    }
  }

  public onChange(value: any) {
    if (!value || typeof value === 'string') {
      this.selectLocation(null);
    }
  }

  searchLocation = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(name => {
        const query = { name, limit: 100, isDeleted: false };
        return this.apiClient.get<any>(query).pipe(
          map(x => x.data),
          catchError(() => of([]))
        );
      })
    );

  // eslint-disable-next-line class-methods-use-this
  locationFormatter = (x: { name: string; slug: string; venue: any }) => {
    if (x.name) {
      return `${x.name} (${x.slug}) - ${x.venue.name}`;
    }
    if (typeof x === 'string') {
      return x;
    }
    return '';
  };
}
