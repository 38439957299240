import { createAction, props } from '@ngrx/store';
import { Org } from '../../models';

export enum OrgActions {
  LOAD_ORGS = 'Load Orgs',
  LOAD_ORGS_SUCCESS = 'Load Orgs Success',
  ORG_SELECTED = 'Org Selected',
}

export const retrievedOrgs = createAction(OrgActions.LOAD_ORGS_SUCCESS, props<{ orgs: Org[] }>());

export const selectedOrg = createAction(OrgActions.ORG_SELECTED, props<{ selectedOrg: Org }>());
