import _ from 'lodash';
import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ApiResources } from '@app/enums';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-domain-select',
  templateUrl: './domain-select.component.html',
  styleUrls: ['./domain-select.component.scss'],
})
export class DomainSelectComponent extends BaseComponent implements OnChanges {
  @Input() domainDetails: any;
  @Input() orgId: any;

  @Output() domainDetailsChange = new EventEmitter<any>();

  private zoneUrl: string;

  public domains: Array<any> = [];
  public subdomain: string;
  public domain: any;
  public customDomain: boolean;
  public disable = false;
  public useOwnDns = false;

  constructor() {
    super();
    this.apiClient.setResource(ApiResources.CLOUDFLARE);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes?.domainDetails?.currentValue) {
      const change = this.domainDetails;
      if (change?.domain) {
        const split = change.domain.split('.');
        if (split.length > 2) {
          this.subdomain = split.slice(0, split.length - 2).join('.');
        } else {
          this.subdomain = '@';
        }
        this.zoneUrl = split.slice(split.length - 2).join('.');
        if (change.useOwnDns) {
          this.domain = this.zoneUrl;
        } else {
          this.findZone();
        }
      }
      if (change?.recordId) {
        this.disable = true;
      }
      if (change?.customDomain) {
        this.customDomain = change?.customDomain;
      }
      if (change?.useOwnDns) {
        this.useOwnDns = change?.useOwnDns;
      }
      if (change?.zoneId) {
        this.domain = {};
      }
      this.listZones();
    }
  }

  private findZone() {
    if (this.domains.length && this.zoneUrl) {
      const domain = this.domains.find((dom: any) => dom.name === this.zoneUrl);
      if (domain) {
        this.domain = domain;
      }
    }
  }

  async listZones() {
    try {
      const query: any = {};
      if (this.customDomain && this.orgId) {
        query.orgId = this.orgId._id || this.orgId;
      } else if (this.customDomain && !this.orgId) {
        this.toast.error('Please select an org first before setting a custom domain.');
        this.customDomain = false;
        return;
      }
      const result = await this.apiClient.get<any>(query, 'zones').toPromise();
      this.domains = result.data;
      this.findZone();
    } catch (err) {
      this.domains = [];
      this.displayError(err);
    }
  }

  select() {
    if (this.subdomain && this.domain?.name) {
      const domain = [this.subdomain === '@' ? null : this.subdomain, this.domain.name]
        .filter(x => x)
        .join('.');
      if (!this.domainDetails) {
        this.domainDetails = {};
      }
      this.domainDetails.domain = domain;
      this.domainDetails.zoneId = this.domain.id;
      this.domainDetails.customDomain = this.customDomain;
      this.domainDetails.useOwnDns = false;
      this.domainDetailsChange.emit(this.domainDetails);
    }
  }

  selectOwnDomain() {
    if (this.subdomain && _.isString(this.domain)) {
      const domain = [this.subdomain === '@' ? null : this.subdomain, this.domain]
        .filter(x => x)
        .join('.');
      if (!this.domainDetails) {
        this.domainDetails = {};
      }
      this.domainDetails.domain = domain;
      this.domainDetails.zoneId = null;
      this.domainDetails.customDomain = this.customDomain;
      this.domainDetails.useOwnDns = this.useOwnDns;
      this.domainDetailsChange.emit(this.domainDetails);
    }
  }
}
