export function getValue(type: string, components: Array<any>) {
  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    if (component.types && component.types.indexOf(type) > -1) {
      return component.short_name;
    }
  }
  return null;
}

export function getComponent(type: string, addressComponents: Array<any>) {
  const values = [];
  switch (type) {
    case 'line1': {
      const street = this.getValue('street_number', addressComponents);
      const route = this.getValue('route', addressComponents);
      if (street) {
        values.push(street);
      }
      if (route) {
        values.push(route);
      }
      break;
    }
    case 'line2': {
      const line2 = this.getValue('neighborhood', addressComponents);
      if (line2) {
        values.push(line2);
      }
      break;
    }
    case 'city': {
      const city = this.getValue('locality', addressComponents);
      if (city) {
        values.push(city);
      }
      break;
    }
    case 'state': {
      const state = this.getValue('administrative_area_level_1', addressComponents);
      if (state) {
        values.push(state);
      }
      break;
    }
    case 'zip': {
      const zip = this.getValue('postal_code', addressComponents);
      if (zip) {
        values.push(zip);
      }
      break;
    }
  }
  return values.join(' ');
}

export function getAddressFromComponents(components) {
  if (components) {
    const addressComponents = components.address_components;
    return {
      line1: this.getComponent('line1', addressComponents),
      line2: this.getComponent('line2', addressComponents),
      city: this.getComponent('city', addressComponents),
      state: this.getComponent('state', addressComponents),
      zip: this.getComponent('zip', addressComponents),
      formatted: components.formatted_address,
    };
  }
}
