/* eslint-disable import/prefer-default-export */
export enum ApiHeader {
  AUTH_TOKEN = 'x-access-token',
  API_VERSION = 'x-api-version',
  APP_ID = 'x-partake-app-id',
  APP_NAME = 'x-partake-app',
  APP_SCOPE = 'x-partake-app-scope',
  APP_VERSION = 'x-partake-app-version',
  APP_VIEW_MODE = 'x-partake-app-view-mode',
  APP_PATH = 'x-partake-app-path',
  ORG_ID = 'x-partake-org-id',
  VENUE_ID = 'x-partake-venue-id',
  LOCATION_ID = 'x-partake-location-id',
  LIVE_MODE = 'x-partake-live-mode',
  POS_ID = 'x-partake-pos-id',
  SHIFT_ID = 'x-partake-shift-id',
  ORDER_CHANNEL = 'x-partake-order-channel',
  MENU_FEATURE = 'x-partake-menu-feature',
  POS_APP_MODE = 'x-partake-pos-app-mode',
}
