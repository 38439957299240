import { Pipe, PipeTransform } from '@angular/core';

import { ProductTypeName } from '@app/enums';

@Pipe({
  name: 'productFilter',
})
export class ProductFilterPipe implements PipeTransform {
  transform(filter: any): string {
    let value = '';
    switch (filter.property) {
      case 'name':
        value = `Name contains ${filter.value}`;
        break;
      case 'type': {
        const type = ProductTypeName[filter.value];
        value = `Type is ${type}`;
        break;
      }
      case 'category':
        value = `Category is ${filter.value}`;
        break;
      default:
        value = `${filter.property} is ${filter.value}`;
        break;
    }
    return value;
  }
}
