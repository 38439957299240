import { Org, Venue } from '../../models';

export interface AppState {
  locations: Readonly<any[]>;
  integrations: Readonly<any[]>;
  orgs: Readonly<Org[]>;
  selectedOrg: Readonly<Org>;
  selectedVenue: Readonly<Venue>;
  viewMode: 'org' | 'admin';
}
