import { ApiDomain, ApiResources, ApiVersion } from '../enums';

export enum Widget {
  NUMBER = 'number',
  CURRENCY = 'currency',
  SELECT = 'select',
  LONG_STRING = 'textarea',
  WYSWYG = 'wyswyg',
  HTML = 'html',
  JAVASCRIPT = 'javascript',
  SCSS = 'scss',
  IMAGES = 'images',
  CLASSIFICATIONS = 'classifications',
}

export interface SelectEnum {
  key: string | number | null;
  name: any;
}

export interface ApiRequestOptions {
  resource: ApiResources;
  domain?: ApiDomain;
  version?: ApiVersion;
  subCollection?: string;
  viewAsMode?: 'org' | 'admin';
  modelProp: string;
  dataProp?: string; // The name of the property in response - defaults to 'data'
  search?: { [k: string]: any };
  displayFields?: string[];
}

export interface SelectWidget {
  type: Widget.SELECT;
  enum?: SelectEnum[];
  multiple?: boolean;
  format?: boolean;
  options?: ApiRequestOptions & {
    preserveModel?: boolean;
    /**
     * @description
     * This allows the widget to uses a property of the model as as part of the query
     */
    searchLocalField?: {
      searchKey: string;
      property: string;
      valueTransformer?: (data: any) => any;
    };
  };
}

export type IWidget =
  | {
      type: Widget;
      inputType?: string;
      lines?: number;
      showPreview?: boolean;
      options?: any;
    }
  | SelectWidget;

export enum Type {
  ARRAY = 'array',
  BOOLEAN = 'boolean',
  COLOR = 'color',
  CURRENCY = 'currency',
  DATE = 'date',
  DATE_TIME = 'dateTime',
  EMAIL = 'email',
  HTML = 'html',
  JAVASCRIPT = 'javascript',
  SCSS = 'scss',
  IMAGES = 'images',
  FILE = 'file',
  LONG_STRING = 'longString',
  OBJECT = 'object',
  NUMBER = 'number',
  PASSWORD = 'password',
  PHONE = 'tel',
  STRING = 'string',
  TYPEAHEAD = 'typeahead',
  USER = 'user', // @todo DELETE
  WYSWYG = 'wyswyg',
  SELF = 'self', // Can only be used within arrays
}

export type ComparitorFunc = (value: any) => boolean;

export interface ComparitorOptions {
  field: string;
  shouldShow: ComparitorFunc;
}

export interface ArrayType {
  hideInList?: boolean;
  showInList?: boolean;
}

export type IProperties = {
  label: string;
  description?: string;
  section?: string;
  side?: 'left' | 'right';
  required?: boolean;
  default?: any;
  partakeOnly?: boolean;
  isHidden?: boolean;
  immutable?: boolean;
  widget?: IWidget;
  /** @deprecated favor widget options */
  enum?: SelectEnum[];
  /** @deprecated favor widget options */
  multiple?: boolean;
  bucket?: string;
  onlyIf?: string | ComparitorOptions[];
  notIf?: string | ComparitorOptions[];
  reference?: string;
  reloadOnChange?: boolean;
  minLength?: number;
  maxLength?: number;
  hideInList?: boolean;
  showInList?: boolean;
  min?: number;
  max?: number;
  onChange?: (value: any, data: any, input?: any) => void;
} & (
  | {
      type:
        | Type.BOOLEAN
        | Type.CURRENCY
        | Type.EMAIL
        | Type.HTML
        | Type.JAVASCRIPT
        | Type.SCSS
        | Type.IMAGES
        | Type.FILE
        | Type.LONG_STRING
        | Type.NUMBER
        | Type.PASSWORD
        | Type.PHONE
        | Type.STRING
        | Type.WYSWYG
        | Type.SELF;
    }
  | {
      type: Type.DATE | Type.DATE_TIME;
      timezone?: string;
    }
  | {
      type: Type.OBJECT;
      properties: ISchema & ArrayType;
    }
  | {
      type: Type.COLOR;
      position?: 'top' | 'right' | 'bottom' | 'left';
    }
  | {
      type: Type.ARRAY;
      items?: IProperties;
      widget?: {
        type: Widget.CLASSIFICATIONS | Widget.SELECT;
      };
    }
  | ({
      type: Type.TYPEAHEAD;
    } & ApiRequestOptions)
  | {
      type: Type.USER;
      search?: { [k: string]: any };
    }
);

export type ISchema<Data = any> = Record<keyof Data, IProperties>;
