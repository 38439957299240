import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '@app/providers/order/order.service';
import { ChargeService } from '@app/providers/wallet/charge.service';
import { ChargeUtilService } from '@app/providers/wallet/charge.util.service';
import { TenderMapService } from '@app/providers/tender/tender-map.service';
import { CartUtilService } from '@app/providers/cart/cart.util.service';
import { ShiftService } from '@app/providers/shift/shift.service';
import { PaymentIntentService } from '../../../../providers/wallet/payment-intent.service';

@Component({
  selector: 'app-stripe-payment-modal',
  templateUrl: './stripe-payment-modal.component.html',
  styleUrls: ['./stripe-payment-modal.component.scss'],
})
export class StripePaymentModalComponent implements OnInit {
  @Input() orderId: any;
  @Input() paymentType: string;
  @Input() location: any;
  @Input() config: any;
  @Input() saveCard: false;

  private _charges: Array<any> = [];
  private _tenderMap: any;
  private _tender: any;
  private _shift: any;

  public order: any;
  public charge: any;
  public view = 'items';
  public amountTendered = 0;

  constructor(
    public _modal: NgbActiveModal,
    private _toastrService: ToastrService,
    private _orderService: OrderService,
    private _chargeService: ChargeService,
    private _paymentIntentService: PaymentIntentService,
    private _chargeUtil: ChargeUtilService,
    private _tenderService: TenderMapService,
    private _shiftService: ShiftService,
    private _cartUtil: CartUtilService
  ) {}

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this._shift = this._shiftService.getShift();
      this.order = await this._orderService.findById(this.orderId).toPromise();
      this._charges = await this._chargeService.findForOrder(this.orderId).toPromise();
      this._tenderMap = await this._tenderService.self().toPromise();
      this._tender = this._tenderMap[this.paymentType];
      this.setupCharges();
    } catch (e) {
      const message = e.error?.message || 'An unexpected error occurred.';
      this._toastrService.error(message, 'Error');
    }
  }

  private async setupCharges() {
    if (!this._charges.length) {
      const defaultCharge = this._chargeUtil.defaultCharge(this.order, this.order.total, null);
      defaultCharge.tender = this._tender;
      this.charge = await this._chargeService.create(defaultCharge).toPromise();
      this._charges = [this.charge];
    } else if (this._charges.length === 1) {
      this.charge = this._charges[0];
      if (!this.charge.isPaid) {
        this._charges = this._chargeUtil.processCharges(this._charges, this.order, null, {
          value: 1,
        });
        this.charge = await this.updateCharge(this._charges[0]);
      }
    }
  }

  private async updateCharge(charge: any): Promise<any> {
    if (!charge.isPaid) {
      charge.tender = this._tender;
      charge.cart = null;
      charge.shift = this._shift;
    }
    return this._chargeService.update(charge._id, charge).toPromise();
  }

  public dismiss() {
    this._modal.dismiss();
  }

  public async itemSelected(item: any) {
    try {
      const taxes = this._cartUtil.getTaxes(item, item.price, 0);
      const toAdd = this._cartUtil.getBaseItem(
        item,
        item.price,
        1,
        this.location,
        [],
        taxes,
        [],
        null
      );
      await this._orderService.addItem(this.order._id, toAdd).toPromise();
      this.init();
    } catch (e) {
      const message = e.error?.message || 'An unexpected error occurred.';
      this._toastrService.error(message, 'Error');
    }
  }

  public async removeItem(item: any) {
    try {
      await this._orderService.removeItem(this.order._id, item).toPromise();
      this.init();
    } catch (e) {
      const message = e.error?.message || 'An unexpected error occurred.';
      this._toastrService.error(message, 'Error');
    }
  }

  public payment() {
    this.view = 'payment';
  }

  public cancel() {
    this.view = 'items';
  }

  public async tokenize(token: any) {
    const { card } = token;
    this.charge.token = token.id;
    this.charge.paymentType = card.brand;
    this.charge.paymentIntent = null;
    this.charge.tender = this._tender;
    this.charge.location = 'web';
    this.charge.shift = this._shift;
    try {
      if (this.saveCard) {
        await this._chargeService.update(this.charge._id, this.charge).toPromise();
      } else {
        this.charge = await this._chargeService
          .completePayment(this.charge._id, this.charge)
          .toPromise();
      }
      this._modal.close(true);
    } catch (e) {
      const message = e.error ? e.error.message : 'An unexpected error occured.';
      this._toastrService.error(message);
    }
  }

  public async completePayment() {
    this.charge.location = 'web';
    this.charge.shift = this._shift;
    try {
      if (this.charge.paymentIntent) {
        this.charge = await this._chargeService.update(this.charge._id, this.charge).toPromise();
        const paymentIntent = await this._paymentIntentService.confirm(this.charge._id).toPromise();
        // This is merely a backup process
        if (paymentIntent.status === 'requires_capture') {
          await this._paymentIntentService.capture(paymentIntent._id).toPromise();
        }
      } else {
        this.charge = await this._chargeService
          .completePayment(this.charge._id, this.charge)
          .toPromise();
      }
      this._modal.close(true);
    } catch (e) {
      console.log(e);
      const message = e.error ? e.error.message : 'An unexpected error occured.';
      this._toastrService.error(message);
    }
  }

  public async completeCash() {
    try {
      this.charge.isPaid = true;
      this.charge.paymentType = 'cash';
      this.charge.tender = this._tender;
      this.charge.location = 'web';
      this.charge.paymentIntent = null;
      this.charge.token = null;
      this.charge.customer = null;
      await this._chargeService.completeCashPayment(this.charge._id, this.charge).toPromise();
      this._modal.close(true);
    } catch (e) {
      const message = e.error ? e.error.message : 'An unexpected error occured.';
      this._toastrService.error(message);
    }
  }
}
