import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { LocationHelper } from '@app/helpers';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
})
export class AddressInputComponent implements OnChanges {
  @Input() name: string;
  @Input() address: any = {};

  @Output() nameChange = new EventEmitter<string>();
  @Output() addressChange = new EventEmitter<any>();

  public gMapsOptions: any = {
    types: [],
    componentRestrictions: {
      country: 'us',
    },
  };

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.address?.currentValue && changes?.address?.firstChange) {
      this.address = {
        line1: null,
        line2: null,
        city: null,
        state: null,
        zip: null,
        country: null,
        formatted: null,
      };
    }
  }

  change() {
    this.addressChange.emit(this.address);
  }

  addressSearchChange(components: any) {
    this.name = components.name;
    this.nameChange.emit(this.name);
    const address = LocationHelper.getAddressFromComponents(components);
    if (address) {
      Object.assign(this.address, address);
      this.addressChange.emit(this.address);
    }
  }
}
