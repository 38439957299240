import _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(value: Array<any>, args?: any): any {
    if (!_.size(value)) {
      return [];
    }
    return value.filter(item => item.isAdmin === false);
  }
}
