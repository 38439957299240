import { ApiResources, ApiVersion, ClassificationType } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { OrgData } from '../core';
import { ISchema, Type, Widget } from '../schema';

export interface ClassificationSegment {
  type: ClassificationType.SEGMENT;
}
export interface ClassificationGenre {
  type: ClassificationType.GENRE;
  segment?: string;
}
export interface ClassificationSubGenre {
  type: ClassificationType.SUB_GENRE;
  genres?: string[];
}

interface ClassificationBaseData {
  name: string;
  orgId: string | OrgData;
}

export type ClassificationData = ClassificationBaseData &
  (ClassificationSegment | ClassificationGenre | ClassificationSubGenre) &
  AbstractModelData;

export type ClassificationSchemaData = ClassificationBaseData &
  ClassificationSegment &
  ClassificationGenre &
  ClassificationSubGenre;

export const ClassificationSchema: ISchema<ClassificationSchemaData> = {
  type: {
    label: 'Type',
    description:
      '<b class="text-primary">Segment</b> is a primary genre for an entity (Music, Sports, Arts, etc).\n<b class="text-primary">Genre</b> to further describe an entity (Rock, Golf, Animation, etc).\n<b class="text-primary">Sub-Genre</b> for additional detail when describring an entity (Alternative Rock, PGA Tour, etc)',
    type: Type.STRING,
    enum: EnumHelper.getEnumArray(ClassificationType),
    widget: {
      type: Widget.SELECT,
    },
    immutable: true,
    required: true,
  },
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  segment: {
    label: 'Segment',
    description: 'The primary genre this secondary genre belongs to.',
    type: Type.TYPEAHEAD,
    resource: ApiResources.CLASSIFICATIONS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    search: { type: ClassificationType.SEGMENT },
    onlyIf: [
      {
        field: 'type',
        shouldShow: (value: ClassificationType) => value === ClassificationType.GENRE,
      },
    ],
  },
  genres: {
    type: Type.ARRAY,
    label: 'Genres',
    description: 'The secondary genres this tertiary genre belongs to.',
    items: {
      type: Type.TYPEAHEAD,
      label: 'Classification',
      resource: ApiResources.CLASSIFICATIONS,
      version: ApiVersion.V2,
      modelProp: 'name',
      dataProp: 'data.data',
      search: { type: ClassificationType.GENRE },
    },
    onlyIf: [
      {
        field: 'type',
        shouldShow: (value: ClassificationType) => value === ClassificationType.SUB_GENRE,
      },
    ],
  },
  orgId: {
    label: 'Org',
    type: Type.TYPEAHEAD,
    resource: ApiResources.ORGS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    partakeOnly: true,
  },
};

export class Classification extends AbstractModel<ClassificationData> {
  constructor(public data: ClassificationData) {
    super(data);
  }
}
