import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return;
    }
    let split = value.split(' ');
    if (split.length > 2) {
      split = split.splice(0, 2);
    }
    return split.map(val => val.charAt(0).toUpperCase()).join('');
  }
}
