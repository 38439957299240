export enum IntegrationType {
  ECOMM_PAYMENT = 'ecomm-payment',
  PAYMENT = 'payment',
  POS = 'pos',
  LOYALTY = 'loyalty',
  GIFT_CARD = 'gift-card',
  EMAIL = 'email',
  TEXT = 'text',
  PUSH = 'push',
  DNS = 'dns',
}

export enum InstanceType {
  PRODUCTION = 'production',
  SANDBOX = 'sandbox',
}
