import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiClient } from '../../clients';
import { ApiDomain, ApiResources, ApiVersion } from '../../enums';
import { ApiResponse } from '../../models';
import { IntegrationActions, retrievedIntegrations } from './integration.actions';

@Injectable()
export class IntegrationEffects {
  private apiClient: ApiClient;

  loadIntegrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.LOAD_INTEGRATIONS),
      mergeMap((payload: any) =>
        this.apiClient.get({}).pipe(
          map((i9nResult: ApiResponse.LegacySearchResult) =>
            retrievedIntegrations({
              integrations: i9nResult.data,
            })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    httpClient: HttpClient,
    private actions$: Actions
  ) {
    this.apiClient = new ApiClient(httpClient).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.INTEGRATIONS
    );
  }
}
