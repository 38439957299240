import { ApiDomain, ApiResources, ApiVersion, CategoryType } from '../../enums';
import { EnumHelper } from '../../helpers';
import { ISchema, Type, Widget } from '../schema';

export const AddDiscountsToProductsSchema: ISchema = {
  discountIds: {
    section: 'Filters',
    type: Type.STRING,
    label: 'Modifiers',
    widget: {
      type: Widget.SELECT,
    },
    enum: [],
    required: true,
    isHidden: true,
  },
  types: {
    section: 'Filters',
    type: Type.STRING,
    label: 'Types',
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(CategoryType),
    multiple: true,
  },
  categoryIds: {
    section: 'Filters',
    type: Type.STRING,
    label: 'Categories',
    widget: {
      type: Widget.SELECT,
      enum: [],
      multiple: true,
      options: {
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.CATEGORY,
        subCollection: 'self',
        modelProp: 'name',
        dataProp: 'categories',
        search: { sort: 'name', limit: 100 },
        displayFields: ['name'],
      },
    },
  },
};
