import { ISchema, Type } from '../schema';

export const AddressSchema: ISchema = {
  line1: {
    type: Type.STRING,
    label: 'Line 1',
  },
  line2: {
    type: Type.STRING,
    label: 'Line 2',
  },
  city: {
    type: Type.STRING,
    label: 'City',
  },
  state: {
    type: Type.STRING,
    label: 'State',
  },
  zip: {
    type: Type.STRING,
    label: 'Postal Code',
  },
  formatted: {
    type: Type.STRING,
    label: 'Formatted',
    isHidden: true,
  },
};

export interface Address {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  formatted: string;
}
