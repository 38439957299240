import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taxCode',
})
export class TaxCodePipe implements PipeTransform {
  transform(taxCode: any, args?: any): any {
    if (!taxCode) {
      return;
    }

    if (taxCode.type === 'percentage') {
      return `${taxCode.taxRate}%`;
    }
    return `$${taxCode.taxRate}`;
  }
}
