<div class="row no-padding" *ngIf="metaData">
  <div class="col-md-12">
    <div class="mb-3">
      <label>Title</label>
      <input class="form-control" type="text" [(ngModel)]="metaData.title" (change)="change()" />
      <p class="text-muted">Title seen in navigation bars.</p>
    </div>
  </div>
  <div class="col-md-12">
    <div class="mb-3">
      <label>Description</label>
      <input
        class="form-control"
        type="text"
        [(ngModel)]="metaData.description"
        (change)="change()"
      />
      <p class="text-muted">Longer text shown when sharing a page.</p>
    </div>
  </div>
  <div class="col-md-12">
    <div class="mb-3">
      <label>Favicon</label>
      <app-image-filepicker
        #filepicker
        bucket="favicon"
        (selected)="onImageFileSelected('icon', $event)"
        (uploaded)="onImageFileUploaded('icon', $event)"
      ></app-image-filepicker>
    </div>
    <div class="image-container favicon" *ngIf="metaData?.icon">
      <img class="carousel-image" [src]="metaData?.icon.preview || metaData?.icon.url" />
    </div>
  </div>
  <div class="col-md-12">
    <div class="mb-3">
      <label>Image</label>
      <app-image-filepicker
        #filepicker
        bucket="og-image"
        (selected)="onImageFileSelected('image', $event)"
        (uploaded)="onImageFileUploaded('image', $event)"
      ></app-image-filepicker>
    </div>
    <div class="image-container og-image" *ngIf="metaData?.image">
      <img class="carousel-image" [src]="metaData?.image.preview || metaData?.image.url" />
    </div>
  </div>
</div>
