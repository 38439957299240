<input
  type="text"
  class="form-control"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [ngbTypeahead]="searchCategory"
  [resultTemplate]="selectedCategoryTpl"
  [inputFormatter]="categoryFormatter"
  [(ngModel)]="value"
  (ngModelChange)="onChange($event)"
  (selectItem)="selectCategory($event)"
/>

<ng-template #selectedCategoryTpl let-r="result">
  {{ r.name }}
</ng-template>
