import { IntegrationSystem, IntegrationType } from '@app/enums';
import { Integration, IIntegrationBase } from './integration';

export const PayloadSchema = {
  system: {
    type: 'string',
    isHidden: true,
    default: IntegrationSystem.PAYLOAD,
  },
  type: {
    type: 'string',
    isHidden: true,
    default: IntegrationType.PAYMENT,
  },
  processingAccountId: {
    type: 'string',
    required: true,
    label: 'Processing Account ID',
  },
  payloadClientKey: {
    type: 'string',
    required: true,
    label: 'Client Key',
  },
  payloadSecretKey: {
    type: 'password',
    required: true,
    label: 'Secret Key',
  },
};

export interface IPayload extends IIntegrationBase {
  processingAccountId: string;
  payloadClientKey: string;
  payloadSecretKey: string;
}

export class Payload extends Integration<IPayload> {
  constructor(public data: IPayload) {
    super(data);
  }
}
