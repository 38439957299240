<div [ngClass]="{ card: !embeded }" *ngIf="object">
  <div [ngClass]="{ 'card-body': !embeded }">
    <h4 *ngIf="label">{{ label }}</h4>
    <p class="text-muted">Min width 900px, min height 510px</p>
    <div class="mb-3">
      <div class="input-group">
        <app-image-filepicker
          #filepicker
          [bucket]="bucket"
          (selected)="onImageFileSelected($event)"
          (uploaded)="onImageFileUploaded($event)"
        >
        </app-image-filepicker>
      </div>
      <div class="col carousel-container" *ngIf="object && object[prop] && object[prop].length">
        <ngb-carousel
          #carousel
          activeId="0"
          [interval]="3.6e6"
          [showNavigationArrows]="object[prop].length > 1"
          [showNavigationIndicators]="object[prop].length > 1"
          (slide)="setCurrentImage($event)"
        >
          <ng-container *ngFor="let image of object[prop]; let i = index">
            <ng-template ngbSlide [id]="i.toString()">
              <img
                class="carousel-image"
                [src]="image.url"
                [style.opacity]="filepicker.uploadingImageOpacity || 1"
              />
              <p>{{ image.name }} ({{ image.size }})</p>
            </ng-template>
          </ng-container>
        </ngb-carousel>
        <div class="btn-group image-buttons" *ngIf="object[prop].length">
          <button class="btn btn-secondary btn-block" (click)="deleteImage()">Delete Image</button>
        </div>
      </div>
    </div>
  </div>
</div>
