<div class="col">
  <div class="row">
    <div class="col">Subtotal</div>
    <div class="col text-right">
      {{ order?.subTotal * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
    </div>
  </div>
  <div class="row">
    <div class="col">Discount</div>
    <div class="col text-right">
      {{ order?.discount * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
    </div>
  </div>
  <div class="row">
    <div class="col">Taxes</div>
    <div class="col text-right">
      {{ order?.taxTotal * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
    </div>
  </div>
  <div class="row">
    <div class="col">Service Fees</div>
    <div class="col text-right">
      {{ order?.serviceFee * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
    </div>
  </div>
  <div class="row">
    <div class="col">Gratuity</div>
    <div class="col text-right" *ngIf="order?.isConsumerOrder">
      {{ order?.gratuity * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
    </div>
    <div class="col text-right" *ngIf="!order?.isConsumerOrder">
      {{ (order?.gratuity + charge?.gratuity) * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
    </div>
  </div>
  <hr class="separator" />
  <div class="row text-bold">
    <div class="col">TOTAL DUE</div>
    <div class="col text-right">
      {{ order?.total * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
    </div>
  </div>
</div>
