import { MemberStatus } from '../../enums';
import { AbstractModelData } from '../abstract.model';

export interface MemberData extends AbstractModelData {
  fullName: string;
  firstName: string;
  lastName: string;
  memberId: string;
  i9nId?: string;
  userId?: string;
  status: MemberStatus;
  comment: string;
}
