enum ToolsType {
  UN_SYNC_ORDERS = 'un-sync-orders',
}

module ToolsType {
  export function name(type: ToolsType): string {
    switch (type) {
      case ToolsType.UN_SYNC_ORDERS:
        return 'Un-Sync Orders';
      default:
        return 'Unknown';
    }
  }
}

export default ToolsType;
