import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cash',
  templateUrl: './cash.component.html',
  styleUrls: ['./cash.component.scss'],
})
export class CashComponent {
  @Input() charge: any;

  @Output() complete = new EventEmitter<any>();

  public changeDue = 0;
  public amountTendered = 0;

  constructor(private _toastrService: ToastrService) {}

  public change(value: number) {
    this.amountTendered = value;
    this.changeDue = this.amountTendered - this.charge.total;
  }

  public chargeCash() {
    if (this.amountTendered < this.charge.total) {
      this._toastrService.warning(
        'Please ensure the cash tendered is greater than or equal to the amount due.'
      );
    } else {
      this.complete.emit();
    }
  }
}
