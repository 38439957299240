import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { ApiQuery, ApiQueryParams } from '@app/clients';

@Injectable({
  providedIn: 'root',
})
export class TenderMapService {
  private domain: string;
  private path = 'tender-map';
  private baseUrl: string;

  constructor(private _http: HttpClient) {
    this.domain = environment.url;
    this.baseUrl = `${this.domain}/${this.path}`;
  }

  public find(query: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this._http.get<any>(`${this.baseUrl}`, { params });
  }

  public self(): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/self`);
  }

  public create(body: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}`, body);
  }

  public update(id: string, body: any): Observable<any> {
    return this._http.put(`${this.baseUrl}/${id}`, body);
  }
}
