import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import { values } from 'underscore';

@Pipe({
  name: 'camelCaseToSpaces',
})
export class CamelCaseToSpacesPipe implements PipeTransform {
  transform(value: string, titleCase?: boolean): string {
    if (!values) {
      return;
    }
    if (!_.isString(value)) {
      return value;
    }
    let transformed = value.replace(/([A-Z])/g, ' $1');
    // uppercase the first character
    if (titleCase) {
      transformed = transformed.replace(/^./, char => char.toUpperCase());
    }
    return transformed;
  }
}
