import _ from 'lodash';
import { EventData } from '../events';
import { ShiftData } from '../operations';
import { ExportType, ReportType, UserRoles } from '../../enums';
import { TimeHelper } from '../../helpers';
import { ISchema, Type } from '../schema';

export interface ReportData {
  [key: string]: any;
}

export interface EventReportParams {
  eventIds: string[];
  events: EventData[];
}

export interface ShiftReportParams {
  shiftIds: ShiftData[];
}

export interface ReportPeriodParams {
  from: string;
  to?: string;
  title?: string;
  sections?: string[];
}

export type ReportParams = EventReportParams | ShiftReportParams | ReportPeriodParams;

export interface IReport<T> {
  icon: string;
  data: T;
  schema: ISchema;
  roles: UserRoles[];
  type: ReportType;
  exportOptions: ExportType[];
}

export interface ValidationResponse {
  errors: Array<string>;
  isValid: boolean;
}

export abstract class Report<T> implements IReport<T> {
  protected abstract readonly ignoreProperties: string[];
  public abstract readonly icon: string;
  public abstract readonly title: string;
  public abstract readonly description: string;
  public abstract readonly isImmediate: boolean;
  public abstract readonly schema: ISchema;
  public abstract readonly roles: UserRoles[];
  public abstract readonly type: ReportType;
  public abstract readonly exportOptions: ExportType[];
  public abstract data: T;

  // eslint-disable-next-line class-methods-use-this
  public generateExport(period: ReportParams, data: any) {
    throw new Error('Method has not been implemented yet');
  }

  public validate(): ValidationResponse {
    const properties = _.keys(this.schema);
    const errors: Array<string> = [];
    _.forEach(properties, (prop: string) => {
      if (!_.get(this.data, prop) && this.schema[prop].required) {
        errors.push(`Missing required field ${this.schema[prop].label}`);
      }
    });
    return { errors, isValid: !!_.isEmpty(errors) };
  }

  public getQueryParams(exportType: ExportType): { [key: string]: any } {
    const properties = _.keys(this.schema);
    return _.reduce(
      properties,
      (prev: { [key: string]: any }, prop: string) => {
        // Sections are report sections
        if (this.ignoreProperties?.includes(prop)) {
          return prev;
        }
        if (!this.data[prop]) {
          return prev;
        }
        if (_.includes([Type.DATE], this.schema[prop].type)) {
          if (this.data[prop]) {
            prev[prop] = TimeHelper.getDateString(this.data[prop]);
          }
          return prev;
        }
        if (_.includes([Type.DATE_TIME], this.schema[prop].type)) {
          prev[prop] = this.data[prop]?.toISOString();
          return prev;
        }
        if (Type.ARRAY === this.schema[prop].type) {
          const schemaField = this.schema[prop] as any;
          if (schemaField.items?.type === Type.TYPEAHEAD) {
            prev[prop] = this.data[prop].map((arrayData: any) => _.get(arrayData, '_id'));
            return prev;
          }
          prev[prop] = this.data[prop]?.join(',');
          return prev;
        }
        if (_.includes([Type.TYPEAHEAD], this.schema[prop].type)) {
          prev[prop] = _.get(this.data[prop], '_id');
          return prev;
        }
        prev[prop] = this.data[prop];
        return prev;
      },
      { format: exportType }
    );
  }
}
