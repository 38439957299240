import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-total',
  templateUrl: './payment-total.component.html',
  styleUrls: ['./payment-total.component.scss'],
})
export class PaymentTotalComponent {
  @Input() order: any;
  @Input() charge: any;
}
