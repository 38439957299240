<div class="input-group picker">
  <input
    type="file"
    multiple
    class="form-control"
    [id]="id"
    [(ngModel)]="file"
    [ngModelOptions]="{ standalone: true }"
    [disabled]="disabled || isUploading"
    (change)="onFilePicked($event)"
  />
</div>

<div *ngIf="isUploading && progress" class="progress-container mt-2">
  <div>Uploading...</div>
  <div class="progress ml-2" *ngIf="uploadProgress$ | async as progress">
    <div
      class="progress-bar bg-success"
      role="progressbar"
      [style.width.%]="progress"
      aria-valuemin="0"
      aria-valuemax="100"
      [attr.aria-valuenow]="progress"
    >
      {{ progress }}%
    </div>
  </div>
</div>
