import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent {
  @Output() action = new EventEmitter<boolean>();

  @Input() disabled: boolean;

  @Input() buttonText: string;

  @Input() loading: boolean;

  @Input() color = 'secondary';

  @Input() size = 'md';

  public takeAction() {
    this.action.emit(true);
  }
}
