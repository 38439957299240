/* eslint-disable import/prefer-default-export */
enum AppName {
  ADMIN_APP = 'partake-everywhere',
  CONSUMER_APP = 'partake-consumer-web',
  POS_APP = 'partake-ionic-pos-app',
}

module AppName {
  export function name(appName: AppName): string {
    switch (appName) {
      case AppName.ADMIN_APP:
        return 'Admin';
      case AppName.CONSUMER_APP:
        return 'Consumer';
      case AppName.POS_APP:
        return 'POS';
      default:
        return 'Unknown';
    }
  }
}

export default AppName;
