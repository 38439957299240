<div class="col">
  <div class="col-md-12">
    <label>Key In Details</label>
  </div>
  <div class="form-horizontal form-material">
    <div class="mb-3">
      <input
        type="text"
        placeholder="Name on Card"
        [(ngModel)]="name"
        (ngModelChange)="canSubmit()"
        name="name"
        class="form-control form-control-line"
      />
    </div>
    <div class="stripe-elements" id="card-element">
      <!-- A Stripe Element will be inserted here. -->
    </div>
  </div>
  <div class="col no-padding mt-3">
    <button class="btn btn-secondary btn-block" [disabled]="!isComplete" (click)="tokenizeCard()">
      <span *ngIf="!saveCard">Complete Payment</span>
      <span *ngIf="saveCard">Save Card</span>
    </button>
  </div>
</div>
