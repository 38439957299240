import { createSelector } from '@ngrx/store';
import { Org } from '../../models';
import { AppState } from './app.state';

export const selectOrgs = createSelector(
  (state: AppState) => state.orgs,
  (orgs: Org[]) => orgs
);

export const selectOrg = createSelector(
  (state: AppState) => state.selectedOrg,
  (selectedOrg: Org) => selectedOrg
);
