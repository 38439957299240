import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'a[href]', // eslint-disable-line @angular-eslint/directive-selector
})
export class PassiveLinkDirective {
  @Input()
  href: string;

  @HostListener('click', ['$event'])
  clickHandler = this.onClick;

  constructor() {}

  onClick(event: Event): void {
    if (this.href === '' || this.href === '#') {
      event.preventDefault();
    }
  }
}
