import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
})
export class FilePickerComponent {
  @Input() multiple = false;

  @Input() data: any = {};

  @Input() prop: string;

  @Input() disabled: boolean;

  public label = 'Choose File';

  public file: File;

  constructor() {}

  private getLabel(fileList: FileList): string {
    const names: string[] = [];
    for (let i = 0; i < fileList.length; i++) {
      if (!this.multiple && i) {
        break;
      }
      const file = fileList[i];
      names.push(file.name);
    }
    return names.join(', ');
  }

  public async onFilePicked(ev: Event) {
    const input = ev.target as HTMLInputElement;
    const files = input.files;
    if (!files?.length) {
      return;
    }
    if (this.multiple) {
      this.data[this.prop] = files;
    } else {
      this.data[this.prop] = files[0];
    }
    this.label = this.getLabel(files);
  }
}
