import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthService } from '@app/providers/auth/auth.service';
import { from } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiHeader, MenuFeature } from '../enums';
import { LocalStorage } from '../helpers';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private orgId: string;

  private venueId: string;

  private viewMode: string;

  private liveMode: string;

  constructor(private authService: AuthService) {}

  private async init() {
    this.orgId = await LocalStorage.getItem(ApiHeader.ORG_ID);
    this.venueId = await LocalStorage.getItem(ApiHeader.VENUE_ID);
    this.viewMode = await LocalStorage.getItem('view-mode');
    this.liveMode = (await LocalStorage.getItem(ApiHeader.LIVE_MODE)) || 'true';
  }

  private async handle(req: HttpRequest<any>, next: HttpHandler) {
    await this.init();

    const authToken = this.authService.getToken();
    let headers = req.headers
      .set(ApiHeader.API_VERSION, environment.apiVersion)
      .set(ApiHeader.APP_NAME, environment.app)
      .set(ApiHeader.APP_VERSION, environment.appVersion)
      .set(ApiHeader.APP_SCOPE, 'partake')
      .set(ApiHeader.APP_PATH, window.location.pathname)
      .set(ApiHeader.LIVE_MODE, this.liveMode)
      .set(ApiHeader.MENU_FEATURE, `${MenuFeature.TAX_INCLUDED}`);

    if (authToken) {
      headers = headers.set(ApiHeader.AUTH_TOKEN, authToken);
    }
    if (this.orgId) {
      headers = headers.set(ApiHeader.ORG_ID, this.orgId);
    }
    if (this.venueId) {
      headers = headers.set(ApiHeader.VENUE_ID, this.venueId);
    }
    if (this.viewMode) {
      headers = headers.set(ApiHeader.APP_VIEW_MODE, this.viewMode);
    }
    const authRequest = req.clone({ headers });
    return next.handle(authRequest).toPromise();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return from(this.handle(req, next));
  }
}
