import { IImageFile } from '@partake/interfaces';
import { ApiDomain, ApiResources, ApiVersion } from '../../enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type } from '../schema';
import { Competition, CompetitionData } from './competition.model';

export const ContestantSchema: ISchema = {
  name: {
    label: 'Name e.g. New York Yankees',
    type: Type.STRING,
    required: true,
  },
  shortName: {
    label: 'Short Name e.g. Yankees',
    type: Type.STRING,
    required: true,
  },
  officialName: {
    label: 'Official Name e.g. New York Yankees',
    type: Type.STRING,
    required: true,
  },
  code: {
    label: 'Code e.g. NYY',
    type: Type.STRING,
    required: true,
  },
  competitionId: {
    type: Type.TYPEAHEAD,
    label: 'Competition',
    domain: ApiDomain.API,
    version: ApiVersion.V2,
    resource: ApiResources.COMPETITION,
    modelProp: 'name',
    displayFields: ['name', 'competitionCode'],
    dataProp: 'data.data',
    required: true,
  },
  logo: {
    section: 'images',
    type: Type.IMAGES,
    label: 'Logo',
    bucket: 'contestant',
  },
};

export interface ContestantData extends AbstractModelData {
  name: string;
  shortName: string;
  officialName: string;
  code: string;
  competitionId: string | Competition | CompetitionData;
  logo: IImageFile[];
}

export class Contestant extends AbstractModel<ContestantData> {
  constructor(public data: ContestantData) {
    super(data);
  }
}
