import { PickupLocationType, DiningOptionsBehavior, DeliveryLocationType } from '../../enums';
import {
  IAsapSchedule,
  IDeliveryOptions,
  DiningOptionData,
  IFutureSchedule,
  IFutureScheduleTimeGapConfig,
  IPickupLocation,
} from '../../models';

export function blankDeliveryOption(): IDeliveryOptions {
  return {
    name: null,
    type: DeliveryLocationType.OTHER,
    // For now this property won't be used until a delivery integration is provided
    deliveryProvider: null,
    options: [],
  };
}

export function blankPickupLocation(): IPickupLocation {
  return {
    name: null,
    locationType: PickupLocationType.ADDRESS,
    address: {
      line1: null,
      line2: null,
      city: null,
      state: null,
      zip: null,
      formatted: null,
    },
    description: null,
  };
}

export function blankAsapSchedule(): IAsapSchedule {
  return {
    availableNow: false,
    availableAt: null,
    intervalMin: 15,
    intervalMax: 30,
  };
}

export function blankTimeGapConfig(): IFutureScheduleTimeGapConfig {
  return {
    daysOfWeek: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    customDaySchedule: [],
    minDaysBefore: 0,
    skipTimeRequirement: false,
    timeGapDaysAhead: 7,
    timeGapMinutes: 15,
    timeGapStartHour: 9,
    timeGapStartMinute: 0,
    timeGapEndHour: 23,
    timeGapEndMinute: 0,
  };
}

export function blankFutureSchedule(): IFutureSchedule {
  return {
    dates: [],
    timeGapConfig: blankTimeGapConfig(),
    exclusionDates: [],
  };
}

export function blankDiningOption(): DiningOptionData {
  return {
    name: 'Default PICKUP',
    behaviour: DiningOptionsBehavior.PICKUP,
    asapSchedule: blankAsapSchedule(),
    futureSchedule: blankFutureSchedule(),
    deliveryOptions: blankDeliveryOption(),
    pickupLocation: blankPickupLocation(),
    requiredGuestFields: [],
  } as DiningOptionData;
}
