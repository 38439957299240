import {
  Directive,
  ElementRef,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

/**
 * # Ways to turn off autoselect: any js-falsely value, except empty string
 *
 *     <!-- with data binding -->
 *     <input [autoselect]=""> <!-- undefined value -->
 *     <input [autoselect]="undefined">
 *     <input [autoselect]="false">
 *     <input [autoselect]="null">
 *     <input [autoselect]="0">
 *
 *     <!-- without data binding -->
 *     <input autoselect="undefined">
 *     <input autoselect="false">
 *     <input autoselect="null">
 *     <input autoselect="0">
 *
 *     <input> <!-- disabled by default -->
 *
 *
 * # Ways to enable autoselect: any js-true value and empty string
 *
 *
 *     <!-- empty string will enable autoselect, this is default html behavior -->
 *     <input [autoselect]="''">
 *     <input autoselect="">
 *     <input autoselect>
 *
 *     <input [autoselect]="true">
 *     <input autoselect="true">
 *
 *     <input [autoselect]="'any other values'">
 *
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[autoselect]',
})
export class AutoselectDirective implements AfterViewInit, OnChanges {
  @Input()
  public set autoselect(value: any) {
    this._autoselect =
      value !== false &&
      value !== null &&
      value !== undefined &&
      value !== 0 &&
      value !== 'false' &&
      value !== 'null' &&
      value !== 'undefined' &&
      value !== '0';
  }

  private _autoselect = true;

  public constructor(private el: ElementRef) {}

  public ngAfterViewInit() {
    this.handleAutoselect();
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.handleAutoselect();
  }

  private handleAutoselect() {
    if (this._autoselect) {
      const el: HTMLInputElement = this.el.nativeElement;

      const inputElements = ['INPUT', 'SELECT', 'TEXTAREA'];
      const input: HTMLInputElement =
        el.focus && inputElements.includes(el.nodeName)
          ? el
          : el.querySelector(inputElements.join(',')) || el;

      if (input.select) {
        input.select();
      } else {
        console.warn('AutoselectDirective: Element is not selectable:', this.el.nativeElement);
      }
    }
  }
}
