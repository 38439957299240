export enum PrinterStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  COVER_OPEN = 'cover-open',
  OUT_OF_PAPER = 'out-of-paper',
  PAPER_LOW = 'paper-low',
  ERROR = 'error',
  UNKNOWN = 'unknown',
}

export enum PrinterJobStatus {
  PENDING = 'pending',
  PRINTING = 'printing',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum PrinterFulfillmentMode {
  LOCATION = 'location',
  STATION = 'station',
  LOCATION_STATION = 'location-station',
}
