import { ExportType, OrderStatus, ReportType, UserRoles } from '@app/enums';
import { ISchema } from '../schema';
import { Report } from './report';
import { BaseReportProps } from './base-report-props';

export const OrdersReportSchema: ISchema = {
  status: BaseReportProps.status,
  restaurant: BaseReportProps.restaurant,
  isFutureOrder: BaseReportProps.isFutureOrder,
  consumerOnly: BaseReportProps.consumerOnly,
  from: BaseReportProps.from,
  to: BaseReportProps.to,
  email: BaseReportProps.email,
};

export interface IOrdersReportData {
  status: OrderStatus;
  restaurant: string;
  isFutureOrder: boolean;
  consumerOnly: boolean;
  from: string;
  to: string;
}

export class OrdersReport extends Report<IOrdersReportData> {
  ignoreProperties = [];
  icon = 'fas fa-shopping-basket';
  title = 'Orders Export';
  description = 'Export orders and all related information. Each order will be in its own tab.';
  isImmediate = false;
  data: IOrdersReportData = {} as IOrdersReportData;
  schema: ISchema = OrdersReportSchema;
  roles: UserRoles[] = [UserRoles.ADMIN, UserRoles.CUSTOMER];
  type: ReportType = ReportType.ORDERS;
  exportOptions: ExportType[] = [ExportType.EXCEL];
}
