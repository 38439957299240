import { UserType } from '../../enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type } from '../schema';
import { Address, AddressSchema } from './address';
import { LocationGroup, LocationGroupData } from './location-group';
import { UserRole, UserRoleData } from './user-role';

export const UserSchema: ISchema = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  email: {
    label: 'Email',
    type: Type.EMAIL,
    required: true,
    immutable: true,
  },
  phone: {
    label: 'Phone',
    type: Type.PHONE,
  },
  address: {
    label: 'Address',
    type: Type.OBJECT,
    properties: AddressSchema,
  },
};

export const OrgUserUpdateSchema: ISchema = {
  name: UserSchema.name,
  phone: UserSchema.phone,
  address: UserSchema.address,
};

export interface OrgMembership {
  orgId: string;
  orgName: string;
  orgSlug: string;
  locationGroupId: string | LocationGroupData;
  userRoleId: string | UserRoleData;
  venueId?: string;
}

export interface UserData extends AbstractModelData {
  name: string;
  username?: string;
  email: string;

  type: UserType;

  // This is only for customer users
  scope?: string;

  // This is only for org users
  orgMemberships?: OrgMembership[];

  /**
   * @deprecated
   */
  orgId?: string;

  /**
   * Phone Info
   */
  phone?: string;
  countryCode?: string;

  address?: Address;

  timeZone?: string;

  isAdmin?: boolean;
}

export class User extends AbstractModel<UserData> {
  constructor(public data: UserData) {
    super(data);
  }

  public getRole(locationGroup: LocationGroup): string {
    const orgMembership = this.data.orgMemberships.find(
      (membership: OrgMembership) => membership.locationGroupId === locationGroup.id
    );
    if (!orgMembership) {
      return 'Unknown';
    }
    const userRole = new UserRole(orgMembership.userRoleId as UserRoleData);
    return userRole.displayName;
  }
}
