import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../../providers/user/user.service';
import { ViewJsonModalController } from '../../view-json-modal/view-json-modal.controller';

@Component({
  selector: 'app-view-json-button',
  templateUrl: './view-json-button.component.html',
  styleUrls: ['./view-json-button.component.scss'],
})
export class ViewJsonButtonComponent implements OnInit {
  @Input() data: any;

  @Input() expanded = true;

  public user: any;

  constructor(
    private userService: UserService,
    private viewJsonModalCtrl: ViewJsonModalController
  ) {}

  ngOnInit() {
    this.user = this.userService.getUser();
  }

  async viewJson() {
    await this.viewJsonModalCtrl.show(this.data, this.expanded);
  }
}
