import _ from 'lodash';
import {
  AccountingCodeType,
  ApiDomain,
  ApiResources,
  ApiVersion,
  InventoryTrackingType,
  ProductType,
} from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModelData } from '../abstract.model';
import { IImage } from '../apps';
import { MenuItemData } from '../concession';
import { ISchema, Type, Widget } from '../schema';
import { PriceOptionData, PriceOptionSchema } from './price-option';

export interface StandSheetParValues {
  standSheetId: string;
  quantity: number;
}

export interface ProductSchemaData {
  name: string;
  type: ProductType;
  description: string;
  category: string;
  images: IImage[];
  isAlcoholic?: boolean;
  isParent?: boolean;
  parentProductId?: string;
  isOpen?: boolean;
  priceOptions?: PriceOptionData[];
  trackingType: InventoryTrackingType;
  trackingLocations: string[];
  parValuesByStandSheetId: StandSheetParValues[];
  sku: string;
  taxCodes: string[];
  accountCode: string;
  statCode: string;
  classCode: string;
  vendorCode: string;
}

export type ProductData = {
  ingredients?: any[];
} & ProductSchemaData &
  AbstractModelData;

export type PopulatedProductData = ProductData & {
  menuItems: MenuItemData[];
};

export const ProductSchema: ISchema<ProductSchemaData> = {
  type: {
    section: 'Basic Info',
    side: 'left',
    type: Type.STRING,
    label: 'Type',
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(ProductType),
    required: true,
  },
  isParent: {
    section: 'Basic Info',
    side: 'left',
    type: Type.BOOLEAN,
    label: 'Is Parent Item',
    description: 'A parent item can only be one which is synced',
    default: false,
    isHidden: true,
  },
  parentProductId: {
    section: 'Basic Info',
    side: 'left',
    label: 'Parent Product',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.PRODUCT,
        subCollection: 'self',
        modelProp: 'name',
        dataProp: 'data.data',
        search: { sort: 'name', limit: 100, isParent: true },
        displayFields: ['name'],
      },
    },
    notIf: 'isParent',
    isHidden: true,
  },
  category: {
    section: 'Basic Info',
    side: 'left',
    label: 'Category',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.CATEGORY,
        subCollection: 'self',
        modelProp: 'name',
        dataProp: 'categories',
        search: { sort: 'name', limit: 100 },
        displayFields: ['name'],
      },
    },
  },
  name: {
    section: 'Basic Info',
    side: 'left',
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  description: {
    section: 'Basic Info',
    side: 'left',
    type: Type.STRING,
    label: 'Description',
    notIf: 'isParent',
  },
  isAlcoholic: {
    section: 'Basic Info',
    side: 'left',
    type: Type.BOOLEAN,
    label: 'Alcoholic',
    description: 'Set if the product is alcoholic.',
    onlyIf: [
      {
        field: 'isParent',
        shouldShow: (isParent: boolean) => !isParent,
      },
      {
        field: 'type',
        shouldShow: (type: ProductType) =>
          [ProductType.MENU_ITEM, ProductType.MODIFIER].includes(type),
      },
    ],
  },
  isOpen: {
    section: 'Pricing',
    side: 'left',
    type: Type.BOOLEAN,
    label: 'Open Price',
    description: 'Set if the product has an open price.',
    onlyIf: [
      {
        field: 'isParent',
        shouldShow: (isParent: boolean) => !isParent,
      },
      {
        field: 'type',
        shouldShow: (type: ProductType) =>
          [ProductType.MENU_ITEM, ProductType.GOLF, ProductType.MERCHANDISE].includes(type),
      },
    ],
  },
  priceOptions: {
    section: 'Pricing',
    side: 'left',
    type: Type.ARRAY,
    label: 'Price Options',
    description:
      'Set multiple price options and a default set of modifier groups for each price option e.g. Single, Double or Sandwich, Combo',
    items: {
      type: Type.OBJECT,
      label: null,
      properties: PriceOptionSchema,
    },
    onlyIf: [
      {
        field: 'isParent',
        shouldShow: (isParent: boolean) => !isParent,
      },
      {
        field: 'type',
        shouldShow: (type: ProductType) =>
          [
            ProductType.MENU_ITEM,
            ProductType.GOLF,
            ProductType.MODIFIER,
            ProductType.MERCHANDISE,
          ].includes(type),
      },
      {
        field: 'isOpen',
        shouldShow: (isOpen: boolean) => !isOpen,
      },
    ],
  },
  trackingType: {
    section: 'Inventory Tracking',
    side: 'right',
    type: Type.STRING,
    label: 'Track for Locations',
    description: 'Only enable inventory tracking if you are tracking physical inventory values.',
    default: () => InventoryTrackingType.NONE,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(InventoryTrackingType),
    },
    onlyIf: [
      {
        field: 'isParent',
        shouldShow: (isParent: boolean) => !isParent,
      },
      {
        field: 'type',
        shouldShow: (type: ProductType) =>
          [ProductType.MENU_ITEM, ProductType.MERCHANDISE, ProductType.MODIFIER].includes(type),
      },
    ],
  },
  trackingLocations: {
    section: 'Inventory Tracking',
    side: 'right',
    type: Type.ARRAY,
    label: 'Track for Locations',
    items: {
      type: Type.STRING,
      label: 'Location',
      widget: {
        type: Widget.SELECT,
        multiple: true,
        enum: [],
        options: {
          preserveModel: true,
          domain: ApiDomain.API,
          version: ApiVersion.V2,
          resource: ApiResources.LOCATION,
          modelProp: 'name',
          search: { type: 'restaurant,pro-shop,store', limit: 100 },
          displayFields: ['name'],
        },
      },
    },
    onlyIf: [
      {
        field: 'isParent',
        shouldShow: (isParent: boolean) => !isParent,
      },
      {
        field: 'trackingType',
        shouldShow: (trackingType: InventoryTrackingType) =>
          [
            InventoryTrackingType.INCLUDE_LOCATIONS,
            InventoryTrackingType.EXCLUDE_LOCATIONS,
          ].includes(trackingType),
      },
    ],
  },
  parValuesByStandSheetId: {
    section: 'Inventory Tracking',
    side: 'right',
    type: Type.ARRAY,
    label: 'Par Values for Stand Sheets',
    items: {
      label: 'Stand Sheet Par Value',
      type: Type.OBJECT,
      properties: {
        standSheetId: {
          side: 'left',
          type: Type.STRING,
          label: 'Stand Sheet',
          hideInList: true,
          widget: {
            type: Widget.SELECT,
            enum: [],
            options: {
              domain: ApiDomain.API,
              version: ApiVersion.V2,
              resource: ApiResources.STAND_SHEET,
              modelProp: 'name',
              dataProp: 'data.data',
              search: { limit: 100 },
              displayFields: ['name'],
            },
          },
          onChange: (propValue: any, data: any, sectionInfo: any) => {
            const optionsByKey = _.keyBy(sectionInfo.options, 'key');
            data.normalizedStandSheetName = optionsByKey[propValue].name;
          },
        },
        normalizedStandSheetName: {
          side: 'left',
          type: Type.STRING,
          label: 'Stand Sheet',
          isHidden: true,
          showInList: true,
        },
        quantity: {
          side: 'right',
          type: Type.NUMBER,
          label: 'Quantity',
        },
      },
    },
    onlyIf: [
      {
        field: 'isParent',
        shouldShow: (isParent: boolean) => !isParent,
      },
      {
        field: 'trackingType',
        shouldShow: (trackingType: InventoryTrackingType) =>
          ![InventoryTrackingType.NONE].includes(trackingType),
      },
    ],
  },
  sku: {
    section: 'Inventory Tracking',
    side: 'right',
    type: Type.STRING,
    label: 'SKU',
    notIf: 'isParent',
  },
  taxCodes: {
    section: 'Taxes',
    side: 'left',
    type: Type.ARRAY,
    label: 'Product Taxes',
    widget: {
      type: Widget.SELECT,
      multiple: true,
      enum: [],
      options: {
        preserveModel: true,
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.TAX_CODES,
        modelProp: 'name',
        dataProp: 'data.data',
        search: { limit: 100, sort: 'name' },
        displayFields: ['name', 'taxRate'],
      },
    },
    onlyIf: [
      {
        field: 'isParent',
        shouldShow: (isParent: boolean) => !isParent,
      },
      {
        field: 'type',
        shouldShow: (type: ProductType) =>
          [
            ProductType.MENU_ITEM,
            ProductType.GOLF,
            ProductType.MERCHANDISE,
            ProductType.MODIFIER,
          ].includes(type),
      },
    ],
  },
  images: {
    section: 'images',
    side: 'right',
    type: Type.IMAGES,
    label: 'Product Images',
    bucket: 'product',
    onlyIf: [
      {
        field: 'isParent',
        shouldShow: (isParent: boolean) => !isParent,
      },
      {
        field: 'type',
        shouldShow: (type: ProductType) =>
          [ProductType.MENU_ITEM, ProductType.MERCHANDISE].includes(type),
      },
    ],
  },
  accountCode: {
    section: 'Accounting Codes',
    side: 'left',
    label: 'Account Code',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        resource: ApiResources.ACCOUNTING_CODE,
        version: ApiVersion.V2,
        viewAsMode: 'admin',
        modelProp: 'name',
        displayFields: ['name', 'code'],
        dataProp: 'data.data',
        search: {
          type: AccountingCodeType.ACCOUNT,
          sort: 'name',
        },
        notIf: 'isParent',
      },
    },
  },
  statCode: {
    section: 'Accounting Codes',
    side: 'left',
    label: 'Stat Code',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        resource: ApiResources.ACCOUNTING_CODE,
        version: ApiVersion.V2,
        viewAsMode: 'admin',
        modelProp: 'name',
        displayFields: ['name', 'code'],
        dataProp: 'data.data',
        search: {
          type: AccountingCodeType.STAT,
          sort: 'name',
        },
        notIf: 'isParent',
      },
    },
  },
  classCode: {
    section: 'Accounting Codes',
    side: 'left',
    label: 'Class Code',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        resource: ApiResources.ACCOUNTING_CODE,
        version: ApiVersion.V2,
        viewAsMode: 'admin',
        modelProp: 'name',
        displayFields: ['name', 'code'],
        dataProp: 'data.data',
        search: {
          type: AccountingCodeType.CLASS,
          sort: 'name',
        },
        notIf: 'isParent',
      },
    },
  },
  vendorCode: {
    section: 'Accounting Codes',
    side: 'left',
    label: 'Vendor Code',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        resource: ApiResources.ACCOUNTING_CODE,
        version: ApiVersion.V2,
        viewAsMode: 'admin',
        modelProp: 'name',
        displayFields: ['name', 'code'],
        dataProp: 'data.data',
        search: {
          type: AccountingCodeType.VENDOR,
          sort: 'name',
        },
        notIf: 'isParent',
      },
    },
  },
};
