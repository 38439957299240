import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inventoryFilter',
})
export class InventoryFilterPipe implements PipeTransform {
  transform(filter: any): string {
    let value = '';
    switch (filter.property) {
      case 'product.name':
        value = `Name contains ${filter.value}`;
        break;
      case 'location':
        if (filter.selected) {
          const concession = filter.selected;
          value = `Concession is ${concession.name}`;
        } else {
          value = `Concession is ${filter.value}`;
        }
        break;
      case 'product.category.type':
        value = `Type is ${filter.value}`;
        break;
      case 'product.category':
        if (filter.selected) {
          const category = filter.selected;
          value = `Category is ${category.name} - ${category.type}`;
        } else {
          value = `Category is ${filter.value}`;
        }
        break;
      case 'SKU':
        value = `SKU is ${filter.value}`;
        break;
      case 'isTracked':
        value = filter.value ? 'Is Tracked' : 'Is Not Tracked';
        break;
      default:
        value = `${filter.property} is ${filter.value}`;
        break;
    }
    return value;
  }
}
