import { IImageFile } from '@partake/interfaces';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type } from '../schema';

export const CompetitionSchema: ISchema = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  competitionCode: {
    label: 'Code e.g. NLF',
    type: Type.STRING,
    required: true,
  },
  logo: {
    section: 'images',
    type: Type.IMAGES,
    label: 'Logo',
    bucket: 'competition',
  },
};

export interface CompetitionData extends AbstractModelData {
  name: string;
  competitionCode: string;
  logo: IImageFile[];
}

export class Competition extends AbstractModel<CompetitionData> {
  constructor(public data: CompetitionData) {
    super(data);
  }
}
