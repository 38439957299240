import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type } from '../schema';

export interface LocationCategorySchemaData {
  name: string;
  slug: string;
  venue?: string;
  orgId?: string;
}

export const LocationCategorySchema: ISchema<LocationCategorySchemaData> = {
  name: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  slug: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Slug',
    isHidden: true,
  },
  orgId: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Org',
    isHidden: true,
  },
  venue: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Venue',
    isHidden: true,
  },
};

export type LocationCategoryData = LocationCategorySchemaData & AbstractModelData;

/**
 * @todo - Remove patch once all locations have correct info
 */
export class LocationCategory extends AbstractModel<LocationCategoryData> {
  constructor(public data: LocationCategoryData) {
    super(data);
  }
}
