import _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
  name: 'ptTitlecase',
})
export class PtTitleCasePipe implements PipeTransform {
  constructor(private _titlecase: TitleCasePipe) {}

  transform(value: any): string {
    if (!value) {
      return;
    }
    // uppercase the first character
    if (_.isString(value)) {
      return this._titlecase.transform(value);
    }
    if (_.isBoolean(value)) {
      return value ? 'Yes' : 'No';
    }
    return value;
  }
}
