import { TenderType, ApiResources, ApiVersion, TenderProcedure } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { OrgData } from '../core';
import { ISchema, Type, Widget } from '../schema';

export const TenderSchema: ISchema = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  type: {
    label: 'Tender Type',
    type: Type.STRING,
    enum: EnumHelper.getEnumArray(TenderType),
    widget: {
      type: Widget.SELECT,
    },
    immutable: true,
    required: true,
  },
  procedure: {
    label: 'Tender Procedure',
    type: Type.STRING,
    enum: EnumHelper.getEnumArray(TenderProcedure),
    widget: {
      type: Widget.SELECT,
    },
    immutable: true,
    required: true,
  },
  orgId: {
    label: 'Org',
    type: Type.TYPEAHEAD,
    resource: ApiResources.ORGS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    isHidden: true,
  },
};

export interface TenderData extends AbstractModelData {
  name: string;
  type: TenderType;
  procedure: TenderProcedure;
  orgId: string | OrgData;
}

export class Tender extends AbstractModel<TenderData> {
  constructor(public data: TenderData) {
    super(data);
  }
}
