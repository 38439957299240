import { createReducer, on } from '@ngrx/store';
import { Venue } from '../../models';
import { selectedVenue } from './venue.actions';

export const selectedVenueInitialState: Readonly<Venue> = null;

export const selectedVenueReducer = createReducer(
  selectedVenueInitialState,
  on(selectedVenue, (state, { selectedVenue: venue }) => venue)
);
