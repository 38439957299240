import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { ApiResources, ApiVersion } from '../../enums';

export interface PosButton {
  text: string;
  backgroundColor: string;
  textColor: string;
}

export interface MenuSectionSchemaData {
  name: string;
  displayName: string;
  posButton: PosButton;
  menuItemIds: string[];
  isActive: boolean;
}

export const MenuSectionSchema: ISchema<MenuSectionSchemaData> = {
  name: {
    section: 'Basic Info',
    side: 'left',
    type: Type.STRING,
    label: 'Name',
  },
  displayName: {
    section: 'Basic Info',
    side: 'left',
    type: Type.STRING,
    label: 'Display Name',
    description: 'For display in menus',
  },
  isActive: {
    section: 'Basic Info',
    side: 'left',
    type: Type.BOOLEAN,
    label: 'Active',
    default: true,
  },
  posButton: {
    section: 'POS Settings',
    label: 'POS Button',
    side: 'left',
    type: Type.OBJECT,
    properties: {
      text: {
        label: 'Text',
        type: Type.STRING,
      },
      backgroundColor: {
        label: 'Background Color',
        type: Type.COLOR,
      },
      textColor: {
        label: 'Text Color',
        type: Type.COLOR,
      },
    },
  },
  menuItemIds: {
    section: 'Menu',
    side: 'right',
    type: Type.ARRAY,
    label: 'Menu Items',
    items: {
      type: Type.STRING,
      label: 'Menu Item',
      widget: {
        type: Widget.SELECT,
        multiple: true,
        enum: [],
        options: {
          preserveModel: true,
          resource: ApiResources.MENU_ITEMS,
          version: ApiVersion.V2,
          subCollection: 'self',
          search: { limit: 10000, sort: 'name' },
          dataProp: 'menuItems',
          modelProp: 'name',
          displayFields: ['name', 'category.name'],
        },
      },
    },
  },
};

export type MenuSectionData = MenuSectionSchemaData & AbstractModelData;

export class MenuSection extends AbstractModel<MenuSectionData> {
  constructor(public data: MenuSectionData) {
    super(data);
  }
}
