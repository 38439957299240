export enum DaysOfTheWeek {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum MonthOfYear {
  JANUARY = '1',
  FEBRUARY = '2',
  MARCH = '3',
  APRIL = '4',
  MAY = '5',
  JUNE = '6',
  JULY = '7',
  AUGUST = '8',
  SEPTEMBER = '9',
  OCTOBER = '10',
  NOVEMBER = '11',
  DECEMBER = '12',
}

export const MonthOfYearDisplayName = {
  [MonthOfYear.JANUARY]: 'January',
  [MonthOfYear.FEBRUARY]: 'February',
  [MonthOfYear.MARCH]: 'March',
  [MonthOfYear.APRIL]: 'April',
  [MonthOfYear.MAY]: 'May',
  [MonthOfYear.JUNE]: 'June',
  [MonthOfYear.JULY]: 'July',
  [MonthOfYear.AUGUST]: 'August',
  [MonthOfYear.SEPTEMBER]: 'September',
  [MonthOfYear.OCTOBER]: 'October',
  [MonthOfYear.NOVEMBER]: 'November',
  [MonthOfYear.DECEMBER]: 'December',
};
