import { ExportType, ReportType, UserRoles } from '@app/enums';
import { PdfHelper } from '../../helpers';
import { PdfContent, PdfSectionCreateParams } from '../../helpers/pdf.helper';
import { ISchema, Type } from '../schema';
import { Report, ReportPeriodParams } from './report';
import { SalesReportResponseData } from './report-export-summaries';
import {
  getReportPeriod,
  OrdersSummary,
  PrintedAt,
  SalesSummary,
  ShiftSalesSummary,
  TransactionDisclaimer,
  TransactionsSummaryForOrders,
  RefundTransactionsSummaryForOrders,
  TransactionsSummaryByShifts,
  RefundTransactionsSummaryByShifts,
  TransactionForPeriodDisclaimer,
  TransactionsSummaryForPeriod,
  RefundTransactionsSummaryForPeriod,
} from './report-sections';

export const TransactionsSummaryReportSchema: ISchema = {
  from: {
    section: 'basic',
    description: 'The starting period',
    type: Type.DATE,
    default: () => new Date(),
    label: 'Start Date',
    required: true,
  },
  to: {
    section: 'basic',
    description: 'The end of a the period',
    type: Type.DATE,
    label: 'End Date',
  },
};

export interface TransactionsSummaryReportData {
  from: string;
  to: string;
}

export class TransactionsSummaryReport extends Report<TransactionsSummaryReportData> {
  ignoreProperties = [];
  icon = 'fas fa-wallet';
  title = 'Transactions Summary Report';
  description =
    'Export all transaction information such as tender and tenders for shifts for a period of time.';
  data: TransactionsSummaryReportData = {} as TransactionsSummaryReportData;
  isImmediate = true;
  schema: ISchema = TransactionsSummaryReportSchema;
  roles: UserRoles[] = [UserRoles.ADMIN, UserRoles.CUSTOMER];
  type: ReportType = ReportType.SALES;
  exportOptions: ExportType[] = [ExportType.PDF];

  pdfSectionCreateParams: PdfSectionCreateParams<SalesReportResponseData>[] = [
    OrdersSummary,
    SalesSummary,
    TransactionsSummaryForOrders,
    TransactionDisclaimer,
    RefundTransactionsSummaryForOrders,
    TransactionsSummaryForPeriod,
    TransactionForPeriodDisclaimer,
    RefundTransactionsSummaryForPeriod,
    ShiftSalesSummary,
    TransactionsSummaryByShifts,
    TransactionForPeriodDisclaimer,
    RefundTransactionsSummaryByShifts,
  ];

  public generateExport(period: ReportPeriodParams, responseData: SalesReportResponseData): void {
    const pdfContent: PdfContent = PdfHelper.generateContent<SalesReportResponseData>(
      'Transactions Summary Report',
      [PrintedAt, getReportPeriod(period, true)].concat(this.pdfSectionCreateParams),
      responseData
    );
    const periodAsString = [period.from, period.to].filter(val => !!val).join('_');
    PdfHelper.generatePdf(pdfContent, `transactions-report_${periodAsString}`);
  }
}
