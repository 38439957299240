import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { ApiResources } from '@app/enums';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-heroku-app-domains',
  templateUrl: './heroku-app-domains.component.html',
  styleUrls: ['./heroku-app-domains.component.scss'],
})
export class HerokuAppDomainsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() appId: string;

  public domains: Array<any> = [];

  constructor() {
    super();
    this.apiClient.setResource(ApiResources.HEROKU);
  }

  ngOnInit() {
    console.log(this.appId);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes.appId?.currentValue) {
      this.getDomains(this.appId);
    }
  }

  async getDomains(id: string) {
    try {
      const query = { resource: `${id}/domains` };
      const result = await this.apiClient
        .get<{ success: boolean; data: Array<any> }>(query)
        .toPromise();
      this.domains = result.data;
    } catch (err) {
      this.displayError(err);
    }
  }
}
