<input
  type="text"
  class="form-control"
  placeholder="Search products"
  [ngbTypeahead]="searchProduct"
  [resultTemplate]="selectedProductTpl"
  [inputFormatter]="productFormatter"
  [(ngModel)]="value"
  (ngModelChange)="onChange($event)"
  (selectItem)="selectProduct($event)"
/>
<ng-template #selectedProductTpl let-r="result">
  {{ r.name }}
</ng-template>
