/* eslint-disable class-methods-use-this */
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from '@app/providers/file/file.service';
import { ImageCropperOptions } from './image-cropper-modal.interface';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss'],
})
export class ImageCropperModalComponent implements ImageCropperOptions {
  @Input() imageChangedEvent: Event;
  @Input() aspectRatio: number;
  @Input() cropperMinWidth: any;
  @Input() resizeToWidth: any;

  private _croppedImage: any;
  public loaded = false;
  public format = 'png';
  public maintainAspectRatio = true;

  public formats = ['png', 'jpeg'];

  constructor(
    private _modal: NgbActiveModal,
    private _fileService: FileService
  ) {}

  public async imageCropped(ev: any) {
    this._croppedImage = ev;
  }

  public imageLoaded() {
    this.loaded = true;
  }

  public cropperReady() {
    // cropper ready
  }

  public loadImageFailed() {
    // show message
  }

  public dismiss() {
    this._modal.dismiss();
  }

  public crop() {
    this._croppedImage.format = this.format;
    if (!this.maintainAspectRatio) {
      this._croppedImage.imageSize = 'original';
    }
    if (this.maintainAspectRatio) {
      if (this.aspectRatio === 1) {
        this._croppedImage.imageSize = 'thumbnail';
      } else {
        this._croppedImage.imageSize = 'full';
      }
    }
    this._modal.close(this._croppedImage);
  }
}
