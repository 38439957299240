import { ISchema, Type } from '../schema';

export const AcceptInviteSchema: ISchema = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  phone: {
    label: 'Phone',
    type: Type.PHONE,
  },
  password: {
    label: 'Password',
    description:
      'Min 8 characters. Only alphanumeric and special characters .!@#$%^&*()_+-= accepted.',
    type: Type.PASSWORD,
    required: true,
  },
  confirmPassword: {
    label: 'Confirm Password',
    type: Type.PASSWORD,
    required: true,
  },
};

export interface AcceptInviteData {
  name: string;
  phone?: string;
  password: string;
  confirmPassword: string;
}
