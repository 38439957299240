import moment from 'moment-timezone';

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

export enum DateFormat {
  DATE = 'YYYY-MM-DD',
  MEDIUM = 'MMMM Do, YYYY',
  MEDIUM_TIME = 'MMMM Do, YYYY hh:mma',
  TIME = 'hh:mma',
  DAY_OF_WEEK = 'ddd',
  DAY_OF_MONTH = 'Do',
  DAY_AND_MONTH = 'Do MMM',
  DAY_MONTH_AND_YEAR = 'Do MMM, YYYY',
  MONTH_OF_YEAR = 'MMM',
  MONTH_AND_YEAR = 'MMM, YYYY',
  QUARTER_OF_YEAR = 'Qo',
  QUARTER_AND_YEAR = 'Qo, YYYY',
  YEAR = 'YYYY',
}

export const US_TIMEZONES = moment.tz
  .zonesForCountry('US', true)
  .sort((a, b) => a.offset - b.offset);

export function getDateFromDateString(dateStr: string) {
  const [year, month, day] = dateStr.split('-');
  const date = new Date();
  date.setFullYear(parseInt(year, 10));
  date.setMonth(parseInt(month, 10) - 1);
  date.setDate(parseInt(day, 10));
  return date;
}

export function getDateStringWithFormat(date: Date | string, format: DateFormat): string {
  return moment(date).format(format);
}

export function getDateString(date: Date) {
  return getDateStringWithFormat(date, DateFormat.DATE);
}

export function getMediumDate(date: Date) {
  return getDateStringWithFormat(date, DateFormat.MEDIUM);
}

export function getMediumDateWithTime(date: Date) {
  return getDateStringWithFormat(date, DateFormat.MEDIUM_TIME);
}

export function isSameMonth(startPeriod: Date, endPeriod: Date): boolean {
  const startMonth = getDateStringWithFormat(startPeriod, DateFormat.MONTH_AND_YEAR);
  const endMonth = getDateStringWithFormat(endPeriod, DateFormat.MONTH_AND_YEAR);
  return startMonth === endMonth;
}

export function getHourAndMinute(date: Date) {
  const d = moment(date);
  return {
    hour: d.hours(),
    minute: d.minutes(),
  };
}

export function getDatePartsFromDate(date: string | Date) {
  const d = moment(date);
  return {
    year: d.year(),
    month: d.month(),
    day: d.date(),
    hour: d.hours(),
    minute: d.minutes(),
  };
}

export function utcOffset(date: Date) {
  return moment(date).utcOffset();
}

export function getTimezoneFromDate(date: Date) {
  const dateUtcOffset = utcOffset(date);
  const timezoneData = US_TIMEZONES.find(tzInfo => tzInfo.offset === -dateUtcOffset);
  return timezoneData?.name;
}

export function getTimePeriodsWithTimeGap(gap: number): { name: string; key: string }[] {
  const hours: { name: string; key: string }[] = [];
  for (let hour = 0; hour < 24; hour++) {
    let hourStr = '';
    const amPm = hour < 11 ? 'am' : 'pm';
    if (!hour) {
      hourStr = '12';
    }
    if (hour) {
      hourStr = hour > 12 ? `${hour - 12}` : hour.toString();
    }
    for (let minute = 0; minute < 60; minute += gap) {
      const timeStr = `${hourStr}:${minute.toString().padStart(2, '0')}${amPm}`;
      hours.push({
        name: timeStr,
        key: `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`,
      });
    }
  }
  return hours;
}
