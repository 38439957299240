import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ImageHelper } from '../../../helpers';

@Component({
  selector: 'app-item-image',
  templateUrl: './item-image.component.html',
  styleUrls: ['./item-image.component.scss'],
})
export class ItemImageComponent implements OnChanges {
  @Input() item: any;
  @Input() default: string;
  @Input() width = 50;
  @Input() height = 50;
  @Input() size = 'full';
  @Input() url = false;

  public src: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.item?.currentValue) {
      if (this.url) {
        this.src = this.item;
      } else {
        this.src = ImageHelper.getImage(this.item, this.size) || this.default;
      }
    }
  }
}
