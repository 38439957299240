<div class="mb-3">
  <label>
    {{ dateLabel }}
    <p class="text-muted" *ngIf="description">{{ description }}</p>
  </label>
  <div class="input-group">
    <input
      class="form-control"
      placeholder="yyyy-mm-dd"
      name="dp"
      [(ngModel)]="dateInput"
      (ngModelChange)="change()"
      ngbDatepicker
      #d="ngbDatepicker"
    />
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
        <i class="mdi mdi-calendar"></i>
      </button>
    </div>
  </div>
</div>
<div class="mb-3" *ngIf="!hideTime">
  <label>{{ timeLabel }}</label>
  <div class="input-group">
    <ngb-timepicker
      [(ngModel)]="timeInput"
      (ngModelChange)="change()"
      [meridian]="true"
    ></ngb-timepicker>
  </div>
</div>
