import { ApiResources, ApiVersion, ProductType } from '../../enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

export const GreenFeeRateSchema: ISchema = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  color: {
    label: 'Color',
    type: Type.COLOR,
    required: true,
  },
  includeCart: {
    label: 'Include Cart',
    type: Type.BOOLEAN,
  },
  itemId: {
    section: 'pointers',
    type: Type.STRING,
    label: 'Tee Time Item',
    widget: {
      type: Widget.SELECT,
      options: {
        resource: ApiResources.MENU_ITEMS,
        version: ApiVersion.V2,
        dataProp: 'menuItems',
        modelProp: 'name',
        search: {
          type: ProductType.GOLF,
          limit: 1000,
        },
      },
    },
  },
  cartItemId: {
    section: 'pointers',
    type: Type.STRING,
    label: 'Golf Cart Item',
    widget: {
      type: Widget.SELECT,
      options: {
        resource: ApiResources.MENU_ITEMS,
        version: ApiVersion.V2,
        dataProp: 'menuItems',
        modelProp: 'name',
        search: {
          type: ProductType.GOLF,
          limit: 1000,
        },
      },
    },
    onlyIf: 'includeCart',
  },
};

export interface GreenFeeRateData extends AbstractModelData {
  name: string;
  color: string;
  includeCart: boolean;
  itemId: string | Record<string, any>;
  cartItemId: string | Record<string, any>;
  orgId: string;
  venueId: string;
}

export class GreenFeeRate extends AbstractModel<GreenFeeRateData> {
  constructor(public data: GreenFeeRateData) {
    super(data);
  }
}
