enum SubscriptionInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

module SubscriptionInterval {
  export function name(value: SubscriptionInterval) {
    switch (value) {
      case SubscriptionInterval.DAY:
        return 'Daily';
      case SubscriptionInterval.WEEK:
        return 'Weekly';
      case SubscriptionInterval.MONTH:
        return 'Monthly';
      case SubscriptionInterval.YEAR:
        return 'Yearly';
    }
  }
}

enum SubscriptionType {
  MEMBERSHIP = 'membership',
  SERVICE = 'service',
  PRODUCT = 'product',
}

module SubscriptionType {
  export function name(value: SubscriptionType) {
    switch (value) {
      case SubscriptionType.MEMBERSHIP:
        return 'Membership';
      case SubscriptionType.SERVICE:
        return 'Service';
      case SubscriptionType.PRODUCT:
        return 'Product';
    }
  }
}

enum SubscriptionCollectionMethod {
  CHARGE_AUTOMATICALLY = 'charge-automatically',
  SEND_INVOICE = 'send-invoice',
  MEMBER_INVOICE = 'member-invoice',
}

module SubscriptionCollectionMethod {
  export function name(value: SubscriptionCollectionMethod) {
    switch (value) {
      case SubscriptionCollectionMethod.CHARGE_AUTOMATICALLY:
        return 'Charge Automatically';
      case SubscriptionCollectionMethod.SEND_INVOICE:
        return 'Send Invoice';
      case SubscriptionCollectionMethod.MEMBER_INVOICE:
        return 'Member Invoice';
    }
  }
}

enum SubscriptionStatus {
  INCOMPLETE = 'incomplete',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past-due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

module SubscriptionStatus {
  export function name(value: SubscriptionStatus) {
    switch (value) {
      case SubscriptionStatus.INCOMPLETE:
        return 'Incomplete';
      case SubscriptionStatus.TRIALING:
        return 'Trialing';
      case SubscriptionStatus.ACTIVE:
        return 'Active';
      case SubscriptionStatus.PAST_DUE:
        return 'Past Due';
      case SubscriptionStatus.CANCELED:
        return 'Canceled';
      case SubscriptionStatus.UNPAID:
        return 'Unpaid';
    }
  }
}

export { SubscriptionInterval, SubscriptionCollectionMethod, SubscriptionStatus, SubscriptionType };
