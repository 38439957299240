export enum Mcc {
  BAKERIES = '5462',
  RESTAURANT = '5812',
  FAST_FOOD_RESTAURANT = '5814',
  PUBLIC_GOLF_COURSE = '7992',
  COUNTRY_CLUB = '7997',
}

export enum MccName {
  BAKERIES = 'Bakeries',
  RESTAURANT = 'Restaurant',
  FAST_FOOD_RESTAURANT = 'Fast Food Restaurant',
  PUBLIC_GOLF_COURSE = 'Public Golf Course',
  COUNTRY_CLUB = 'Country Club',
}

export enum StripeAccountType {
  CUSTOM = 'custom',
  // EXPRESS = 'express',
  STANDARD = 'standard',
}
