import { PromptForMember } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

export const VenueMapSchema: ISchema<Omit<VenueMapData, keyof AbstractModelData>> = {
  venue: {
    section: 'Settings',
    label: 'Venue',
    type: Type.STRING,
    isHidden: true,
  },
  shareTabs: {
    section: 'Settings',
    label: 'Share Tabs Across Locations',
    type: Type.BOOLEAN,
  },
  trackEmployees: {
    section: 'Settings',
    label: 'Track Employees at Shift Open/Close',
    type: Type.BOOLEAN,
  },
  authForRefunds: {
    section: 'Settings',
    label: 'Require Passcode for Refunds',
    type: Type.BOOLEAN,
  },
  authForVoids: {
    section: 'Settings',
    label: 'Require Passcode for Voids',
    type: Type.BOOLEAN,
  },
  promptForMember: {
    section: 'Settings',
    label: 'Prompt for Member',
    description: 'Require a member to be selected before completing a sale.',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(PromptForMember),
    },
    default: PromptForMember.NONE,
  },
  promptForEmployee: {
    section: 'Settings',
    label: 'Enforce Employee Pins at the Start of Shifts',
    type: Type.BOOLEAN,
    onlyIf: [
      {
        field: 'trackEmployees',
        shouldShow: (value: boolean) => !!value,
      },
    ],
  },
  usesFullScreenModsViews: {
    section: 'POS Settings',
    label: 'Use Full Screen Modifier View',
    type: Type.BOOLEAN,
  },
  autoCloseOpenOrders: {
    section: 'Settings',
    label: 'Auto Close Open Tabs with Cards on File',
    type: Type.BOOLEAN,
  },
  useCachedBackedMenu: {
    section: 'Settings',
    label: 'Use Cached Backed Menu',
    description: 'Menu fetching will now entail publishing changes for them to flow into the POS.',
    type: Type.BOOLEAN,
  },
  guestReceiptConfig: {
    type: Type.OBJECT,
    section: 'Receipt Settings',
    label: 'Guest Receipt Configuration',
    properties: {
      header: {
        label: 'Guest Receipt Header',
        type: Type.LONG_STRING,
      },
      footer: {
        label: 'Guest Receipt Footer',
        type: Type.LONG_STRING,
      },
      showTipOptions: {
        label: 'Show Tip Options',
        type: Type.BOOLEAN,
        default: false,
      },
      tipOptions: {
        type: Type.ARRAY,
        label: 'Tip Options',
        items: {
          label: 'Tip Option (e.g. 20 for 20%)',
          type: Type.NUMBER,
        },
        default: [18, 20, 22],
      },
      useTransactionAmountForTip: {
        section: 'Receipt Settings',
        label: 'Use Transaction Amount for Tip',
        type: Type.BOOLEAN,
        default: false,
      },
      useAdditionalTipText: {
        section: 'Receipt Settings',
        label: 'Use "Additional Tip" Text',
        type: Type.BOOLEAN,
        default: false,
      },
    },
  },
  isActive: {
    section: 'Settings',
    label: 'Active',
    type: Type.BOOLEAN,
    isHidden: true,
  },
};

export interface VenueMapData extends AbstractModelData {
  venue: string;
  shareTabs: boolean;
  trackEmployees: boolean;
  authForRefunds: boolean;
  authForVoids: boolean;
  promptForMember: PromptForMember;
  promptForEmployee: boolean;
  usesFullScreenModsViews: boolean;
  autoCloseOpenOrders: boolean;
  useCachedBackedMenu: boolean;
  guestReceiptConfig: {
    header: string;
    footer: string;
    showTipOptions: boolean;
    tipOptions: number[];
    useTransactionAmountForTip: boolean;
    useAdditionalTipText: boolean;
  };
  isActive: boolean;
}

export class VenueMap extends AbstractModel<VenueMapData> {
  constructor(public data: VenueMapData) {
    super(data);
  }
}
