import { ISchema, Type, Widget } from '../schema';
import { UserSchema } from './user';

export const InviteUserSchema: ISchema = {
  user: {
    section: 'User Info',
    label: 'User Info',
    type: Type.OBJECT,
    required: true,
    properties: {
      name: UserSchema.name,
      email: UserSchema.email,
    },
  },
  userRoleId: {
    section: 'Role Info',
    label: 'User Role',
    required: true,
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
    },
    enum: [],
  },
  locationGroupId: {
    section: 'Role Info',
    label: 'Location Group',
    required: true,
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
    },
    enum: [],
  },
};
