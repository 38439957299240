<div class="custom-file picker">
  <input
    type="file"
    class="custom-file-input"
    id="file"
    [(ngModel)]="file"
    [multiple]="multiple"
    [disabled]="disabled"
    (change)="onFilePicked($event)"
  />
  <label class="custom-file-label" for="file">
    {{ label }}
  </label>
</div>
