import _ from 'lodash';
import async from 'async';
import moment from 'moment';
import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Func, IFilterOption } from '@app/models';
import { BaseComponent } from '../base.component';
import { ApiClient } from '../../../clients';
import { UserType } from '../../../enums';

@Component({
  selector: 'app-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
})
export class ListFilterComponent extends BaseComponent implements OnChanges {
  @ViewChild('filterDropdown') filterDropdown: NgbDropdown;

  @Input() showCreate = true;
  @Input() createType: 'router' | 'click' = 'router';

  @Input() filterOptions: Array<IFilterOption>;
  @Input() filter: Array<any> = [];

  @Input() createOptions: Array<{ key: string; name: string }>;

  @Output() filterChange = new EventEmitter<any>();

  @Output() create = new EventEmitter<any>();

  public keys = Object.keys;
  public filterObj = {
    name: null,
    property: null,
    value: null,
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.filterOptions?.currentValue) {
      this.loadOptions();
    }
  }

  private static getNameForOption(data: any, displayFields?: string[]) {
    if (!_.size(displayFields)) {
      return data.name;
    }
    return displayFields
      .map((field: string) => _.get(data, field))
      .filter((val: any) => val)
      .join(' - ');
  }

  private async loadOptions() {
    const tasks: { [k: string]: any } = {};
    this.filterOptions
      .filter((opt: IFilterOption) => {
        if (this.user?.type !== UserType.PARTAKE && opt.adminOnly) {
          return false;
        }
        return true;
      })
      .forEach((option: IFilterOption) => {
        if (option.options?.type === 'select' && !_.size(option.options?.values)) {
          tasks[option.key] = async (callback: Func) => {
            const query = {
              limit: 100,
              ...(option.options?.query && option.options?.query),
            };
            const method =
              this.user?.type === 'partake' || option.options?.subCollection === 'self'
                ? 'get'
                : 'self';
            const resource = ApiClient.createUrl([option.key, option.options?.subCollection]);
            const result = await this.makeSafeRequest<any>(this.apiClient[method](query, resource));
            const returnDataProp = option.options?.returnDataProp || 'data';
            if (result && (_.isArray(result) || _.isArray(_.get(result, returnDataProp)))) {
              const values = _.isArray(result) ? result : _.get(result, returnDataProp);
              option.options.values = values.map((res: any) => ({
                name: ListFilterComponent.getNameForOption(res, option.options.displayFields),
                value: res._id,
              }));
            }
            callback(null, result);
          };
        }
      });
    async.auto(tasks, err => {
      if (err) {
        console.log(err);
      }
    });
  }

  public selectProp(value: any) {
    this.filterObj.value = value?._id || value;
  }

  public doCreate(key?: string) {
    this.create.emit(key);
  }

  public optionChange() {
    const temp = {
      ...this.filterObj,
      value: null,
    };
    this.filterObj = { name: null, property: '', value: null };
    setTimeout(() => {
      this.filterObj = temp;
    }, 1);
  }

  // eslint-disable-next-line class-methods-use-this
  public dateChange(date: any) {
    this.filterObj.value = moment(date).format('YYYY-MM-DD');
  }

  public addFilter() {
    const name =
      this.filterObj.property.name || this.filterObj.property.search || this.filterObj.property.key;
    const property = this.filterObj.property.search || this.filterObj.property.key;
    if (_.isArray(this.filterObj.value)) {
      this.filter.push({
        name,
        property,
        value: this.filterObj.value.join(','),
      });
    } else if (_.get(this.filterObj.value, '_id')) {
      this.filter.push({
        name,
        property,
        value: this.filterObj.value._id,
      });
    } else {
      this.filter.push({
        name,
        property,
        value: this.filterObj.value,
      });
    }
    this.filterObj = { name, property: '', value: null };
    this.filterDropdown.close();
    this.filterChange.emit(this.filter);
  }

  public removeFilter(i: number) {
    this.filter.splice(i, 1);
    this.filterChange.emit(this.filter);
  }
}
