import {
  Directive,
  ElementRef,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

/**
 * # Ways to turn off autofocus: any js-falsely value, except empty string
 *
 *     <!-- with data binding -->
 *     <input [autofocus]=""> <!-- undefined value -->
 *     <input [autofocus]="undefined">
 *     <input [autofocus]="false">
 *     <input [autofocus]="null">
 *     <input [autofocus]="0">
 *
 *     <!-- without data binding -->
 *     <input autofocus="undefined">
 *     <input autofocus="false">
 *     <input autofocus="null">
 *     <input autofocus="0">
 *
 *     <input> <!-- disabled by default -->
 *
 *
 * # Ways to enable autofocus: any js-true value and empty string
 *
 *
 *     <!-- empty string will enable autofocus, this is default html behavior -->
 *     <input [autofocus]="''">
 *     <input autofocus="">
 *     <input autofocus>
 *
 *     <input [autofocus]="true">
 *     <input autofocus="true">
 *
 *     <input [autofocus]="'any other values'">
 *
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[autofocus]',
})
export class AutofocusDirective implements AfterViewInit, OnChanges {
  @Input()
  public set autofocus(value: any) {
    this._autofocus =
      value !== false &&
      value !== null &&
      value !== undefined &&
      value !== 0 &&
      value !== 'false' &&
      value !== 'null' &&
      value !== 'undefined' &&
      value !== '0';
  }

  @Input()
  public delay = 1;

  private _autofocus = true;

  public constructor(private el: ElementRef) {}

  public ngAfterViewInit() {
    this.handleAutofocus();
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.handleAutofocus();
  }

  private handleAutofocus() {
    if (this._autofocus) {
      const el: HTMLInputElement = this.el.nativeElement;

      const inputElements = ['INPUT', 'SELECT', 'TEXTAREA'];
      const input: HTMLInputElement =
        el.focus && inputElements.includes(el.nodeName)
          ? el
          : el.querySelector(inputElements.join(',')) || el;

      if (input.focus) {
        setTimeout(() => {
          input.focus();
          el.scrollIntoView({
            block: el.offsetTop <= window.scrollY ? 'start' : 'end',
            inline: 'nearest',
          });
        }, this.delay);
      } else {
        console.warn('AutofocusDirective: Element is not focusable:', this.el.nativeElement);
      }
    }
  }
}
