export enum OrderChannel {
  APP = 'app',
  BANQUET = 'banquet',
  KIOSK = 'kiosk',
  POS = 'point-of-sale',
  QSR = 'qsr',
  SIT_DOWN = 'sit-down',
  WEB = 'web',
}

export const orderChannelColor = {
  [OrderChannel.APP]: 'primary',
  [OrderChannel.BANQUET]: 'secondary',
  [OrderChannel.KIOSK]: 'tertiary',
  [OrderChannel.POS]: 'medium',
  [OrderChannel.QSR]: 'success',
  [OrderChannel.SIT_DOWN]: 'warning',
  [OrderChannel.WEB]: 'danger',
};

export enum PricingStrategy {
  INHERIT = 'inherit',
  OVERRIDE = 'override',
}

export const pricingStrategyColor = {
  [PricingStrategy.INHERIT]: 'primary',
  [PricingStrategy.OVERRIDE]: 'secondary',
};
