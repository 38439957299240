import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  EventEmitter,
  Output,
} from '@angular/core';
import * as c3 from 'c3';
import { Subject } from 'rxjs';
import { switchMap, throttleTime } from 'rxjs/operators';
import { SafeSubscribable } from '@app/shared/rxjs/extensions';
import { Logger } from '@app/shared/logger';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit, SafeSubscribable
{
  private static counter = 0;

  // #region Binding Props

  @Input()
  data: c3.Data;

  @Input()
  config: c3.ChartConfiguration;

  @Input()
  placeholder: string;

  @Input() showPagination = false;

  @Input() paginationOptions = {
    show: false,
    page: 1,
    limit: 20,
    total: 0,
    nextPage: (ev: any) => {},
  };

  @Output()
  render = new EventEmitter<c3.ChartAPI>();

  // #endregion

  // #region Data Props

  public chartId: number;
  public chartClass: string;
  public instance: c3.ChartAPI;

  private generateChart$ = new Subject<c3.ChartConfiguration>();

  private data$ = new Subject<c3.Data>();

  // #endregion

  // #region Component Lifecycle

  constructor(private logger: Logger) {}

  ngOnInit() {
    this.chartId = ++ChartComponent.counter;
    this.chartClass = `chart-component-${this.chartId}`;
    this.subscribeToChartConfig();
    this.subscribeToChartData();
  }

  private subscribeToChartConfig() {
    this.generateChart$
      .pipe(throttleTime(200, undefined, { leading: true, trailing: true }))
      .safeSubscribe(this, config => {
        this.destroyChart();
        this.generateChart(config);
      });
  }

  private subscribeToChartData() {
    this.render
      .pipe(
        switchMap(render => this.data$),
        throttleTime(200, undefined, { leading: true, trailing: true })
      )
      .safeSubscribe(this, data => {
        if (this.instance) {
          this.instance.load(data);
        } else {
          this.logger.warn(
            ChartComponent,
            'subscribeToChartData',
            "Chart is not initialized yet and data can't be rendered"
          );
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && !changes.config.firstChange) {
      this.generateChart$.next(changes.config.currentValue);
    }
    if (changes.data && !changes.data.firstChange) {
      this.data$.next(changes.data.currentValue);
    }
  }

  ngAfterViewInit() {
    this.generateChart$.next(this.config);
  }

  ngOnDestroy() {
    this.destroyChart();
  }

  // #endregion

  // #region Private Methods

  private generateChart(config: c3.ChartConfiguration) {
    try {
      if (config) {
        this.instance = c3.generate({
          ...config,
          bindto: `.${this.chartClass}`,
        });
        this.render.emit(this.instance);
      }
    } catch (err) {
      this.logger.error(ChartComponent, 'generateChart', 'Failed to generate c3 chart', err);
    }
  }

  private destroyChart() {
    if (this.instance) {
      try {
        this.instance.destroy();
      } catch (err) {
        this.logger.warn(ChartComponent, 'destroyChart', 'Failed to destroy c3 chart', err);
      }
      this.instance = null;
    }
  }

  // #endregion
}
