import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';

import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { MenuItemService } from '@app/providers/restaurant/menu-item.service';
import { RestaurantService } from '@app/providers/restaurant/restaurant.service';

@Component({
  selector: 'app-payment-menu-items',
  templateUrl: './payment-menu-items.component.html',
  styleUrls: ['./payment-menu-items.component.scss'],
})
export class PaymentMenuItemsComponent implements OnInit {
  @Output() itemSelected = new EventEmitter<any>();

  @ViewChild('search') searchEl: ElementRef;

  public items: Array<any> = [];

  private _keyup$: any;
  private _location: any;

  constructor(
    private _restaurantService: RestaurantService,
    private _menuItemService: MenuItemService
  ) {}

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      const locations = await this._restaurantService.find({ type: 'pro-shop' }).toPromise();
      if (locations && locations.data.length) {
        this._location = locations.data[0];
      }
      this.setupSearchListener();
    } catch (e) {
      console.log(e);
    }
  }

  private setupSearchListener() {
    this._keyup$ = fromEvent(this.searchEl.nativeElement, 'keyup');
    this._keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(this.search);
  }

  search = async (val: string) => {
    try {
      const result = await this._menuItemService
        .self({
          name: val,
          limit: 100,
          isDeleted: false,
          restaurant: this._location._id,
        })
        .toPromise();
      this.items = result.menuItems;
    } catch (e) {
      console.log(e);
    }
  };

  public clear() {
    this.searchEl.nativeElement.value = '';
    this.items = [];
  }

  public addToCart(item: any) {
    this.itemSelected.emit(item);
  }
}
