import { Integration, IIntegrationBase } from './integration';

export const PosBaseSchema = {
  syncCategories: {
    type: 'boolean',
    label: 'Sync Categories / Menu Sections',
    default: false,
  },
  syncProducts: {
    type: 'boolean',
    label: 'Sync Products / Items',
    default: false,
  },
  syncDiscounts: {
    type: 'boolean',
    label: 'Sync Discounts',
    default: false,
  },
  syncGratuities: {
    type: 'boolean',
    label: 'Sync Gratuities',
    default: false,
  },
  syncTenders: {
    type: 'boolean',
    label: 'Sync Tenders',
    default: false,
  },
  syncTaxes: {
    type: 'boolean',
    label: 'Sync Taxes',
    default: false,
  },
  syncEmployees: {
    type: 'boolean',
    label: 'Sync Employees',
    default: false,
  },
  canSync: {
    type: 'boolean',
    label: 'Can Sync',
    default: true,
    isHidden: true,
  },
};

export interface IPosBase extends IIntegrationBase {
  syncCategories: boolean;
  syncProducts: boolean;
  syncDiscounts: boolean;
  syncGratuities: boolean;
  syncTenders: boolean;
  syncTaxes: boolean;
  syncEmployees: boolean;
}

export class PosBase<T extends IPosBase> extends Integration<T> {
  constructor(public data: T) {
    super(data);
  }
}
