import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten',
})
export class ShortenPipe implements PipeTransform {
  constructor() {}

  transform(value: string, len: number): string {
    if (value && value.length > len) {
      return value.substring(0, len);
    }
    return value;
  }
}
