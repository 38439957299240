export enum OrderStatus {
  ABANDONED = 'abandoned',
  ACCEPTED = 'accepted',
  AUTHORIZING = 'authorizing',
  AUTHORIZED = 'authorized',
  CANCELLED = 'cancelled',
  CART = 'cart',
  DELIVERED = 'delivered',
  OUT_FOR_DELIVERY = 'out-for-delivery',
  PENDING = 'pending',
  POINT_OF_SALE = 'point-of-sale',
  READY_FOR_PICKUP = 'ready-for-pickup',
}

export enum FulfillmentState {
  FULFILLED = 'fulfilled',
  REQUIRES_FULFILLMENT = 'requires-fulfillment',
}
