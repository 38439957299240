import { ApiResources, ApiVersion } from '../../enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

export interface StandSheetSchemaData {
  name: string;
  locationId: string;
}

export type StandSheetData = StandSheetSchemaData & AbstractModelData;

export const StandSheetSchema: ISchema<StandSheetSchemaData> = {
  name: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Name',
  },
  locationId: {
    section: 'Basic Info',
    label: 'Location',
    type: Type.STRING,
    immutable: true,
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        version: ApiVersion.V2,
        resource: ApiResources.LOCATION,
        modelProp: 'name',
        search: { isDeleted: false },
        displayFields: ['name'],
      },
    },
  },
};

export class StandSheet extends AbstractModel<StandSheetData> {}
