import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { environment } from '@env/environment';
import { ApiQuery, ApiQueryParams } from '@app/clients';
import { ApiResponse, TransactionData } from '../../models';

@Injectable({ providedIn: 'root' })
export class OrderService {
  private _statusListener = new Subject<any>();

  private domain = environment.url;
  private path = 'order';
  private baseUrl = `${this.domain}/${this.path}`;

  constructor(private http: HttpClient) {}

  public getStatusListener(): Observable<any> {
    return this._statusListener.asObservable();
  }

  public notifyStatusChange(order: any) {
    this._statusListener.next(order);
  }

  /**
   * @description Create a cart for a user or shift
   * @param order The basic info needed to create an empty cart
   * @example
   * @returns Observable<any>
   */
  create(order: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}`, order);
  }

  /**
   * @description Get the logged in users order count
   * @returns Observable<any>
   */
  count(): Observable<any> {
    return this.http.get(`${this.baseUrl}/count`);
  }

  /**
   * @description Search for orders for the account with varias query params
   * @param query properties to search by
   * @example
   * {
   *    date: '2019-01-31',// the date you want orders for
   *    page: 1, // the current page used for pagination
   *    limit: 20, // the number of orders to be returned (default=20)
   *    shift: 'dkhgdfkg8953tdhg3580fdlgjl', // the specific shift (venues only)
   *    restaurant: 'dkhgdfkg8953tdhg3580fdlgjl', // the specific restaurant
   * }
   * @returns Observable<any>
   */
  find(query?: ApiQuery): Observable<any> {
    if (query) {
      const params = new ApiQueryParams(query);
      return this.http.get<any>(`${this.baseUrl}`, { params });
    }
    return this.http.get<any>(`${this.baseUrl}`);
  }

  /**
   * @description Get closed orders for account
   * @param query properties to search by
   * @example
   * {
   *    user: 'dkhgdfkg8953tdhg3580fdlgjl', // required
   *    limit: 20, // the number of orders to be returned (default=20)
   * }
   * @returns Observable<any>
   */
  history(query: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this.http.get<any>(`${this.baseUrl}/history`, { params });
  }

  /**
   * @description Get the details of a single order
   * @param id The id of the order
   * @returns Observable<any>
   */
  findById(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${id}`);
  }

  /**
   * Get orders summary
   *
   * @returns Observable<any>
   */
  getOrdersMeta(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/meta`);
  }

  /**
   * @description Returns the open orders based on the query.
   * @param query properties to search by
   * @example
   * {
   *    user: '', // required - by consumer applications. `self` for logged in user
   *    restaurant: '', // optional - venue accounts only
   *    venue: '', // optional - venue accounts only
   * }
   *
   * @returns Observable<any>
   */
  getOpenOrders(query: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this.http.get<any>(`${this.baseUrl}/open-orders`, { params });
  }

  getTransactions(orderId: string): Observable<ApiResponse.RequestResult<TransactionData[]>> {
    return this.http.get<any>(`${this.baseUrl}/${orderId}/transactions`);
  }

  /**
   * @description Add a menu item to an existing cart / order
   * @param id This is the order/cart id
   * @param item This is the details of the item being added to the order
   * @example
   * @returns Observable<any>
   */
  addItem(id: string, item: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}/add-item`, item);
  }

  /**
   * @description Edit a line item in the order
   * @param id This is the order/cart id
   * @param item This is the details of the line item that needs to be edited
   * @example
   */
  editItem(id: string, item: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}/update-item`, item);
  }

  /**
   * @description Remove a specific line item from the order
   * @param id This is the order/cart id
   * @param item This is the details of the line item that needs to be removed
   * @example
   */
  removeItem(id: string, item: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}/remove-item`, item);
  }

  /**
   * @description Voids a line item from the order - only for venues/management
   * @param id This is the order/cart id
   * @param item This is the line item in the order's items array
   * @example
   */
  voidItem(id: string, item: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}/void-item`, item);
  }

  /**
   * @description Update order
   * @param id This is the order `_id`
   * @param body These are the properties to be updated
   * @deprecated This will no longer be available in v2 as all updates will be on the backend
   */
  update(id: string, body: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}`, body);
  }

  save(id: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}/save-order`, {});
  }

  /**
   * @description Set the status of the order
   * @param id The order `_id`
   * @param update The object with the valid properties
   * @example
   * {
   *    status: 'accepted',
   *    expectedPickup: Date,
   *    utcOffset: '-5',
   *    acceptedTime: Date
   * }
   */
  setStatus(id: string, update: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/update_status/${id}`, update);
  }

  /**
   * @description Get active days when orders were delivered and their counts
   * @example
   * [
   *   {
   *     _id: "2020-01-01"
   *     count: 3
   *   }
   * ]
   */
  activeDays(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/active-days`);
  }
}
