import { AlertFrequency, AlertType, ApiDomain, ApiResources, ApiVersion } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

export const AlertSettingSchema: ISchema = {
  frequency: {
    label: 'Frequency',
    type: Type.STRING,
    enum: EnumHelper.getEnumArray(AlertFrequency),
    default: AlertFrequency.IMMEDIATE,
    widget: {
      type: Widget.SELECT,
    },
  },
  locations: {
    label: 'Locations',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      multiple: true,
      options: {
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.LOCATION,
        modelProp: 'name',
        search: { isDeleted: false, limit: 100 },
        displayFields: ['name'],
      },
    },
    required: true,
  },
};

const AlertSettings: ISchema = {
  [AlertType.ORDER as string]: {
    label: 'Order Alerts',
    description: 'Get an email for each new order that is placed',
    type: Type.OBJECT,
    properties: AlertSettingSchema,
  },
  [AlertType.DAILY_OPS_SUMMARY as string]: {
    label: 'Daily Summary Alerts',
    description: 'Get a daily summary of the previous days operations',
    type: Type.OBJECT,
    properties: {
      ...AlertSettingSchema,
      locations: {
        ...AlertSettingSchema.locations,
        isHidden: true,
      },
    },
  },
};

export const AlertSchema: ISchema = {
  email: {
    label: 'Email',
    type: Type.EMAIL,
    required: true,
  },
  alertSettings: {
    label: 'Alert Settings',
    type: Type.OBJECT,
    properties: AlertSettings,
  },
};

export interface AlertSetting {
  frequency: AlertFrequency;
  locations: string[];
}

export interface AlertData extends AbstractModelData {
  email: string;
  alertSettings: {
    [AlertType.ORDER]: AlertSetting;
    [AlertType.DAILY_OPS_SUMMARY]: AlertSetting;
  };
  accountId?: string;
}

export class Alert extends AbstractModel<AlertData> {
  constructor(public data: AlertData) {
    super(data);
  }
}
