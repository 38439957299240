import { Component, EventEmitter, Output } from '@angular/core';

import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { DiscountService } from '@app/providers/promotions/discount.service';

@Component({
  selector: 'app-discount-search-typeahead',
  templateUrl: './discount-search-typeahead.component.html',
  styleUrls: ['./discount-search-typeahead.component.scss'],
})
export class DiscountSearchTypeaheadComponent {
  @Output() selected = new EventEmitter<any>();

  constructor(private _discountService: DiscountService) {}

  public selectDiscount(selected: any, input: any) {
    selected.preventDefault();
    this.selected.emit(selected.item);
    input.value = '';
  }

  searchDiscount = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(name =>
        this._discountService.self({ name, limit: 100 }).pipe(
          map(x => x.discounts),
          catchError(() => of([]))
        )
      )
    );

  // eslint-disable-next-line class-methods-use-this
  discountFormatter = (x: { name: string; type: string }) => {
    if (x.name) {
      return `${x.name}`;
    }
    return '';
  };
}
