export enum Trigger {
  ACCOUNT_DEPOSIT = 'account-deposit',
  ACCOUNT_WITHDRAWAL = 'account-withdrawal',
  CAMPAIGN = 'campaign',
  DAILY_OPS_SUMMARY = 'daily-ops-summary',
  GAME_RESULT = 'game-result',
  ORDER_CANCELED = 'order-cancelled',
  ORDER_RECEIPT = 'order-receipt',
  ORDER_REFUND = 'order-refund',
  ORDER_REMINDER = 'order-reminder',
  PASSWORD_RESET = 'password-reset',
  WELCOME = 'welcome',
}
