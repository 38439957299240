import { createAction, props } from '@ngrx/store';

export enum IntegrationActions {
  LOAD_INTEGRATIONS = '[Integration] Load Integrations',
  LOAD_INTEGRATIONS_SUCCESS = '[Integration] Load Integration Success',
}

export const retrievedIntegrations = createAction(
  IntegrationActions.LOAD_INTEGRATIONS_SUCCESS,
  props<{ integrations: any[] }>()
);
