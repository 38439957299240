import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAdjust',
})
export class TimeAdjustPipe implements PipeTransform {
  constructor() {}

  transform(value: number, adjust: number): number {
    if (value > adjust) {
      return value - adjust;
    }
    return value;
  }
}
