/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import pkg from '../../package.json';

const BASE_URL = 'https://dev.partakeapi.com';
// const BASE_URL = 'http://localhost:8080';

export const environment = {
  name: 'dev',
  production: false,
  app: pkg.name,
  appVersion: pkg.version,
  apiVersion: '2',
  realmDbName: 'partake',
  // realmAppId: 'partake-everywhere-demo-bevft',
  realmAppId: 'partake-pos-demo-kntgk',
  posUrl: 'https://demo.partakepos.com',
  /**
   * @deprecated versions
   */
  url: `${BASE_URL}/api`,
  /**
   * @deprecated versions
   */
  url_v2: `${BASE_URL}/v2`,

  /**
   * NEW FORMAT
   */
  urls: {
    domains: {
      api: BASE_URL,
      socket: 'https://demo.socket.partakeapi.com',
      stats: 'https://staging.stats.partakeapi.com',
    },
    versions: {
      v1: 'api',
      legacy_v2: 'api/v2',
      v2: 'v2',
    },
  },
  stripePK: 'pk_test_we0TWA247otFa2At2LgjKfdG',
  stripe: {
    liveClientId: 'ca_9U4vuT3DYpQFBIZB711zfnqMb9Ykyar1',
    testClientId: 'ca_9U4vr5IaQaI0W4r85D6mm89T3Uq8lOa3',
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
