import {
  InstanceType,
  IntegrationSystem,
  IntegrationType,
  Mcc,
  MccName,
  StripeAccountType,
} from '@app/enums';
import { EnumHelper } from '../../helpers';
import { ISchema, Type, Widget } from '../schema';
import { Integration, IIntegrationBase } from './integration';

const customAccountCheck = {
  field: 'accountType',
  shouldShow: (accountType: StripeAccountType) => accountType === StripeAccountType.CUSTOM,
};

export const StripeSchema: ISchema = {
  system: {
    type: Type.STRING,
    label: 'System',
    isHidden: true,
    default: IntegrationSystem.STRIPE,
  },
  type: {
    type: Type.STRING,
    label: 'Type',
    isHidden: true,
    default: IntegrationType.PAYMENT,
  },
  instanceType: {
    type: Type.STRING,
    label: 'Instance Type',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(InstanceType),
    },
    default: InstanceType.PRODUCTION,
  },
  stripeAccountId: {
    type: Type.STRING,
    partakeOnly: true,
    label: 'Stripe Account ID',
    onlyIf: '_id',
  },
  stripeLocationId: {
    type: Type.STRING,
    partakeOnly: true,
    label: 'Stripe Terminal Location ID',
    onlyIf: '_id',
  },
  accountType: {
    type: Type.STRING,
    label: 'Account Type',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(StripeAccountType),
    },
    default: StripeAccountType.CUSTOM,
  },
  mcc: {
    type: Type.STRING,
    label: 'MCC',
    required: true,
    partakeOnly: true,
    enum: EnumHelper.getEnumArray(Mcc, '', (key: string) => `${MccName[key]} (${Mcc[key]})`),
    widget: {
      type: Widget.SELECT,
    },
  },
  statementDescriptor: {
    type: Type.STRING,
    required: true,
    label: 'Statement Descriptor - 5-22 characters',
    minLength: 5,
    maxLength: 22,
  },
  onDeviceTippingConfig: {
    type: Type.OBJECT,
    label: 'On Device Tipping Config',
    properties: {
      isEnabled: {
        type: Type.BOOLEAN,
        label: 'Enabled',
        default: false,
      },
      tipOnTax: {
        type: Type.BOOLEAN,
        label: 'Tip on Tax',
        default: false,
      },
      tipOptions: {
        type: Type.ARRAY,
        label: 'Tip Options',
        default: [15, 18, 20],
        items: {
          type: Type.NUMBER,
          label: 'Tip %',
          description: 'e.g. 15 for 15%',
          min: 0,
          max: 100,
        },
      },
      stripeConfigurationId: {
        type: Type.STRING,
        label: 'Stripe Configuration ID',
        isHidden: true,
      },
    },
  },
  supportsEcomm: {
    type: Type.BOOLEAN,
    required: false,
    default: true,
    isHidden: true,
    label: 'Supports Ecommerce Payments',
  },
  manualTransfers: {
    type: Type.BOOLEAN,
    default: false,
    label: 'Manually Transfer Funds to Account',
    partakeOnly: true,
    onlyIf: [customAccountCheck],
  },
  usesApplicationFee: {
    type: Type.BOOLEAN,
    default: true,
    label: 'Use Application for Online vs Regular Pricing',
    partakeOnly: true,
    onlyIf: [customAccountCheck],
  },
  serviceFee: {
    type: Type.NUMBER,
    default: 199,
    partakeOnly: true,
    label: 'Delivery Fee e.g. 199 for $1.99',
    onlyIf: [customAccountCheck],
  },
  pickupFee: {
    type: Type.NUMBER,
    default: 99,
    partakeOnly: true,
    label: 'Pickup Fee e.g. 99 for $0.99',
    onlyIf: [customAccountCheck],
  },
  applicationFee: {
    type: Type.NUMBER,
    default: 0.1,
    partakeOnly: true,
    label: 'Application Fee e.g. 0.15 as 15%',
    description: 'Defaults to 10%',
  },
  baseRate: {
    type: Type.NUMBER,
    partakeOnly: true,
    default: 0.035,
    label: 'Base Rate e.g. 0.035 as 3.5%',
    description: 'Defaults to 3.5%',
    onlyIf: [customAccountCheck],
  },
  baseFlatRate: {
    type: Type.NUMBER,
    partakeOnly: true,
    default: 30,
    label: 'Base Flat Rate e.g. 30 as 30c',
    description: 'Defaults to 30c',
    onlyIf: [customAccountCheck],
  },
  amexRate: {
    type: Type.NUMBER,
    partakeOnly: true,
    default: 0.03,
    label: 'Amex Base Rate e.g. 0.035 as 3.5%',
    description: 'Defaults to 3.5%',
    onlyIf: [customAccountCheck],
  },
  amexFlatRate: {
    type: Type.NUMBER,
    partakeOnly: true,
    default: 30,
    label: 'Amex Flat Rate e.g. 30 as 30c',
    description: 'Defaults to 30c',
    onlyIf: [customAccountCheck],
  },
  externalAccounts: {
    type: Type.ARRAY,
    label: 'Bank Accounts',
    onlyIf: [
      customAccountCheck,
      {
        field: 'stripeAccountId',
        shouldShow: (stripeAccountId: string) => !!stripeAccountId,
      },
    ],
    items: {
      type: Type.OBJECT,
      label: 'Account for Payouts',
      immutable: true,
      properties: {
        bankName: {
          type: Type.STRING,
          label: 'Bank Name',
        },
        routingNumber: {
          type: Type.STRING,
          label: 'Routing',
        },
        last4: {
          type: Type.STRING,
          label: 'Last 4',
        },
      },
    },
  },
};

export interface ExternalAccount {
  bankName: string;
  last4: string;
  routingNumber: string;
  i9nId: string;
}

export interface IStripe extends IIntegrationBase {
  preAuthOnline: boolean;
  supportsEcomm: boolean;
  usesApplicationFee: boolean;
  applicationFee: number;
  baseRate: number;
  baseFlatRate: number;
  amexRate: number;
  amexFlatRate: number;
  externalAccounts: ExternalAccount[];
}

export class Stripe extends Integration<IStripe> {
  constructor(public data: IStripe) {
    super(data);
  }

  public description = 'Partake Payments powered by Stripe.';
}
