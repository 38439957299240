/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
// see https://github.com/mszewcz/safe-subscribe/blob/develop/src/safeSubscribe.ts

import { OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription, BehaviorSubject, ReplaySubject } from 'rxjs';

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    safeSubscribe: typeof safeSubscribe;
  }
}
export interface SafeSubscribable extends OnDestroy {
  _subscriptionFromSafeSubscribe$?: Subscription;
}

export function safeSubscribe<T = any>(
  target: SafeSubscribable,
  next?: (value: T) => void,
  error?: (error: any) => void,
  complete?: () => void
): Subscription {
  const sub = this.subscribe(next, error, complete);
  if (target) {
    if (!('_subscriptionFromSafeSubscribe$' in target)) {
      target._subscriptionFromSafeSubscribe$ = new Subscription();

      const originalDestroy = target.ngOnDestroy;
      if (!originalDestroy) {
        // eslint-disable-next-line max-len
        console.warn(
          `${
            (target as any).constructor.name
          } must implement OnDestroy otherwise Observable<T>.safeSubscribe will have no effect.`
        );
      }
      target.ngOnDestroy = function () {
        if (originalDestroy && typeof originalDestroy === 'function') {
          originalDestroy.apply(this, arguments);
        }
        target._subscriptionFromSafeSubscribe$.unsubscribe();
      };
    }
    target._subscriptionFromSafeSubscribe$.add(sub);
  }
  return sub;
}

Observable.prototype.safeSubscribe = safeSubscribe;
Subject.prototype.safeSubscribe = safeSubscribe;
BehaviorSubject.prototype.safeSubscribe = safeSubscribe;
ReplaySubject.prototype.safeSubscribe = safeSubscribe;
