import {
  ApiResources,
  ApiVersion,
  IntegrationSystem,
  IntegrationType,
  ProductType,
} from '../../enums';
import { Type, Widget } from '../schema';
import { CrmBaseData, CrmBaseSchema } from './crm-base';
import { IPosBase, PosBase, PosBaseSchema } from './pos-base';

export const EzLinksSchema = {
  system: {
    type: Type.STRING,
    isHidden: true,
    default: IntegrationSystem.EZ_LINKS,
  },
  type: {
    type: Type.STRING,
    isHidden: true,
    default: IntegrationType.POS,
  },
  username: {
    type: Type.STRING,
    required: true,
    label: 'Username',
  },
  password: {
    type: Type.STRING,
    required: true,
    label: 'Password',
  },
  syncDays: {
    type: Type.NUMBER,
    label: 'Sync Days',
    desciption: 'Number of days back to look for orders to sync.',
    default: 1,
  },
  useSingleQuantities: {
    type: 'boolean',
    label: 'use Single Quantities for Ordered Items',
  },
  departmentId: {
    type: Type.STRING,
    label: 'Department ID',
    widget: {
      type: Widget.SELECT,
      enum: [],
    },
  },
  defaultMemberId: {
    type: Type.STRING,
    label: 'Partake Member ID',
    description: 'This is the EZLinks Member GUID',
  },
  creditTenderId: {
    type: Type.STRING,
    label: 'Credit Tender',
    widget: {
      type: Widget.SELECT,
      enum: [],
    },
  },
  cashTenderId: {
    type: Type.STRING,
    label: 'Cash Tender',
    widget: {
      type: Widget.SELECT,
      enum: [],
    },
  },
  memberTenderId: {
    type: Type.STRING,
    label: 'Member Tender',
    widget: {
      type: Widget.SELECT,
      enum: [],
    },
  },
  eventTenderId: {
    type: Type.STRING,
    label: 'Event Tender',
    widget: {
      type: Widget.SELECT,
      enum: [],
    },
  },
  giftCardTenderId: {
    type: Type.STRING,
    label: 'Gift Card Tender',
    widget: {
      type: Widget.SELECT,
      enum: [],
    },
  },
  gratuityId: {
    type: Type.STRING,
    label: 'Gratuity',
    description: 'Its recommended to use a flat grat',
    widget: {
      type: Widget.SELECT,
      enum: [],
    },
  },
  useRetailForFB: {
    type: 'boolean',
    label: 'Use Retail Department for F&B',
  },
  tipProductId: {
    type: Type.TYPEAHEAD,
    label: 'Product ID for Gratuity',
    description: 'Select the product for Gratuity',
    resource: ApiResources.PRODUCT,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    search: {
      isDeleted: false,
      type: ProductType.MENU_ITEM,
    },
    displayFields: ['name', 'category.name'],
    onlyIf: 'useRetailForFB',
  },
  donationProductId: {
    type: Type.TYPEAHEAD,
    label: 'Product ID for Round Up Donations',
    description: 'Select the product for Donations',
    resource: ApiResources.PRODUCT,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    search: {
      isDeleted: false,
      type: ProductType.MENU_ITEM,
    },
    displayFields: ['name', 'category.name'],
  },
  serviceChargeProductId: {
    type: Type.TYPEAHEAD,
    label: 'Product ID for Service Charges',
    partakeOnly: true,
    description: 'Select the product for service charges',
    resource: ApiResources.PRODUCT,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    search: {
      isDeleted: false,
      type: ProductType.MENU_ITEM,
    },
    displayFields: ['name', 'category.name'],
    notIf: 'serviceChargeGratuityId',
  },
  serviceChargeGratuityId: {
    type: Type.STRING,
    label: 'Gratuity ID for Service Charges',
    description: 'Select the Gratuity ID for service charges',
    widget: {
      type: Widget.SELECT,
      enum: [],
    },
    partakeOnly: true,
    notIf: 'serviceChargeProductId',
  },
  usesParentChildStructure: {
    type: Type.BOOLEAN,
    label: 'Supports Parent / Child Products',
    description: "Allows orgs to create products which will sync to their parents id's",
    partakeOnly: true,
  },
  ...PosBaseSchema,
  ...CrmBaseSchema,
};

export interface IEzLinks extends IPosBase, CrmBaseData {
  username: string;
  password: string;
  syncDays: number;
  departmentId?: string;
  defaultMemberId?: string;
  useRetailForFB?: boolean;
  tipProductId?: string;
  donationProductId?: string;
  serviceChargeProductId?: string;
  serviceChargeGratuityId?: string;
  usesParentChildStructure: boolean;
}

export class EzLinks extends PosBase<IEzLinks> {
  constructor(public data: IEzLinks) {
    super(data);
  }
}
