import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import _ from 'underscore';

import { RestaurantService } from '@app/providers/restaurant/restaurant.service';
import { ValueAccessorBase } from '@app/shared/forms/value-accessor-base';

@Component({
  selector: 'app-concession-category-search-typeahead',
  templateUrl: './concession-category-search-typeahead.component.html',
  styleUrls: ['./concession-category-search-typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => ConcessionCategorySearchTypeaheadComponent),
      multi: true,
    },
  ],
})
export class ConcessionCategorySearchTypeaheadComponent extends ValueAccessorBase<any | string> {
  @Input() placeholder = 'Search category or create one';
  @Input() disabled: boolean;

  @Output() selected = new EventEmitter<any>();

  public selectedItem = null;

  constructor(private _restaurantService: RestaurantService) {
    super();
  }

  public selectCategory(selected: any) {
    if (selected) {
      this.selectedItem = selected.item;
      this.selected.emit(selected.item);
    } else if (this.selectedItem) {
      this.selectedItem = null;
      this.selected.emit(null);
    }
  }

  public onChange(value: any) {
    if (!value || typeof value === 'string') {
      this.selectCategory(null);
    }
  }

  searchCategory = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(name => {
        const query = _({ name, limit: 20 }).pick(value => value !== undefined);
        return this._restaurantService.categories(query).pipe(
          map(x => x.categories),
          catchError(() => of([]))
        );
      })
    );

  // eslint-disable-next-line class-methods-use-this
  categoryFormatter = (x: { name: string }) => {
    if (x.name) {
      return `${x.name}`;
    }
    return '';
  };
}
