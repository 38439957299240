import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import moment from 'moment';

@Pipe({
  name: 'orderTime',
})
export class OrderTimePipe implements PipeTransform {
  constructor(private _datePipe: DatePipe) {}

  transform(value: string): string {
    if (value) {
      const date = new Date(value);
      const min = moment(date).subtract(5, 'minutes').toDate();
      const max = moment(date).add(5, 'minutes').toDate();
      return `${this._datePipe.transform(min, 'hh:mm a')}-${this._datePipe.transform(
        max,
        'hh:mm a'
      )}`;
    }
    return '';
  }
}
