<input
  type="text"
  class="form-control"
  placeholder="Search products"
  [ngbTypeahead]="searchOptions"
  [resultTemplate]="selectedOptionTpl"
  [inputFormatter]="optionFormatter"
  [(ngModel)]="value"
  (ngModelChange)="onChange($event)"
  (selectItem)="selectItem($event)"
/>

<ng-template #selectedOptionTpl let-r="result">
  <span>{{ r.item?.name || r }}</span>
  <span *ngIf="r.item?.description" class="affix text-muted">
    &nbsp;(
    <span class="description">&nbsp;{{ r.item.description }}</span>
    )
  </span>
</ng-template>
