import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import pkg from '../package.json';
import '@app/shared/rxjs/extensions';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjector } from './app/app-injector.service';

if (environment.production) {
  Sentry.init({
    dsn: 'https://58cddd62adfe43ce93ecf17eec7c3004@o447922.ingest.sentry.io/6351146',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['/^//'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    release: `v${pkg.version}`,
    tracesSampleRate: 1.0,
    environment: environment.name,
    denyUrls: [/^stripe\.com/i],
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(moduleRef => {
    AppInjector.setInjector(moduleRef.injector);
  })
  .catch(err => console.log(err));
