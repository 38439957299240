<ng-container *ngIf="tags.length">
  <label>Tags</label>
  <div class="btn-group btn-group-toggle flex-wrap">
    <label
      class="btn-secondary"
      [class.btn-light]="!item.selected"
      ngbButtonLabel
      *ngFor="let item of tags"
    >
      <input type="checkbox" ngbButton [(ngModel)]="item.selected" (change)="change()" />
      {{ item.name }}
    </label>
  </div>
</ng-container>
