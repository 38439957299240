import { OrgRight, PartakeRight, Right } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

export const UserRoleSchema: ISchema = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  rights: {
    label: 'Rights',
    type: Type.ARRAY,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(OrgRight),
      multiple: true,
    },
    required: true,
  },
};

export const PartakeUserRoleSchema: ISchema = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  rights: {
    label: 'Rights',
    type: Type.ARRAY,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(PartakeRight),
      multiple: true,
    },
    required: true,
  },
};

export interface UserRoleData extends AbstractModelData {
  name: string;
  orgId: string;
  rights: Right[];
}

export class UserRole extends AbstractModel<UserRoleData> {
  constructor(public data: UserRoleData) {
    super(data);
  }
}
