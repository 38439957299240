import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe, CurrencyPipe } from '@angular/common';

import { CustomerChargePipe } from '@app/pipes/customer-charge.pipe';
import { InitialsPipe } from '@app/pipes/initials.pipe';
import { AppRatePipe } from '@app/pipes/app-rate.pipe';
import { InpersonRatePipe } from '@app/pipes/inperson-rate.pipe';
import { ConcessionPipe } from './concession.pipe';
import { MenuItemPipe } from './menu-item.pipe';
import { PhonePipe } from './phone.pipe';
import { StatusPipe } from './status.pipe';
import { OrderTimePipe } from './order-time.pipe';
import { OrderInstructionsPipe } from './order-instructions.pipe';
import { CCBrandPipe } from './cc-brand.pipe';
import { VenueFilterPipe } from './venue-filter.pipe';
import { ProductFilterPipe } from './product-filter.pipe';
import { InventoryFilterPipe } from './inventory-filter.pipe';
import { MenuFilterPipe } from './menu-filter.pipe';
import { DiscountValuePipe } from './discount-value.pipe';
import { IncrementFilterPipe } from './increment-filter.pipe';
import { PadPipe } from './pad.pipe';
import { ShortenPipe } from './shorten.pipe';
import { TeeTimeRateColorPipe } from './tee-time-rate-color.pipe';
import { TimeAdjustPipe } from './time-adjust.pipe';
import { OrderFilterPipe } from './order-filter.pipe';
import { OrderTypePipe } from './order-type.pipe';
import { OrderStatusPipe } from './order-status.pipe';
import { VenueRestaurantPipe } from './venue-restaurant.pipe';
import { OrderRestaurantsPipe } from './order-restaurants.pipe';
import { PaymentStatusPipe } from './payment-status.pipe';
import { CreditCardPipe } from './credit-card.pipe';
import { TicketTimePipe } from './ticket-time.pipe';
import { TitleCasePipe } from './title-case.pipe';
import { TaxCodePipe } from './tax-code.pipe';
import { FilterPipe } from './filter.pipe';
import { ShiftsFilterPipe } from './shifts-filter.pipe';
import { RestaurantPipe } from './restaurant.pipe';
import { GuestSincePipe } from './guest-since.pipe';
import { OrderByPipe } from './order-by.pipe';
import { CamelCaseToSpacesPipe } from './camel-case-to-spaces.pipe';
import { PtTitleCasePipe } from './pt-titlecase.pipe';
import { VoucherStatusPipe } from './voucher-status.pipe';
import { VoucherSummaryPipe } from './voucher-summary.pipe';
import { ShiftAcceptingPipe } from './shift-accepting.pipe';
import { GuestFilterPipe } from './guest-filter.pipe';
import { GuestAgePipe } from './guest-age.pipe';
import { UserPhonePipe } from './user-phone.pipe';
import { FirstNamePipe } from './first-name.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { EzTicketSummaryPipe } from './ezlinks.pipe';

@NgModule({
  declarations: [
    ConcessionPipe,
    MenuItemPipe,
    PhonePipe,
    StatusPipe,
    OrderTimePipe,
    OrderInstructionsPipe,
    CCBrandPipe,
    VenueFilterPipe,
    ProductFilterPipe,
    InventoryFilterPipe,
    OrderFilterPipe,
    MenuFilterPipe,
    DiscountValuePipe,
    IncrementFilterPipe,
    PadPipe,
    ShortenPipe,
    TeeTimeRateColorPipe,
    TimeAdjustPipe,
    CustomerChargePipe,
    InitialsPipe,
    AppRatePipe,
    InpersonRatePipe,
    TitleCasePipe,
    TaxCodePipe,
    FilterPipe,
    ShiftsFilterPipe,
    RestaurantPipe,
    OrderTypePipe,
    OrderStatusPipe,
    OrderRestaurantsPipe,
    VenueRestaurantPipe,
    PaymentStatusPipe,
    CreditCardPipe,
    TicketTimePipe,
    GuestSincePipe,
    OrderByPipe,
    CamelCaseToSpacesPipe,
    PtTitleCasePipe,
    VoucherStatusPipe,
    VoucherSummaryPipe,
    ShiftAcceptingPipe,
    GuestFilterPipe,
    GuestAgePipe,
    UserPhonePipe,
    FirstNamePipe,
    SafeHtmlPipe,
    EzTicketSummaryPipe,
  ],
  exports: [
    DatePipe,
    DecimalPipe,
    CurrencyPipe,
    ConcessionPipe,
    MenuItemPipe,
    PhonePipe,
    StatusPipe,
    OrderTimePipe,
    OrderInstructionsPipe,
    CCBrandPipe,
    VenueFilterPipe,
    ProductFilterPipe,
    InventoryFilterPipe,
    OrderFilterPipe,
    MenuFilterPipe,
    DiscountValuePipe,
    IncrementFilterPipe,
    PadPipe,
    ShortenPipe,
    TeeTimeRateColorPipe,
    TimeAdjustPipe,
    OrderTypePipe,
    OrderStatusPipe,
    OrderRestaurantsPipe,
    VenueRestaurantPipe,
    PaymentStatusPipe,
    CreditCardPipe,
    TicketTimePipe,
    CustomerChargePipe,
    InitialsPipe,
    AppRatePipe,
    InpersonRatePipe,
    TitleCasePipe,
    TaxCodePipe,
    FilterPipe,
    ShiftsFilterPipe,
    RestaurantPipe,
    GuestSincePipe,
    OrderByPipe,
    CamelCaseToSpacesPipe,
    PtTitleCasePipe,
    VoucherStatusPipe,
    VoucherSummaryPipe,
    ShiftAcceptingPipe,
    GuestFilterPipe,
    GuestAgePipe,
    UserPhonePipe,
    FirstNamePipe,
    SafeHtmlPipe,
    EzTicketSummaryPipe,
  ],
  imports: [CommonModule],
  providers: [
    DatePipe,
    DecimalPipe,
    CurrencyPipe,
    ConcessionPipe,
    MenuItemPipe,
    PhonePipe,
    StatusPipe,
    OrderTimePipe,
    OrderInstructionsPipe,
    CCBrandPipe,
    VenueFilterPipe,
    ProductFilterPipe,
    InventoryFilterPipe,
    OrderFilterPipe,
    MenuFilterPipe,
    DiscountValuePipe,
    IncrementFilterPipe,
    PadPipe,
    ShortenPipe,
    TeeTimeRateColorPipe,
    TimeAdjustPipe,
    CustomerChargePipe,
    InitialsPipe,
    AppRatePipe,
    InpersonRatePipe,
    TitleCasePipe,
    TaxCodePipe,
    FilterPipe,
    ShiftsFilterPipe,
    CurrencyPipe,
    RestaurantPipe,
    OrderTypePipe,
    OrderStatusPipe,
    OrderRestaurantsPipe,
    VenueRestaurantPipe,
    PaymentStatusPipe,
    CreditCardPipe,
    TicketTimePipe,
    GuestSincePipe,
    OrderByPipe,
    CamelCaseToSpacesPipe,
    PtTitleCasePipe,
    ShiftAcceptingPipe,
    GuestFilterPipe,
    GuestAgePipe,
    UserPhonePipe,
    FirstNamePipe,
    SafeHtmlPipe,
    EzTicketSummaryPipe,
  ],
})
export class PipesModule {}
