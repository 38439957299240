import { Integration, IIntegrationBase } from './integration';

export const CrmBaseSchema = {
  syncMembers: {
    type: 'boolean',
    label: 'Sync Members',
    default: false,
  },
};

export interface CrmBaseData extends IIntegrationBase {
  syncCategories: boolean;
  syncProducts: boolean;
  syncDiscounts: boolean;
  syncGratuities: boolean;
  syncTenders: boolean;
  syncTaxes: boolean;
  syncEmployees: boolean;
}

export class CrmBase<T extends CrmBaseData> extends Integration<T> {
  constructor(public data: T) {
    super(data);
  }
}
