import { ApiResources, ApiVersion, ExportType, ReportType, UserRoles } from '@app/enums';
import { ISchema, Type, Widget } from '../schema';
import { Report } from './report';
import { BaseReportProps } from './base-report-props';

export const GuestReportSchema: ISchema = {
  dateField: {
    section: 'basic',
    type: Type.STRING,
    label: 'Date Field',
    description: 'Select the date field to filter by.',
    required: true,
    widget: {
      type: Widget.SELECT,
    },
    default: ['createdAt'],
    enum: [
      {
        key: 'createdAt',
        name: 'Guest Created',
      },
      {
        key: 'ordersSummary.lastOrder',
        name: 'Last Order',
      },
      {
        key: 'ordersSummary.firstOrder',
        name: 'First Order',
      },
    ],
  },
  from: BaseReportProps.from,
  to: BaseReportProps.to,
  consumerOnly: BaseReportProps.consumerOnly,
  productPurchased: {
    section: 'basic',
    type: Type.TYPEAHEAD,
    label: 'Product Purchased',
    description: 'Filter guests by a specific product purchased.',
    resource: ApiResources.PRODUCT,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    search: { isDeleted: false },
    displayFields: ['name', 'category.name'],
  },
  email: BaseReportProps.email,
};

export interface IGuestReportData {
  dateField: string;
  from: string;
  to: string;
  email: string;
}

export class GuestReport extends Report<IGuestReportData> {
  ignoreProperties = [];
  icon = 'fas fa-user';
  title = 'Guest Export';
  description = 'Export guests data and order summary info';
  isImmediate = false;
  data: IGuestReportData = {} as IGuestReportData;
  schema: ISchema = GuestReportSchema;
  roles: UserRoles[] = [UserRoles.ADMIN, UserRoles.CUSTOMER];
  type: ReportType = ReportType.GUEST;
  exportOptions: ExportType[] = [ExportType.CSV];
}
