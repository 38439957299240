/* eslint-disable no-param-reassign */
/* eslint-disable no-console */

import { Injectable, Type } from '@angular/core';
import { LogLevel } from './log-level';

@Injectable({
  providedIn: 'root',
})
export class Logger {
  constructor() {}

  /**
   * Used to log a informational message.
   *
   * @param tagPrefix The prefix for the log entries tag (normally the name of the component).
   * @param tag The tag for the log (normally the name of the method).
   * @param message The descriptive text for the log entry.
   * @param [metadata] An optional object to be logged with the log entry.
   */
  trace(tagPrefix: Type<any> | string, tag: string, message: string, ...metadata) {
    this.log(LogLevel.TRACE, tagPrefix, tag, message, ...metadata);
  }

  /**
   * Used to log debugging information.
   *
   * @param tagPrefix The prefix for the log entries tag (normally the name of the component).
   * @param tag The tag for the log (normally the name of the method).
   * @param message The descriptive text for the log entry.
   * @param [metadata] An optional object to be logged with the log entry.
   */
  debug(tagPrefix: Type<any> | string, tag: string, message: string, ...metadata) {
    this.log(LogLevel.DEBUG, tagPrefix, tag, message, ...metadata);
  }

  /**
   * Used to log a informational message.
   *
   * @param tagPrefix The prefix for the log entries tag (normally the name of the component).
   * @param tag The tag for the log (normally the name of the method).
   * @param message The descriptive text for the log entry.
   * @param [metadata] An optional object to be logged with the log entry.
   */
  info(tagPrefix: Type<any> | string, tag: string, message: string, ...metadata) {
    this.log(LogLevel.INFO, tagPrefix, tag, message, ...metadata);
  }

  /**
   * Used to log a warning.
   *
   * @param tagPrefix The prefix for the log entries tag (normally the name of the component).
   * @param tag The tag for the log (normally the name of the method).
   * @param message The descriptive text for the log entry.
   * @param [metadata] An optional object to be logged with the log entry.
   */
  warn(tagPrefix: Type<any> | string, tag: string, message: string, ...metadata) {
    this.log(LogLevel.WARN, tagPrefix, tag, message, ...metadata);
  }

  /**
   * Used to log an error.
   *
   * @param tagPrefix The prefix for the log entries tag (normally the name of the component).
   * @param tag The tag for the log (normally the name of the method).
   * @param error The descriptive text or Error object for the log entry.
   * @param [metadata] An optional object to be logged with the log entry.
   */
  error(tagPrefix: Type<any> | string, tag: string, error: Error | string, ...metadata) {
    this.log(LogLevel.ERROR, tagPrefix, tag, error, ...metadata);
  }

  /**
   * Used to log an exception.
   *
   * @param tagPrefix The prefix for the log entries tag (normally the name of the component).
   * @param tag The tag for the log (normally the name of the method).
   * @param error An Error object thrown when runtime error occurred.
   * @param [metadata] An optional object to be logged with the log entry.
   */
  exception(tagPrefix: Type<any> | string, tag: string, error: Error | string, ...metadata) {
    this.log(LogLevel.ERROR, tagPrefix, tag, error, ...metadata);
  }

  /**
   * Used to log a fatal error.
   *
   * @param tagPrefix The prefix for the log entries tag (normally the name of the component).
   * @param tag The tag for the log (normally the name of the method).
   * @param error An Error object thrown when runtime error occurred.
   * @param [metadata] An optional object to be logged with the log entry.
   */
  fatal(tagPrefix: Type<any> | string, tag: string, error: Error | string, ...metadata) {
    this.log(LogLevel.FATAL, tagPrefix, tag, error, ...metadata);
  }

  // #region Private Methods

  private log(
    logLevel: LogLevel,
    tagPrefix: Type<any> | string,
    tag: string,
    message: any,
    ...metadata
  ) {
    if (!logLevel) {
      logLevel = LogLevel.DEBUG;
    }

    // skip optional tagPrefix and tag arguments
    if (tagPrefix && !tag && !message) {
      return this.log(logLevel, null, null, tagPrefix, ...metadata);
    }

    if (!tagPrefix) {
      tagPrefix = '';
    }

    // resolve type name in tagPrefix
    if (tagPrefix instanceof Type) {
      tagPrefix = tagPrefix.name;
    }

    if (!tag) {
      tag = 'NoTag';
    }

    if (!message) {
      message = '[No Message]';
    }

    const consoleMessage = `${tagPrefix ? `[${tagPrefix}.${tag}]` : `[${tag}]`} ${message}`;

    switch (logLevel) {
      case LogLevel.TRACE:
        console.trace(consoleMessage, ...metadata);
        break;
      case LogLevel.DEBUG:
        console.debug(consoleMessage, ...metadata);
        break;
      case LogLevel.INFO:
        console.info(consoleMessage, ...metadata);
        break;
      case LogLevel.WARN:
        console.warn(consoleMessage, ...metadata);
        // TODO: connect external logging service, like Raygun
        break;
      case LogLevel.ERROR:
        console.error(consoleMessage, ...metadata);
        // TODO: connect external logging service, like Raygun
        break;
      case LogLevel.FATAL:
        console.error(consoleMessage, ...metadata);
        // TODO: connect external crash reporting service, like Crashlytics
        break;
      default:
        console.debug(consoleMessage, ...metadata);
        break;
    }
  }

  // #endregion
}
