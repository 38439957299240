import _ from 'lodash';

export interface AbstractModelData {
  _id?: string;
  name?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export type Selectable<T> = T & { selected: boolean };

export class AbstractModel<Data extends AbstractModelData> {
  constructor(public data: Data) {}

  get id() {
    return this.data._id;
  }

  get json() {
    return _.cloneDeep(this.data);
  }

  get displayName() {
    return this.data.name;
  }

  /**
   * @deprecated
   * - Use .data instead
   */
  public get(key: string) {
    return _.get(this.data, key);
  }

  /**
   * @deprecated
   * - Use .data instead
   */
  public set(key: string, val: any) {
    this.data[key] = val;
  }

  public getDiff(update: Partial<Data>, original: Data, allowNulls = false): Partial<Data> {
    if (_.isEqual(update, original)) {
      return null;
    }
    const diff: Partial<Data> = _.reduce(
      update,
      (result: Partial<Data>, value: any, key: string) => {
        if (_.isPlainObject(value)) {
          const objectIsDifferent = this.getDiff(value, _.get(original, key), allowNulls);
          if (_.size(objectIsDifferent)) {
            result[key] = value;
          }
        } else if (!_.isEqual(value, _.get(original, key))) {
          result[key] = value;
        }
        return result;
      },
      {}
    );
    if (allowNulls) {
      return diff;
    }
    return _.omitBy(diff, _.isNull) as Partial<Data>;
  }
}
