import {
  AccountSettingsLinks,
  ApiResources,
  ApiVersion,
  DefaultIconBySettingsPage,
  DefaultIconByTab,
  DefaultTitleByTab,
  DefaultTitleForSettingsPage,
  NavigationTabs,
} from '../../enums';
import { EnumHelper } from '../../helpers';
import { ISchema, Type, Widget } from '../schema';

const DomainDetailsSchema: ISchema = {
  domain: {
    type: Type.STRING,
    label: 'Hidden',
    isHidden: true,
  },
  zoneId: {
    type: Type.STRING,
    label: 'Hidden',
    isHidden: true,
  },
  appId: {
    type: Type.STRING,
    label: 'Hidden',
    isHidden: true,
  },
  cname: {
    type: Type.STRING,
    label: 'Hidden',
    isHidden: true,
  },
  cnameId: {
    type: Type.STRING,
    label: 'Hidden',
    isHidden: true,
  },
  recordId: {
    type: Type.STRING,
    label: 'Hidden',
    isHidden: true,
  },
  customDomain: {
    type: Type.BOOLEAN,
    label: 'Hidden',
    isHidden: true,
  },
  useOwnDns: {
    type: Type.BOOLEAN,
    label: 'Hidden',
    isHidden: true,
  },
};

const AppMetaDataSchema: ISchema = {
  title: {
    type: Type.STRING,
    label: 'Title',
    description: 'Title seen in navigation bars.',
  },
  description: {
    type: Type.STRING,
    label: 'Description',
    description: 'Longer text shown when sharing a page.',
  },
  icon: {
    type: Type.IMAGES,
    label: 'Favicon',
    bucket: 'favicon',
  },
  image: {
    type: Type.IMAGES,
    label: 'Image',
    bucket: 'og-image',
  },
};

const BrandSchema: ISchema = {
  name: {
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  logo: {
    type: Type.IMAGES,
    label: 'Logo',
    bucket: 'brand-logo',
  },
};

const AuthSchema: ISchema = {
  placeholderText: {
    type: Type.STRING,
    label: 'Placeholder Text',
    description: 'Text shown to the user on Account page to drive signup/login.',
  },
  loginText: {
    type: Type.STRING,
    label: 'Login Text',
    description: 'Text on login page',
  },
  signupText: {
    type: Type.STRING,
    label: 'Signup Text',
    description: 'Text on signup page',
  },
  requireUsername: {
    type: Type.BOOLEAN,
    label: 'Require username on signup',
    description: 'Does a username need to be added for a new user',
  },
};

const CustomPageSettingsSchema: ISchema = {
  page: {
    type: Type.STRING,
    label: 'Page Type',
    enum: [
      { key: 'branded', name: 'Branded' },
      { key: 'default', name: 'Default' },
    ],
    widget: {
      type: Widget.SELECT,
    },
  },
  title: {
    type: Type.STRING,
    label: 'Title',
  },
};

const CustomPageSchema: ISchema = {
  name: {
    type: Type.STRING,
    label: 'Page',
    enum: [
      { key: 'fantasy', name: 'Fantasy' },
      { key: 'live', name: 'Live' },
    ],
    widget: {
      type: Widget.SELECT,
    },
  },
  settings: {
    type: Type.OBJECT,
    label: 'Settings',
    properties: CustomPageSettingsSchema,
  },
};

const TabDefaultsByUrl = {
  [NavigationTabs.ACCOUNT]: {
    title: DefaultTitleByTab.ACCOUNT,
    iconName: DefaultIconByTab.ACCOUNT,
  },
  [NavigationTabs.ATTRACTIONS]: {
    title: DefaultTitleByTab.ATTRACTIONS,
    iconName: DefaultIconByTab.ATTRACTIONS,
  },
  [NavigationTabs.BETTING]: {
    title: DefaultTitleByTab.BETTING,
    iconName: DefaultIconByTab.BETTING,
  },
  [NavigationTabs.DISCOVER]: {
    title: DefaultTitleByTab.DISCOVER,
    iconName: DefaultIconByTab.DISCOVER,
  },
  [NavigationTabs.EVENTS]: {
    title: DefaultTitleByTab.EVENTS,
    iconName: DefaultIconByTab.EVENTS,
  },
  [NavigationTabs.FANTASY]: {
    title: DefaultTitleByTab.FANTASY,
    iconName: DefaultIconByTab.FANTASY,
  },
  [NavigationTabs.FAVORITES]: {
    title: DefaultTitleByTab.FAVORITES,
    iconName: DefaultIconByTab.FAVORITES,
  },
  [NavigationTabs.LIVE]: {
    title: DefaultTitleByTab.LIVE,
    iconName: DefaultIconByTab.LIVE,
  },
  [NavigationTabs.MENU]: {
    title: DefaultTitleByTab.MENU,
    iconName: DefaultIconByTab.MENU,
  },
  [NavigationTabs.ORDERS]: {
    title: DefaultTitleByTab.ORDERS,
    iconName: DefaultIconByTab.ORDERS,
  },
  [NavigationTabs.SEARCH]: {
    title: DefaultTitleByTab.SEARCH,
    iconName: DefaultIconByTab.SEARCH,
  },
  [NavigationTabs.SOCIAL]: {
    title: DefaultTitleByTab.SOCIAL,
    iconName: DefaultIconByTab.SOCIAL,
  },
  [NavigationTabs.VENUES]: {
    title: DefaultTitleByTab.VENUES,
    iconName: DefaultIconByTab.VENUES,
  },
};

const TabSchema: ISchema = {
  url: {
    type: Type.STRING,
    label: 'App Url',
    description: 'The view which you want the tab to go to.',
    enum: EnumHelper.getEnumArray(NavigationTabs),
    widget: {
      type: Widget.SELECT,
    },
    notIf: [
      {
        field: 'externalUrl',
        shouldShow: (value: string) => !value?.length,
      },
    ],
    onChange: (value: NavigationTabs, data: any) => {
      const defaults = TabDefaultsByUrl[value];
      data.title = defaults?.title;
      data.iconName = defaults?.iconName;
    },
  },
  externalUrl: {
    type: Type.STRING,
    label: 'External Url',
    description: 'An external url to send the user',
    onChange: (value: NavigationTabs, data: any) => {
      if (value) {
        data.url = null;
      }
    },
  },
  title: {
    type: Type.STRING,
    label: 'Tab Title',
    description: 'Tab Name - label that will be displayed to the user.',
    required: true,
  },
  iconName: {
    type: Type.STRING,
    label: 'Icon Name',
    description: 'View https://ionicons.com for options',
    required: true,
  },
  id: {
    type: Type.STRING,
    label: 'Location (Menu) or Venue ID',
    description:
      "Get the location's _id property from the View JSON option on the location profile.",
    onlyIf: [
      {
        field: 'url',
        shouldShow: (value: NavigationTabs) =>
          [NavigationTabs.MENU, NavigationTabs.VENUES].includes(value),
      },
    ],
  },
  venueId: {
    type: Type.STRING,
    label: 'Venue ID',
    description:
      'Get the venue ID from the location set above. This can be found in the View JSON option on the location profile.',
    onlyIf: [
      {
        field: 'url',
        shouldShow: (value: NavigationTabs) => [NavigationTabs.MENU].includes(value),
      },
    ],
  },
};

const AccountMenuDefaultsByUrl = {
  [AccountSettingsLinks.PROFILE]: {
    title: DefaultTitleForSettingsPage.PROFILE,
    iconName: DefaultIconBySettingsPage.PROFILE,
  },
  [AccountSettingsLinks.PAYMENT]: {
    title: DefaultTitleForSettingsPage.PAYMENT,
    iconName: DefaultIconBySettingsPage.PAYMENT,
  },
  [AccountSettingsLinks.ORDER_HISTORY]: {
    title: DefaultTitleForSettingsPage.ORDER_HISTORY,
    iconName: DefaultIconBySettingsPage.ORDER_HISTORY,
  },
  [AccountSettingsLinks.GAME_HISTORY]: {
    title: DefaultTitleForSettingsPage.GAME_HISTORY,
    iconName: DefaultIconBySettingsPage.GAME_HISTORY,
  },
  [AccountSettingsLinks.SETTINGS]: {
    title: DefaultTitleForSettingsPage.SETTINGS,
    iconName: DefaultIconBySettingsPage.SETTINGS,
  },
  [AccountSettingsLinks.ACCOUNT_BALANCES]: {
    title: DefaultTitleForSettingsPage.ACCOUNT_BALANCES,
    iconName: DefaultIconBySettingsPage.ACCOUNT_BALANCES,
  },
};

const AccountMenuSchema: ISchema = {
  url: {
    type: Type.STRING,
    label: 'App Url',
    description: 'The view which you want the tab to go to.',
    enum: EnumHelper.getEnumArray(AccountSettingsLinks),
    widget: {
      type: Widget.SELECT,
    },
    onChange: (value: AccountSettingsLinks, data: any) => {
      const defaults = AccountMenuDefaultsByUrl[value];
      data.title = defaults?.title;
      data.iconName = defaults?.iconName;
    },
  },
  title: {
    type: Type.STRING,
    label: 'Menu Item Title',
    description: 'Menu Item - label that will be displayed to the user.',
  },
  iconName: {
    type: Type.STRING,
    label: 'Icon Name',
    description: 'View https://ionicons.com for options',
  },
};

const AppConfigSchema: ISchema = {
  home: {
    type: Type.STRING,
    label: 'Home Page',
    description: 'The default tab a user should be taken to.',
    enum: EnumHelper.getEnumArray(NavigationTabs),
    widget: {
      type: Widget.SELECT,
    },
  },
  tabs: {
    section: 'Navigation',
    type: Type.ARRAY,
    label: 'Navigation',
    items: {
      type: Type.OBJECT,
      label: 'Tabs',
      properties: TabSchema,
    },
  },
  accountMenu: {
    section: 'Account',
    type: Type.ARRAY,
    label: 'Account Menu',
    items: {
      type: Type.OBJECT,
      label: 'Account Menu',
      properties: AccountMenuSchema,
    },
  },
  brand: {
    section: 'Brand',
    type: Type.OBJECT,
    label: 'Branding',
    properties: BrandSchema,
  },
  classifications: {
    type: Type.ARRAY,
    label: 'Classifications',
    items: {
      type: Type.TYPEAHEAD,
      label: 'Classification',
      resource: ApiResources.CLASSIFICATIONS,
      version: ApiVersion.V2,
      dataProp: 'data.data',
      modelProp: 'name',
    },
  },
  auth: {
    type: Type.OBJECT,
    label: 'Account Signup / Login Page Text',
    properties: AuthSchema,
  },
  customPages: {
    type: Type.ARRAY,
    label: 'Customize Page Styles',
    items: {
      type: Type.OBJECT,
      label: 'Page Styles',
      properties: CustomPageSchema,
    },
  },
};

export const AppSchema: ISchema = {
  name: {
    section: 'App Details',
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  buildVersion: {
    section: 'App Details',
    type: Type.STRING,
    label: 'Build Version',
    description:
      'This controls which version of Partake Live an app has. Defaults to "latest" as the newest build (not recommended). All versions should be entered as "v2.0.0" if not "latest".',
    default: 'latest',
  },
  orgId: {
    section: 'App Details',
    type: Type.TYPEAHEAD,
    label: 'Org',
    partakeOnly: true,
    immutable: true,
    resource: ApiResources.ORGS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
  },
  active: {
    section: 'App Details',
    type: Type.BOOLEAN,
    label: 'Is Active',
    default: true,
  },
  config: {
    section: 'App Settings',
    type: Type.OBJECT,
    label: 'Config',
    properties: AppConfigSchema,
  },
  domainDetails: {
    section: 'Domain Details',
    type: Type.OBJECT,
    label: 'Domain Details',
    description: 'A custom domain for which this will be displayed for.',
    properties: DomainDetailsSchema,
    isHidden: true,
  },
  meta: {
    section: 'SEO',
    type: Type.OBJECT,
    label: 'SEO Meta Data',
    properties: AppMetaDataSchema,
    isHidden: true,
  },
};
