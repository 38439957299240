<!-- ============================================================== -->
<!-- START ERROR LIST COMPONENT -->
<!-- ============================================================== -->
<div class="page-error">
  <div class="error" *ngFor="let error of errors; let i = index">
    <ngb-alert [type]="error.type" (closed)="removeError(i)">{{ error.message }}</ngb-alert>
  </div>
</div>
<!-- ============================================================== -->
<!-- END ERROR LIST COMPONENT -->
<!-- ============================================================== -->
