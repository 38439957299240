import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentStatus',
})
export class PaymentStatusPipe implements PipeTransform {
  transform(transactions: any[]): string {
    const charges = transactions || [];

    let paid = !!charges.length;
    let cardOnFile = false;
    let partiallyPaid = false;
    let refunded = false;

    charges.forEach(charge => {
      if (charge.isRefunded || charge.isRefund) {
        refunded = true;
      } else if (charge.isPaid && !paid) {
        partiallyPaid = true;
      } else if (!charge.isPaid) {
        paid = false;
      }

      if (charge.token) {
        cardOnFile = true;
      }
      if (charge.paymentIntent) {
        cardOnFile = true;
      }
    });

    let status = 'Unpaid';
    if (paid && !refunded) {
      status = 'Paid';
    } else if (refunded) {
      status = 'Refunded';
    } else if (!paid && partiallyPaid) {
      status = 'Partially Paid';
    } else if (!paid && !partiallyPaid && cardOnFile) {
      status = 'Card on File';
    }

    return status;
  }
}
