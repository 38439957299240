import { IFilterOptionValue, SelectEnum } from '../models';
import { AbstractModel, AbstractModelData } from '../models/abstract.model';
import { TitleCasePipe } from '../pipes/title-case.pipe';

export function getNameValueArray(enumObj: any): IFilterOptionValue[] {
  const titleCasePipe = new TitleCasePipe();
  return Object.keys(enumObj)
    .filter(key => key !== 'name')
    .map((key: string) => ({
      name: enumObj.name ? enumObj.name(enumObj[key]) : titleCasePipe.transform(enumObj[key]),
      value: enumObj[key],
    }));
}

type NameGenerator = (key: string | number) => string;

export function getEnumArray(
  enumObj: any,
  prependName = '',
  nameGenerator?: NameGenerator
): SelectEnum[] {
  const titleCasePipe = new TitleCasePipe();
  return Object.keys(enumObj)
    .filter(key => key !== 'name')
    .map((key: string) => {
      const enumNameGenerator = nameGenerator || enumObj.name || undefined;
      const nameValue = nameGenerator ? key : enumObj[key];
      const name = enumNameGenerator ? enumNameGenerator(nameValue) : nameValue;
      return {
        name: `${prependName}${titleCasePipe.transform(name)}`,
        key: enumObj[key],
      };
    });
}

export function getEnumArrayFromModels<Data>(
  models: AbstractModel<Data>[]
): { name: string; key: string }[] {
  return models.map((model: AbstractModel<Data>) => ({
    name: model.displayName,
    key: model.id,
  }));
}
export function getEnumArrayFromData<Data extends AbstractModelData>(
  data: Data[]
): { name: string; key: string }[] {
  return data.map((model: Data) => ({
    name: model.name,
    key: model._id,
  }));
}

export function getValues(enumObj: any): string[] {
  return Object.keys(enumObj)
    .filter(key => key !== 'name')
    .map((key: string) => enumObj[key]);
}
