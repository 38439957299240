import _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstName',
})
export class FirstNamePipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    }
    const split = value.split(' ');
    return _.first(split);
  }
}
