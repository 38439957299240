import { ISchema, Type } from '../schema';

export const DateRangeSchema: ISchema = {
  startMonth: {
    label: 'Start Month',
    type: Type.NUMBER,
    isHidden: true,
  },
  startDay: {
    label: 'Start Day',
    type: Type.NUMBER,
    isHidden: true,
  },
  startYear: {
    label: 'Start Year',
    type: Type.NUMBER,
    isHidden: true,
  },
  startDate: {
    label: 'Start Date',
    type: Type.DATE,
    default: () => new Date(),
    required: true,
  },
  endMonth: {
    label: 'End Month',
    type: Type.NUMBER,
    isHidden: true,
  },
  endDay: {
    label: 'End Day',
    type: Type.NUMBER,
    isHidden: true,
  },
  endYear: {
    label: 'End Year',
    type: Type.NUMBER,
    isHidden: true,
  },
  endDate: {
    label: 'End Date',
    type: Type.DATE,
    default: () => new Date(),
    required: true,
  },
  utcOffset: {
    label: 'Start Hour',
    type: Type.NUMBER,
    isHidden: true,
  },
};

export interface DateRange {
  startMonth: number;
  startDay: number;
  startYear: number;
  startDate: string;
  endMonth: number;
  endDay: number;
  endYear: number;
  endDate: string;
  utcOffset: number;
}

export const TeeTimeRangeSchema: ISchema = {
  startHours: {
    label: 'Start Hour',
    type: Type.NUMBER,
    required: true,
  },
  startMins: {
    label: 'Start Minute',
    type: Type.NUMBER,
    required: true,
  },
  doNotAddBeforeSunrise: {
    label: 'Do Not Add Before Sunrise',
    type: Type.BOOLEAN,
    default: false,
  },
  sunriseOffset: {
    label: 'Sunrise Offset',
    description: 'The number of minutes after sunrise that a tee time can be created.',
    type: Type.NUMBER,
    onlyIf: 'doNotAddBeforeSunrise',
  },
  endHours: {
    label: 'End Hour',
    type: Type.NUMBER,
    required: true,
  },
  endMins: {
    label: 'End Minute',
    type: Type.NUMBER,
    required: true,
  },
  doNotAddBeforeSunset: {
    label: 'Do Not Add Before Sunset',
    type: Type.BOOLEAN,
    default: false,
  },
  sunsetOffset: {
    label: 'Sunset Offset',
    description: 'The number of minutes before sunset that a tee time can start.',
    type: Type.NUMBER,
    onlyIf: 'doNotAddBeforeSunset',
  },
};

export interface TeeTimeRange {
  startHours: number;
  startMins: number;
  doNotAddBeforeSunrise: boolean;
  sunriseOffset: number;
  endHours: number;
  endMins: number;
  doNotAddBeforeSunset: boolean;
  sunsetOffset: number;
}

export const DaysOfWeekActiveSchema: ISchema = {
  monday: {
    label: 'Monday',
    type: Type.BOOLEAN,
    default: true,
  },
  tuesday: {
    label: 'Tuesday',
    type: Type.BOOLEAN,
    default: true,
  },
  wednesday: {
    label: 'Wednesday',
    type: Type.BOOLEAN,
    default: true,
  },
  thursday: {
    label: 'Thursday',
    type: Type.BOOLEAN,
    default: true,
  },
  friday: {
    label: 'Friday',
    type: Type.BOOLEAN,
    default: true,
  },
  saturday: {
    label: 'Saturday',
    type: Type.BOOLEAN,
    default: true,
  },
  sunday: {
    label: 'Sunday',
    type: Type.BOOLEAN,
    default: true,
  },
};

export interface DaysOfWeekActive {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}
