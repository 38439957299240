<div class="m-b-10 ms-auto d-flex align-items-center">
  <div class="col-md-8 col-sm-12 p-0" *ngIf="filterOptions?.length">
    <div
      ngbDropdown
      #filterDropdown="ngbDropdown"
      container="body"
      autoClose="outside"
      class="d-inline-block dropdown"
    >
      <button class="btn btn-outline-secondary" ngbDropdownToggle>Filter</button>
      <div ngbDropdownMenu>
        <div class="col p-15">
          <p>Filter By</p>
          <div class="mb-3">
            <select
              class="form-select"
              [(ngModel)]="filterObj.property"
              (ngModelChange)="optionChange()"
            >
              <option *ngFor="let option of filterOptions" [ngValue]="option">
                {{ option.name }}
              </option>
            </select>
          </div>
          <ng-container *ngIf="filterObj.property">
            <p>is</p>
            <ng-container [ngSwitch]="filterObj.property?.options?.type">
              <div class="mb-3" *ngSwitchCase="'input'">
                <input
                  type="text"
                  class="form-control form-control-line"
                  placeholder="Search..."
                  [(ngModel)]="filterObj.value"
                />
              </div>
              <div class="mb-3" *ngSwitchCase="'date'">
                <app-date-time-input
                  [hideTime]="true"
                  [(date)]="filterObj.value"
                  (dateChange)="dateChange($event)"
                ></app-date-time-input>
              </div>
              <div class="mb-3" *ngSwitchCase="'typeahead'">
                <app-resource-typeahead
                  [domain]="filterObj.property?.options?.domain"
                  [resource]="filterObj.property?.key"
                  [version]="filterObj.property?.options?.version"
                  [query]="filterObj.property?.options?.query"
                  [placeholder]="'Search ' + filterObj.property?.name"
                  [property]="filterObj.property?.options?.modelProp"
                  [dataProp]="filterObj.property?.options?.dataProp"
                  [(value)]="filterObj.value"
                  [displayFields]="filterObj.property?.options?.displayFields"
                  (selected)="selectProp($event)"
                ></app-resource-typeahead>
              </div>
              <div class="mb-3" *ngSwitchDefault>
                <ng-container *ngIf="filterObj.property?.options?.searchable">
                  <ng-select
                    #select
                    [items]="filterObj.property?.options?.values"
                    [searchable]="false"
                    [(ngModel)]="filterObj.value"
                    [multiple]="filterObj.property?.options?.multiple"
                    [closeOnSelect]="!filterObj.property?.options?.multiple"
                    bindLabel="name"
                    bindValue="value"
                  >
                    <ng-template ng-header-tmp>
                      <input
                        style="width: 100%; line-height: 24px"
                        type="text"
                        (input)="select.filter($event.target.value)"
                      />
                    </ng-template>
                    <ng-template
                      ng-option-tmp
                      let-opt="item"
                      let-opt$="item$"
                      let-index="index"
                      let-search="searchTerm"
                    >
                      <input
                        *ngIf="filterObj.property?.options?.multiple"
                        id="item-{{ index }}"
                        type="checkbox"
                        [ngModel]="opt$.selected"
                      />
                      {{ opt.name }}
                    </ng-template>
                  </ng-select>
                </ng-container>
                <ng-container *ngIf="!filterObj.property?.options?.searchable">
                  <select
                    [multiple]="filterObj.property?.options?.multiple || false"
                    class="form-select"
                    [(ngModel)]="filterObj.value"
                  >
                    <option value="">Select</option>
                    <option
                      *ngFor="let item of filterObj.property?.options?.values"
                      [ngValue]="item.value"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <button
            class="btn btn-info btn-block"
            [disabled]="!filterObj.value && !filterObj.value?.length"
            (click)="addFilter()"
          >
            Add Filter
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col text-right p-0" *ngIf="showCreate">
    <button
      class="btn btn-info btn-new"
      *ngIf="createType === 'router'"
      [routerLink]="['./create']"
    >
      Create
    </button>
    <button class="btn btn-info btn-new" *ngIf="createType === 'click'" (click)="doCreate()">
      Create
    </button>
    <div
      ngbDropdown
      *ngIf="createType === 'dropdown' && createOptions"
      #filterDropdown="ngbDropdown"
      container="body"
      autoClose="outside"
      class="d-inline-block dropdown"
    >
      <button class="btn btn-outline-secondary" ngbDropdownToggle>Actions</button>
      <div ngbDropdownMenu>
        <a
          class="clickable dropdown-item"
          *ngFor="let opt of createOptions"
          (click)="doCreate(opt.key)"
        >
          {{ opt.name }}
        </a>
      </div>
    </div>
  </div>
</div>
<div class="m-b-15 ms-auto d-flex align-items-center" *ngIf="filter.length">
  <span class="badge badge-secondary badge-filter" *ngFor="let item of filter; let i = index">
    {{ item | venueFilter }}
    <i class="mdi mdi-close" (click)="removeFilter(i)"></i>
  </span>
</div>
