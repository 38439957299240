<div class="modal-header">
  <h4 class="modal-title">Crop Image</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="dismiss()"
    tabindex="-1"
  ></button>
</div>
<div class="modal-body">
  <div class="d-flex justify-content-center align-items-center spinner-container" *ngIf="!loaded">
    <div class="spinner-grow" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="maintainAspectRatio"
    [aspectRatio]="aspectRatio ? aspectRatio : 16 / 9"
    [cropperMinWidth]="cropperMinWidth ? cropperMinWidth : 900"
    [resizeToWidth]="resizeToWidth ? resizeToWidth : 900"
    [onlyScaleDown]="true"
    [format]="format"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-8 text-left">
      <button
        class="btn btn-outline-primary"
        [ngClass]="{ 'btn-outline-secondary': maintainAspectRatio && aspectRatio !== 1 }"
        (click)="maintainAspectRatio = true; aspectRatio = 16 / 9"
      >
        Full
      </button>
      <button
        class="btn btn-outline-primary"
        [ngClass]="{ 'btn-outline-secondary': maintainAspectRatio && aspectRatio === 1 }"
        (click)="maintainAspectRatio = true; aspectRatio = 1"
      >
        Thumbnail
      </button>
      <button
        class="btn btn-outline-primary"
        [ngClass]="{ 'btn-outline-secondary': !maintainAspectRatio }"
        (click)="maintainAspectRatio = false"
      >
        Original
      </button>
      <button
        class="btn btn-outline-primary"
        *ngFor="let f of formats"
        [ngClass]="{ 'btn-outline-secondary': format === f }"
        (click)="format = f"
      >
        {{ f | uppercase }}
      </button>
    </div>
    <div class="col-4 text-right">
      <button class="btn btn-outline-secondary" (click)="dismiss()">Cancel</button>
      <button class="btn btn-info" type="button" (click)="crop()">Crop Image</button>
    </div>
  </div>
</div>
