import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import _ from 'lodash';

@Pipe({
  name: 'ezTicketSummary',
})
export class EzTicketSummaryPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(items: any, property: string, format: string = 'currency'): string {
    if (items && items.length) {
      const sum = items.reduce((prev, curr) => prev + +_.get(curr, property), 0);
      if (format === 'currency') {
        return this.currencyPipe.transform(sum, 'USD', 'symbol-narrow', '0.2-2');
      }
      return sum.toString();
    }
    return format === 'currency' ? '$0.00' : '0';
  }
}
