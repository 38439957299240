import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { EnumHelper } from '../../helpers';
import { ApiResources, ApiVersion, MenuItemType, PricingStrategy, ProductType } from '../../enums';
import { PriceOptionData, PriceOptionSchema } from '../products';
import { MenuItemStationData } from './menu-item-station';

export const ModifierGroupSchema: ISchema = {
  name: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Modifier Group Name',
    required: true,
  },
  type: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Type',
    required: true,
    default: MenuItemType.FOOD,
    enum: EnumHelper.getEnumArray(MenuItemType),
    widget: {
      type: Widget.SELECT,
    },
  },
  options: {
    section: 'Modifier Group Options',
    type: Type.ARRAY,
    label: 'Options',
    items: {
      label: '',
      type: Type.OBJECT,
      properties: {
        name: {
          type: Type.STRING,
          label: 'Name',
          immutable: true,
        },
        product: {
          label: 'Product',
          type: Type.TYPEAHEAD,
          description: 'Select the product for modifier group option',
          resource: ApiResources.PRODUCT,
          version: ApiVersion.V2,
          modelProp: 'name',
          dataProp: 'data.data',
          search: {
            isDeleted: false,
            type: [ProductType.MENU_ITEM, ProductType.MODIFIER].join(','),
          },
          displayFields: ['name', 'category.name'],
          onChange: (product: any, data: ModifierGroupOptionData) => {
            data.name = product?.name;
          },
          hideInList: true,
        },
        posName: {
          type: Type.STRING,
          label: 'POS Name',
          isHidden: true,
        },
        pricingStrategy: {
          type: Type.STRING,
          label: 'Pricing Strategy',
          enum: EnumHelper.getEnumArray(PricingStrategy),
          widget: {
            type: Widget.SELECT,
          },
          default: PricingStrategy.INHERIT,
        },
        priceOptions: {
          section: 'Pricing',
          type: Type.ARRAY,
          label: 'Price Options',
          description:
            'Set Price Options if you wish to override the defaults that come from the products',
          items: {
            type: Type.OBJECT,
            label: null,
            properties: PriceOptionSchema,
          },
          onlyIf: [
            {
              field: 'pricingStrategy',
              shouldShow: (strategy: PricingStrategy) => PricingStrategy.INHERIT !== strategy,
            },
          ],
        },
        isActive: {
          type: Type.BOOLEAN,
          label: 'Is Active',
        },
      },
    },
  },
  stations: {
    section: 'Station Selection',
    type: Type.ARRAY,
    label: 'Stations',
    widget: {
      type: Widget.SELECT,
      multiple: true,
      enum: [],
      options: {
        resource: ApiResources.MENU_ITEM_STATION,
        version: ApiVersion.V2,
        modelProp: 'name',
        dataProp: 'data.data',
      },
    },
  },
};

export interface ModifierGroupOptionData {
  name: string;
  posName: string;
  product: string | Record<string, any>;
  pricingStrategy: PricingStrategy;
  priceOptions: PriceOptionData[];
  isActive: boolean;
}

export interface ModifierGroupData extends AbstractModelData {
  name: string;
  type: MenuItemType;
  options: ModifierGroupOptionData[];
  stations: MenuItemStationData['_id'][] | MenuItemStationData[];
  onlyOne: boolean;
  isRequired: boolean;
  /**
   * The number a customer can select
   * - this also works for multiple so its the sum of all mod.quantity
   */
  minQuantity: number;
  maxQuantity: number;
  multiple: boolean;
}

export class ModifierGroup extends AbstractModel<ModifierGroupData> {
  constructor(public data: ModifierGroupData) {
    super(data);
  }
}
