export enum JobType {
  REPORT = 'report',
}

export enum JobStatus {
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum JobResultObject {
  FILE = 'file',
}

export enum JobResultFileType {
  CSV = 'csv',
  PDF = 'pdf',
  EXCEL = 'excel',
  JSON = 'json',
  PNG = 'png',
  JPG = 'jpg',
}
