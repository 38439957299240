import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import * as Sentry from '@sentry/angular';
import { UserService } from '@app/providers/user/user.service';
import { select } from '@ngrx/store';
import _ from 'lodash';
import {
  OrgActions,
  selectOrgs,
  selectedOrg,
  selectedVenue,
  LocationActions,
  IntegrationActions,
  setViewMode,
} from '../../state';
import { Org, UserData, Venue, VenueData } from '../../models';
import { EnumHelper, LocalStorage } from '../../helpers';
import { ApiHeader, OrgState } from '../../enums';
import { BaseComponent } from '../components/base.component';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() toggleSidebar = new EventEmitter<void>();

  private orgId: string;

  private venueId: string;

  private org$ = this.store.pipe(select(selectOrgs));

  public orgSearchText: string;

  public orgs: Org[];

  public orgStates = EnumHelper.getEnumArray(OrgState);

  public orgsByState: Record<OrgState, Org[]>;

  public selectedOrg: Org;

  public selectedVenue: any;

  public searchText: string;

  public user: any;

  public orgModeEnabled = false;

  public trainingModeEnabled = false;

  constructor(private userService: UserService) {
    super();
  }

  ngOnInit() {
    this.initializeApp();
  }

  ngOnDestroy() {
    while (this.subscriptions.length) {
      const subscription = this.subscriptions.pop();
      subscription.unsubscribe();
    }
  }

  private async initializeApp() {
    this.orgId = await LocalStorage.getItem(ApiHeader.ORG_ID);
    this.venueId = await LocalStorage.getItem(ApiHeader.VENUE_ID);
    const viewMode = ((await LocalStorage.getItem('view-mode')) as any) || 'org';
    const liveMode = ((await LocalStorage.getItem(ApiHeader.LIVE_MODE)) as any) || 'true';
    this.store.dispatch(setViewMode({ viewMode }));
    this.orgModeEnabled = viewMode === 'org';
    this.trainingModeEnabled = liveMode === 'false';
    this.getMe();
  }

  private static setSentryContext(opts: { org?: Org; user?: UserData }) {
    const { org, user } = opts;
    if (org) {
      Sentry.setContext('org', {
        id: org.id,
        name: org.data.name,
      });
    }
    if (user) {
      Sentry.setUser({
        id: user._id,
        name: user.name,
        email: user.email,
      });
    }
  }

  private static clearSentryScope() {
    Sentry.configureScope((scope: any) => {
      scope.clear();
    });
  }

  private setOrgsByState() {
    const filteredOrgs = this.orgs.filter((org: Org) => {
      if (this.orgSearchText) {
        const regex = new RegExp(this.orgSearchText, 'ig');
        return regex.test(org.displayName);
      }
      return true;
    });
    this.orgsByState = _.groupBy(filteredOrgs, 'data.state') as Record<OrgState, Org[]>;
  }

  private loadOrgs() {
    const orgSub = this.org$.subscribe((orgs: Org[]) => {
      this.orgs = orgs?.sort((orgA: Org, orgB: Org) => {
        if (orgA.displayName < orgB.displayName) {
          return -1;
        }
        if (orgA.displayName < orgB.displayName) {
          return 1;
        }
        return 0;
      });
      this.setOrgsByState();
      if (this.orgs.length) {
        const org = this.orgId ? this.orgs.find((x: Org) => x.id === this.orgId) : this.orgs[0];
        this.store.dispatch(
          selectedOrg({
            selectedOrg: org,
          })
        );
      }
    });
    const selectedOrgSub = this.selectedOrg$.subscribe((org: Org) => {
      this.selectedOrg = org;
      if (this.selectedOrg) {
        this.selectedVenue = this.selectedOrg.get('venueIds')[0];
        this.store.dispatch(
          selectedVenue({
            selectedVenue: new Venue(this.selectedVenue),
          })
        );
        NavigationComponent.setSentryContext({ org });
        /**
         * @todo
         * Initialize state in some central manner
         */
        this.store.dispatch({ type: LocationActions.LOAD_LOCATIONS });
        this.store.dispatch({ type: IntegrationActions.LOAD_INTEGRATIONS });
      }
    });
    this.store.dispatch({ type: OrgActions.LOAD_ORGS });
    this.subscriptions.push(orgSub);
    this.subscriptions.push(selectedOrgSub);
  }

  private getMe() {
    const userSub = this.userService.me().subscribe(
      user => {
        this.user = user;
        NavigationComponent.setSentryContext({ user });
        this.loadOrgs();
      },
      error => {
        console.log(error);
      }
    );
    this.subscriptions.push(userSub);
  }

  public filterOrgs() {
    this.setOrgsByState();
  }

  public async toggleMode(isOrgModeEnabled: boolean) {
    this.orgModeEnabled = isOrgModeEnabled;
    const viewMode = this.orgModeEnabled ? 'org' : 'admin';
    await LocalStorage.addItem('view-mode', viewMode);
    this.store.dispatch(setViewMode({ viewMode }));
    const path = this.orgModeEnabled ? '/dashboard' : '/dashboard/overview';
    this.router.navigate([path]);
  }

  public async toggleTrainingMode(isTrainingModeEnabled: boolean) {
    this.trainingModeEnabled = isTrainingModeEnabled;
    const liveMode = this.trainingModeEnabled ? 'false' : 'true';
    await LocalStorage.addItem(ApiHeader.LIVE_MODE, liveMode);
    window.location.reload();
  }

  public logout() {
    this.userService.logout();
    NavigationComponent.clearSentryScope();
  }

  public async switchOrg(org: Org) {
    await LocalStorage.addItem(ApiHeader.ORG_ID, org.id);
    this.store.dispatch(selectedOrg({ selectedOrg: org }));
    window.location.href = '/dashboard';
  }

  public async switchVenue(venue: VenueData) {
    await LocalStorage.addItem(ApiHeader.VENUE_ID, venue._id);
    this.store.dispatch(selectedVenue({ selectedVenue: new Venue(venue) }));
    window.location.href = '/dashboard';
  }

  public async search() {
    console.log(this.searchText);
    this.router.navigate(['/search'], { queryParams: { query: this.searchText } });
  }
}
