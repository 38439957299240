import { createReducer, on } from '@ngrx/store';
import { Org } from '../../models';
import { retrievedOrgs, selectedOrg } from './org.actions';

export const orgsInitialState: Readonly<Org[]> = [];

export const orgsReducer = createReducer(
  orgsInitialState,
  on(retrievedOrgs, (state, { orgs }) => orgs)
);

export const selectedOrgInitialState: Readonly<Org> = null;

export const selectedOrgReducer = createReducer(
  selectedOrgInitialState,
  on(selectedOrg, (state, { selectedOrg: org }) => org)
);
