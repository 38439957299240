<input
  type="text"
  class="form-control"
  placeholder="Search discounts"
  [ngbTypeahead]="searchDiscount"
  [resultTemplate]="resultTpl"
  [inputFormatter]="discountFormatter"
  (selectItem)="selectDiscount($event, searchInput)"
  #searchInput
/>
<ng-template #resultTpl let-r="result">
  {{ r.name }}
</ng-template>
