import { AbstractModel, AbstractModelData } from '../abstract.model';
import {
  ApiDomain,
  ApiResources,
  ApiVersion,
  DaysOfTheWeek,
  DeliveryLocationType,
  DiningOptionsBehavior,
  DiningOptionsOrderType,
  PickupLocationType,
  RequiredGuestFields,
} from '../../enums';
import { ISchema, Type, Widget } from '../schema';
import { EnumHelper } from '../../helpers';
import {
  blankAsapSchedule,
  blankDeliveryOption,
  blankFutureSchedule,
  blankPickupLocation,
} from '../../concession/dining-options/dining-options.helper';
import { ServiceChargeData } from './service-charge';
import { EventData } from '../events';
import { ApiQueryParams } from '../../clients';

export const DeliveryOptionSchema: ISchema = {
  name: {
    type: Type.STRING,
    label: 'Option Name',
  },
  options: {
    type: Type.ARRAY,
    label: 'Options',
    items: {
      type: Type.SELF,
      label: 'Option',
    },
  },
};

export const DeliveryOptionsSchema: ISchema = {
  name: {
    type: Type.STRING,
    label: 'Option Name',
    description: 'E.g. Section or Table Number',
  },
  type: {
    type: Type.STRING,
    label: 'Option Type',
    enum: EnumHelper.getEnumArray(DeliveryLocationType),
    widget: {
      type: Widget.SELECT,
    },
  },
  // For now this property won't be used until a delivery integration is provided
  deliveryProvider: {
    type: Type.STRING,
    label: 'Delivery Provider',
    isHidden: true,
  },
  options: {
    type: Type.ARRAY,
    label: 'Available Options',
    items: {
      type: Type.OBJECT,
      label: 'Available Options',
      properties: DeliveryOptionSchema,
    },
  },
};

export const DayTimeGapSchema: ISchema = {
  dayOfWeek: {
    label: 'Day of the Week',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(DaysOfTheWeek),
  },
  timeGapMinutes: {
    type: Type.NUMBER,
    label: 'Time Gap Minutes',
    required: true,
  },
  timeGapStartHour: {
    type: Type.NUMBER,
    label: 'Start Hour',
    required: true,
  },
  timeGapStartMinute: {
    type: Type.NUMBER,
    label: 'Start Min',
    required: true,
  },
  timeGapEndHour: {
    type: Type.NUMBER,
    label: 'End Hour e.g 14 for 2pm',
    required: true,
  },
  timeGapEndMinute: {
    type: Type.NUMBER,
    label: 'End Min',
    required: true,
  },
};

export const CustomDaySchedule: ISchema = {
  customDaySchedule: {
    section: 'Custom Day Schedule',
    type: Type.ARRAY,
    label: 'Custom Day Schedule',
    items: {
      type: Type.OBJECT,
      label: 'Custom Day Schedule',
      properties: DayTimeGapSchema,
    },
  },
};

export const DiningOptionSchema: ISchema = {
  name: {
    section: 'basic info',
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  displayName: {
    section: 'basic info',
    type: Type.STRING,
    label: 'Display Name',
    description: 'The label visible in the POS or Online Ordering',
  },
  behaviour: {
    section: 'basic info',
    type: Type.STRING,
    label: 'Behavior',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(DiningOptionsBehavior),
    },
  },
  requiredGuestFields: {
    section: 'basic info',
    type: Type.ARRAY,
    label: 'Required Guest Fields',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(RequiredGuestFields),
      multiple: true,
    },
  },
  serviceCharges: {
    label: 'Service Charges',
    type: Type.ARRAY,
    widget: {
      type: Widget.SELECT,
      enum: [],
      multiple: true,
      options: {
        version: ApiVersion.V2,
        domain: ApiDomain.API,
        resource: ApiResources.SERVICE_CHARGE,
        modelProp: 'name',
        dataProp: 'data.data',
        search: { isDeleted: false, limit: 100, sort: 'name' },
        displayFields: ['name'],
      },
    },
  },
  floorPlanId: {
    label: 'Floor Plan',
    section: 'Floor Plan',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        version: ApiVersion.V2,
        domain: ApiDomain.API,
        resource: ApiResources.FLOOR_PLAN,
        modelProp: 'name',
        dataProp: 'data.data',
        search: { isDeleted: false, limit: 100, sort: 'name' },
        displayFields: ['name'],
      },
    },
    onlyIf: [
      {
        field: 'behaviour',
        shouldShow: (value: DiningOptionsBehavior) => value === DiningOptionsBehavior.TABLE,
      },
    ],
  },
  posOnly: {
    section: 'basic info',
    type: Type.BOOLEAN,
    label: 'POS Only',
    description: 'Whether this dining option is only available on the POS.',
    default: false,
  },
};

export interface IAsapSchedule {
  availableNow: boolean;
  availableAt: Date;
  intervalMin: number;
  intervalMax: number;
}

export interface IFutureFulfillmentTime {
  time: Date;
  timeString: string; // HH:MM
  tz: string; // EST
}

export interface IFutureScheduleDate {
  date: string;
  timesAndGaps: Array<IFutureFulfillmentTime>;
}

export interface DayTimeGap {
  dayOfWeek: DaysOfTheWeek;
  timeGapMinutes: number;
  timeGapStartHour: number;
  timeGapStartMinute: number;
  timeGapEndHour: number;
  timeGapEndMinute: number;
}

export interface IFutureScheduleTimeGapConfig {
  daysOfWeek: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  customDaySchedule: DayTimeGap[];
  timeGapDaysAhead: number;
  minDaysBefore: number;
  skipTimeRequirement: boolean;
  timeGapMinutes: number;
  timeGapStartHour: number;
  timeGapStartMinute: number;
  timeGapEndHour: number;
  timeGapEndMinute: number;
}

export interface IFutureSchedule {
  exclusionDates: Array<Date>;
  dates: Array<IFutureScheduleDate>;
  timeGapConfig: IFutureScheduleTimeGapConfig;
}

export interface IDeliveryOption {
  name: string;
  options: IDeliveryOption[];
}

export interface IDeliveryOptions {
  name: string;
  type: DeliveryLocationType;
  // For now this property won't be used until a delivery integration is provided
  deliveryProvider: string;
  options: IDeliveryOption[];
}

export interface IPickupLocation {
  name: string;
  locationType: PickupLocationType;
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: number;
    formatted: string;
  };
  description: string;
}

export interface DiningOptionData extends AbstractModelData {
  name: string;
  displayName: string;
  behaviour: DiningOptionsBehavior;
  asapSchedule: IAsapSchedule;
  futureSchedule: IFutureSchedule;
  deliveryOptions: IDeliveryOptions;
  pickupLocation: IPickupLocation;
  events: EventData[];
  floorPlanId: string;
  requiredGuestFields: Array<RequiredGuestFields>;
  serviceCharges: string[] | ServiceChargeData[];
  orgId: string;
  isActive: boolean;
  posOnly: boolean;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

/**
 * @todo - Remove patch once all locations have correct info
 */
export class DiningOption extends AbstractModel<DiningOptionData> {
  constructor(public data: DiningOptionData) {
    super(data);
    if (!this.get('asapSchedule')) {
      this.set('asapSchedule', blankAsapSchedule());
    }
    if (!this.get('futureSchedule')) {
      this.set('futureSchedule', blankFutureSchedule());
    }
    if (!this.get('deliveryOptions')) {
      this.set('deliveryOptions', blankDeliveryOption());
    }
    if (!this.get('pickupLocation')) {
      this.set('pickupLocation', blankPickupLocation());
    }
    if (!this.get('events')) {
      this.set('events', []);
    }
    if (!this.get('requiredGuestFields')) {
      this.set('requiredGuestFields', []);
    }
    if (!this.get('serviceCharges')) {
      this.set('serviceCharges', []);
    }
  }

  get json() {
    const {
      asapSchedule,
      futureSchedule,
      deliveryOptions,
      floorPlanId,
      pickupLocation,
      events,
      ...remainder
    } = this.data;
    return {
      ...([
        DiningOptionsBehavior.PICKUP,
        DiningOptionsBehavior.DELIVERY,
        DiningOptionsBehavior.TABLE,
      ].includes(remainder.behaviour) && {
        asapSchedule,
        futureSchedule,
      }),
      ...([DiningOptionsBehavior.PICKUP].includes(remainder.behaviour) && {
        pickupLocation,
      }),
      ...([DiningOptionsBehavior.DELIVERY, DiningOptionsBehavior.TABLE].includes(
        remainder.behaviour
      ) && {
        deliveryOptions,
        floorPlanId,
      }),
      ...([DiningOptionsBehavior.EVENT].includes(remainder.behaviour) && {
        events,
      }),
      ...remainder,
    };
  }

  public supportsQRCodeGenerator(): boolean {
    switch (this.data.behaviour) {
      case DiningOptionsBehavior.DELIVERY:
      case DiningOptionsBehavior.PICKUP:
      case DiningOptionsBehavior.FAST_CLOSE:
      case DiningOptionsBehavior.TAB:
        return false;
      case DiningOptionsBehavior.EVENT:
      case DiningOptionsBehavior.TABLE:
        return true;
    }
  }

  /**
   * Pickup, Table, Event only
   */
  public getQrCodeQueryString(
    option: IPickupLocation | IDeliveryOption | EventData,
    parentOption?: IDeliveryOption
  ): string {
    if (
      ![
        DiningOptionsBehavior.EVENT,
        DiningOptionsBehavior.TABLE,
        DiningOptionsBehavior.PICKUP,
      ].includes(this.data.behaviour)
    ) {
      return null;
    }
    const queryParams = {
      diningOptionId: this.id,
      behaviour: this.data.behaviour,
      orderType: DiningOptionsOrderType.NOW,
      option1: null,
      option2: null,
      option3: null,
    };
    // We only support QR Codes for 2 levels deep for now
    if (this.data.behaviour === DiningOptionsBehavior.TABLE) {
      queryParams.option1 = parentOption?.name || option.name;
      // This will only exist if its nested
      queryParams.option2 = parentOption ? option.name : null;
    }
    if (this.data.behaviour === DiningOptionsBehavior.PICKUP) {
      const pickupOption = option as IPickupLocation;
      queryParams.option1 = pickupOption.name;
      queryParams.option2 = pickupOption.address.formatted;
    }
    if (this.data.behaviour === DiningOptionsBehavior.EVENT) {
      const eventOption = option as EventData;
      queryParams.option1 = eventOption.name;
      queryParams.option2 = eventOption.id;
      queryParams.option3 = eventOption._id;
    }
    const params = new ApiQueryParams(queryParams);
    return params.toString();
  }
}
