import {
  TransactionState,
  TransactionResult,
  TransactionType,
  TransactionGateway,
  TransactionCardScheme,
  TransactionCardEntryMethod,
} from '../../enums';

export interface RefundInfo {
  reason: string;
  detail: string;
}

export interface TransactionData {
  id: number;
  state: TransactionState;
  result: TransactionResult;
  type: TransactionType;
  gateway: TransactionGateway;
  cardScheme: TransactionCardScheme;
  cardEntryMethod: TransactionCardEntryMethod;
  authCode: string;
  maskPan: string;
  last4: string;
  tenderId: string;
  orderId: string;
  orgId: string;
  venueId: string;
  accountId: string;
  shiftId: string;
  refundedTransactionId: string;
  currencyCode: 'usd';
  refundInfo: RefundInfo;
  amountAuthorized: number;
  amount: number;
  applicationFee: number;
  refundedAmount: number;
  gratuity: number;
  gratuityId: string;
  serviceFee: number;
  serviceFeeId: string;
  i9nId: string;
  payoutDetails: {
    i9nId: string;
    payoutDate: Date;
    amount: number;
    fee: number;
    net: number;
  };
  json: any;
  signature: string;
  startedAt: Date;
  finishedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}
