import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  // For the Admin Component to listen for auth success to pull in the user profile
  private errorListener = new Subject<boolean>();
  private errors: Array<{ type: string; message: string }> = [];

  constructor() {}

  public getErrorListener() {
    return this.errorListener.asObservable();
  }

  public getErrors(): Array<{ type: string; message: string }> {
    return this.errors;
  }

  public addError(type: string, message: string) {
    this.errors.push({
      type,
      message,
    });
    this.errorListener.next(true);
  }

  public removeError(index: number) {
    this.errors.splice(index, 1);
    this.errorListener.next(true);
  }

  public removeErrors() {
    this.errors = [];
    this.errorListener.next(true);
  }
}
