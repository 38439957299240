import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'titleCase',
})
export class TitleCasePipe implements PipeTransform {
  transform(value: any, split = ' ', join = ' '): any {
    if (value && _.isString(value)) {
      return value
        .toLowerCase()
        .split(split)
        .filter(x => x)
        .map((str: string) => {
          if (str.indexOf('-') > -1) {
            return this.transform(str, '-');
          }
          if (str.indexOf('/') > -1) {
            return this.transform(str, '/', ' / ');
          }
          if (str.indexOf('_') > -1) {
            return this.transform(str, '_');
          }
          return str[0].toUpperCase() + str.substring(1);
        })
        .join(join);
    }
    return value;
  }
}
