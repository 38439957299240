import { ApiDomain, ApiResources, ApiVersion, HomeOrAway } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { Competition, CompetitionData } from './competition.model';

const FixtureContestantSchema: ISchema = {
  contestantId: {
    type: Type.TYPEAHEAD,
    label: 'Contestant',
    domain: ApiDomain.API,
    version: ApiVersion.V2,
    resource: ApiResources.CONTESTANT,
    modelProp: 'name',
    displayFields: ['name', 'code'],
    dataProp: 'data.data',
    required: true,
  },
  homeOrAway: {
    label: 'Home or Away',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(HomeOrAway),
  },
};

export const FixtureSchema: ISchema = {
  description: {
    label: 'Description',
    type: Type.STRING,
  },
  date: {
    label: 'Date',
    type: Type.DATE_TIME,
    required: true,
  },
  dateString: {
    label: 'Date String',
    type: Type.STRING,
    isHidden: true,
  },
  time: {
    label: 'Time',
    type: Type.NUMBER, // milliseconds in day
    isHidden: true,
  },
  timeString: {
    label: 'Time String e.g. 19:00 PM ET',
    type: Type.STRING,
  },
  competition: {
    type: Type.TYPEAHEAD,
    label: 'Competition',
    domain: ApiDomain.API,
    version: ApiVersion.V2,
    resource: ApiResources.COMPETITION,
    modelProp: 'name',
    displayFields: ['name', 'competitionCode'],
    dataProp: 'data.data',
    required: true,
  },
  contestants: {
    label: 'Contestants',
    type: Type.ARRAY,
    items: {
      type: Type.OBJECT,
      label: null,
      properties: FixtureContestantSchema,
    },
  },
};

export interface FixtureContestant {
  contestantId: string;
  homeOrAway: HomeOrAway;
}

export interface GameStat {
  type: string;
  value: number;
}

export interface GameOdds {
  name: string;
  type: string;
  value: number;
}

export interface FixtureData extends AbstractModelData {
  description: string;
  date: Date;
  dateString: string;
  time: number;
  timeString: string;
  competition: string | Competition | CompetitionData;
  contestants: FixtureContestant[];
  scores?: {
    homeTeam: number;
    awayTeam: number;
  };
  gameStats?: GameStat[];
  odds?: GameOdds[];
}

export class Fixture extends AbstractModel<FixtureData> {
  constructor(public data: FixtureData) {
    super(data);
  }
}
