import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  private domain = environment.url_v2;
  private path = 'permissions';
  private baseUrl = `${this.domain}/${this.path}`;

  constructor(private _http: HttpClient) {}

  public hasPermissions(roles: Array<string>): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}`, { roles });
  }
}
