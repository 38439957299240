import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'voucherStatus',
})
export class VoucherStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let status = 'Inactive';
    if (value) {
      if (value.isActive) {
        status = 'Active';
      } else if (value.endDate && moment().format('YYYY-MM-DD') > value.endDate) {
        status = 'Expired';
      }
    }
    return status;
  }
}
