import { Routes } from '@angular/router';

import { FullComponent } from '@app/layouts/full/full.component';
import { BlankComponent } from '@app/layouts/blank/blank.component';

import { AdminGuard } from '@app/guards/admin.guard';

export const Approutes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },

  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./authentication/authentication.module').then(m => m.AuthenticationModule),
      },
    ],
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'account',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      },
      {
        path: 'billing',
        loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'venues',
        loadChildren: () => import('./venue/venue.module').then(m => m.VenueModule),
      },
      {
        path: 'orders',
        loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
      },
      {
        path: 'products',
        loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
      },
      {
        path: 'modifier-groups',
        loadChildren: () =>
          import('./modifier-groups/modifier-groups.module').then(m => m.ModifierGroupsModule),
      },
      {
        path: 'inventory',
        loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
      },
      {
        path: 'locations',
        loadChildren: () => import('./concession/concession.module').then(m => m.ConcessionModule),
      },
      {
        path: 'categories',
        loadChildren: () => import('./category/category.module').then(m => m.CategoryModule),
      },
      {
        path: 'events',
        loadChildren: () => import('./event/event.module').then(m => m.EventModule),
      },
      {
        path: 'fantasy',
        loadChildren: () => import('./fantasy/fantasy.module').then(m => m.FantasyModule),
      },
      {
        path: 'tee-sheet',
        loadChildren: () => import('./tee-sheet/tee-sheet.module').then(m => m.TeeSheetModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'shifts',
        loadChildren: () => import('./shifts/shifts.module').then(m => m.ShiftsModule),
      },
      {
        path: 'guests',
        loadChildren: () => import('./guests/guests.module').then(m => m.GuestsModule),
      },
      {
        path: 'vouchers',
        loadChildren: () => import('./voucher/voucher.module').then(m => m.VoucherModule),
      },
      {
        path: 'point-of-sale',
        loadChildren: () =>
          import('./point-of-sale/point-of-sale.module').then(m => m.PointOfSaleModule),
      },
      {
        path: 'integrations',
        loadChildren: () =>
          import('./integrations/integrations.module').then(m => m.IntegrationsModule),
      },
      {
        path: 'marketing',
        loadChildren: () => import('./marketing/marketing.module').then(m => m.MarketingModule),
      },
      {
        path: 'activity-log',
        loadChildren: () =>
          import('./activity-log/activity-log.module').then(m => m.ActivityLogModule),
      },
      {
        path: 'logs',
        loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule),
      },
      {
        path: 'support',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
      },
      {
        path: 'search',
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
      },
    ],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
