import { ApiDomain, ApiResources, ApiVersion } from '../../enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type } from '../schema';

export const LocationGroupSchema: ISchema = {
  name: {
    label: 'Group Name',
    type: Type.STRING,
    required: true,
  },
  isOrgWide: {
    label: 'All Org Locations',
    description: 'Users have access to all locations for this org.',
    type: Type.BOOLEAN,
    required: true,
    default: true,
  },
  isVenueWide: {
    label: 'All Venue Locations',
    description: 'Users have access to all locations for this venue.',
    type: Type.BOOLEAN,
    required: true,
    default: false,
    isHidden: true,
    notIf: 'isOrgWide',
  },
  locationIds: {
    label: 'Locations',
    type: Type.ARRAY,
    items: {
      type: Type.TYPEAHEAD,
      label: 'Location Name',
      domain: ApiDomain.API,
      version: ApiVersion.V2,
      resource: ApiResources.LOCATION,
      modelProp: 'name',
      search: { isDeleted: false },
      displayFields: ['name'],
    },
    notIf: [
      {
        field: 'isOrgWide',
        shouldShow: (value: string) => !value,
      },
      {
        field: 'isVenueWide',
        shouldShow: (value: string) => !value,
      },
    ],
  },
};

export interface LocationGroupData extends AbstractModelData {
  name: string;
  orgId: string;
  locationIds: string[] | Record<string, any>[];
  isOrgWide: boolean;
  isVenueWide: boolean;
  venueId?: string;
}

export class LocationGroup extends AbstractModel<LocationGroupData> {
  constructor(public data: LocationGroupData) {
    super(data);
  }
}
