import _ from 'lodash';
import { DiningOptionsOrderType, OrderSource, OrderStatus } from '../enums';
import { Order, OrderData, OrderDestination, OrderedItem, OrderedItemTax } from '../models/order';

export class OrderHelper {
  constructor(private menuItemById: Record<string, any>) {}

  public static nameFromStatus(status: OrderStatus): string {
    switch (status) {
      case OrderStatus.POINT_OF_SALE:
        return 'tab';
      default:
        return status;
    }
  }

  public static nameFromOrderType(orderType: DiningOptionsOrderType): string {
    switch (orderType) {
      case DiningOptionsOrderType.FUTURE:
        return 'Future';
      default:
        return 'ASAP';
    }
  }

  public updateMenuItemsById(menuItemsById: Record<string, any>) {
    this.menuItemById = menuItemsById;
  }

  public canAdd(item: OrderedItem): boolean {
    const { inventoryItem } = _.get(this.menuItemById, item.item) || {};
    if (!inventoryItem?.isTracked) {
      return true;
    }
    if (inventoryItem?.isTracked && inventoryItem.quantity >= item.quantity) {
      return true;
    }
    return false;
  }

  public static getItemTaxes(
    itemPrice: OrderedItem['price'],
    itemDiscountTotal: OrderedItem['discountTotal'],
    taxes: Array<any>
  ): Array<OrderedItemTax> {
    return taxes.map((tax: any) => {
      const rate = tax && tax.taxRate ? tax.taxRate : 0;
      const total = Math.ceil(rate * 0.01 * (itemPrice - itemDiscountTotal));
      return {
        id: tax._id,
        total,
        ptTotal: 0,
      };
    });
  }

  public getTaxes(item: OrderedItem) {
    const { taxCodes: taxes } = _.get(this.menuItemById, item.item) || { taxes: [] };
    const itemTaxes = OrderHelper.getItemTaxes(item.price, item.discountTotal, taxes);
    const itemTaxTotal = _.reduce(
      itemTaxes,
      (prev: number, curr: OrderedItemTax) => prev + curr.total + curr.ptTotal,
      0
    );
    return {
      taxes: itemTaxes,
      taxTotal: itemTaxTotal,
    };
  }

  public static newOrder(
    restaurant: any,
    shiftId: string,
    orderDestination: OrderDestination,
    guestId?: string
  ): Order {
    return new Order({
      venue: restaurant.venue?._id || restaurant.venue,
      restaurant: restaurant._id,
      shift: shiftId,
      isConsumerOrder: false,
      status: OrderStatus.POINT_OF_SALE,
      source: OrderSource.PARTAKE_WEB, // Make based on BUILD_SETTING
      orderDestination,
      guest: guestId,
      user: null,
      items: [],
      subTotal: 0,
      discount: 0,
      taxTotal: 0,
      partakeTax: 0,
      gratuity: 0,
      serviceFee: 0,
      roundUpTotal: 0,
      total: 0,
      amountPaid: 0,
      due: 0,
      alcoholCount: 0,
      shouldRoundUp: false,
      isFirstOrder: false,
    } as OrderData);
  }
}
