import { ISchema, Type } from '../schema';
import { ProductSchema } from './product';

export const MassUpdateProductSchema: ISchema = {
  name: {
    type: Type.STRING,
    label: 'Name',
  },
  description: {
    type: Type.STRING,
    label: 'Description',
  },
  isAlcoholic: {
    type: Type.BOOLEAN,
    label: 'Alcoholic',
    description: 'Only Menu Items and Modifiers can have this field.',
    onlyIf: [
      {
        field: 'isParent',
        shouldShow: (isParent: boolean) => !isParent,
      },
    ],
  },
  parValuesByStandSheetId: {
    ...ProductSchema.parValuesByStandSheetId,
    side: 'left',
  },
  taxCodes: {
    ...ProductSchema.taxCodes,
    section: 'Taxes',
    label: 'Taxes',
    description: 'Only Menu Items and Modifiers can have taxes',
    onlyIf: [
      {
        field: 'isParent',
        shouldShow: (isParent: boolean) => !isParent,
      },
    ],
  },
  accountCode: ProductSchema.accountCode,
  statCode: ProductSchema.statCode,
  classCode: ProductSchema.classCode,
  vendorCode: ProductSchema.vendorCode,
};
