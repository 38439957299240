// eslint-disable-next-line import/prefer-default-export
export enum EmployeeType {
  ADMIN = 'admin',
  SERVER = 'waiter',
  CART = 'cart',
  KITCHEN = 'kitchen',
  BAR = 'bar',
  PRO_SHOP = 'proshop',
  STARTER = 'starter',
  GREENS_KEEPER = 'greenkeeper',
}
