import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusPipe } from './order-status.pipe';

@Pipe({
  name: 'orderFilter',
})
export class OrderFilterPipe implements PipeTransform {
  constructor(private _orderStatusPipe: OrderStatusPipe) {}

  transform(filter: any): string {
    let value = '';
    switch (filter.property) {
      case 'shift':
        value = `Shift is ${filter.selected ? filter.selected.name : filter.value}`;
        break;
      case 'restaurant':
        value = `Concession is ${filter.selected ? filter.selected.name : filter.value}`;
        break;
      case 'date':
        value = `Date is ${filter.selected ? filter.selected.name : filter.value}`;
        break;
      case 'orderNumber':
        value = `Order Number is ${filter.value}`;
        break;
      case 'syncStatus':
        value = `Sync Status is ${filter.selected ? filter.selected.name : filter.value}`;
        break;
      case 'status':
        value = `Status is ${this._orderStatusPipe.transform(filter.value)}`;
        break;
      default:
        value = `${filter.property} is ${filter.value}`;
        break;
    }
    return value;
  }
}
