<div class="modal-header">
  <h4 class="modal-title">View JSON Data</h4>
</div>
<div class="modal-body">
  <div class="col">
    <div class="row">
      <div class="col">
        <app-json-viewer [expanded]="expanded" [json]="json"></app-json-viewer>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row text-right">
      <button type="button" class="btn btn-outline-secondary btn-delete" (click)="modal.close()">
        Close
      </button>
    </div>
  </div>
</div>
