import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { StripeService } from '@app/providers/stripe/stripe.service';

@Component({
  selector: 'app-credit-key-in',
  templateUrl: './credit-key-in.component.html',
  styleUrls: ['./credit-key-in.component.scss'],
})
export class CreditKeyInComponent implements OnInit {
  @Input() saveCard = false;
  @Output() tokenize = new EventEmitter<any>();

  private _card: any;
  private _stripe: any;

  private _style = {
    base: {
      color: '#0b0d22',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
    },
    invalid: {
      color: '#96281b',
      iconColor: '#96281b',
    },
  };

  private _formComplete = false;

  public isComplete = false;
  public name: string;

  constructor(private _stripeService: StripeService) {}

  ngOnInit() {
    this.init();
  }

  private async init() {
    this._stripe = this._stripeService.getStripe();
    if (!this._stripe) {
      const { stripe } = await this._stripeService.init();
      if (stripe) {
        this._stripe = stripe;
        this.setupElements();
      }
    } else {
      this.setupElements();
    }
  }

  private setupElements() {
    const elements = this._stripe.elements();
    this._card = elements.create('card', { style: this._style });
    this._card.mount('#card-element');
    this._card.addEventListener('change', (event: any) => {
      if (event.complete) {
        this._formComplete = true;
        this.canSubmit();
      }
    });
  }

  public canSubmit() {
    this.isComplete = !!(this._formComplete && this.name);
  }

  public async tokenizeCard() {
    const { token, error } = await this._stripe.createToken(this._card, { name: this.name });
    if (token) {
      console.log('token', token);
      this.tokenize.emit(token);
    } else {
      console.log('error', error);
      this.tokenize.emit();
    }
  }
}
