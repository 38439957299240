import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { environment } from '@env/environment';
import { ApiQuery, ApiQueryParams } from '@app/clients';

@Injectable({
  providedIn: 'root',
})
export class ShiftService {
  private _KEY = 'pt-shift';
  private _shiftListener = new Subject<any>();

  private _path = 'shift';
  private _domain = environment.url_v2;
  private _baseUrl = `${this._domain}/${this._path}`;

  constructor(private _http: HttpClient) {}

  getShiftListener() {
    return this._shiftListener.asObservable();
  }

  self(): Observable<any> {
    return this._http.get(`${this._baseUrl}/self`);
  }

  create(body: any): Observable<any> {
    return this._http.post(`${this._baseUrl}`, body);
  }

  find(query?: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this._http.get(`${this._baseUrl}`, { params });
  }

  findOne(id: string): Observable<any> {
    return this._http.get(`${this._baseUrl}/${id}`);
  }

  update(id: string, body: any): Observable<any> {
    return this._http.put(`${this._baseUrl}/${id}`, body);
  }

  heartbeat(id: any): Observable<any> {
    return this._http.put(`${this._baseUrl}/${id}/heartbeat`, {});
  }

  setShift(shift: any): void {
    if (shift) {
      localStorage.setItem(this._KEY, JSON.stringify(shift));
    } else {
      localStorage.removeItem(this._KEY);
    }
    this._shiftListener.next(shift);
  }

  getShift(): any {
    try {
      return JSON.parse(localStorage.getItem(this._KEY));
    } catch (e) {
      return null;
    }
  }
}
