import { Component, EventEmitter, Input, Output } from '@angular/core';

import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { MenuItemService } from '@app/providers/restaurant/menu-item.service';

@Component({
  selector: 'app-menu-item-search-typeahead',
  templateUrl: './menu-item-search-typeahead.component.html',
  styleUrls: ['./menu-item-search-typeahead.component.scss'],
})
export class MenuItemSearchTypeaheadComponent {
  @Input() item: any;
  @Input() types = 'menu-item';

  @Output() selected = new EventEmitter<any>();
  @Output() itemChange = new EventEmitter<any>();

  constructor(private _menuItemService: MenuItemService) {}

  public selectItem(selected: any) {
    this.selected.emit(selected.item);
    this.itemChange.emit(selected.item);
  }

  searchItem = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(name =>
        this._menuItemService.self({ name, limit: 100, isDeleted: false }).pipe(
          map(x => x.menuItems),
          catchError(() => of([]))
        )
      )
    );

  // eslint-disable-next-line class-methods-use-this
  itemFormatter = (x: { name: string; category: any }) => {
    if (x.name && x.category.name) {
      return `${x.name} - ${x.category.name}`;
    }
    if (x.name) {
      return `${x.name}`;
    }
    return '';
  };
}
