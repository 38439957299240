import {
  SubscriptionType,
  SubscriptionInterval,
  SubscriptionStatus,
  SubscriptionCollectionMethod,
  ApiResources,
  ApiVersion,
  ProductType,
} from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

export interface SubscriptionItem {
  productId: string;
  productName: string;
  priceOptionId: string;
  price: number;
}

export interface SubscriptionData extends AbstractModelData {
  description: string;
  type: SubscriptionType;
  interval: SubscriptionInterval;
  /**
   * The number of intervals between subscription billings.
   * For example, interval=month and intervalCount=3 bills every 3 months.
   * Maximum of three years interval allowed (3 years, 36 months, or 156 weeks).
   */
  intervalCount: number;
  status: SubscriptionStatus;
  collectionMethod: SubscriptionCollectionMethod;
  lastInvoice?: string;

  items: SubscriptionItem[];
  discount?: {
    discountId: string;
    amount: number;
  };
  currency: string;
  billingCycleAnchor: string;
  billingCycleAnchorConfig: {
    dayOfMonth: number;
    hour?: number;
    minute?: number;
    month?: number;
  };
  currentPeriodEnd: string;
  currentPeriodStart: string;

  trialStart?: string;
  trialEnd?: string;

  endedAt?: string;
  cancelAt?: string;
  cancelationDetails: {
    comment: string;
    feedback: string;
    reason: string;
  };

  orgId: string;
  memberId?: string;
  isDeleted: boolean;
}

export class Subscription extends AbstractModel<SubscriptionData> {
  constructor(public data: SubscriptionData) {
    super(data);
  }
}

type OmitFields =
  | '_id'
  | 'endedAt'
  | 'cancelAt'
  | 'cancelationDetails'
  | 'currentPeriodEnd'
  | 'currentPeriodStart'
  | 'orgId'
  | 'memberId'
  | 'isDeleted'
  | 'name'
  | 'createdAt'
  | 'updatedAt';

export const SubscriptionSchema: ISchema<Omit<SubscriptionData, OmitFields>> = {
  description: {
    section: 'Subscription Info',
    label: 'Description',
    type: Type.STRING,
  },
  type: {
    section: 'Subscription Info',
    label: 'Type',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(SubscriptionType),
    },
    default: SubscriptionType.MEMBERSHIP,
  },
  interval: {
    section: 'Subscription Info',
    label: 'Interval',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(SubscriptionInterval),
    },
    default: SubscriptionInterval.MONTH,
  },
  intervalCount: {
    section: 'Subscription Info',
    label: 'Interval Count',
    description:
      '&bull;&nbsp;The number of intervals between subscription billings.<br>&bull;&nbsp;For example, interval is month and intervalCount is 3, the subscription bills every 3 months.<br>&bull;&nbsp;Maximum of three years interval allowed (3 years, 36 months, or 156 weeks).',
    type: Type.NUMBER,
    widget: {
      type: Widget.SELECT,
      enum: [
        {
          name: '1',
          key: 1,
        },
        {
          name: '2',
          key: 2,
        },
        {
          name: '3',
          key: 3,
        },
      ],
    },
  },
  status: {
    section: 'Subscription Info',
    label: 'Status',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(SubscriptionStatus),
    },
    default: SubscriptionStatus.ACTIVE,
  },
  collectionMethod: {
    section: 'Subscription Info',
    label: 'Collection Method',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(SubscriptionCollectionMethod),
    },
    default: SubscriptionCollectionMethod.MEMBER_INVOICE,
  },
  currency: {
    section: 'Subscription Info',
    label: 'Currency',
    type: Type.STRING,
    default: 'usd',
    isHidden: true,
  },
  lastInvoice: {
    section: 'Subscription Info',
    label: 'Last Invoice',
    type: Type.STRING,
    isHidden: true,
  },
  items: {
    section: 'Items',
    label: 'Items',
    type: Type.ARRAY,
    items: {
      label: 'Items',
      type: Type.OBJECT,
      properties: {
        productId: {
          label: 'Product',
          type: Type.TYPEAHEAD,
          description: 'Select the product for subscription',
          resource: ApiResources.PRODUCT,
          version: ApiVersion.V2,
          modelProp: 'name',
          dataProp: 'data.data',
          search: {
            isDeleted: false,
            type: ProductType.PLAN,
          },
          displayFields: ['name', 'category.name'],
          onChange: (product: any, data: SubscriptionItem) => {
            data.productName = product?.productName;
            const priceOption = product.priceOptions[0];
            data.price = priceOption.price;
            data.priceOptionId = priceOption.id;
          },
          hideInList: true,
        },
        productName: {
          type: Type.STRING,
          label: 'Product Name',
          isHidden: true,
        },
        priceOptionId: {
          label: 'Price Option',
          type: Type.STRING,
          isHidden: true,
        },
        price: {
          type: Type.NUMBER,
          label: 'Price',
          isHidden: true,
        },
      },
    },
  },
  discount: {
    section: 'Items',
    label: 'Discount',
    type: Type.OBJECT,
    isHidden: true,
    properties: {
      discountId: {
        label: 'Discount',
        type: Type.STRING,
        description: 'Select the discount for subscription',
        widget: {
          type: Widget.SELECT,
          options: {
            resource: ApiResources.DISCOUNTS,
            version: ApiVersion.V2,
            modelProp: 'name',
            dataProp: 'data.data',
            search: {
              isDeleted: false,
            },
            displayFields: ['name', 'type'],
          },
        },
        hideInList: true,
      },
      amount: {
        type: Type.NUMBER,
        label: 'Amount',
        isHidden: true,
      },
    },
  },
  billingCycleAnchor: {
    section: 'Billing Cycle',
    label: 'Billing Cycle Anchor',
    type: Type.DATE,
  },
  billingCycleAnchorConfig: {
    section: 'Billing Cycle',
    label: 'Billing Cycle Anchor Config',
    type: Type.OBJECT,
    properties: {
      dayOfMonth: {
        type: Type.NUMBER,
        label: 'Day of Month',
      },
      hour: {
        type: Type.NUMBER,
        label: 'Hour',
      },
      minute: {
        type: Type.NUMBER,
        label: 'Minute',
      },
      month: {
        type: Type.NUMBER,
        label: 'Month',
      },
    },
    isHidden: true,
  },
  trialStart: {
    section: 'Trial',
    label: 'Trial Start',
    type: Type.DATE,
    isHidden: true,
  },
  trialEnd: {
    section: 'Trial',
    label: 'Trial End',
    type: Type.DATE,
    isHidden: true,
  },
};
