import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inpersonRate',
})
export class InpersonRatePipe implements PipeTransform {
  public pos: string;
  public index: any;

  transform(config: any, type?: any): any {
    if (!config) {
      return 'TBC';
    }
    if (type === 'amex') {
      this.pos = `${config.amexPercentage * 100}`;
      this.index = this.pos.indexOf('.') + 3;
      return `${this.pos.slice(0, this.index)}% + ${config.flatFee}c`;
    }
    this.pos = `${config.stripePercentage * 100}`;
    this.index = this.pos.indexOf('.') + 3;
    return `${this.pos.slice(0, this.index)}% + ${config.flatFee}c`;
  }
}
