<h5 *ngIf="!instance && placeholder" class="placeholder">{{ placeholder }}</h5>
<div class="chart" [ngClass]="[chartClass]"></div>
<div class="chart-pagination" *ngIf="paginationOptions.show">
  <ngb-pagination
    class="d-flex justify-content-center"
    [boundaryLinks]="true"
    [(page)]="paginationOptions.page"
    [pageSize]="paginationOptions.limit"
    [maxSize]="5"
    [collectionSize]="paginationOptions.total"
    (pageChange)="paginationOptions.nextPage($event)"
  ></ngb-pagination>
</div>
