import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { ApiQuery, ApiQueryParams } from '@app/clients';

@Injectable({ providedIn: 'root' })
export class RestaurantService {
  // OVERIDE if using a new version for this feature
  private path = 'restaurant';
  private domain = environment.url_v2;
  private baseUrl = `${this.domain}/${this.path}`;

  constructor(private http: HttpClient) {}

  find(query?: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this.http.get<any>(`${this.baseUrl}`, { params });
  }

  findById(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${id}`);
  }

  self(query?: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this.http.get<any>(`${this.baseUrl}/self`, { params });
  }

  venues(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/venues`);
  }

  categories(query?: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this.http.get<any>(`${this.baseUrl}/categories`, { params });
  }

  create(body: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}`, body);
  }

  update(id: string, body: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}`, body);
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }
}
