import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ticketTime',
})
export class TicketTimePipe implements PipeTransform {
  transform(value: number, type?: string): string {
    if (value) {
      const seconds = Math.trunc((value / 1000) % 60);
      const minutes = Math.trunc((value / (1000 * 60)) % 60);
      const hours = Math.trunc((value / (1000 * 60 * 60)) % 24);
      const days = Math.trunc((value / (24 * 1000 * 60 * 60)) % 365);

      const daysStr = days < 10 ? `0${days}` : days;
      const hoursStr = hours < 10 ? `0${hours}` : hours;
      const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
      const secondsStr = seconds < 10 ? `0${seconds}` : seconds;

      if (type === 'full') {
        if (days) {
          return `${days} days, ${hours} hours, ${minutes} mins`;
        }
        if (hours) {
          return `${hours} hours, ${minutes} mins, ${seconds} secs`;
        }
        if (minutes) {
          return `${minutes} mins, ${seconds} secs`;
        }
        return `${seconds} secs`;
      }

      if (days) {
        return `${daysStr};${hoursStr}:${minutesStr}:${secondsStr}`;
      }
      return `${hoursStr}:${minutesStr}:${secondsStr}`;
    }
    return '00:00:00';
  }
}
