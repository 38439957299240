import { VenueType } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { Address, AddressSchema } from './address';

export const VenueSchema: ISchema = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  slug: {
    label: 'Slug',
    type: Type.STRING,
    required: true,
  },
  type: {
    label: 'Type',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(VenueType),
    required: true,
  },
  phone: {
    label: 'Phone',
    type: Type.STRING,
  },
  classifications: {
    label: 'Classifications',
    type: Type.ARRAY,
    isHidden: true,
  },
  address: {
    label: 'Address',
    type: Type.OBJECT,
    properties: AddressSchema,
  },
  images: {
    section: 'images',
    type: Type.IMAGES,
    label: 'Images',
    bucket: 'venue',
  },
  isPublic: {
    label: 'Public',
    type: Type.BOOLEAN,
  },
};

export interface VenueData extends AbstractModelData {
  name: string;
  slug: string;
  type: VenueType;
  location: string;
  classifications: string[];
  isPublic: boolean;
  isPreview: boolean;
  address: Address;
  phone: string;
  countryCode: string;
  website: string;
  /** @deprecated */
  imageUrl: string;
  images: any[];
  longitude: number;
  latitude: number;
  loc: number[];
}

export class Venue extends AbstractModel<VenueData> {
  constructor(public data: VenueData) {
    super(data);
  }
}
