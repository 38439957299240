<div class="modal-header">
  <h4 class="modal-title">
    {{ title }}
  </h4>
</div>
<div class="modal-body">
  <div [innerHtml]="text | safeHtml"></div>
</div>
<div class="modal-footer">
  <div class="text-right">
    <button type="button" class="btn btn-outline-secondary btn-delete" (click)="modal.close()">
      {{ cancelText }}
    </button>
    <button *ngIf="!hideConfirm" type="button" class="btn btn-info" (click)="confirm()">
      {{ confirmText }}
    </button>
  </div>
</div>
