import { AccountingCodeType } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { OrgData } from '../core';
import { ISchema, Type, Widget } from '../schema';

export const AccountingCodeSchema: ISchema = {
  type: {
    label: 'Code Type',
    type: Type.STRING,
    enum: EnumHelper.getEnumArray(AccountingCodeType),
    widget: {
      type: Widget.SELECT,
    },
    immutable: true,
    required: true,
  },
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  code: {
    label: 'Code',
    type: Type.STRING,
    required: true,
  },
};

export interface AccountingCodeData extends AbstractModelData {
  type: AccountingCodeType;
  code: string;
  name: string;
  orgId: string | OrgData;
}

export class AccountingCode extends AbstractModel<AccountingCodeData> {
  constructor(public data: AccountingCodeData) {
    super(data);
  }
}
