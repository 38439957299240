<input
  type="text"
  [class]="size"
  [disabled]="disabled"
  [(ngModel)]="search"
  [ngbTypeahead]="searchUser"
  [placeholder]="placeholder"
  [resultTemplate]="selectedUserTpl"
  (selectItem)="selectUser($event)"
  (blur)="blur()"
/>
<ng-template #selectedUserTpl let-r="result">
  <div>{{ r.name }}</div>
  <span *ngIf="r.email"> <i class="fas fa-envelope"></i> {{ r.email }} </span>
  <span *ngIf="r.phone"> <i class="fas fa-phone"></i> {{ r | userPhone }} </span>
</ng-template>
