import { ApiResources, ApiVersion } from '../../enums';
import { ISchema, Type } from '../schema';

export const SearchGuestSchema: ISchema = {
  guestByName: {
    label: 'Name',
    type: Type.TYPEAHEAD,
    resource: ApiResources.GUESTS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    displayFields: ['name', 'email'],
  },
  guestByEmail: {
    label: 'Email',
    type: Type.TYPEAHEAD,
    resource: ApiResources.GUESTS,
    version: ApiVersion.V2,
    modelProp: 'email',
    dataProp: 'data.data',
    displayFields: ['name', 'email'],
  },
};
