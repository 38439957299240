import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { ApiResources, ApiVersion, OrderChannel } from '../../enums';
import { EnumHelper } from '../../helpers';
import { LocationData } from './location';
import { MenuSectionData } from './menu-section';
import { DayPartData } from './day-part';

export interface MenuSchemaData {
  name: string;
  channel: OrderChannel;
  dayPartId: string[] | DayPartData[];
  menuSectionIds: string[] | MenuSectionData[];
  gratuityIds: string[] | any[];
  isActive: boolean;
  locationId: string | LocationData;
}

export const MenuSchema: ISchema<MenuSchemaData> = {
  name: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Name',
    side: 'left',
  },
  locationId: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Location',
    description: 'Select the location for the Menu',
    side: 'left',
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        version: ApiVersion.V2,
        resource: ApiResources.LOCATION,
        modelProp: 'name',
        search: { isDeleted: false },
        displayFields: ['name'],
      },
    },
    reloadOnChange: true,
  },
  dayPartId: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Day Part',
    description: 'Select the open/close schedule for the Menu',
    side: 'left',
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        version: ApiVersion.V2,
        resource: ApiResources.DAY_PART,
        modelProp: 'name',
        displayFields: ['name'],
        dataProp: 'data.data',
      },
    },
    reloadOnChange: true,
  },
  channel: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Channel',
    side: 'left',
    immutable: true,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(OrderChannel),
    },
  },
  isActive: {
    section: 'Basic Info',
    type: Type.BOOLEAN,
    side: 'left',
    label: 'Active',
    default: true,
  },
  menuSectionIds: {
    section: 'Menu Sections',
    type: Type.ARRAY,
    label: 'Menu Sections',
    side: 'right',
    items: {
      label: 'Menu Section',
      type: Type.STRING,
      widget: {
        type: Widget.SELECT,
        multiple: true,
        enum: [],
        options: {
          preserveModel: true,
          resource: ApiResources.MENU_SECTION,
          version: ApiVersion.V2,
          modelProp: 'name',
          query: { sort: 'name' },
          displayFields: ['name'],
          dataProp: 'data.data',
        },
      },
    },
  },
  gratuityIds: {
    section: 'Menu',
    type: Type.ARRAY,
    label: 'Gratuities',
    description: 'Select the gratuities for the Menu, min 3, max 4 - leave empty for no gratuity',
    side: 'left',
    items: {
      label: 'Gratuity',
      type: Type.STRING,
      widget: {
        type: Widget.SELECT,
        multiple: true,
        enum: [],
        options: {
          preserveModel: true,
          resource: ApiResources.GRATUITIES,
          subCollection: 'self',
          version: ApiVersion.V2,
          modelProp: 'name',
          query: { sort: 'name' },
          displayFields: ['name'],
          dataProp: 'data.data',
        },
      },
    },
  },
};

export type MenuData = MenuSchemaData & AbstractModelData;

export class Menu extends AbstractModel<MenuData> {
  constructor(public data: MenuData) {
    super(data);
  }

  get json() {
    return {
      ...this.data,
      locationId: (this.data.locationId as LocationData)._id || this.data.locationId,
    };
  }
}
