import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';

import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';

import { ProductService } from '@app/providers/operations/product.service';
import { Observable, of } from 'rxjs';
import _ from 'underscore';

@Component({
  selector: 'app-product-search-typeahead',
  templateUrl: './product-search-typeahead.component.html',
  styleUrls: ['./product-search-typeahead.component.scss'],
})
export class ProductSearchTypeaheadComponent implements OnChanges {
  @Input() product: any;
  @Input() types: string | string[] = 'menu-item';
  @Input() category: string;
  @Input() value: string;
  @Input() clearOnSelect: boolean;

  @Output() selected = new EventEmitter<any>();
  @Output() productChange = new EventEmitter<any>();

  public selectedItem = null;

  constructor(private _productService: ProductService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.product) {
      this.selectedItem = this.product;
      this.value = this.product;
    }
  }

  public selectProduct(selected: any) {
    if (selected) {
      this.selectedItem = selected.item;
      this.selected.emit(selected.item);
      this.productChange.emit(selected.item);

      if (this.clearOnSelect) {
        this.value = '';
      }
    } else if (this.selectedItem) {
      this.selectedItem = null;
      this.selected.emit(null);
      this.productChange.emit(null);
    }
  }

  public onChange(value: any) {
    if (!value || typeof value === 'string') {
      this.selectProduct(null);
    }
  }

  searchProduct = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(name => {
        const query = _({
          name,
          limit: 100,
          isDeleted: false,
          type: this.types,
          'category.type': this.category,
        }).pick(value => value !== undefined);
        return this._productService.self(query).pipe(
          map(x => x.products),
          catchError(() => of([]))
        );
      })
    );

  // eslint-disable-next-line class-methods-use-this
  productFormatter = (x: { name: string; type: string }) => {
    if (x.name) {
      return `${x.name}`;
    }
    if (typeof x === 'string') {
      return x;
    }
    return '';
  };
}
