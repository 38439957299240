import _ from 'lodash';
import { DiningOptionUsageType } from '../../enums';
import { EnumHelper } from '../../helpers';
import { ISchema, Type, Widget } from '../schema';

export const DiningOptionDatasToItems: ISchema = {
  diningOptionUsageType: {
    section: 'Dining Option Selection',
    type: Type.STRING,
    label: 'Dining Options Types',
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(DiningOptionUsageType),
    required: true,
  },
  diningOptions: {
    section: 'Dining Option Selection',
    type: Type.STRING,
    label: 'Dining Options',
    widget: {
      type: Widget.SELECT,
    },
    enum: [],
    multiple: true,
    onlyIf: [
      {
        field: 'diningOptionUsageType',
        shouldShow: (value: any) =>
          _.includes(
            [
              DiningOptionUsageType.EXCLUDE_DINING_OPTIONS,
              DiningOptionUsageType.INCLUDE_DINING_OPTIONS,
            ],
            value
          ),
      },
    ],
  },
};
