import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import _ from 'lodash';
import { TimeHelper } from '../../../helpers';
import { IProperties, ISchema, Type } from '../../../models';

interface IProp {
  name: string;
  key: string;
  value: string;
}

@Component({
  selector: 'app-read-only-view',
  templateUrl: './read-only-view.component.html',
  styleUrls: ['./read-only-view.component.scss'],
})
export class ReadOnlyViewComponent implements OnChanges {
  @Input() schema: ISchema;
  @Input() data: any;
  @Input() embedded = true;

  public props: Array<IProp> = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.schema?.currentValue) {
      this.props = this.getProps(this.schema);
    }
  }

  private getProps(
    schema: ISchema | IProperties,
    parent?: string,
    labelPrepend?: string,
    labelAppend?: string
  ): Array<IProp> {
    const props: Array<IProp> = [];
    _.keys(schema).forEach((key: string) => {
      const prop = schema[key];
      if (prop.isHidden) {
        return;
      }
      switch (prop.type) {
        case Type.OBJECT: {
          const propertyKey = parent ? `${parent}.${key}` : key;
          // @ts-ignore
          props.push(...this.getProps(prop.properties, propertyKey));
          break;
        }
        case Type.ARRAY: {
          const arr = this.data[key];
          arr.forEach((val: any, idx: number) => {
            if (prop.items.properties) {
              props.push(
                ...this.getProps(
                  prop.items.properties,
                  `${key}.${idx}`,
                  `${prop.label} `,
                  ` (${idx + 1})`
                )
              );
            } else if (prop.items.type === Type.TYPEAHEAD) {
              const propertyKey = parent ? `${parent}.${key}` : key;
              const values = prop.items.displayFields.map(field =>
                this.get(`${propertyKey}.${idx}.${field}`)
              );
              props.push({
                name: `${prop.items.label} (${idx + 1})`,
                key: propertyKey,
                value: values.join(' - '),
              });
            } else {
              const propertyKey = parent ? `${parent}.${key}` : key;
              props.push({
                name: `${prop.items.label} (${idx + 1})`,
                key: propertyKey,
                value: this.get(propertyKey),
              });
            }
          });
          break;
        }
        case Type.IMAGES: {
          const arr = this.data[key];
          arr.forEach((val: any, idx: number) => {
            const propertyKey = parent ? `${parent}.${key}.${idx}.name` : `${key}.${idx}.name`;
            props.push({
              name: `${prop.label} (${idx + 1})`,
              key: propertyKey,
              value: this.get(propertyKey),
            });
          });
          break;
        }
        case Type.DATE_TIME: {
          const propertyKey = parent ? `${parent}.${key}` : key;
          const value = this.get(propertyKey)
            ? TimeHelper.getMediumDateWithTime(this.get(propertyKey))
            : '';
          props.push({
            name: prop.label,
            key: propertyKey,
            value,
          });
          break;
        }
        case Type.DATE: {
          const propertyKey = parent ? `${parent}.${key}` : key;
          const value = this.get(propertyKey)
            ? TimeHelper.getMediumDateWithTime(this.get(propertyKey))
            : '';
          props.push({
            name: prop.label,
            key: propertyKey,
            value,
          });
          break;
        }
        default: {
          const propertyKey = parent ? `${parent}.${key}` : key;
          props.push({
            name: `${labelPrepend ?? ''}${prop.label}${labelAppend ?? ''}`,
            key: propertyKey,
            value: this.get(propertyKey),
          });
        }
      }
    });
    return props;
  }

  public get(key: string) {
    return _.get(this.data, key);
  }
}
