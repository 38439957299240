export enum PointOfSaleType {
  POS = 'pos',
  KIOSK = 'kiosk',
}

export enum PointOfSaleStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export enum PrinterRole {
  RECEIPT = 'receipt',
  FULFILLMENT = 'fulfillment',
  SHIFT = 'shift',
  CASH_DRAWER = 'cash-drawer',
}
