import _ from 'lodash';
import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ApiQuery } from '@app/clients';
import { ApiResources, ApiDomain, ApiVersion, ApiHeader } from '@app/enums';
import { BaseComponent } from '../base.component';
import { Func } from '../../../models';
import { LocalStorage } from '../../../helpers';

@Component({
  selector: 'app-resource-typeahead',
  templateUrl: './resource-typeahead.component.html',
  styleUrls: ['./resource-typeahead.component.scss'],
})
export class ResourceTypeaheadComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() value: any;
  @Input() domain: ApiDomain = ApiDomain.API;
  @Input() version: ApiVersion;
  @Input() resource: ApiResources;
  @Input() subCollection: string;
  @Input() viewAsMode: 'org' | 'admin';
  @Input() placeholder = 'Search';
  @Input() property = 'name';
  @Input() dataProp: string;
  @Input() size = 'form-control';
  @Input() label: string;
  @Input() displayFilter: Func;
  @Input() displayFields: Array<string> = [];
  @Input() showCreate = false;
  @Input() query: { [key: string]: any };
  @Input() clearOnSelect = false;
  @Input() disabled = false;

  @Output() selected = new EventEmitter<any>();
  @Output() valueChange = new EventEmitter<any>();
  @Output() createProp = new EventEmitter();

  private orgId: string;

  public search: string;

  constructor() {
    super();
  }

  ngOnInit() {
    this.apiClient.setDomain(this.domain, this.version, this.resource).setCacheTtl(1);
    this.fetchValue(this.value);
    this.fetchOptionalQueryParams();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.value?.currentValue && changes?.value?.isFirstChange) {
      if (changes.value.currentValue[this.property]) {
        this.search = changes.value.currentValue[this.property];
      } else {
        this.search = changes.value.currentValue;
      }
    }
  }

  private async fetchOptionalQueryParams() {
    this.orgId = await LocalStorage.getItem(ApiHeader.ORG_ID);
  }

  private async fetchValue(value: any) {
    if (_.isString(value)) {
      const fetchResult = await this.makeSafeRequest(
        this.apiClient.findById(value, this.subCollection)
      );
      if (_.get(fetchResult, 'data')) {
        this.value = _.get(fetchResult, 'data');
        this.search = this.value[this.property];
      }
      if (_.get(fetchResult, '_id')) {
        this.value = fetchResult;
        this.search = this.value[this.property];
      }
    }
  }

  public select(selected: any) {
    if (selected) {
      this.value = selected.item;
      this.search = this.value[this.property];
      this.selected.emit(this.value);
      this.valueChange.emit(this.value);
      if (this.clearOnSelect) {
        setTimeout(() => {
          this.value = null;
          this.search = null;
        }, 1);
      }
    }
  }

  public clear() {
    this.value = null;
    this.search = null;
    this.selected.emit(this.value);
    this.valueChange.emit(this.value);
  }

  searchCollection = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        const query: ApiQuery = {
          ...(this.query && this.query),
          ...(this.viewAsMode === 'admin' && { orgId: this.orgId }),
        };
        query[this.property] = value;
        return this.apiClient.get(query, this.subCollection).pipe(
          map((x: any) => {
            if (_.isArray(x)) {
              return x;
            }
            return _.get(x, this.dataProp || 'data') || [];
          }),
          catchError(() => of([]))
        );
      })
    );

  formatter = (value: any) => {
    if (!value) {
      return '';
    }
    if (value[this.property]) {
      return value[this.property];
    }
    return value;
  };

  basicDisplay(item: any): string {
    if (!item) {
      return 'Unknown';
    }
    if (!_.size(this.displayFields)) {
      return item.name;
    }
    return this.displayFields
      .map((field: string) => _.get(item, field))
      .filter((val: any) => val)
      .join(' - ');
  }
}
