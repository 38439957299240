import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'restaurant',
})
export class RestaurantPipe implements PipeTransform {
  transform(type: any, args?: any): any {
    let name = 'Restaurants';
    if (type) {
      switch (type) {
        case 'arena':
          name = 'Concessions';
          break;
        case 'food-truck':
          name = 'Food Trucks';
      }
    }
    return name;
  }
}
