/* eslint-disable class-methods-use-this */
import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
})
export class DateTimeInputComponent implements OnChanges {
  @Input() date: Date;
  @Input() dateLabel = 'Date';
  @Input() timeLabel = 'Time';
  @Input() hideTime = false;
  @Input() description: string;
  @Input() timezone: string;

  @Output() dateChange = new EventEmitter<Date>();

  public dateInput: NgbDateStruct;
  public timeInput: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.date && changes.date.currentValue && changes.date.firstChange) {
      this.setDateAndTimeInputs(changes.date.currentValue);
    }
  }

  private setDateAndTimeInputs(date: Date) {
    const d = moment.tz(date, this.timezone || moment.tz.guess());
    this.dateInput = {
      year: d.year(),
      month: d.month() + 1,
      day: d.date(),
    };
    if (!this.hideTime) {
      this.timeInput = {
        hour: d.hours(),
        minute: d.minutes(),
      };
    }
  }

  private getDate() {
    if (!_.isNumber(this.dateInput?.year)) {
      return;
    }
    if (!this.hideTime && !_.isNumber(this.timeInput?.hour)) {
      return;
    }
    const dateParts: string[] = [];
    const datePart = `${this.dateInput.year}-${this.dateInput.month
      .toString()
      .padStart(2, '0')}-${this.dateInput.day.toString().padStart(2, '0')}`;
    dateParts.push(datePart);
    if (!this.hideTime) {
      const timePart = `${this.timeInput.hour.toString().padStart(2, '0')}:${this.timeInput.minute
        .toString()
        .padStart(2, '0')}`;
      dateParts.push(timePart);
    }
    const dateStr = dateParts.join(' ');
    return moment.tz(dateStr, this.timezone || moment.tz.guess()).toDate();
  }

  change() {
    const date = this.getDate();
    this.date = date;
    this.dateChange.emit(this.date);
  }
}
