import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiClient } from '../../clients';
import { ApiDomain, ApiResources, ApiVersion } from '../../enums';
import { ApiResponse } from '../../models';
import { LocationActions, retrievedLocations } from './locations.actions';

@Injectable()
export class LocationEffects {
  private apiClient: ApiClient;

  loadLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.LOAD_LOCATIONS),
      mergeMap((payload: any) =>
        this.apiClient.get({ limit: 1000 }).pipe(
          map((locationsResult: ApiResponse.LegacySearchResult) =>
            retrievedLocations({
              locations: locationsResult.data,
            })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    httpClient: HttpClient,
    private actions$: Actions
  ) {
    this.apiClient = new ApiClient(httpClient).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.LOCATION
    );
  }
}
