import { Pipe, PipeTransform } from '@angular/core';
import { Guest } from '../models';
import { PhonePipe } from './phone.pipe';

@Pipe({
  name: 'userPhone',
})
export class UserPhonePipe implements PipeTransform {
  constructor(private _phonePipe: PhonePipe) {}

  transform(guest: any, args?: any): any {
    if (guest) {
      if (guest instanceof Guest) {
        const guestUser = guest.data.user as any;
        if (guest.data.phone && guest.data.phone[0] !== '+' && guest.data.countryCode) {
          return this._phonePipe.transform(guest.data.phone, guest.data.countryCode);
        }
        if (guestUser?.phone && guestUser?.phone[0] !== '+' && guestUser?.countryCode) {
          return this._phonePipe.transform(guest.data.phone, guest.data.countryCode);
        }
        if (guest.data.phone) {
          return this._phonePipe.transform(guest.data.phone);
        }
        if (guestUser?.phone) {
          return this._phonePipe.transform(guestUser.phone);
        }
      }
      if (guest.phone && guest.phone[0] !== '+' && guest.countryCode) {
        return this._phonePipe.transform(guest.phone, guest.countryCode);
      }
      if (guest.phone) {
        return this._phonePipe.transform(guest.phone);
      }
    }
  }
}
