import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-user-info',
  templateUrl: './payment-user-info.component.html',
  styleUrls: ['./payment-user-info.component.scss'],
})
export class PaymentUserInfoComponent {
  @Input() user: any;
}
