import { ApiResources, ApiVersion, ExportType, ReportType, UserRoles } from '@app/enums';
import { ISchema, Type, Widget } from '../schema';
import { Report } from './report';

export const TransactionsReportSchema: ISchema = {
  from: {
    section: 'basic',
    description: 'The starting period',
    type: Type.DATE,
    default: () => new Date(),
    label: 'Start Date',
    required: true,
  },
  to: {
    section: 'basic',
    description: 'The end of a the period',
    type: Type.DATE,
    label: 'End Date',
  },
  employeeIds: {
    section: 'basic',
    description: 'Employees to filter by',
    type: Type.ARRAY,
    label: 'Employees',
    widget: {
      type: Widget.SELECT,
      enum: [],
      multiple: true,
      options: {
        resource: ApiResources.EMPLOYEE,
        version: ApiVersion.V2,
        search: { limit: 1000, sort: 'name' },
        modelProp: 'name',
        dataProp: 'data.data',
        displayFields: ['name', 'employeeId'],
      },
    },
  },
};

export interface TransactionsReportData {
  from: string;
  to: string;
}

export class TransactionsReport extends Report<TransactionsReportData> {
  ignoreProperties = [];
  icon = 'fas fa-money-bill';
  title = 'Transactions Report';
  description = 'Export all transaction information for orders made during a period of time.';
  data: TransactionsReportData = {} as TransactionsReportData;
  isImmediate = true;
  schema: ISchema = TransactionsReportSchema;
  roles: UserRoles[] = [UserRoles.ADMIN, UserRoles.CUSTOMER];
  type: ReportType = ReportType.TRANSACTIONS;
  exportOptions: ExportType[] = [ExportType.CSV];
}
