import {
  ApiDomain,
  ApiResources,
  ApiVersion,
  BillingCycle,
  CustomStringKey,
  GameType,
  MonthOfYear,
  MonthOfYearDisplayName,
  OrgFeature,
  OrgState,
} from '../../enums';
import { EnumHelper, TimeHelper } from '../../helpers';
import { TitleCasePipe } from '../../pipes/title-case.pipe';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { AddressSchema } from './address';

const titleCasePipe = new TitleCasePipe();

export const OrgSchema: ISchema = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  slug: {
    label: 'Slug',
    type: Type.STRING,
    isHidden: true,
  },
  state: {
    label: 'State',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(OrgState),
    required: true,
  },
  timezone: {
    label: 'Timezone',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
      enum: TimeHelper.US_TIMEZONES.map(timezone => ({
        key: timezone.name,
        name: `${titleCasePipe.transform(timezone.name)} (-${timezone.offset / 60})`,
      })),
      format: false,
    },
    required: true,
  },
  address: {
    label: 'Address',
    type: Type.OBJECT,
    properties: AddressSchema,
  },
  enabledFeatures: {
    label: 'Features',
    type: Type.ARRAY,
    items: {
      type: Type.STRING,
      label: 'Feature',
      widget: {
        type: Widget.SELECT,
      },
      enum: EnumHelper.getEnumArray(OrgFeature),
    },
    required: true,
  },
  enabledGameTypes: {
    label: 'Games',
    type: Type.ARRAY,
    items: {
      type: Type.STRING,
      label: 'Game',
      widget: {
        type: Widget.SELECT,
      },
      enum: EnumHelper.getEnumArray(GameType),
    },
    onlyIf: [
      {
        field: 'enabledFeatures',
        shouldShow: (features: OrgFeature[]) => features?.includes(OrgFeature.GAMES),
      },
    ],
  },
  venueIds: {
    label: 'Venues',
    type: Type.ARRAY,
    items: {
      type: Type.TYPEAHEAD,
      label: 'Venue Name',
      domain: ApiDomain.API,
      version: ApiVersion.V2,
      resource: ApiResources.VENUES,
      modelProp: 'name',
      search: { isDeleted: false },
      displayFields: ['name'],
    },
    required: true,
  },
  customerAccountScope: {
    label: 'Customer Account Scope',
    type: Type.STRING,
    required: true,
    immutable: true,
  },
  autoVerifyCustomerAccounts: {
    label: 'Auto Verify New User Accounts',
    type: Type.BOOLEAN,
    default: false,
  },
  usesBlindCashDrops: {
    label: 'Hide Tender Summary From POS Shift Summary',
    description:
      'If an org wishes to support blind cash drops, they can enable this feature. This will hide the tender summary from the POS shift summary.',
    type: Type.BOOLEAN,
    default: false,
  },
  autoCloseShifts: {
    label: 'Auto Close Open Shifts from Previous Day',
    type: Type.BOOLEAN,
    default: false,
  },
  customStrings: {
    label: 'Custom Strings',
    description: 'Set customized values for the orgs consumer facing apps.',
    type: Type.ARRAY,
    items: {
      label: 'Custom String',
      type: Type.OBJECT,
      properties: {
        key: {
          type: Type.STRING,
          label: 'String',
          required: true,
          widget: {
            type: Widget.SELECT,
            enum: EnumHelper.getEnumArray(CustomStringKey),
          },
        },
        value: {
          type: Type.STRING,
          required: true,
          label: 'String Value',
        },
      },
    },
  },
  subscriptionInfo: {
    type: Type.OBJECT,
    label: 'Subscription Info',
    properties: {
      startDate: {
        type: Type.DATE,
        label: 'Start Date',
      },
      price: {
        type: Type.CURRENCY,
        label: 'Price',
      },
      billingCycleMonthStart: {
        type: Type.STRING,
        label: 'Billing Cycle Month Start',
        widget: {
          type: Widget.SELECT,
          enum: EnumHelper.getEnumArray(
            MonthOfYear,
            undefined,
            (key: MonthOfYear) => MonthOfYearDisplayName[MonthOfYear[key]]
          ),
        },
      },
      billingCycle: {
        type: Type.STRING,
        label: 'Billing Cycle',
        widget: {
          type: Widget.SELECT,
          enum: EnumHelper.getEnumArray(BillingCycle),
        },
      },
      lastPaidDate: {
        type: Type.DATE,
        label: 'Last Paid Date',
      },
    },
  },
};

export interface CustomString {
  key: CustomStringKey;
  value: string;
}

export interface SubscriptionInfo {
  startDate: Date;
  price: number;
  billingCycleMonthStart: number;
  billingCycle: BillingCycle;
  lastPaidDate: Date;
}

export interface OrgData extends AbstractModelData {
  name: string;
  slug: string;
  customerAccountScope: string;
  autoVerifyCustomerAccounts: boolean;
  usesBlindCashDrops: boolean;
  autoCloseShifts: boolean;
  state: OrgState;
  subscriptionInfo?: SubscriptionInfo;
  enabledFeatures: OrgFeature[];
  enabledGameTypes: GameType[];
  venueIds: string[];
  customStrings: CustomString[];
}

export class Org extends AbstractModel<OrgData> {
  constructor(public data: OrgData) {
    super(data);
  }

  public hasFeature(feature: OrgFeature): boolean {
    return this.data.enabledFeatures.includes(feature);
  }
}
