<div class="modal-header">
  <h4 class="modal-title">Sale</h4>
  <button type="button" class="btn btn-light" (click)="dismiss()">Close</button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-8 left-side">
      <div
        *ngIf="view === 'payment' && (paymentType === 'credit' || paymentType === 'card-on-file')"
      >
        <app-payment-gratuity [(order)]="order" [(charge)]="charge"></app-payment-gratuity>
      </div>
      <!-- CARD ENTRY -->
      <div
        class="payment-credit-key-in-container"
        *ngIf="view === 'payment' && paymentType === 'credit'"
      >
        <app-credit-key-in [saveCard]="saveCard" (tokenize)="tokenize($event)"></app-credit-key-in>
      </div>
      <div
        class="payment-credit-key-in-container"
        *ngIf="view === 'payment' && paymentType === 'cash'"
      >
        <app-cash [charge]="charge" (complete)="completeCash()"></app-cash>
      </div>
      <div
        class="payment-credit-key-in-container"
        *ngIf="view === 'payment' && paymentType === 'card-on-file'"
      >
        <app-card-on-file [charge]="charge" (complete)="completePayment()"></app-card-on-file>
      </div>
      <!-- MENU ITEMS -->
      <div *ngIf="view === 'items'">
        <div class="payment-items-container">
          <app-payment-items
            [edit]="view === 'items'"
            (removeItem)="removeItem($event)"
            [items]="order?.items"
          ></app-payment-items>
        </div>
      </div>
    </div>
    <div class="col-md-4 right-side">
      <div class="mb-2">
        <app-payment-user-info [user]="order?.user"></app-payment-user-info>
      </div>
      <div>
        <div class="mb-2">
          <app-payment-total [order]="order" [charge]="charge"></app-payment-total>
        </div>
        <div class="">
          <button class="btn btn-success btn-block" *ngIf="view === 'items'" (click)="payment()">
            Payment
          </button>
          <button class="btn btn-light btn-block" *ngIf="view === 'payment'" (click)="cancel()">
            Cancel Payment
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
