export enum LegacyReports {
  CATEGORY_SALES = 'category-sales-report',
  EMPLOYEE_SALES_SUMMARY = 'employee-sales-summary',
  EMPLOYEE_TENDERS_SUMMARY = 'employee-tenders-summary',
  ITEM_SALES = 'item-sales-report',
  Z_REPORT = 'z-report',
  SHIFT = 'shift-report',
}

export enum ReportType {
  GUEST = 'guest',
  ORDERED_ITEMS = 'ordered-items',
  ORDERS = 'orders',
  PAYOUT = 'payout',
  SALES = 'sales',
  SHIFT = 'shift',
  EMPLOYEE = 'employee',
  TRANSACTIONS = 'transactions',
}

export enum ExportType {
  CSV = 'csv',
  PDF = 'pdf',
  EXCEL = 'excel',
}
