/* eslint-disable class-methods-use-this */
import _ from 'lodash';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CanShow,
  ListTableHeaderCell,
  ListTableHeaderOptions,
  ListTableRow,
} from '../../../models';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
})
export class TableViewComponent {
  @Input() options: ListTableHeaderOptions;

  @Input() headers: ListTableHeaderCell[] = [];

  @Input() tableRows: ListTableRow[] = [];

  @Input() selected: number;

  @Input() selectedAll: boolean;

  @Input() sortProp: { field: string; reverse: boolean };

  @Input() paginationOptions: {
    page: number;
    limit: number;
    total: number;
    maxSize: number;
    nextPage: (ev: any) => void;
  } = {
    page: 1,
    limit: 20,
    total: 0,
    maxSize: 5,
    nextPage: (ev: any) => {},
  };

  @Output() selectAll = new EventEmitter<boolean>();

  @Output() duplicate = new EventEmitter<boolean>();

  @Output() delete = new EventEmitter<boolean>();

  @Output() sort = new EventEmitter<any>();

  @Output() checkSelected = new EventEmitter<void>();

  sortList(sort: any) {
    this.sort.emit(sort);
  }

  doDuplicate() {
    this.duplicate.emit(true);
  }

  doDelete() {
    this.delete.emit(true);
  }

  doSortList(ev: any) {}

  doSelectAll() {
    this.selectAll.emit(true);
  }

  doCheckSelected() {
    this.checkSelected.emit();
  }

  canShow(show: CanShow | boolean): boolean {
    if (_.isBoolean(show)) {
      return show;
    }
    return show();
  }
}
