/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';

import { UserService } from '@app/providers/user/user.service';
import { select, Store } from '@ngrx/store';
import _ from 'lodash';
import pkg from '../../../../package.json';
import { ApiHeader, OrgFeature, Right } from '../../enums';
import { LocalStorage } from '../../helpers';
import { Org, OrgMembership, UserData, UserRoleData } from '../../models';
import { selectOrg, selectViewMode } from '../../state';

import { ROUTES } from './navigation-items';

declare let $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  private orgId: string;

  private selectedOrg$ = this.store.pipe(select(selectOrg));

  private viewMode$ = this.store.pipe(select(selectViewMode));

  private orgMembership: OrgMembership;

  private orgFeatures: OrgFeature[];

  private viewMode: 'admin' | 'org';

  public showMenu = '';

  public showSubMenu = '';

  public sidebarnavItems: any[];

  public user: UserData;

  public version = pkg.version;

  constructor(
    private store: Store,
    private userService: UserService
  ) {}

  // End open close
  ngOnInit() {
    this.init();
  }

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  private canView(features: OrgFeature[], rights: Right[]): boolean {
    const { isAdmin } = this.user;
    /**
     * @todo
     * Remove once everyone is usng menu sections
     */
    if (isAdmin && features?.includes(OrgFeature.MENU_SECTIONS)) {
      return true;
    }
    if (features?.length) {
      const orgHasFeature = features.reduce((isEnabled, feature) => {
        if (this.orgFeatures?.includes(feature)) {
          return true;
        }
        return isEnabled;
      }, false);
      if (!orgHasFeature) {
        return false;
      }
    }
    if (isAdmin) {
      return rights.indexOf(Right.PARTAKE_ADMIN) > -1;
    }
    const userRole = this.orgMembership.userRoleId as UserRoleData;
    const hasRight: boolean = userRole.rights.reduce<boolean>((prev: boolean, curr: Right) => {
      if (rights.includes(curr)) {
        return true;
      }
      return prev;
    }, false);
    return hasRight;
  }

  private setupSideMenu() {
    const copy = _.cloneDeep(ROUTES);
    this.sidebarnavItems = copy.filter(sidebarnavItem => {
      if (!sidebarnavItem.mode.includes(this.viewMode)) {
        return false;
      }
      sidebarnavItem.submenu = sidebarnavItem.submenu.filter(submenuItem => {
        if (!submenuItem.mode.includes(this.viewMode)) {
          return false;
        }
        return this.canView(submenuItem.features, submenuItem.rights);
      });
      return this.canView(sidebarnavItem.features, sidebarnavItem.rights);
    });
  }

  async init() {
    try {
      this.user = await this.userService.me().toPromise();
      this.selectedOrg$.subscribe(async (org: Org) => {
        if (!org) {
          return;
        }
        this.orgFeatures = org.data.enabledFeatures;
        this.orgId = await LocalStorage.getItem(ApiHeader.ORG_ID);
        this.orgMembership = this.user.orgMemberships.find(
          (orgMembership: OrgMembership) => orgMembership.orgId === this.orgId
        );
        this.setupSideMenu();
      });
      this.viewMode$.subscribe((viewMode: 'admin' | 'org') => {
        this.viewMode = viewMode;
        this.setupSideMenu();
      });
    } catch (e) {
      console.log(e);
    }
  }

  logout() {
    this.userService.logout();
  }
}
