import { ExportType, ReportType, UserRoles } from '@app/enums';
import { PdfHelper } from '../../helpers';
import { PdfContent, PdfSectionCreateParams } from '../../helpers/pdf.helper';
import { ISchema, Type } from '../schema';
import { Report, ReportPeriodParams } from './report';
import { SalesReportResponseData } from './report-export-summaries';
import {
  getReportPeriod,
  OrdersSummary,
  PrintedAt,
  SalesSummary,
  RefundTransactionsSummaryForOrders,
  VoidsSummary,
  AccountCodeSalesByLocationSummary,
  AccountCodeSalesSummary,
  ClassCodeSalesByLocationSummary,
  ClassCodeSalesSummary,
  StatCodeSalesByLocationSummary,
  StatCodeSalesSummary,
  VendorCodeSalesByLocationSummary,
  VendorCodeSalesSummary,
} from './report-sections';

export const AccountingCodeReportSchema: ISchema = {
  from: {
    section: 'basic',
    description: 'The starting period',
    type: Type.DATE,
    default: () => new Date(),
    label: 'Start Date',
    required: true,
  },
  to: {
    section: 'basic',
    description: 'The end of a the period',
    type: Type.DATE,
    label: 'End Date',
  },
};

export interface AccountingCodeReportData {
  from: string;
  to: string;
}

export class AccountingCodeReport extends Report<AccountingCodeReportData> {
  ignoreProperties = [];
  icon = 'fas fa-file-invoice-dollar';
  title = 'Accounting Code Report';
  description =
    'Export all accounting code related information for orders placed during a particular period.';
  data: AccountingCodeReportData = {} as AccountingCodeReportData;
  isImmediate = true;
  schema: ISchema = AccountingCodeReportSchema;
  roles: UserRoles[] = [UserRoles.ADMIN, UserRoles.CUSTOMER];
  type: ReportType = ReportType.SALES;
  exportOptions: ExportType[] = [ExportType.PDF];

  pdfSectionCreateParams: PdfSectionCreateParams<SalesReportResponseData>[] = [
    OrdersSummary,
    SalesSummary,
    VoidsSummary,
    RefundTransactionsSummaryForOrders,
    AccountCodeSalesSummary,
    ClassCodeSalesSummary,
    StatCodeSalesSummary,
    VendorCodeSalesSummary,
    AccountCodeSalesByLocationSummary,
    ClassCodeSalesByLocationSummary,
    StatCodeSalesByLocationSummary,
    VendorCodeSalesByLocationSummary,
  ];

  public generateExport(period: ReportPeriodParams, responseData: SalesReportResponseData): void {
    const pdfContent: PdfContent = PdfHelper.generateContent<SalesReportResponseData>(
      'Accounting Code Sales Report',
      [PrintedAt, getReportPeriod(period, true)].concat(this.pdfSectionCreateParams),
      responseData
    );
    const periodAsString = [period.from, period.to].filter(val => !!val).join('_');
    PdfHelper.generatePdf(pdfContent, `accounting-code-sales-report_${periodAsString}`);
  }
}
