export enum HomeOrAway {
  HOME = 'home',
  AWAY = 'away',
}

export enum PersonType {
  PLAYER = 'player',
  REFEREE = 'referee',
  COACH = 'coach',
  ASSISTANT_COACH = 'assistant coach',
  STAFF = 'staff',
}

export enum PlayingStatus {
  ACTIVE = 'active',
  RETIRED = 'retired',
  DIED = 'died',
}
