import { Injectable } from '@angular/core';
import { StripeLazyLoadService } from './stripe.lazy-load.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const Stripe: any;
@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private _STRIPE: any;

  constructor(private _stripeLazyLoad: StripeLazyLoadService) {}

  public async init() {
    const promise = new Promise<any>((resolve, reject) => {
      if (window.Stripe) {
        resolve({ loaded: true });
      } else {
        this._stripeLazyLoad
          .init()
          .then(success => {
            this._STRIPE = success.stripe;
            resolve(success);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
    return promise;
  }

  public getStripe(): any {
    return this._STRIPE;
  }
}
