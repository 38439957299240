<div class="modal-header">
  <h4 class="modal-title">
    {{ title }}
  </h4>
</div>
<div class="modal-body">
  <div [innerHtml]="text | safeHtml"></div>
  <div class="row">
    <div class="col-6 btn-container" *ngFor="let btn of buttons">
      <button
        type="button"
        class="btn btn-block option-button"
        [ngClass]="'btn-' + btn.color"
        (click)="action(btn.action)"
      >
        {{ btn.label }}
      </button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="row text-right">
    <button type="button" class="btn btn-outline-secondary btn-delete" (click)="modal.close()">
      Cancel
    </button>
  </div>
</div>
