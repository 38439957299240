import _ from 'lodash';
import { OrderStatus } from '../../enums';
import { StringHelper, TimeHelper } from '../../helpers';
import { PdfSectionCreateParams, TableRow, TableWidth } from '../../helpers/pdf.helper';
import { LocationData } from '../concession';
import { EventData } from '../events';
import { ShiftData } from '../operations';
import { EventReportParams, ReportPeriodParams, ShiftReportParams } from './report';

export function getReportPeriod(
  params: ReportPeriodParams,
  toPresent?: boolean
): PdfSectionCreateParams<any> {
  const { from, to } = params;
  const startDateStr = TimeHelper.getMediumDate(TimeHelper.getDateFromDateString(from));
  const endDateStr = to
    ? `to ${TimeHelper.getMediumDate(TimeHelper.getDateFromDateString(to))}`
    : '';
  const toPresentStr = toPresent ? `to ${TimeHelper.getMediumDate(new Date())}` : '';
  return {
    type: 'title',
    label: `Report Period: ${startDateStr} ${endDateStr || toPresentStr}`,
  };
}

export function getEventReportInfo(params: EventReportParams): PdfSectionCreateParams<any>[] {
  const { events } = params;
  console.log(events);
  return events.map((event: EventData) => {
    const startDateStr = TimeHelper.getMediumDate(
      TimeHelper.getDateFromDateString(event.dates.start.date)
    );
    const endDateStr =
      event.dates.start.date !== event.dates.end.date
        ? `to ${TimeHelper.getMediumDate(TimeHelper.getDateFromDateString(event.dates.end.date))}`
        : '';
    return {
      type: 'title',
      label: `Event: ${event.name} (${event.id}) - ${startDateStr} ${endDateStr}`,
    };
  });
}

export function getShiftReportInfo(params: ShiftReportParams): PdfSectionCreateParams<any>[] {
  const { shiftIds } = params;
  return shiftIds.map((shift: ShiftData) => {
    const startDateStr = TimeHelper.getMediumDate(TimeHelper.getDateFromDateString(shift.openTime));
    const endDateStr = shift.closeTime
      ? `Closed: ${TimeHelper.getMediumDate(TimeHelper.getDateFromDateString(shift.closeTime))}`
      : '';
    const employeeName = shift.openingBy?.name ? ` (${shift.openingBy?.name}) ` : '';
    const locationName = (shift.restaurant as LocationData).name;
    return {
      type: 'title',
      label: `Shift: ${shift.shiftId}${employeeName} - Opened: ${startDateStr} ${endDateStr} - ${locationName}`,
    };
  });
}

export const PrintedAt: PdfSectionCreateParams<any> = {
  type: 'title',
  label: `Printed: ${TimeHelper.getMediumDate(new Date())}`,
};

export const OrdersSummary: PdfSectionCreateParams<any> = {
  type: 'custom-table',
  theme: 'plain',
  label: 'Orders Summary',
  margin: { right: 300 },
  columns: ['Orders Summary', ''],
  dataProperty: 'salesSummary',
  children: [
    {
      type: 'text',
      label: '# Orders',
      dataProperty: 'orderCount',
    },
    {
      type: 'text',
      label: '# In-Person Orders',
      dataProperty: 'posCount',
    },
    {
      type: 'text',
      label: '# Online Orders',
      dataProperty: 'onlineCount',
    },
  ],
};

export const SalesSummary: PdfSectionCreateParams<any> = {
  type: 'custom-table',
  theme: 'plain',
  label: 'Sales Summary',
  margin: { left: 300 },
  columns: ['Sales Summary', ''],
  dataProperty: 'salesSummary',
  children: [
    {
      type: 'text',
      label: 'Sales',
      dataProperty: 'salesTotal',
      format: 'currency',
    },
    {
      type: 'text',
      label: 'Discounts',
      dataProperty: 'discountTotal',
      format: 'currency',
    },
    {
      type: 'text',
      label: 'Taxes',
      dataProperty: 'taxTotal',
      format: 'currency',
    },
    {
      type: 'text',
      label: 'Gratuities',
      dataProperty: 'gratuityTotal',
      format: 'currency',
    },
    {
      type: 'text',
      label: 'Service Fees',
      dataProperty: 'serviceFeeTotal',
      format: 'currency',
    },
    {
      type: 'text',
      label: 'Round Up',
      dataProperty: 'roundUpTotal',
      format: 'currency',
    },
    {
      type: 'text',
      label: 'Refunds',
      dataProperty: 'refundTotal',
      format: 'currency',
    },
    {
      type: 'text',
      label: 'Refunded Gratuities',
      dataProperty: 'tipRefundTotal',
      format: 'currency',
    },
  ],
};

export const VoidsSummary: PdfSectionCreateParams<any> = {
  type: 'custom-table',
  theme: 'plain',
  label: 'Voided Sales Summary',
  margin: { left: 300 },
  columns: ['Orders Summary', ''],
  dataProperty: 'voidsSummary',
  children: [
    {
      type: 'text',
      label: 'Voided Sales',
      dataProperty: 'voidTotal',
      format: 'currency',
    },
    {
      type: 'text',
      label: 'Voided Item Quantity',
      dataProperty: 'voidQuantity',
    },
  ],
};

export const TransactionForPeriodDisclaimer: PdfSectionCreateParams<any> = {
  type: 'title',
  label:
    'Transactions have all occured for the reporting period and may not be related to orders completed in the same period. For transactions only related to orders view the Transactions for Orders Summary.',
  options: {
    fontStyle: 'italic',
    fontColor: [150, 40, 27],
  },
};

export const TransactionDisclaimer: PdfSectionCreateParams<any> = {
  type: 'title',
  label:
    'Transactions may not have occured on the same day as the order. View the Transactions for Period Summary for transaction for the period.',
  options: {
    fontStyle: 'italic',
    fontColor: [150, 40, 27],
  },
};

interface TransactionSummaryOptions {
  label: string;
  dataProperty:
    | 'saleTransactionSummariesForPeriod'
    | 'saleTransactionSummariesForOrdersInPeriod'
    | 'saleTransactionSummariesByShiftForPeriod'
    | 'refundTransactionSummariesForPeriod'
    | 'refundTransactionSummariesForOrdersInPeriod'
    | 'refundTransactionSummariesByShiftForPeriod';
  groupField?: string;
  groupLabelProperty?: string | string[];
  noItemsMessage?: string;
  showTransactionCount?: boolean;
}

function getTransactionSummary(options: TransactionSummaryOptions): PdfSectionCreateParams<any> {
  const {
    label,
    noItemsMessage = 'No transactions completed.',
    dataProperty,
    groupField,
    groupLabelProperty,
    showTransactionCount,
  } = options;
  const rows: TableRow[] = [
    {
      type: 'text',
      label: 'Tender',
      dataProperty: 'tenderName',
    },
    {
      type: 'text',
      label: '# Orders',
      dataProperty: 'orderCount',
    },
    {
      type: 'text',
      label: 'Gratuity',
      dataProperty: 'gratuity',
      format: 'currency',
      showTotal: true,
    },
    {
      type: 'text',
      label: 'Total',
      dataProperty: 'total',
      format: 'currency',
      halign: 'right',
      showTotal: true,
    },
  ];
  if (showTransactionCount) {
    rows.splice(1, 0, {
      type: 'text',
      label: '# Transactions',
      dataProperty: 'count',
    });
  }
  return {
    type: 'table',
    theme: 'plain',
    label,
    noItemsMessage,
    dataProperty,
    groupField,
    groupLabelProperty,
    columnStyles: { 0: { cellWidth: TableWidth * 0.5 } },
    sort: {
      field: 'tenderName',
      reverse: false,
    },
    showTotalsRow: true,
    rows,
  };
}

export const TransactionsSummaryForPeriod: PdfSectionCreateParams<any> = getTransactionSummary({
  label: 'Transactions for Period Summary',
  dataProperty: 'saleTransactionSummariesForPeriod',
  showTransactionCount: true,
});

export const RefundTransactionsSummaryForPeriod: PdfSectionCreateParams<any> =
  getTransactionSummary({
    label: 'Refunded Transactions for Period Summary',
    dataProperty: 'refundTransactionSummariesForPeriod',
    showTransactionCount: true,
  });

export const TransactionsSummaryForOrders: PdfSectionCreateParams<any> = getTransactionSummary({
  label: 'Transactions for Orders Summary',
  dataProperty: 'saleTransactionSummariesForOrdersInPeriod',
});

export const RefundTransactionsSummaryForOrders: PdfSectionCreateParams<any> =
  getTransactionSummary({
    label: 'Refunded Transactions Summary',
    dataProperty: 'refundTransactionSummariesForOrdersInPeriod',
  });

export const TransactionsSummaryByShifts: PdfSectionCreateParams<any> = getTransactionSummary({
  label: 'Transactions for Shift {{shiftId}} ({{employeeName}} / {{locationName}}) Summary',
  dataProperty: 'saleTransactionSummariesByShiftForPeriod',
  groupLabelProperty: ['shiftId', 'employeeName', 'locationName'],
  groupField: '_id.groupName',
  showTransactionCount: true,
});

export const RefundTransactionsSummaryByShifts: PdfSectionCreateParams<any> = getTransactionSummary(
  {
    label:
      'Refunded Transactions for Shift {{shiftId}} ({{employeeName}} / {{locationName}}) Summary',
    dataProperty: 'refundTransactionSummariesByShiftForPeriod',
    groupLabelProperty: ['shiftId', 'employeeName', 'locationName'],
    groupField: '_id.groupName',
    showTransactionCount: true,
  }
);

export const TaxesSummary: PdfSectionCreateParams<any> = {
  type: 'table',
  theme: 'plain',
  label: 'Tax Summary',
  noItemsMessage: 'No taxes collected',
  dataProperty: 'taxesSummaries',
  columnStyles: { 0: { cellWidth: TableWidth * 0.5 } },
  sort: {
    field: 'taxName',
    reverse: false,
  },
  showTotalsRow: true,
  rows: [
    {
      type: 'text',
      label: 'Tax',
      dataProperty: 'taxName',
    },
    {
      type: 'text',
      label: '# Orders',
      dataProperty: 'orderCount',
    },
    {
      type: 'text',
      label: 'Total',
      dataProperty: 'taxTotal',
      format: 'currency',
      halign: 'right',
      showTotal: true,
    },
  ],
};

export const DiscountsSummary: PdfSectionCreateParams<any> = {
  type: 'table',
  theme: 'plain',
  label: 'Discounts Summary',
  noItemsMessage: 'No discounts used.',
  dataProperty: 'discountsSummaries',
  columnStyles: { 0: { cellWidth: TableWidth * 0.5 } },
  sort: {
    field: 'discountName',
    reverse: false,
  },
  showTotalsRow: true,
  rows: [
    {
      type: 'text',
      label: 'Discount',
      dataProperty: 'discountName',
    },
    {
      type: 'text',
      label: '# Orders',
      dataProperty: 'orderCount',
    },
    {
      type: 'text',
      label: 'Total',
      dataProperty: 'discountTotal',
      format: 'currency',
      halign: 'right',
      showTotal: true,
    },
  ],
};

function generateServiceChargesSummaryForProperty(
  options: SalesSummaryOptions
): PdfSectionCreateParams<any> {
  const { label, noItemsMessage, dataProperty, groupField, groupLabelProperty, firstRowLabel } =
    options;
  return {
    type: 'table',
    theme: 'plain',
    label,
    noItemsMessage,
    dataProperty,
    ...(groupField && { groupField }),
    ...(groupLabelProperty && { groupLabelProperty }),
    columnStyles: { 0: { cellWidth: TableWidth * 0.3 } },
    sort: {
      field: 'serviceChargeName',
      reverse: false,
    },
    showTotalsRow: true,
    rows: [
      {
        type: 'text',
        label: firstRowLabel,
        dataProperty: 'serviceChargeName',
      },
      {
        type: 'text',
        label: '# Orders',
        dataProperty: 'orderCount',
      },
      {
        type: 'text',
        label: 'Total',
        dataProperty: 'serviceChargeTotal',
        format: 'currency',
        halign: 'right',
        showTotal: true,
      },
    ],
  };
}

export const ServiceChargesSummary: PdfSectionCreateParams<any> =
  generateServiceChargesSummaryForProperty({
    label: 'Service Charges Summary',
    noItemsMessage: 'No service charges applied.',
    dataProperty: 'serviceChargesSummaries',
    firstRowLabel: 'Service Charge',
  });

export const ServiceChargesByEmployeeSummary: PdfSectionCreateParams<any> =
  generateServiceChargesSummaryForProperty({
    label: 'Service Charges for {{employeeName}} Summary',
    noItemsMessage: 'No service charges applied.',
    dataProperty: 'serviceChargesByEmployeeSummaries',
    firstRowLabel: 'Service Charge',
    groupField: 'groupId',
    groupLabelProperty: 'employeeName',
  });

interface SalesSummaryOptions {
  label: string;
  noItemsMessage: string;
  dataProperty: string;
  firstRowLabel: string;
  firstRowProperty?: string | string[];
  firstRowFormatter?: (value: any) => string;
  groupField?: string;
  groupLabelProperty?: string;
  showQuantity?: boolean;
}

function generateSalesSummaryForProperty(
  options: SalesSummaryOptions
): PdfSectionCreateParams<any> {
  const {
    label,
    noItemsMessage,
    dataProperty,
    firstRowLabel,
    firstRowProperty,
    firstRowFormatter,
    groupField,
    groupLabelProperty,
    showQuantity,
  } = options;
  const rows: TableRow[] = [
    {
      type: 'text',
      label: firstRowLabel,
      dataProperty: firstRowProperty || 'name',
      format: firstRowFormatter ? 'custom' : 'string',
      ...(firstRowFormatter && { formatter: firstRowFormatter }),
    },
    {
      type: 'text',
      label: '# Orders',
      dataProperty: 'orderCount',
    },
    {
      type: 'text',
      label: 'Quantity',
      dataProperty: 'quantity',
    },
    {
      type: 'text',
      label: 'Sales',
      dataProperty: 'salesTotal',
      format: 'currency',
      halign: 'right',
      showTotal: true,
    },
    {
      type: 'text',
      label: 'Discounts',
      dataProperty: 'discountTotal',
      format: 'currency',
      halign: 'right',
      showTotal: true,
    },
    {
      type: 'text',
      label: 'Taxes',
      dataProperty: 'taxTotal',
      format: 'currency',
      halign: 'right',
      showTotal: true,
    },
    {
      type: 'text',
      label: 'Gratuities',
      dataProperty: 'gratuityTotal',
      format: 'currency',
      halign: 'right',
      showTotal: true,
    },
  ];
  if (!showQuantity) {
    rows.splice(2, 1);
  }
  return {
    type: 'table',
    theme: 'plain',
    label,
    noItemsMessage,
    dataProperty,
    groupField,
    groupLabelProperty,
    columnStyles: { 0: { cellWidth: TableWidth * 0.3 } },
    sort: {
      field: _.isArray(firstRowProperty) ? firstRowProperty[0] : firstRowProperty || 'name',
      reverse: false,
    },
    showTotalsRow: true,
    rows,
  };
}

export const LocationSalesSummary: PdfSectionCreateParams<any> = generateSalesSummaryForProperty({
  label: 'Location Sales Summary',
  noItemsMessage: 'No sales at any locations.',
  dataProperty: 'locationsSummaries',
  firstRowLabel: 'Location',
});

export const LocationCategorySalesSummary: PdfSectionCreateParams<any> =
  generateSalesSummaryForProperty({
    label: 'Location Category Sales Summary',
    noItemsMessage: 'No sales at any locations.',
    dataProperty: 'locationCategorySummaries',
    firstRowLabel: 'Location Category',
  });

export const ShiftSalesSummary: PdfSectionCreateParams<any> = generateSalesSummaryForProperty({
  label: 'Shift Sales Summary',
  noItemsMessage: 'No sales at any shifts.',
  dataProperty: 'shiftsSummaries',
  firstRowLabel: 'Shift',
  firstRowProperty: ['name', 'employeeName', 'locationName'],
});

export const EmployeeSalesSummary: PdfSectionCreateParams<any> = generateSalesSummaryForProperty({
  label: 'Employee Sales Summary',
  noItemsMessage: 'No sales by any employees.',
  dataProperty: 'employeesSummaries',
  firstRowLabel: 'Employee',
});

export const ProductCategorySalesSummary: PdfSectionCreateParams<any> =
  generateSalesSummaryForProperty({
    label: 'Menu Item Product Category Sales Summary',
    noItemsMessage: 'No sales.',
    dataProperty: 'menuItemCategorySummaries',
    firstRowLabel: 'Product Category',
    showQuantity: true,
  });

export const ModifierProductCategorySalesSummary: PdfSectionCreateParams<any> =
  generateSalesSummaryForProperty({
    label: 'Modifier Product Category Sales Summary',
    noItemsMessage: 'No sales.',
    dataProperty: 'modifierCategorySummaries',
    firstRowLabel: 'Product Category',
    showQuantity: true,
  });

export const ProductSalesSummary: PdfSectionCreateParams<any> = generateSalesSummaryForProperty({
  label: 'Menu Item Product Sales Summary',
  noItemsMessage: 'No sales.',
  dataProperty: 'itemsSummaries',
  firstRowLabel: 'Product',
  showQuantity: true,
});

export const ModifierProductSalesSummary: PdfSectionCreateParams<any> =
  generateSalesSummaryForProperty({
    label: 'Modifier Product Sales Summary',
    noItemsMessage: 'No sales.',
    dataProperty: 'modifiersSummmaries',
    firstRowLabel: 'Product',
    showQuantity: true,
  });

export const StatusSalesSummary: PdfSectionCreateParams<any> = generateSalesSummaryForProperty({
  label: 'Order Status Sales Summary',
  noItemsMessage: 'No sales.',
  dataProperty: 'salesSummaryByStatus',
  firstRowLabel: 'Status',
  firstRowProperty: 'name',
  firstRowFormatter: (value: string) => {
    switch (value) {
      case OrderStatus.POINT_OF_SALE:
        return 'Open';
      default:
        return StringHelper.titlecase(value);
    }
  },
});

export const BehaviorSalesSummary: PdfSectionCreateParams<any> = generateSalesSummaryForProperty({
  label: 'Order Type Sales Summary',
  noItemsMessage: 'No sales.',
  dataProperty: 'salesSummaryByBehavior',
  firstRowLabel: 'Order Type',
  firstRowProperty: 'name',
});

export const EventSalesSummary: PdfSectionCreateParams<any> = generateSalesSummaryForProperty({
  label: 'Event Sales Summary',
  noItemsMessage: 'No sales.',
  dataProperty: 'salesSummaryByEvent',
  firstRowLabel: 'Event',
  firstRowProperty: 'name',
});

export const ProductSalesByBehaviorSummary: PdfSectionCreateParams<any> =
  generateSalesSummaryForProperty({
    label: 'Product Sales by {{groupName}} Summary',
    noItemsMessage: 'No sales.',
    dataProperty: 'itemsByBehaviorSummaries',
    groupLabelProperty: 'groupName',
    groupField: '_id.fieldName',
    firstRowLabel: 'Product',
    firstRowProperty: 'name',
    showQuantity: true,
  });

export const ProductSalesByEventSummary: PdfSectionCreateParams<any> =
  generateSalesSummaryForProperty({
    label: 'Product Sales for {{groupName}} Event',
    noItemsMessage: 'No sales.',
    dataProperty: 'itemsByEventSummaries',
    groupLabelProperty: 'groupName',
    groupField: '_id.fieldName',
    firstRowLabel: 'Product',
    firstRowProperty: 'name',
    showQuantity: true,
  });

export const ProductSalesByEmployeeSummary: PdfSectionCreateParams<any> =
  generateSalesSummaryForProperty({
    label: 'Product Sales for {{groupName}}',
    noItemsMessage: 'No sales.',
    dataProperty: 'employeesMenuItemSummaries',
    groupLabelProperty: 'groupName',
    groupField: '_id.fieldName',
    firstRowLabel: 'Product',
    firstRowProperty: 'name',
    showQuantity: true,
  });

export const ModifierProductSalesByBehaviorSummary: PdfSectionCreateParams<any> =
  generateSalesSummaryForProperty({
    label: 'Modifier Product Sales by {{groupName}} Summary',
    noItemsMessage: 'No sales.',
    dataProperty: 'modifiersByBehaviorSummmaries',
    groupLabelProperty: 'groupName',
    groupField: '_id.fieldName',
    firstRowLabel: 'Product',
    firstRowProperty: 'name',
    showQuantity: true,
  });

export const ModifierProductSalesByEventSummary: PdfSectionCreateParams<any> =
  generateSalesSummaryForProperty({
    label: 'Modifier Product Sales for {{groupName}} Event',
    noItemsMessage: 'No sales.',
    dataProperty: 'modifiersByEventSummmaries',
    groupLabelProperty: 'groupName',
    groupField: '_id.fieldName',
    firstRowLabel: 'Product',
    firstRowProperty: 'name',
    showQuantity: true,
  });

export const ModifierProductSalesByEmployeeSummary: PdfSectionCreateParams<any> =
  generateSalesSummaryForProperty({
    label: 'Modifier Product Sales for {{groupName}}',
    noItemsMessage: 'No sales.',
    dataProperty: 'employeesModifierSummaries',
    groupLabelProperty: 'groupName',
    groupField: '_id.fieldName',
    firstRowLabel: 'Product',
    firstRowProperty: 'name',
    showQuantity: true,
  });

function generateAccountingCodeSalesSummaryForProperty(
  options: SalesSummaryOptions
): PdfSectionCreateParams<any> {
  const { label, noItemsMessage, dataProperty, groupField, groupLabelProperty, firstRowLabel } =
    options;
  return {
    type: 'table',
    theme: 'plain',
    label,
    noItemsMessage,
    dataProperty,
    ...(groupField && { groupField }),
    ...(groupLabelProperty && { groupLabelProperty }),
    columnStyles: { 0: { cellWidth: TableWidth * 0.3 } },
    sort: {
      field: 'accountingCodeName',
      reverse: false,
    },
    showTotalsRow: true,
    rows: [
      {
        type: 'text',
        label: firstRowLabel,
        dataProperty: 'accountingCodeName',
      },
      {
        type: 'text',
        label: 'Code',
        dataProperty: 'accountingCodeCode',
      },
      {
        type: 'text',
        label: '# Orders',
        dataProperty: 'orderCount',
      },
      {
        type: 'text',
        label: 'Quantity',
        dataProperty: 'quantity',
      },
      {
        type: 'text',
        label: 'Sales',
        dataProperty: 'accountingCodeTotal',
        format: 'currency',
        halign: 'right',
        showTotal: true,
      },
      {
        type: 'text',
        label: 'Discounts',
        dataProperty: 'accountingCodeDiscountTotal',
        format: 'currency',
        halign: 'right',
        showTotal: true,
      },
    ],
  };
}

export const AccountCodeSalesSummary: PdfSectionCreateParams<any> =
  generateAccountingCodeSalesSummaryForProperty({
    label: 'Account Code Sales Summary',
    noItemsMessage: 'No sales.',
    dataProperty: 'accountCodeSummaries',
    firstRowLabel: 'Account Code',
  });

export const AccountCodeSalesByLocationSummary: PdfSectionCreateParams<any> =
  generateAccountingCodeSalesSummaryForProperty({
    label: 'Account Code Sales for {{locationName}} Summary',
    noItemsMessage: 'No sales.',
    dataProperty: 'accountCodeByLocationSummaries',
    groupField: '_id.groupNameId',
    groupLabelProperty: 'locationName',
    firstRowLabel: 'Account Code',
  });

export const ClassCodeSalesSummary: PdfSectionCreateParams<any> =
  generateAccountingCodeSalesSummaryForProperty({
    label: 'Class Code Sales Summary',
    noItemsMessage: 'No sales.',
    dataProperty: 'classCodeSummaries',
    firstRowLabel: 'Class Code',
  });
export const ClassCodeSalesByLocationSummary: PdfSectionCreateParams<any> =
  generateAccountingCodeSalesSummaryForProperty({
    label: 'Class Code Sales for {{locationName}} Summary',
    noItemsMessage: 'No sales.',
    dataProperty: 'classCodeByLocationSummaries',
    groupField: '_id.groupNameId',
    groupLabelProperty: 'locationName',
    firstRowLabel: 'Class Code',
  });

export const StatCodeSalesSummary: PdfSectionCreateParams<any> =
  generateAccountingCodeSalesSummaryForProperty({
    label: 'Stat Code Sales Summary',
    noItemsMessage: 'No sales.',
    dataProperty: 'statCodeSummaries',
    firstRowLabel: 'Stat Code',
  });

export const StatCodeSalesByLocationSummary: PdfSectionCreateParams<any> =
  generateAccountingCodeSalesSummaryForProperty({
    label: 'Stat Code Sales for {{locationName}} Summary',
    noItemsMessage: 'No sales.',
    dataProperty: 'statCodeByLocationSummaries',
    groupField: '_id.groupNameId',
    groupLabelProperty: 'locationName',
    firstRowLabel: 'Stat Code',
  });

export const VendorCodeSalesSummary: PdfSectionCreateParams<any> =
  generateAccountingCodeSalesSummaryForProperty({
    label: 'Vendor Code Sales Summary',
    noItemsMessage: 'No vendor code sales.',
    dataProperty: 'vendorCodeSummaries',
    firstRowLabel: 'Vendor Code',
  });

export const VendorCodeSalesByLocationSummary: PdfSectionCreateParams<any> =
  generateAccountingCodeSalesSummaryForProperty({
    label: 'Vendor Code Sales for {{locationName}} Summary',
    noItemsMessage: 'No sales.',
    dataProperty: 'vendorCodeByLocationSummaries',
    groupField: '_id.groupNameId',
    groupLabelProperty: 'locationName',
    firstRowLabel: 'Vendor Code',
  });

function generateInventorySummaryForProperty(
  options: SalesSummaryOptions
): PdfSectionCreateParams<any> {
  const { label, noItemsMessage, dataProperty, groupField, groupLabelProperty } = options;
  const rows: TableRow[] = [
    {
      type: 'text',
      label: 'Product',
      dataProperty: 'name',
    },
    {
      type: 'text',
      label: 'Open',
      halign: 'center',
      dataProperty: 'openQuantity',
    },
    {
      type: 'text',
      label: 'Sales',
      halign: 'center',
      dataProperty: 'soldQuantity',
    },
    {
      type: 'text',
      label: 'Close',
      halign: 'center',
      dataProperty: 'closeQuantity',
    },
    {
      type: 'text',
      label: 'Discrepancy',
      halign: 'center',
      dataProperty: 'discrepancy',
    },
    {
      type: 'text',
      label: 'Comment',
      dataProperty: 'comment',
    },
  ];
  return {
    type: 'table',
    theme: 'plain',
    label,
    noItemsMessage,
    dataProperty,
    groupField,
    groupLabelProperty,
    columnStyles: { 0: { cellWidth: TableWidth * 0.2 } },
    sort: {
      field: 'created',
      reverse: false,
    },
    rows,
  };
}

export const InventorySummary: PdfSectionCreateParams<any> = generateInventorySummaryForProperty({
  label: 'Inventory Summary',
  noItemsMessage: 'No tracked.',
  dataProperty: 'inventoryItemsSummary',
  firstRowLabel: 'Inventory Summary',
});

function generateInventoryLogsForProperty(
  options: SalesSummaryOptions
): PdfSectionCreateParams<any> {
  const { label, noItemsMessage, dataProperty, groupField, groupLabelProperty } = options;
  const rows: TableRow[] = [
    {
      type: 'text',
      label: 'Product',
      dataProperty: 'inventoryItem.product.name',
    },
    {
      type: 'text',
      label: 'Event',
      dataProperty: ['event', 'order.orderNumber'],
      format: 'string',
    },
    {
      type: 'text',
      label: 'Adj / New Qty',
      halign: 'center',
      dataProperty: ['adjustment', 'quantity'],
    },
    {
      type: 'text',
      label: 'Created At',
      halign: 'right',
      dataProperty: 'created',
      format: 'date',
    },
  ];
  return {
    type: 'table',
    theme: 'plain',
    label,
    noItemsMessage,
    dataProperty,
    groupField,
    groupLabelProperty,
    columnStyles: { 0: { cellWidth: TableWidth * 0.2 } },
    sort: {
      field: 'created',
      reverse: false,
    },
    rows,
  };
}

export const InventoryLogSummary: PdfSectionCreateParams<any> = generateInventoryLogsForProperty({
  label: 'Inventory Logs',
  noItemsMessage: 'No inventory logs.',
  dataProperty: 'inventoryLogs',
  firstRowLabel: 'Inventory Logs',
});

export function getReportSectionOptions(): {
  label: string;
  section: PdfSectionCreateParams<any>;
  description?: string;
}[] {
  return [
    {
      label: OrdersSummary.label,
      section: OrdersSummary,
    },
    {
      label: SalesSummary.label,
      section: SalesSummary,
    },
    {
      label: StatusSalesSummary.label,
      section: StatusSalesSummary,
    },
    {
      label: BehaviorSalesSummary.label,
      section: BehaviorSalesSummary,
    },
    {
      label: EventSalesSummary.label,
      section: EventSalesSummary,
    },
    {
      label: TransactionsSummaryForOrders.label,
      section: TransactionsSummaryForOrders,
    },
    {
      label: RefundTransactionsSummaryForOrders.label,
      section: RefundTransactionsSummaryForOrders,
    },
    {
      label: TransactionsSummaryForPeriod.label,
      section: TransactionsSummaryForPeriod,
    },
    {
      label: RefundTransactionsSummaryForPeriod.label,
      section: RefundTransactionsSummaryForPeriod,
    },
    {
      label: TaxesSummary.label,
      section: TaxesSummary,
    },
    {
      label: DiscountsSummary.label,
      section: DiscountsSummary,
    },
    {
      label: ServiceChargesSummary.label,
      section: ServiceChargesSummary,
    },
    {
      label: ServiceChargesByEmployeeSummary.label,
      section: ServiceChargesByEmployeeSummary,
    },
    {
      label: LocationSalesSummary.label,
      section: LocationSalesSummary,
    },
    {
      label: LocationCategorySalesSummary.label,
      section: LocationCategorySalesSummary,
    },
    {
      label: ShiftSalesSummary.label,
      section: ShiftSalesSummary,
    },
    {
      label: EmployeeSalesSummary.label,
      section: EmployeeSalesSummary,
    },
    {
      label: TransactionsSummaryByShifts.label,
      section: TransactionsSummaryByShifts,
    },
    {
      label: RefundTransactionsSummaryByShifts.label,
      section: RefundTransactionsSummaryByShifts,
    },
    {
      label: ProductCategorySalesSummary.label,
      section: ProductCategorySalesSummary,
    },
    {
      label: ModifierProductCategorySalesSummary.label,
      section: ModifierProductCategorySalesSummary,
    },
    {
      label: ProductSalesSummary.label,
      section: ProductSalesSummary,
    },
    {
      label: ModifierProductSalesSummary.label,
      section: ModifierProductSalesSummary,
    },
    {
      label: ProductSalesByBehaviorSummary.label,
      section: ProductSalesByBehaviorSummary,
    },
    {
      label: ModifierProductSalesByBehaviorSummary.label,
      section: ModifierProductSalesByBehaviorSummary,
    },
    {
      label: ProductSalesByEventSummary.label,
      section: ProductSalesByEventSummary,
    },
    {
      label: ModifierProductSalesByEventSummary.label,
      section: ModifierProductSalesByEventSummary,
    },
    {
      label: ProductSalesByEmployeeSummary.label,
      section: ProductSalesByEmployeeSummary,
    },
    {
      label: ModifierProductSalesByEmployeeSummary.label,
      section: ModifierProductSalesByEventSummary,
    },
    {
      label: AccountCodeSalesSummary.label,
      section: AccountCodeSalesSummary,
    },
    {
      label: AccountCodeSalesByLocationSummary.label,
      section: AccountCodeSalesByLocationSummary,
    },
    {
      label: ClassCodeSalesSummary.label,
      section: ClassCodeSalesSummary,
    },
    {
      label: ClassCodeSalesByLocationSummary.label,
      section: ClassCodeSalesByLocationSummary,
    },
    {
      label: StatCodeSalesSummary.label,
      section: StatCodeSalesSummary,
    },
    {
      label: StatCodeSalesByLocationSummary.label,
      section: StatCodeSalesByLocationSummary,
    },
    {
      label: VendorCodeSalesSummary.label,
      section: VendorCodeSalesSummary,
    },
    {
      label: VendorCodeSalesByLocationSummary.label,
      section: VendorCodeSalesByLocationSummary,
    },
  ];
}

const sectionsByLabel = _.keyBy(getReportSectionOptions(), 'label');

export function getSectionFromName(label: string): PdfSectionCreateParams<any> {
  return sectionsByLabel[label].section;
}
