import { ApiResources, ApiVersion } from '../../enums';
import { ISchema, Type, Widget } from '../schema';

export const CopyMenuToLocationSchema: ISchema = {
  fromLocationId: {
    section: 'Location Selection',
    type: Type.STRING,
    label: 'From Location',
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        version: ApiVersion.V2,
        resource: ApiResources.LOCATION,
        modelProp: 'name',
        search: { isDeleted: false, limit: 100 },
        displayFields: ['name'],
      },
    },
    required: true,
  },
  toLocationIds: {
    section: 'Location Selection',
    type: Type.STRING,
    label: 'To Locations',
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        version: ApiVersion.V2,
        resource: ApiResources.LOCATION,
        modelProp: 'name',
        search: { isDeleted: false, limit: 100 },
        displayFields: ['name'],
      },
    },
    multiple: true,
    required: true,
  },
};
