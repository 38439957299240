import {
  ApiResources,
  ApiVersion,
  ExportType,
  OrderStatus,
  ReportType,
  UserRoles,
} from '@app/enums';
import { ISchema, Type, Widget } from '../schema';
import { Report } from './report';
import { BaseReportProps } from './base-report-props';

export const OrderedItemReportSchema: ISchema = {
  status: BaseReportProps.status,
  restaurant: BaseReportProps.restaurant,
  isFutureOrder: BaseReportProps.isFutureOrder,
  consumerOnly: BaseReportProps.consumerOnly,
  from: BaseReportProps.from,
  to: BaseReportProps.to,
  email: BaseReportProps.email,
  employeeIds: {
    section: 'basic',
    description: 'Employees to filter by',
    type: Type.ARRAY,
    label: 'Employees',
    widget: {
      type: Widget.SELECT,
      enum: [],
      multiple: true,
      options: {
        resource: ApiResources.EMPLOYEE,
        version: ApiVersion.V2,
        search: { limit: 1000, sort: 'name' },
        modelProp: 'name',
        dataProp: 'data.data',
        displayFields: ['name', 'employeeId'],
      },
    },
  },
};

export interface IOrderedItemReportData {
  status: OrderStatus;
  restaurant: string;
  isFutureOrder: boolean;
  from: string;
  to: string;
}

export class OrderedItemReport extends Report<IOrderedItemReportData> {
  ignoreProperties = [];
  icon = 'fas fa-tags';
  title = 'Product Sales Report (CSV/Excel)';
  description = 'Export sales for all products for orders placed during a particular period.';
  isImmediate = false;
  data: IOrderedItemReportData = {} as IOrderedItemReportData;
  schema: ISchema = OrderedItemReportSchema;
  roles: UserRoles[] = [UserRoles.ADMIN, UserRoles.CUSTOMER];
  type: ReportType = ReportType.ORDERED_ITEMS;
  exportOptions: ExportType[] = [ExportType.EXCEL, ExportType.CSV];
}
