import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ChargeService {
  private domain: string;
  private path = 'stripe/order-charge';
  private baseUrl: string;

  constructor(private _http: HttpClient) {
    this.domain = environment.url;
    this.baseUrl = `${this.domain}/${this.path}`;
  }

  public create(charge: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}`, charge);
  }

  public update(id: string, update: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}`, update);
  }

  public completePayment(id: string, update: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}/complete-payment`, update);
  }

  public completeCashPayment(id: string, update: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}/complete-cash-payment`, update);
  }

  public findOne(id: string): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/${id}`);
  }

  public findForOrder(orderId: string): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/order/${orderId}`);
  }

  public refund(id: string, details: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}/refund`, details);
  }

  public refundCash(id: string, details: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}/refund-cash`, details);
  }

  public delete(id: string): Observable<any> {
    return this._http.delete<any>(`${this.baseUrl}/${id}`);
  }
}
