import { OrgFeature, Right } from '../enums';
import { Org, SelectEnum } from '../models';
import { getEnumArray } from './enum.helper';

const rightsForAllFeatures: Right[] = [
  Right.ORG_ADMIN,
  Right.ORG_OWNER,
  Right.ORG_REPORTING,
  Right.ORG_MARKETING,
];

export function getRightsForOrgFeature(feature: OrgFeature): Right[] {
  switch (feature) {
    case OrgFeature.MARKETING:
      return [Right.ORG_MANAGE_GUESTS].concat(rightsForAllFeatures);
    case OrgFeature.EVENTS:
      return [Right.ORG_MANAGE_EVENTS].concat(rightsForAllFeatures);
    case OrgFeature.POS:
    case OrgFeature.ONLINE_ORDERING:
      return [
        Right.ORG_POS_USER,
        Right.ORG_MANAGE_GUESTS,
        Right.ORG_MANAGE_ALERTS,
        Right.ORG_MANAGE_USERS,
        Right.ORG_MANAGE_TAXES,
        Right.ORG_MANAGE_TENDERS,
        Right.ORG_MANAGE_EMPLOYEES,
        Right.ORG_MANAGE_POS_SETTINGS,
        Right.ORG_MANAGE_LOCATIONS,
        Right.ORG_MANAGE_MENUS,
        Right.ORG_MANAGE_MODIFIERS,
        Right.ORG_MANAGE_DISCOUNTS,
        Right.ORG_MANAGE_GRATUITIES,
        Right.ORG_MANAGE_DINING_OPTIONS,
        Right.ORG_MANAGE_SERVICE_CHARGES,
        Right.ORG_MANAGE_PRODUCTS,
        Right.ORG_MANAGE_INVENTORY,
        Right.ORG_MANAGE_INVENTORY_SETTINGS,
        Right.ORG_MANAGE_INTEGRATIONS,
        Right.ORG_MANAGE_VOUCHERS,
        Right.ORG_REFUNDING,
        Right.ORG_CUSTOMER_SUPPORT,
      ].concat(rightsForAllFeatures);
    case OrgFeature.TEE_SHEET:
      return [Right.ORG_MANAGE_TEE_SHEET, Right.ORG_MANAGE_TEE_SHEET_SETTINGS].concat(
        rightsForAllFeatures
      );
    case OrgFeature.GAMES:
      return [Right.ORG_MANAGE_GAMES].concat(rightsForAllFeatures);
    case OrgFeature.FANTASY_SPORTS:
      return [Right.ORG_MANAGE_GAMES].concat(rightsForAllFeatures);
    default:
      return rightsForAllFeatures;
  }
}

export function getKeyForRight(right: Right) {
  return Object.keys(Right).find(rightKey => {
    if (Right[rightKey] === right) {
      return true;
    }
    return false;
  });
}

export function getUserRightsSchemaEnumForOrg(org: Org): SelectEnum[] {
  const orgRightsDict = org.data.enabledFeatures.reduce(
    (rights: Record<Right, boolean>, feature: OrgFeature) => {
      const rightsForFeature = getRightsForOrgFeature(feature);
      rightsForFeature.forEach(right => {
        const key = getKeyForRight(right);
        rights[key] = right;
      });
      return rights;
    },
    {} as Record<Right, boolean>
  );
  return getEnumArray(orgRightsDict);
}
