export type CanShow = () => boolean;

export type FormatterFunc = (value: any, model?: any) => any;

export type LinkFunc = (model: any) => any;

export type Func = (...args: any[]) => any;
export interface ListTableHeaderOptions {
  collection: {
    label: string;
    plural: string;
  };
  actions: {
    delete: boolean;
    duplicate: boolean;
    custom?: Array<{
      label: string;
      action: Func;
      show?: CanShow | boolean;
      skipCollectionLabel?: boolean;
    }>;
  };
}

export interface ListTableHeaderCell {
  label: string;
  sortable?: boolean;
  property?: string | Array<string>;
  width?: string;
  formatter?: FormatterFunc | Array<FormatterFunc>;
  link?: LinkFunc;
  isImage?: boolean;
}

export interface ListTableRow {
  id: string;
  selected: boolean;
  cols: Array<ListTableRowCell>;
}

export interface ListTableRowCell {
  value: string;
  width?: string;
  link?: LinkFunc;
}

export interface ListTableCell {
  property: string;
}
