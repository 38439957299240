import _ from 'lodash';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiClient } from '@app/clients';
import { ApiDomain, ApiVersion, ApiResources } from '@app/enums';

@Component({
  selector: 'app-tags-selection',
  templateUrl: './tags-selection.component.html',
  styleUrls: ['./tags-selection.component.scss'],
})
export class TagsSelectionComponent implements OnInit, OnChanges {
  @Input() selected: Array<any> = [];
  @Output() selectedChange = new EventEmitter<Array<any>>();

  private apiClient: ApiClient;

  public tags: Array<any> = [];

  constructor(http: HttpClient) {
    this.apiClient = new ApiClient(http)
      .setDomain(ApiDomain.API, ApiVersion.V2, ApiResources.TAGS)
      .setCacheTtl(60);
  }

  ngOnInit() {
    this.fetch();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selected?.currentValue) {
      this.setupTags();
    }
  }

  private async fetch() {
    try {
      const response = await this.apiClient
        .get<{ data: Array<any>; count: number }>({ limit: 100 })
        .toPromise();
      this.tags = response.data;
      this.setupTags();
    } catch (e) {
      console.log(e);
    }
  }

  private setupTags() {
    this.tags.forEach((tag: any) => {
      const selected = _.find(this.selected, (selectedTag: any) => selectedTag._id === tag._id);
      if (selected) {
        tag.selected = true;
      }
    });
  }

  public change() {
    const selected = this.tags.filter((tag: any) => tag.selected);
    this.selectedChange.emit(selected);
  }
}
