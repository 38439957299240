export interface SearchResult<T> {
  data: {
    data: T[];
    total: number;
  };
  success: boolean;
}

export type LegacySearchResult<T = any> = {
  total: number;
} & Record<string, T>;

export type RequestResult<T> =
  | {
      data: T;
      success: true;
      message?: string;
    }
  | { success: false; message: string };
