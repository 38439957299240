import { ISchema, Type, Widget } from '../schema';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ApiResources, ApiVersion } from '../../enums';
import { OrgData } from '../core';
import { ClassificationData } from '../events';
import { FantasyHelper } from '../../fantasy/helpers';

export const GameQuestionSchema: ISchema = {
  question: {
    section: 'Basic Info',
    type: Type.LONG_STRING,
    label: 'Name',
  },
  answers: {
    section: 'Basic Info',
    type: Type.ARRAY,
    label: 'Question Answers',
    items: {
      label: 'Answers',
      type: Type.OBJECT,
      properties: {
        id: {
          label: 'ID',
          type: Type.STRING,
          default: () => FantasyHelper.randomID(),
          isHidden: true,
        },
        answer: {
          label: 'Answer',
          type: Type.STRING,
          required: true,
        },
        isCorrectAnswer: {
          label: 'Is Correct Answer',
          type: Type.BOOLEAN,
        },
      },
    },
  },
  classifications: {
    section: 'Basic Info',
    type: Type.ARRAY,
    label: 'Classifications',
    widget: {
      type: Widget.CLASSIFICATIONS,
    },
  },
  orgId: {
    section: 'Basic Info',
    type: Type.TYPEAHEAD,
    label: 'Org',
    partakeOnly: true,
    immutable: true,
    resource: ApiResources.ORGS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
  },
};

export interface GameQuestionAnswers {
  id: string;
  answer: string;
  isCorrectAnswer: boolean;
}
export interface GameQuestionData extends AbstractModelData {
  question: string;
  answers: GameQuestionAnswers[];
  orgId: string | OrgData['_id'];
  classifications: string[] | ClassificationData['_id'];
  isDeleted: boolean;
}

export class GameQuestion extends AbstractModel<GameQuestionData> {
  constructor(public data: GameQuestionData) {
    super(data);
  }
}
