import { ApiDomain, ApiResources, ApiVersion, PersonType, PlayingStatus } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

export const PersonSchema: ISchema = {
  firstName: {
    label: 'First Name',
    type: Type.STRING,
    required: true,
  },
  middleName: {
    label: 'Middle Name',
    type: Type.STRING,
    isHidden: true,
  },
  lastName: {
    label: 'Last Name',
    type: Type.STRING,
    required: true,
  },
  matchName: {
    label: 'Match Name',
    type: Type.STRING,
    required: true,
  },
  status: {
    label: 'Playing Status',
    type: Type.STRING,
    enum: EnumHelper.getEnumArray(PlayingStatus),
    widget: {
      type: Widget.SELECT,
    },
  },
  type: {
    label: 'Type',
    type: Type.STRING,
    enum: EnumHelper.getEnumArray(PersonType),
    widget: {
      type: Widget.SELECT,
    },
  },
  dateOfBirth: {
    label: 'Date of Birth',
    type: Type.DATE,
    isHidden: true,
  },
  placeOfBirth: {
    label: 'Place of Birth',
    type: Type.STRING,
    isHidden: true,
  },
  countryOfBirthId: {
    label: 'Country of Birth',
    type: Type.STRING,
    isHidden: true,
  },
  height: {
    label: 'Height',
    type: Type.NUMBER,
    isHidden: true,
  },
  weight: {
    label: 'Weight',
    type: Type.NUMBER,
    isHidden: true,
  },
  shirtNumber: {
    label: 'Shirt Number',
    type: Type.NUMBER,
  },
  position: {
    label: 'Position',
    type: Type.STRING,
  },
  contestantId: {
    type: Type.TYPEAHEAD,
    label: 'Team',
    domain: ApiDomain.API,
    version: ApiVersion.V2,
    resource: ApiResources.CONTESTANT,
    modelProp: 'name',
    displayFields: ['name'],
    dataProp: 'data.data',
  },
};

export interface PersonData extends AbstractModelData {
  firstName: string;
  middleName?: string;
  lastName: string;
  matchName: string;
  status: PlayingStatus;
  type: PersonType;
  dateOfBirth?: string;
  placeOfBirth?: string;
  countryOfBirthId?: string;
  height?: number;
  weight?: number;
  shirtNumber?: number;
  position?: string;
  contestantId: string;
}

export class Person extends AbstractModel<PersonData> {
  constructor(public data: PersonData) {
    super(data);
  }
}
