import { SummaryDataDuration, SummaryDataFieldType, SummaryDataType } from '../../enums';
import { StringHelper, TimeHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type } from '../schema';
import { OrgData } from './org';

export type SummaryInfoData = {
  fieldKey: string;
  fieldName: string;
  fieldType: SummaryDataFieldType;
} & (
  | {
      fieldType: SummaryDataFieldType.STRING;
      stringValue: string;
    }
  | {
      fieldType: SummaryDataFieldType.NUMBER | SummaryDataFieldType.CURRENCY;
      numericValue: number;
    }
);
export interface SummaryDataJobPeriod {
  duration: SummaryDataDuration;
  periodStart: Date;
  periodEnd: Date;
}

export interface SummaryDataData extends AbstractModelData {
  type: SummaryDataType;
  summaryInfo: SummaryInfoData[];
  duration: SummaryDataDuration;
  periodStartDateString: string; // 2022-01-21
  periodEndDateString: string; // 2022-01-21
  periodStartTimeString: string; // 11:00 am
  periodEndTimeString: string; // 12:00 pm
  periodStartDate: Date; // 2022-01-21T00:00:00.000-05:00
  periodEndDate: Date; // 2022-01-21T00:00:00.000-05:00
  orgId: OrgData | string;
}

export const SummaryDataUpdateSchema: ISchema = {
  startDate: {
    section: 'basic',
    description: 'The starting period',
    type: Type.DATE,
    label: 'Start Date',
    required: true,
  },
  endDate: {
    section: 'basic',
    description: 'The end of a the period. Max 31 days from start date.',
    type: Type.DATE,
    label: 'End Date',
    required: true,
  },
};

export class SummaryData extends AbstractModel<SummaryDataData> {
  constructor(public data: SummaryDataData) {
    super(data);
  }

  static getFieldValue(summaryInfoData: SummaryInfoData): string {
    switch (summaryInfoData.fieldType) {
      case SummaryDataFieldType.STRING:
        return summaryInfoData.stringValue;
      case SummaryDataFieldType.NUMBER:
        return summaryInfoData.numericValue.toLocaleString('en-US', { maximumFractionDigits: 0 });
      case SummaryDataFieldType.CURRENCY:
        return StringHelper.currency(summaryInfoData.numericValue);
    }
  }

  public getTimeSeriesName() {
    switch (this.data.duration) {
      case SummaryDataDuration.HOUR: {
        const timeStart = this.data.periodStartTimeString;
        const timeEnd = this.data.periodEndTimeString;
        return `${timeStart} to ${timeEnd}`;
      }
      case SummaryDataDuration.DAY:
        return TimeHelper.getDateStringWithFormat(
          this.data.periodStartDateString,
          TimeHelper.DateFormat.DAY_AND_MONTH
        );
      case SummaryDataDuration.WEEK: {
        const dayStart = TimeHelper.getDateStringWithFormat(
          this.data.periodStartDateString,
          TimeHelper.DateFormat.DAY_AND_MONTH
        );
        const dayEnd = TimeHelper.getDateStringWithFormat(
          this.data.periodEndDateString,
          TimeHelper.DateFormat.DAY_MONTH_AND_YEAR
        );
        return `${dayStart} to ${dayEnd}`;
      }
      case SummaryDataDuration.MONTH:
        return TimeHelper.getDateStringWithFormat(
          this.data.periodStartDateString,
          TimeHelper.DateFormat.MONTH_AND_YEAR
        );
      case SummaryDataDuration.QUARTER:
        return TimeHelper.getDateStringWithFormat(
          this.data.periodStartDateString,
          TimeHelper.DateFormat.QUARTER_AND_YEAR
        );
      case SummaryDataDuration.YEAR:
        return TimeHelper.getDateStringWithFormat(
          this.data.periodStartDateString,
          TimeHelper.DateFormat.YEAR
        );
    }
  }

  public getInfoForKey(fieldKey: string): SummaryInfoData | undefined {
    return this.data.summaryInfo.find(summaryInfoData => {
      if (fieldKey.indexOf('*') > -1) {
        const [, key] = fieldKey.split('.');
        const regexp = new RegExp(`^\\w+\\.${key}$`);
        return regexp.test(summaryInfoData.fieldKey);
      }
      if (summaryInfoData.fieldKey === fieldKey) {
        return true;
      }
      return false;
    });
  }

  getSummaryInfoForFieldKey(fieldKey: string): { label: string; value: string } | undefined {
    const fieldKeyData = this.getInfoForKey(fieldKey);
    if (!fieldKeyData) {
      return;
    }
    return {
      label: fieldKeyData.fieldName,
      value: SummaryData.getFieldValue(fieldKeyData),
    };
  }
}
