export enum TableType {
  REGULAR = 'regular',
  BAR = 'bar',
  HIGH_TOP = 'high-top',
  BOOTH = 'booth',
  COUNTER = 'counter',
  COMMUNAL = 'communal',
}

export enum TableName {
  CIRCLE_1 = 'circle-1',
  CIRCLE_2 = 'circle-2',
  CIRCLE_4 = 'circle-4',
  CIRCLE_6 = 'circle-6',
  CIRCLE_8 = 'circle-8',
  CIRCLE_12 = 'circle-12',
  RECTANGLE_1 = 'rectangle-1',
  RECTANGLE_2 = 'rectangle-2',
  RECTANGLE_4 = 'rectangle-4',
  RECTANGLE_6 = 'rectangle-6',
  RECTANGLE_8 = 'rectangle-8',
  RECTANGLE_10 = 'rectangle-10',
  RECTANGLE_12 = 'rectangle-12',
  SQUARE_1 = 'square-1',
  SQUARE_2 = 'square-2',
  SQUARE_4 = 'square-4',
}

export const TableNameImageMap = {
  [TableName.CIRCLE_1]: 'assets/images/table-layout/circle-1-top.png',
  [TableName.CIRCLE_2]: 'assets/images/table-layout/circle-2-top.png',
  [TableName.CIRCLE_4]: 'assets/images/table-layout/circle-4-top.png',
  [TableName.CIRCLE_6]: 'assets/images/table-layout/circle-6-top.png',
  [TableName.CIRCLE_8]: 'assets/images/table-layout/circle-8-top.png',
  [TableName.CIRCLE_12]: 'assets/images/table-layout/circle-12-top.png',
  [TableName.RECTANGLE_1]: 'assets/images/table-layout/rec-1-top.png',
  [TableName.RECTANGLE_2]: 'assets/images/table-layout/rec-2-top.png',
  [TableName.RECTANGLE_4]: 'assets/images/table-layout/rec-4-top.png',
  [TableName.RECTANGLE_6]: 'assets/images/table-layout/rec-6-top.png',
  [TableName.RECTANGLE_8]: 'assets/images/table-layout/rec-8-top.png',
  [TableName.RECTANGLE_10]: 'assets/images/table-layout/rec-10-top.png',
  [TableName.RECTANGLE_12]: 'assets/images/table-layout/rec-12-top.png',
  [TableName.SQUARE_1]: 'assets/images/table-layout/square-1-top.png',
  [TableName.SQUARE_2]: 'assets/images/table-layout/square-2-top.png',
  [TableName.SQUARE_4]: 'assets/images/table-layout/square-4-top.png',
};
