export enum NavigationTabs {
  ACCOUNT = 'account',
  ATTRACTIONS = 'attractions',
  BETTING = 'betting',
  DISCOVER = 'discover',
  EVENTS = 'events',
  FANTASY = 'fantasy',
  FAVORITES = 'favorites',
  LIVE = 'live',
  PRIZES = 'prizes',
  MENU = 'menu',
  ORDERS = 'orders',
  SEARCH = 'search',
  SOCIAL = 'social',
  VENUES = 'venues',
}

export enum DefaultIconByTab {
  ACCOUNT = 'person-outline',
  ATTRACTIONS = 'rocket-outline',
  BETTING = 'home-outline',
  DISCOVER = 'home-outline',
  EVENTS = 'calendar-outline',
  FANTASY = 'trophy-outline',
  FAVORITES = 'heart-outline',
  LIVE = 'play-circle-outline',
  MENU = 'fast-food-outline',
  ORDERS = 'fast-food-outline',
  PROMOTIONS = 'gift-outline',
  PRIZES = 'gift-outline',
  SEARCH = 'search-outline',
  SOCIAL = 'chatbubbles-outline',
  VENUES = 'location-outline',
}

export enum DefaultTitleByTab {
  ACCOUNT = 'Account',
  ATTRACTIONS = 'Attractions',
  BETTING = 'Betting',
  DISCOVER = 'Discover',
  EVENTS = 'Events',
  FANTASY = 'Fantasy',
  FAVORITES = 'Favorites',
  LIVE = 'Live',
  MENU = 'Menu',
  ORDERS = 'Orders',
  PROMOTIONS = 'Offer',
  PRIZES = 'Prizes',
  SEARCH = 'Search',
  SOCIAL = 'Groups',
  VENUES = 'Venue',
}

export enum AccountSettingsLinks {
  PROFILE = '/app/account/profile',
  PAYMENT = '/app/account/payment',
  ORDER_HISTORY = '/app/account/orders',
  GAME_HISTORY = '/app/account/fantasy/history',
  SETTINGS = '/app/account/settings',
  ACCOUNT_BALANCES = '/app/account/balances',
}

export enum DefaultTitleForSettingsPage {
  PROFILE = 'My Profile',
  PAYMENT = 'Payments',
  ORDER_HISTORY = 'Order History',
  GAME_HISTORY = 'Game History',
  SETTINGS = 'Settings',
  ACCOUNT_BALANCES = 'Account Balances',
}

export enum DefaultIconBySettingsPage {
  PROFILE = 'person-outline',
  PAYMENT = 'card-outline',
  ORDER_HISTORY = 'time-outline',
  GAME_HISTORY = 'game-controller-outline',
  SETTINGS = 'settings-outline',
  ACCOUNT_BALANCES = 'cash-outline',
}
