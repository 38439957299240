import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ErrorService } from './errors.service';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss'],
})
export class ErrorsComponent implements OnInit, OnDestroy {
  public errors: Array<any> = [];
  private errorListener: Subscription;

  constructor(private errorService: ErrorService) {}

  ngOnInit() {
    this.errorListener = this.errorService.getErrorListener().subscribe(isUpdated => {
      this.errors = this.errorService.getErrors();
      window.scrollTo(0, 0);
    });
  }

  ngOnDestroy() {
    this.errorListener.unsubscribe();
  }

  public removeError(index: number) {
    this.errorService.removeError(index);
  }
}
