import { IFixture } from '@partake/interfaces';
import moment from 'moment';

export class FantasyHelper {
  public static randomID() {
    return Math.random().toString(36).substr(2, 4).toUpperCase();
  }

  public static getDateInfoFromFixture(fixture: IFixture) {
    return {
      date: fixture.date,
      dateString: fixture.dateString,
      time: fixture.time,
      timeString: fixture.timeString,
      timezone: fixture.timezone,
    };
  }

  public static getDateInfoFromDate(date: Date) {
    const d = moment(date);
    const startOfDay = moment(date).startOf('day');
    return {
      date: d.toDate(),
      dateString: d.format('YYYY-MM-DD'),
      time: d.diff(startOfDay),
      timeString: d.format('HH:mm:ss'),
      timezone: d.format('Z'),
    };
  }
}
