import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type } from '../schema';

export const MenuItemStationSchema: ISchema = {
  name: {
    type: Type.STRING,
    label: 'Station Name',
    required: true,
  },
  isActive: {
    type: Type.BOOLEAN,
    label: 'Is Active',
    required: true,
    default: true,
  },
};

export interface MenuItemStationData extends AbstractModelData {
  name: string;
  isActive: boolean;
}

export class MenuItemStation extends AbstractModel<MenuItemStationData> {
  constructor(public data: MenuItemStationData) {
    super(data);
  }
}
