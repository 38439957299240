import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ApiQuery } from '@app/clients';
import _ from 'lodash';
import { ApiResources } from '../../../enums';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-guest-search-typeahead',
  templateUrl: './guest-search-typeahead.component.html',
  styleUrls: ['./guest-search-typeahead.component.scss'],
})
export class GuestSearchTypeaheadComponent extends BaseComponent implements OnChanges {
  @Input() disabled = false;
  @Input() guest: any;
  @Input() placeholder = 'Search';
  @Input() property = 'name';
  @Input() size = 'form-control';

  @Output() selected = new EventEmitter<any>();
  @Output() guestChange = new EventEmitter<any>();

  public search: string;

  constructor() {
    super();
    this.apiClient.setResource(ApiResources.GUESTS);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.guest?.currentValue) {
      this.search = changes.guest.currentValue[this.property];
    }
  }

  public selectGuest(selected: any) {
    selected.preventDefault();
    this.guest = selected.item;
    this.search = this.guest[this.property];
    this.selected.emit(this.guest);
    this.guestChange.emit(this.guest);
  }

  searchGuest = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        this.guest[this.property] = value;
        const query: ApiQuery = {};
        query[this.property] = value;
        return this.apiClient.get(query).pipe(
          map((x: any) => _.get(x, 'data.data') || []),
          catchError(() => of([]))
        );
      })
    );

  guestFormatter = (value: any) => {
    if (value[this.property]) {
      return value[this.property];
    }
    return '';
  };

  blur() {
    this.guestChange.emit({ ...this.guest, [this.property]: this.search });
  }
}
