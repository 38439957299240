import { ApiResources, ApiVersion, ExportType, ReportType, UserRoles } from '@app/enums';
import { PdfHelper } from '../../helpers';
import { PdfContent, PdfSectionCreateParams } from '../../helpers/pdf.helper';
import { ShiftData } from '../operations';
import { ISchema, Type } from '../schema';
import { Report, ShiftReportParams } from './report';
import { SalesReportResponseData } from './report-export-summaries';
import {
  BehaviorSalesSummary,
  DiscountsSummary,
  EmployeeSalesSummary,
  EventSalesSummary,
  LocationCategorySalesSummary,
  LocationSalesSummary,
  getShiftReportInfo,
  ModifierProductCategorySalesSummary,
  ModifierProductSalesByBehaviorSummary,
  ModifierProductSalesByEventSummary,
  ModifierProductSalesSummary,
  OrdersSummary,
  PrintedAt,
  ProductCategorySalesSummary,
  ProductSalesByBehaviorSummary,
  ProductSalesByEventSummary,
  ProductSalesSummary,
  RefundTransactionsSummaryForOrders,
  SalesSummary,
  ServiceChargesSummary,
  ShiftSalesSummary,
  StatusSalesSummary,
  TaxesSummary,
  TransactionDisclaimer,
  TransactionForPeriodDisclaimer,
  TransactionsSummaryForPeriod,
  InventoryLogSummary,
  InventorySummary,
  RefundTransactionsSummaryByShifts,
  TransactionsSummaryByShifts,
  VoidsSummary,
} from './report-sections';

export const ShiftSalesReportSchema: ISchema = {
  shiftIds: {
    type: Type.ARRAY,
    label: 'Shifts',
    items: {
      type: Type.TYPEAHEAD,
      label: 'Select Shift(s) for the Report',
      resource: ApiResources.SHIFT,
      version: ApiVersion.V2,
      dataProp: 'data.data',
      modelProp: 'shiftId',
      displayFields: ['shiftId', 'openingBy.name'],
    },
  },
};

export interface ShiftSalesReportData {
  shiftIds: ShiftData[];
}

export class ShiftSalesReport extends Report<ShiftSalesReportData> {
  ignoreProperties = [];
  icon = 'fas fa-user-clock';
  title = 'Shift Sales Report';
  description =
    'Export all sales information for particular Shifts(s). This includes Sales Summary, Sales for Category and Product information for Menu Items and Modifiers.';
  data: ShiftSalesReportData = {} as ShiftSalesReportData;
  isImmediate = true;
  schema: ISchema = ShiftSalesReportSchema;
  roles: UserRoles[] = [UserRoles.ADMIN, UserRoles.CUSTOMER];
  type: ReportType = ReportType.SALES;
  exportOptions: ExportType[] = [ExportType.PDF];

  pdfSectionCreateParams: PdfSectionCreateParams<SalesReportResponseData>[] = [
    OrdersSummary,
    SalesSummary,
    VoidsSummary,
    StatusSalesSummary,
    BehaviorSalesSummary,
    EventSalesSummary,
    TransactionsSummaryByShifts,
    RefundTransactionsSummaryForOrders,
    TransactionDisclaimer,
    TransactionsSummaryForPeriod,
    TransactionForPeriodDisclaimer,
    RefundTransactionsSummaryByShifts,
    TransactionForPeriodDisclaimer,
    TransactionDisclaimer,
    TaxesSummary,
    DiscountsSummary,
    ServiceChargesSummary,
    LocationSalesSummary,
    LocationCategorySalesSummary,
    ShiftSalesSummary,
    EmployeeSalesSummary,
    ProductCategorySalesSummary,
    ModifierProductCategorySalesSummary,
    ProductSalesSummary,
    ModifierProductSalesSummary,
    ProductSalesByBehaviorSummary,
    ModifierProductSalesByBehaviorSummary,
    ProductSalesByEventSummary,
    ModifierProductSalesByEventSummary,
    InventorySummary,
    InventoryLogSummary,
  ];

  public generateExport(params: ShiftReportParams, responseData: SalesReportResponseData): void {
    const pdfContent: PdfContent = PdfHelper.generateContent<SalesReportResponseData>(
      'Event Sales Report',
      [
        PrintedAt,
        // @ts-ignore
        ...getShiftReportInfo(params),
      ].concat(this.pdfSectionCreateParams),
      responseData
    );
    const ids = params.shiftIds.map(shift => shift.shiftId).join('_');
    PdfHelper.generatePdf(pdfContent, `shift-sales-report-${ids}`);
  }
}
