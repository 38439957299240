import { ApiDomain, ApiResources, ApiVersion } from '../../enums';
import { ISchema, Type, Widget } from '../schema';

export const AddItemsToMenuSections: ISchema = {
  menuSectionIds: {
    section: 'Menu Sections',
    type: Type.STRING,
    label: 'Menu Sections',
    widget: {
      type: Widget.SELECT,
      multiple: true,
      options: {
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.MENU_SECTION,
        modelProp: 'name',
        dataProp: 'data.data',
        search: { sort: 'name', limit: 100 },
        displayFields: ['name'],
      },
    },
    required: true,
  },
};
