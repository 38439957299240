import { IProperties, ISchema, Type } from '../schema';

export const IAccountMenuItem: ISchema = {
  label: {
    type: Type.STRING,
    label: 'Menu Item Title',
    description: 'Menu Item - label that will be displayed to the user.',
    required: true,
  },
  iconName: {
    type: Type.STRING,
    label: 'Icon Name',
    description: 'View https://ionicons.com for options',
    required: true,
  },
  url: {
    type: Type.STRING,
    label: 'App Url',
    description: 'The view which you want the tab to go to.',
    required: true,
  },
};

export const IAccountMenu: IProperties = {
  type: Type.ARRAY,
  label: 'Account Menu',
  items: {
    type: Type.OBJECT,
    label: null,
    properties: IAccountMenuItem,
  },
};
