import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgSelectModule } from '@ng-select/ng-select';

import { ImageCropperModule } from 'ngx-image-cropper';
import { FormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PipesModule } from '@app/pipes/pipes.module';
import { QuillModule } from 'ngx-quill';
import { DirectivesModule } from './directives/directives.module';

import { NavigationComponent } from './header-navigation/navigation.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ErrorsComponent } from './errors/errors.component';
import { SpinnerComponent } from './spinner.component';
import { ImageCropperModalComponent } from './image-cropper-modal/image-cropper-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { PriceInputComponent } from './components/price-input/price-input.component';
import { ProductSearchTypeaheadComponent } from './components/product-search-typeahead/product-search-typeahead.component';
import { ConcessionCategorySearchTypeaheadComponent } from './components/concession-category-search-typeahead/concession-category-search-typeahead.component';
import { ModifierSearchTypeaheadComponent } from './components/modifier-search-typeahead/modifier-search-typeahead.component';
import { ModifierOptionSearchTypeaheadComponent } from './components/modifier-option-search-typeahead/modifier-option-search-typeahead.component'; // eslint-disable-line max-len
import { DiscountSearchTypeaheadComponent } from './components/discount-search-typeahead/discount-search-typeahead.component';
import { CancelButtonComponent } from './components/cancel-button/cancel-button.component';
import { UserSearchTypeaheadComponent } from './components/user-search-typeahead/user-search-typeahead.component';
import { MenuItemSearchTypeaheadComponent } from './components/menu-item-search-typeahead/menu-item-search-typeahead.component';
import { CreditKeyInComponent } from './payment/stripe/credit-key-in/credit-key-in.component';
import { CreditSwipeComponent } from './payment/stripe/credit-swipe/credit-swipe.component';
import { ChartComponent } from './components/chart/chart.component';
import { ImageFilepickerComponent } from './components/image-filepicker/image-filepicker.component';
import { DateTimeInputComponent } from './components/date-time-input/date-time-input.component';
import { StripePaymentModalComponent } from './payment/stripe/payment-modal/stripe-payment-modal.component';
import { CardOnFileComponent } from './payment/stripe/card-on-file/card-on-file.component';
import { GuestSearchTypeaheadComponent } from './components/guest-search-typeahead/guest-search-typeahead.component';
import { ClassificationSelectionComponent } from './components/classification-selection/classification-selection.component';
import { TagsSelectionComponent } from './components/tags-selection/tags-selection.component';
import { CashComponent } from './payment/stripe/cash/cash.component';
import {
  PaymentItemsComponent,
  PaymentMenuItemsComponent,
  PaymentTotalComponent,
  PaymentUserInfoComponent,
  PaymentGratuityComponent,
} from './payment/components';
import { HerokuAppListComponent } from './components/heroku-app-list/heroku-app-list.component';
import { HerokuAppDomainsComponent } from './components/heroku-app-domains/heroku-app-domains.component';
import { LocationSearchTypeaheadComponent } from './components/location-search-typeahead/location-search-typeahead.component';
import { StatsResourceTypeaheadComponent } from './components/stats-resource-typeahead/stats-resource-typeahead.component';
import { EmailTemplateComponent } from './components/email-template/email-template.component';
import { ListFilterComponent } from './components/list-filter/list-filter.component';
import { DomainSelectComponent } from './components/domain-select/domain-select.component';
import { MetaDataComponent } from './components/meta-data/meta-data.component';
import { TimezoneSelectComponent } from './components/timezone-select/timezone-select.component';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { ResourceTypeaheadComponent } from './components/resource-typeahead/resource-typeahead.component';
import { SecondsInputComponent } from './components/seconds-input/seconds-input.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ViewJsonModalComponent } from './view-json-modal/view-json-modal.component';
import { ViewJsonButtonComponent } from './components/view-json-button/view-json-button.component';
import { ItemImageComponent } from './components/item-image/item-image.component';
import { ListTableHeaderComponent } from './components/list-table-header/list-table-header.component';
import { ReadOnlyViewComponent } from './components/read-only-view/read-only-view.component';
import { FilePickerComponent } from './components/file-picker/file-picker.component';
import { StripeBankAccountComponent } from './payment/stripe/bank-account/stripe-bank-account.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { OptionPickerModalComponent } from './option-picker-modal/option-picker-modal.component';
import { TableViewComponent } from './components/table-view/table-view.component';
import { JsonViewerComponent } from './view-json-modal/json-viewer/json-viewer.component';

@NgModule({
  declarations: [
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    ErrorsComponent,
    SpinnerComponent,
    ImageCropperModalComponent,
    ConfirmModalComponent,
    OptionPickerModalComponent,
    PriceInputComponent,
    ProductSearchTypeaheadComponent,
    ConcessionCategorySearchTypeaheadComponent,
    ModifierSearchTypeaheadComponent,
    ModifierOptionSearchTypeaheadComponent,
    DiscountSearchTypeaheadComponent,
    UserSearchTypeaheadComponent,
    CancelButtonComponent,
    MenuItemSearchTypeaheadComponent,
    CreditKeyInComponent,
    CreditSwipeComponent,
    ChartComponent,
    ImageFilepickerComponent,
    DateTimeInputComponent,
    StripePaymentModalComponent,
    StripeBankAccountComponent,
    PaymentItemsComponent,
    PaymentMenuItemsComponent,
    PaymentTotalComponent,
    PaymentUserInfoComponent,
    CashComponent,
    CardOnFileComponent,
    GuestSearchTypeaheadComponent,
    ClassificationSelectionComponent,
    TagsSelectionComponent,
    PaymentGratuityComponent,
    HerokuAppListComponent,
    HerokuAppDomainsComponent,
    LocationSearchTypeaheadComponent,
    StatsResourceTypeaheadComponent,
    EmailTemplateComponent,
    ListFilterComponent,
    DomainSelectComponent,
    MetaDataComponent,
    TimezoneSelectComponent,
    AddressInputComponent,
    ResourceTypeaheadComponent,
    SecondsInputComponent,
    ImageUploaderComponent,
    ViewJsonModalComponent,
    ViewJsonButtonComponent,
    JsonViewerComponent,
    ItemImageComponent,
    ListTableHeaderComponent,
    ReadOnlyViewComponent,
    FilePickerComponent,
    LoadingButtonComponent,
    TableViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    PipesModule,
    ColorPickerModule,
    ImageCropperModule,
    UiSwitchModule,
    NgSelectModule,
    DirectivesModule,
    PipesModule,
    QuillModule.forRoot(),
  ],
  exports: [
    ColorPickerModule,
    UiSwitchModule,
    DirectivesModule,
    PipesModule,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    ErrorsComponent,
    SpinnerComponent,
    ImageCropperModalComponent,
    PriceInputComponent,
    ProductSearchTypeaheadComponent,
    ConcessionCategorySearchTypeaheadComponent,
    ModifierSearchTypeaheadComponent,
    ModifierOptionSearchTypeaheadComponent,
    DiscountSearchTypeaheadComponent,
    UserSearchTypeaheadComponent,
    MenuItemSearchTypeaheadComponent,
    CancelButtonComponent,
    CreditKeyInComponent,
    CreditSwipeComponent,
    ChartComponent,
    ImageFilepickerComponent,
    DateTimeInputComponent,
    GuestSearchTypeaheadComponent,
    ClassificationSelectionComponent,
    TagsSelectionComponent,
    HerokuAppListComponent,
    HerokuAppDomainsComponent,
    LocationSearchTypeaheadComponent,
    StatsResourceTypeaheadComponent,
    EmailTemplateComponent,
    ListFilterComponent,
    DomainSelectComponent,
    MetaDataComponent,
    TimezoneSelectComponent,
    AddressInputComponent,
    ResourceTypeaheadComponent,
    SecondsInputComponent,
    ImageUploaderComponent,
    ViewJsonButtonComponent,
    ItemImageComponent,
    ListTableHeaderComponent,
    ReadOnlyViewComponent,
    FilePickerComponent,
    StripeBankAccountComponent,
    LoadingButtonComponent,
    TableViewComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
