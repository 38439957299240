import { IGameRules } from '@partake/interfaces';
import { ISchema, Type, Widget } from '../schema';
import { EnumHelper } from '../../helpers';
import { AbstractModel } from '../abstract.model';
import { ApiResources, ApiVersion, GameType } from '../../enums';

export const GameRulesSchema: ISchema = {
  name: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Name',
  },
  gameType: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Game Type',
    enum: EnumHelper.getEnumArray(GameType),
    widget: {
      type: Widget.SELECT,
    },
  },
  orgId: {
    section: 'Basic Info',
    type: Type.TYPEAHEAD,
    label: 'Org',
    partakeOnly: true,
    immutable: true,
    resource: ApiResources.ORGS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
  },
  content: {
    section: 'Game Rules',
    type: Type.WYSWYG,
    label: 'Game Rules',
    description: 'Provide a full description of the game rules.',
  },
};

export class GameRules extends AbstractModel<IGameRules> {
  constructor(public data: IGameRules) {
    super(data);
  }
}
