import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ApiQuery, ApiQueryParams } from '@app/clients';

@Injectable({
  providedIn: 'root',
})
export class MenuItemModifierService {
  // OVERIDE if using a new version for this feature
  private path = 'menu-item-modifier';
  private domain = environment.url_v2;
  private baseUrl = `${this.domain}/${this.path}`;

  constructor(private _http: HttpClient) {}

  /**
   * @description Get all menu items for a particular restaurant/concession
   * @param query properties to search by
   * @example
   * {
   *    page: 1, // required
   *    limit: 20, // required
   *    sort: 'name', // required
   *    reverse: false, // required
   * }
   */
  self(query?: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this._http.get<any>(`${this.baseUrl}/self`, { params });
  }

  find(query?: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this._http.get<any>(`${this.baseUrl}`, { params });
  }

  findOne(id: string): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/${id}`);
  }

  create(body: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}`, body);
  }

  update(id: string, body: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}`, body);
  }

  delete(id: string): Observable<any> {
    return this._http.delete<any>(`${this.baseUrl}/${id}`);
  }

  searchOptions(query?: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this._http.get<any>(`${this.baseUrl}/options`, { params });
  }
}
