<div class="col text-center">
  <h5>Card On File Details</h5>
  <h2>
    {{ charge.paymentType | creditCard | titlecase }}
    <span *ngIf="charge.last4">
      &nbsp;&bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;{{
        charge.last4
      }}
    </span>
  </h2>
</div>
<div class="col mt-3">
  <button class="btn btn-secondary btn-block" (click)="completePayment()">Complete Payment</button>
</div>
