import { ApiDomain, ApiResources, ApiVersion } from '@app/enums';
import { ISchema, Type, Widget } from '../schema';

export const MagicCopySchema: ISchema = {
  orgIds: {
    label: "Org ID's",
    description: 'Select orgs where you want this data copied to.',
    type: Type.ARRAY,
    widget: {
      type: Widget.SELECT,
      enum: [],
      multiple: true,
      options: {
        version: ApiVersion.V2,
        domain: ApiDomain.API,
        resource: ApiResources.ORGS,
        modelProp: 'name',
        dataProp: 'data.data',
        search: { isDeleted: false, limit: 1000, sort: 'name' },
        displayFields: ['name'],
      },
    },
  },
};
