import _ from 'lodash';
import { InventoryTrackingType } from '../../enums';
import { EnumHelper } from '../../helpers';
import { ISchema, Type, Widget } from '../schema';

export const InventoryTrackingSchema: ISchema = {
  trackingType: {
    section: 'Inventory Tracking Options',
    type: Type.STRING,
    label: 'Tracking Type',
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(InventoryTrackingType),
    required: true,
  },
  locationIds: {
    section: 'Inventory Tracking Options',
    type: Type.STRING,
    label: 'Locations',
    widget: {
      type: Widget.SELECT,
    },
    enum: [],
    multiple: true,
    required: true,
    onlyIf: [
      {
        field: 'trackingType',
        shouldShow: (value: any) =>
          _.includes(
            [InventoryTrackingType.EXCLUDE_LOCATIONS, InventoryTrackingType.INCLUDE_LOCATIONS],
            value
          ),
      },
    ],
  },
};
