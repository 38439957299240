<div class="modal-header">
  <h4 class="modal-title">Add Bank Account</h4>
</div>
<div class="modal-body">
  <div class="col">
    <div class="form-horizontal form-material">
      <div class="mb-3">
        <label>Account Holder Name</label>
        <input
          type="text"
          placeholder="ACME Inc"
          [(ngModel)]="bankAccount.account_holder_name"
          (ngModelChange)="canSubmit()"
          name="name"
          class="form-control form-control-line"
        />
      </div>
      <div class="mb-3">
        <label>Routing Number</label>
        <input
          type="text"
          placeholder="110000000"
          [(ngModel)]="bankAccount.routing_number"
          (ngModelChange)="canSubmit()"
          name="routing"
          class="form-control form-control-line"
        />
      </div>
      <div class="mb-3">
        <label>Account Number</label>
        <input
          type="text"
          placeholder="000123456789"
          [(ngModel)]="bankAccount.account_number"
          (ngModelChange)="canSubmit()"
          name="account"
          class="form-control form-control-line"
        />
      </div>
    </div>
    <div class="col no-padding mt-3">
      <button
        class="btn btn-secondary btn-block"
        [disabled]="!isComplete"
        (click)="saveBankAccount()"
      >
        Add Bank Account
      </button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
</div>
