import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'guestSince',
})
export class GuestSincePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const a = moment();
      const b = moment(value);
      const result = b.from(a);
      return result;
    }
  }
}
