import { createAction, props } from '@ngrx/store';

export enum ViewModeActions {
  CHANGE_MODE = 'View Mode Changed',
}

export const setViewMode = createAction(
  ViewModeActions.CHANGE_MODE,
  props<{ viewMode: 'org' | 'admin' }>()
);
