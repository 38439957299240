import { ExportType, ReportType, UserRoles } from '@app/enums';
import { ISchema, Type } from '../schema';
import { Report } from './report';
import { BaseReportProps } from './base-report-props';

export const PayoutReportSchema: ISchema = {
  from: {
    section: 'basic',
    description: 'The first date a payout could occur.',
    type: Type.DATE,
    default: () => new Date(),
    label: 'Start Date',
    required: true,
  },
  to: {
    section: 'basic',
    description: 'The first date a payout could occur.',
    type: Type.DATE,
    label: 'End Date',
    required: true,
  },
  email: BaseReportProps.email,
};

export interface PayoutReportData {
  from: string;
  to: string;
  email?: string;
}

export class PayoutReport extends Report<PayoutReportData> {
  ignoreProperties = [];
  icon = 'fas fa-file-invoice-dollar';
  title = 'Payout Report';
  description = 'Export information for Stripe payouts made between a particular set of dates.';
  isImmediate = false;
  data: PayoutReportData = {} as PayoutReportData;
  schema: ISchema = PayoutReportSchema;
  roles: UserRoles[] = [UserRoles.ADMIN, UserRoles.CUSTOMER];
  type: ReportType = ReportType.PAYOUT;
  exportOptions: ExportType[] = [ExportType.CSV, ExportType.EXCEL];
}
