<div class="mb-3">
  <label>
    {{ label }}
    <p class="text-muted" *ngIf="description">{{ description }}</p>
  </label>
  <input
    type="text"
    placeholder="Seconds"
    [(ngModel)]="seconds"
    (blur)="clean()"
    name="seconds"
    [disabled]="disabled"
    class="form-control form-control-line"
  />
</div>
