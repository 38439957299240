import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'incrementFilter',
})
export class IncrementFilterPipe implements PipeTransform {
  transform(increment: any): string {
    let value = '';
    switch (increment.operation) {
      case 'alternating-interval':
        value = `${increment.interval}/${increment.interval + increment.intervalStep} mins`;
        break;
      default:
        value = `${increment.interval} mins`;
        break;
    }
    return value;
  }
}
