import { Pipe, PipeTransform } from '@angular/core';

import { MenuItemTypeName } from '@app/enums/concessions';

@Pipe({
  name: 'menuFilter',
})
export class MenuFilterPipe implements PipeTransform {
  transform(filter: any, kind: 'item' | 'modifier' = 'item'): string {
    let value = '';
    switch (filter.property) {
      case 'name': {
        value = `Name contains ${filter.value}`;
        break;
      }
      case 'type': {
        const type = MenuItemTypeName[filter.value];
        value = `Type is ${type}`;
        break;
      }
      case 'category': {
        value = `Category is ${filter.name || filter.value}`;
        break;
      }
      case 'restaurant': {
        value = `Concession is ${filter.name || filter.value}`;
        break;
      }
      default: {
        value = `${filter.property} is ${filter.name || filter.value}`;
        break;
      }
    }
    return value;
  }
}
