import { ApiResources, ApiVersion } from '../../enums';
import { ISchema, Type, Widget } from '../schema';
import { PromoPriceSchema } from './promo-price';

export const PriceOptionSchema: ISchema = {
  name: {
    type: Type.STRING,
    label: 'Name',
    default: 'Default',
    description:
      'Only change if offering multiple price options. E.g. Small, Medium, Large, or Single, Double.',
    required: true,
  },
  posName: {
    type: Type.STRING,
    label: 'POS Name',
    description: 'For display on the POS',
  },
  price: {
    type: Type.CURRENCY,
    label: 'Price',
    required: true,
  },
  taxIncluded: {
    type: Type.BOOLEAN,
    label: 'Tax Included',
    default: false,
  },
  preTaxPrice: {
    type: Type.CURRENCY,
    label: 'Pre Tax Price',
    isHidden: true,
  },
  taxAmount: {
    type: Type.CURRENCY,
    label: 'Tax Amount',
    isHidden: true,
  },
  quantityMultiple: {
    type: Type.NUMBER,
    label: 'Inventory Quantity Multiple',
    description:
      'This is the value that will be used to calculate inventory quantities. E.g. 1 for a single, 2 for a double, etc.',
    default: 1,
  },
  promoPricing: {
    type: Type.ARRAY,
    label: 'Promo Pricing',
    description: 'Set promo pricing to offer discounts on certain days of the week.',
    items: {
      label: 'Promo Price',
      type: Type.OBJECT,
      properties: PromoPriceSchema,
    },
  },
  modifiers: {
    label: 'Modifiers',
    type: Type.ARRAY,
    items: {
      type: Type.STRING,
      label: 'Modifier Groups',
      widget: {
        type: Widget.SELECT,
        enum: [],
        options: {
          preserveModel: true,
          version: ApiVersion.V2,
          resource: ApiResources.MENU_ITEM_MODIFIER,
          viewAsMode: 'admin',
          modelProp: 'name',
          dataProp: 'modifiers',
          search: { isDeleted: false, sort: 'name' },
          displayFields: ['name'],
        },
      },
    },
  },
};

export interface PriceOptionData {
  _id?: string;
  name: string;
  posName: string;
  price: number;
  modifiers: string[];
}
