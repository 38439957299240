/* eslint-disable @typescript-eslint/naming-convention */
export enum ProductTypeName {
  'ingredient' = 'Ingredient',
  'golf' = 'Golf',
  'menu-item' = 'Menu Item',
  'merchandise' = 'Merchandise',
  'modifier' = 'Modifier',
  'recipe' = 'Recipe',
  'other' = 'Other',
  'plan' = 'Plan',
}

export enum ProductType {
  INGREDIENT = 'ingredient',
  GOLF = 'golf',
  MENU_ITEM = 'menu-item',
  MERCHANDISE = 'merchandise',
  MODIFIER = 'modifier',
  RECIPE = 'recipe',
  OTHER = 'other',
  PLAN = 'plan',
}
