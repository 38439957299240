import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiResources } from '@app/enums';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-heroku-app-list',
  templateUrl: './heroku-app-list.component.html',
  styleUrls: ['./heroku-app-list.component.scss'],
})
export class HerokuAppListComponent extends BaseComponent implements OnInit {
  @Input() appId: string;
  @Output() appIdChange = new EventEmitter<string>();

  public apps: Array<any> = [];

  constructor() {
    super();
    this.apiClient.setResource(ApiResources.HEROKU);
  }

  ngOnInit() {
    this.getApps();
  }

  async getApps() {
    try {
      const result = await this.apiClient
        .get<{ success: boolean; data: Array<any> }>({})
        .toPromise();
      this.apps = result.data;
    } catch (err) {
      this.displayError(err);
    }
  }

  change() {
    this.appIdChange.emit(this.appId);
  }
}
