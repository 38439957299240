import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

declare const Stripe: any;

@Injectable({
  providedIn: 'root',
})
export class StripeLazyLoadService {
  private stripePK: string;

  constructor() {
    this.stripePK = environment.stripePK;
  }

  public init(): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'stripejs';
      script.src = 'https://js.stripe.com/v3/';
      script.onload = () => {
        // STRIPE INSTANIATION
        const stripe = Stripe(this.stripePK);
        resolve({ stripe });
      };
      script.onerror = () => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ error: 'Unable to load Stripe SDK at this time.' });
      };
      document.body.appendChild(script);
    });
    return promise;
  }
}
