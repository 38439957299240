import { ApiDomain, ApiResources, ApiVersion } from '../enums';

export interface IFilterOptionValue {
  name: string;
  value: string;
}

/**
 * @todo Break out options based on types
 */
export interface IFilterOption {
  name: string;
  key: ApiResources;
  search?: string;
  modelProp?: string;
  options: {
    type: 'input' | 'select' | 'typeahead' | 'date';
    values?: IFilterOptionValue[];
    query?: { [key: string]: string | boolean | number };
    returnDataProp?: string; // e.g. data, users etc
    multiple?: boolean; // Select
    domain?: ApiDomain;
    version?: ApiVersion;
    subCollection?: string;
    modelProp?: string;
    dataProp?: string;
    displayFields?: string[];
    searchable?: boolean;
  };
  adminOnly?: boolean;
}
