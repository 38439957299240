import { AbstractModel } from '../abstract.model';
import { ISchema, Type } from '../schema';
import { TeeTimeRange, TeeTimeRangeSchema } from './tee-sheet-shared';

export interface TeeSheetSettingsData {
  _id?: string;

  /**
   * Open & Close Times for Tee Sheet
   * - Recommended not to change times once created
   * */
  teeTimeRange: TeeTimeRange;

  // Adjust default view on Tee Sheet page
  hideBackNine: boolean;

  /**
   * Set all players based on who booked
   * e.g. Member price vs public price
   */
  pricingFallBacktoFirstPlayer: boolean;

  /**
   * Ensure only being charged for their price class
   * - employees can't change price classes
   * - only charge what customer is set as in customers module
   */
  lockDownGreeFeeSelection: boolean;

  /**
   * Manage view of tee sheets, 1 vs 4 players
   * - splits groups into individual timeslots
   * - Can split check-in groups
   */
  splitTeeTimes: boolean;

  /**
   * List all players in the tee time details
   */
  alwaysListAll: boolean;

  /**
   * Can only check-in players on the day of the tee time
   */
  restrictCheckins: boolean;

  /**
   * Only Impacts courses with multile tee sheets
   * - stack - one on top of the other
   * - sideBySide - next to each other (requires stack)
   */
  stackTeeSheets: boolean;
  sideBySide: boolean;

  /**
   * Active tee time color coding
   * - default to black #000000
   */
  teedOffColor: string;
  completedColor: string;
  orgId: string;
  venueId: string;
}

export const TeeSheetSettingsSchema: ISchema<Omit<TeeSheetSettingsData, '_id'>> = {
  teeTimeRange: {
    label: 'Tee Time Range',
    type: Type.OBJECT,
    properties: TeeTimeRangeSchema,
  },
  hideBackNine: {
    label: 'Hide Back Nine',
    type: Type.BOOLEAN,
    default: true,
    isHidden: true,
  },
  pricingFallBacktoFirstPlayer: {
    label: 'Use Same Pricing as First Player by Default ',
    description: 'Set all players based on who booked. E.g. Member price vs public price',
    type: Type.BOOLEAN,
    default: true,
  },
  lockDownGreeFeeSelection: {
    label: 'Lockdown Green Fee Selection',
    description:
      "Ensure only being charged for their price class<ul><li>Employees can't change price classes.</li><li>Only charge what customer is set as in Booking.</li></ul>",
    type: Type.BOOLEAN,
    default: true,
  },
  splitTeeTimes: {
    label: 'Split Tee Time View',
    description: 'Manage view of tee sheets, 1 vs 4 players.',
    type: Type.BOOLEAN,
    default: true,
  },
  alwaysListAll: {
    label: 'List all players in the tee time details',
    type: Type.BOOLEAN,
    default: true,
  },
  restrictCheckins: {
    label: 'Can only check-in players on the day of the tee time',
    type: Type.BOOLEAN,
    default: true,
  },
  stackTeeSheets: {
    label: 'Stack Tee Sheet',
    description:
      'Only affects layout with multiple tee sheets<ul><li><b>Stack</b> - one on top of the other (default)</li></ul>',
    type: Type.BOOLEAN,
    default: false,
  },
  sideBySide: {
    label: 'Tee Sheets Next to Each Other',
    description:
      '<ul><li>Stack - one on top of the other (default)</li><li>Side-By-Side - next to each other</li></ul>',
    type: Type.BOOLEAN,
    default: true,
    onlyIf: 'stackTeeSheets',
  },
  teedOffColor: {
    label: 'Teed Off Color',
    type: Type.COLOR,
    required: true,
  },
  completedColor: {
    label: 'Completed Color',
    type: Type.COLOR,
    required: true,
  },
  orgId: {
    label: 'Org',
    type: Type.STRING,
    isHidden: true,
  },
  venueId: {
    label: 'Venue',
    type: Type.STRING,
    isHidden: true,
  },
};

export class TeeSheetSettings extends AbstractModel<TeeSheetSettingsData> {
  constructor(public data: TeeSheetSettingsData) {
    super(data);
  }
}
