import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  @Input() text = '';

  @Input() title = '';

  @Input() cancelText = 'Cancel';

  @Input() confirmText = 'Confirm';

  @Input() hideConfirm = false;

  constructor(public modal: NgbActiveModal) {}

  ngOnInit() {
    this.title = this.title || 'Confirm';
  }

  confirm() {
    this.modal.close(true);
  }
}
