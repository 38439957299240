import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { TimeHelper } from '../../../helpers';

@Component({
  selector: 'app-seconds-input',
  templateUrl: './seconds-input.component.html',
  styleUrls: ['./seconds-input.component.scss'],
})
export class SecondsInputComponent implements OnChanges {
  @Input() value: any;
  @Input() disabled: boolean;
  @Input() label = 'Seconds';
  @Input() description: string;
  @Input() multiplier = TimeHelper.SECOND;

  @Output() valueChange = new EventEmitter();

  public tempTime = '15';

  get seconds() {
    return this.tempTime;
  }

  set seconds(val: string) {
    this.tempTime = val;
    const newValue = Math.round(Number(val) * this.multiplier);
    this.value = newValue;
    this.valueChange.emit(this.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value?.currentValue && !changes.value?.previousValue) {
      this.tempTime = (changes.value.currentValue / this.multiplier).toFixed(0);
      this.valueChange.emit(this.value);
    }
  }

  clean() {
    this.tempTime = (Math.round(+this.tempTime * this.multiplier) / this.multiplier).toFixed(0);
  }
}
