<div class="col">
  <div class="form-horizontal form-material">
    <div class="mb-3 input-group">
      <input
        type="text"
        placeholder="Search Items"
        name="search"
        #search
        class="form-control form-control-line"
      />
      <div class="input-group-append" *ngIf="search.value">
        <button class="btn btn-light" (click)="clear()">Clear</button>
      </div>
    </div>
  </div>
  <div class="row btn-row">
    <div class="col-md-3 btn-container" *ngFor="let item of items">
      <button class="btn btn-light" (click)="addToCart(item)">{{ item.name }}</button>
    </div>
    <div class="col text-center" *ngIf="!items.length">
      <p>Search for items to add to the order for checkout.</p>
    </div>
  </div>
</div>
