<div class="mb-3">
  <label>Timezone</label>
  <div>
    <select class="form-select" name="timezone" [(ngModel)]="timezone" (change)="change()">
      <option *ngFor="let zone of timezones" [ngValue]="zone">
        {{ zone }}
      </option>
    </select>
  </div>
</div>
