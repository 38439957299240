<ng-container>
  <div class="table-responsive">
    <table class="table v-middle">
      <thead>
        <tr>
          <th class="th-checkbox" [colSpan]="selected ? headers.length + 1 : 1">
            <div
              class="input-group flex-nowrap"
              placement="right"
              [ngbTooltip]="selected ? '' : ToolTipTemplate"
            >
              <ng-template #ToolTipTemplate>
                Select all {{ options?.collection?.plural }}
              </ng-template>
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    name="active"
                    class="form-check-input"
                    (change)="doSelectAll()"
                    [(ngModel)]="selectedAll"
                  />
                  <label class="form-check-label" for="active" *ngIf="selected">
                    <span [ngPlural]="selected">
                      <ng-template ngPluralCase="=1">
                        {{ selected }} {{ options?.collection?.label }} selected
                      </ng-template>
                      <ng-template ngPluralCase="other">
                        {{ selected }} {{ options?.collection?.plural }} selected
                      </ng-template>
                    </span>
                  </label>
                </div>
              </div>
              <div class="input-group-append" ngbDropdown container="body" *ngIf="selected">
                <button
                  class="btn btn-outline-secondary btn-sm"
                  id="table-actions"
                  ngbDropdownToggle
                >
                  Actions
                </button>
                <div ngbDropdownMenu aria-labelledby="table-actions">
                  <button
                    class="btn"
                    ngbDropdownItem
                    *ngIf="selected === 1 && options?.actions?.duplicate"
                    (click)="doDuplicate()"
                  >
                    Duplicate
                  </button>
                  <button
                    class="btn"
                    ngbDropdownItem
                    *ngIf="options?.actions?.delete"
                    (click)="doDelete()"
                  >
                    <span [ngPlural]="selected">
                      <ng-template ngPluralCase="=1"
                        >Delete {{ options?.collection?.label }}</ng-template
                      >
                      <ng-template ngPluralCase="other"
                        >Delete {{ options?.collection?.plural }}</ng-template
                      >
                    </span>
                  </button>
                  <ng-container *ngIf="options?.actions?.custom?.length">
                    <ng-container *ngFor="let opt of options.actions.custom">
                      <button
                        *ngIf="canShow(opt.show)"
                        class="btn"
                        ngbDropdownItem
                        (click)="opt.action()"
                      >
                        <span [ngPlural]="selected">
                          <ng-template ngPluralCase="=1">
                            {{ opt.label
                            }}<span *ngIf="!opt.skipCollectionLabel"
                              >&nbsp;{{ options?.collection?.label }}</span
                            >
                          </ng-template>
                          <ng-template ngPluralCase="other">
                            {{ opt.label
                            }}<span *ngIf="!opt.skipCollectionLabel"
                              >&nbsp;{{ options?.collection?.plural }}</span
                            >
                          </ng-template>
                        </span>
                      </button>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </th>
          <ng-container *ngIf="!selected">
            <ng-container *ngFor="let header of headers">
              <ng-container *ngIf="header.sortable">
                <th
                  scope="col"
                  class="sortable"
                  [class.asc]="
                    !selected &&
                    !sortProp?.reverse &&
                    (sortProp?.field === header.property || sortProp?.field === header.property[0])
                  "
                  [class.desc]="
                    !selected &&
                    sortProp?.reverse &&
                    (sortProp?.field === header.property || sortProp?.field === header.property[0])
                  "
                  [width]="header.width"
                  [sortable]="header.property"
                  [sort]="sort"
                  (sortChange)="sortList($event)"
                >
                  <span *ngIf="!selected">{{ header.label }}</span>
                </th>
              </ng-container>
              <ng-container *ngIf="!header.sortable">
                <th [width]="header.width" scope="col">
                  <span *ngIf="!selected">{{ header.label }}</span>
                </th>
              </ng-container>
            </ng-container>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of tableRows">
          <td width="5%">
            <div class="mb-3 form-check">
              <input
                type="checkbox"
                name="active"
                (change)="doCheckSelected()"
                class="form-check-input"
                [(ngModel)]="row.selected"
              />
            </div>
          </td>
          <td *ngFor="let col of row.cols" [width]="col.width">
            <ng-container *ngIf="col.isImage">
              <app-item-image [item]="col.value" [url]="true"></app-item-image>
            </ng-container>
            <ng-container *ngIf="col.link">
              <a class="clickable text-primary" (click)="col.link()">
                <div [innerHtml]="col.value | safeHtml"></div>
              </a>
            </ng-container>
            <ng-container *ngIf="!col.isImage && !col.link">
              <div [innerHtml]="col.value | safeHtml"></div>
            </ng-container>
          </td>
        </tr>
        <tr *ngIf="!paginationOptions.total">
          <td [attr.colspan]="headers.length + 1" class="text-center">
            <h5>No {{ options?.collection?.plural }} at this time.</h5>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <ngb-pagination
      class="d-flex justify-content-center"
      [boundaryLinks]="true"
      [(page)]="paginationOptions.page"
      [pageSize]="paginationOptions.limit"
      [maxSize]="paginationOptions.maxSize || 5"
      [collectionSize]="paginationOptions.total"
      (pageChange)="paginationOptions.nextPage($event)"
    ></ngb-pagination>
  </div>
</ng-container>
