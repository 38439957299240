import { IProperties, Type } from '../schema';

export const IClassifications: IProperties = {
  type: Type.ARRAY,
  label: 'ID',
  description: 'Classification ID',
  items: {
    type: Type.STRING,
    label: null,
  },
};
