import { LocationEffects } from './locations.effects';
import { IntegrationEffects } from './integration.effects';
import { OrgEffects } from './org.effects';
import { VenueEffects } from './venue.effects';

import { locationsReducer } from './locations.reducer';
import { integrationsReducer } from './integration.reducer';
import { orgsReducer, selectedOrgReducer } from './org.reducer';
import { selectedVenueReducer } from './venue.reducer';
import { viewModeReducer } from './view-mode.reducer';

export const appEffects: any[] = [LocationEffects, IntegrationEffects, OrgEffects, VenueEffects];

export const rootReducer = {
  locations: locationsReducer,
  integrations: integrationsReducer,
  orgs: orgsReducer,
  selectedOrg: selectedOrgReducer,
  selectedVenue: selectedVenueReducer,
  viewMode: viewModeReducer,
};

export * from './app.state';
export * from './integration.actions';
export * from './integration.effects';
export * from './integration.reducer';
export * from './integration.selectors';
export * from './locations.actions';
export * from './locations.effects';
export * from './locations.reducer';
export * from './locations.selectors';
export * from './org.actions';
export * from './org.effects';
export * from './org.reducer';
export * from './org.selectors';
export * from './venue.actions';
export * from './venue.reducer';
export * from './venue.selectors';
export * from './view-mode.actions';
export * from './view-mode.reducer';
export * from './view-mode.selector';
