import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import moment from 'moment';
import { ApiQuery, ApiQueryParams } from '@app/clients';

@Injectable({
  providedIn: 'root',
})
export class MenuItemService {
  private path = 'menu-item';
  private domain = environment.url_v2;
  private baseUrl = `${this.domain}/${this.path}`;

  constructor(private _http: HttpClient) {}

  /**
   * @description Get all menu items
   * @param query properties to search by
   * @example
   * {
   *    page: 1,
   *    limit: 20,
   *    sort: 'name',
   *    reverse: false,
   *    restaurant: '5a7c752708365e001a59442a',
   *    name: 'burger',
   *    type: 'food',
   *    isDeleted: false
   * }
   */
  find(query?: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this._http.get<any>(`${this.baseUrl}`, { params });
  }

  /**
   * @description Get all menu items for the logged in account
   * @param query properties to search by
   * @example
   * {
   *    page: 1,
   *    limit: 20,
   *    sort: 'name',
   *    reverse: false,
   *    restaurant: '5a7c752708365e001a59442a',
   *    name: 'burger',
   *    type: 'food',
   *    isDeleted: false
   * }
   */
  self(query?: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this._http.get<any>(`${this.baseUrl}/self`, { params });
  }

  findById(id: string): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/${id}`);
  }

  create(body: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}`, body);
  }

  update(id: string, update: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}`, update);
  }

  updatePosition(id: string, metadata: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}/position`, metadata);
  }

  delete(id: string): Observable<any> {
    return this._http.delete<any>(`${this.baseUrl}/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public setPromoInfo(items: Array<any>): void {
    items.forEach(item => {
      const day = moment().format('dddd').toLowerCase();
      const hasPromo = item.promotions ? item.promotions[day].isActive : false;
      if (hasPromo) {
        item.promoActive = true;
        item.promoPrice = item.promotions[day].price;
        item.promoTag = item.promotions[day].tag;
      }
    });
  }
}
