import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { PermissionsService } from '@app/providers/auth/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private _permissionsService: PermissionsService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const checkPermission = async resolve => {
      try {
        const result = await this._permissionsService.hasPermissions(['super']).toPromise();
        if (result.isAdmin) {
          resolve(true);
        } else {
          resolve(false);
        }
      } catch (e) {
        resolve(false);
      }
    };
    return new Promise<boolean>(checkPermission);
  }
}
