import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ApiDomain, ApiResources, ApiVersion, ServiceChargeBehavior } from '../../enums';
import { ISchema, Type, Widget } from '../schema';
import { EnumHelper } from '../../helpers';

export const ServiceChargeSchema: ISchema = {
  name: {
    type: Type.STRING,
    label: 'Service Charge Name',
    required: true,
  },
  behavior: {
    type: Type.STRING,
    label: 'Behavior',
    required: true,
    default: ServiceChargeBehavior.FIXED_AMOUNT,
    enum: EnumHelper.getEnumArray(ServiceChargeBehavior),
    widget: {
      type: Widget.SELECT,
    },
  },
  value: {
    type: Type.NUMBER,
    label: 'Value e.g. 0.1 for 10% or 100 for $1.00',
    required: true,
  },
  excludeMenuItems: {
    type: Type.ARRAY,
    label: 'Excluded Menu Items',
    description: 'A list of menu items that this service charge should not apply to.',
    widget: {
      type: Widget.SELECT,
      enum: [],
      multiple: true,
      options: {
        preserveModel: true,
        resource: ApiResources.MENU_ITEMS,
        version: ApiVersion.V2,
        subCollection: 'self',
        search: { limit: 10000, sort: 'name' },
        dataProp: 'menuItems',
        modelProp: 'name',
        displayFields: ['name', 'category.name'],
      },
    },
    onlyIf: [
      {
        field: 'behavior',
        shouldShow: (value: ServiceChargeBehavior) =>
          ![ServiceChargeBehavior.FIXED_AMOUNT].includes(value),
      },
    ],
  },
  taxCodes: {
    section: 'Taxes',
    type: Type.ARRAY,
    label: 'Service Charge Taxes',
    widget: {
      type: Widget.SELECT,
      multiple: true,
      enum: [],
      options: {
        preserveModel: true,
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.TAX_CODES,
        modelProp: 'name',
        dataProp: 'data.data',
        search: { limit: 100, sort: 'name' },
        displayFields: ['name', 'taxRate'],
      },
    },
  },
  isPartake: {
    type: Type.BOOLEAN,
    label: 'Partake Service Charge',
    partakeOnly: true,
    default: false,
  },
  isTenderCharge: {
    type: Type.BOOLEAN,
    label: 'Partake Credit Tender Service Charge',
    description: 'Apply this service charge only when making credit/debit payments.',
    onlyIf: 'isPartake',
    partakeOnly: true,
    default: false,
  },
};

export interface ServiceChargeData extends AbstractModelData {
  name: string;
  value: number;
  behavior: ServiceChargeBehavior;
  excludeMenuItems: string[];
  taxCodes: string[];
  accountId?: string;
  isPartake?: boolean;
  isTenderCharge?: boolean;
}

export class ServiceCharge extends AbstractModel<ServiceChargeData> {
  constructor(public data: ServiceChargeData) {
    super(data);
  }

  private getStringValue() {
    if (this.data.behavior === ServiceChargeBehavior.FIXED_AMOUNT) {
      return `$${(this.data.value * 0.01).toFixed(2)}`;
    }
    return `${(this.data.value * 100).toFixed(2)}%`;
  }

  private stringFromBehavior() {
    switch (this.data.behavior) {
      case ServiceChargeBehavior.FIXED_AMOUNT:
        return 'Fixed Amount';
      case ServiceChargeBehavior.PERCENTAGE_ON_SUB_TOTAL:
        return 'Percentage on Sub-Total';
      case ServiceChargeBehavior.PERCENTAGE_ON_TOTAL:
        return 'Percentage on Total';
      case ServiceChargeBehavior.PERCENTAGE_PRE_TAX:
        return 'Percentage on Pre-Tax Total';
    }
  }

  get displayName() {
    const base = `${this.data.name} - ${this.stringFromBehavior()} (${this.getStringValue()})`;
    if (this.data.isPartake) {
      return `${base} - Partake Only`;
    }
    return base;
  }
}
