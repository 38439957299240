<div class="mb-3">
  <label>Domain</label>
  <p class="formHelp">A custom domain for which this will be displayed for.</p>
  <div class="input-group">
    <input
      type="text"
      name="subdomain"
      placeholder="Subdomain"
      [(ngModel)]="subdomain"
      (change)="select()"
      [disabled]="disable"
      class="form-control"
    />
    <!-- CUSTOM NON-CLOUDFONT DOMAINS -->
    <input
      type="text"
      *ngIf="useOwnDns"
      name="domain2"
      placeholder="Domain"
      [(ngModel)]="domain"
      (ngModelChange)="selectOwnDomain()"
      [disabled]="disable"
      class="form-control"
    />
    <!-- CLOUDFRONT DOMAINS -->
    <select
      class="form-select"
      *ngIf="!useOwnDns"
      name="domain"
      [(ngModel)]="domain"
      [disabled]="disable"
      (change)="select()"
    >
      <option value="" selected>Select Domain</option>
      <option *ngFor="let domain of domains" [ngValue]="domain">
        {{ domain.name }}
      </option>
    </select>
  </div>
  <div class="mb-3 form-check">
    <input
      type="checkbox"
      [disabled]="!orgId"
      name="customDomain"
      class="form-check-input"
      id="custom-domain"
      [(ngModel)]="customDomain"
      (ngModelChange)="listZones()"
    />
    <label class="form-check-label" for="customDomain"> Use Custom Domain </label>
  </div>
  <div class="mb-3 form-check" *ngIf="customDomain">
    <input
      type="checkbox"
      name="useOwnDns"
      class="form-check-input"
      id="custom-domain"
      [(ngModel)]="useOwnDns"
    />
    <label class="form-check-label" for="useOwnDns"> Use Own DNS </label>
  </div>
  <p class="formHelp text-danger mt-1" *ngIf="disable">Unregister the domain to edit.</p>
</div>
