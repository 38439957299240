import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'voucherSummary',
})
export class VoucherSummaryPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const summaryItems = [];
      if (value && value.discount) {
        if (value.discount.type === 'percentage' && value.discount.percentageOff) {
          const valueSummary = `${value.discount.percentageOff}% off ${value.discount.appliedTo}`;
          summaryItems.push(valueSummary);
        } else if (value.discount.type === 'amount' && value.discount.amountOff) {
          const valueSummary = `${(value.discount.amountOff * 0.01).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })} off ${value.discount.appliedTo}`;
          summaryItems.push(valueSummary);
        }

        if (value.discount.amountMin) {
          const minSpend = `Minimum purchase of ${(value.discount.amountMin * 0.01).toLocaleString(
            'en-US',
            {
              style: 'currency',
              currency: 'USD',
            }
          )}`;
          summaryItems.push(minSpend);
        }

        if (value.redemption.quantity) {
          let limitSummary = `Limit of ${value.redemption.quantity} uses`;
          if (value.redemption.oncePerUser) {
            limitSummary += ', one per customer';
          }
          summaryItems.push(limitSummary);
        } else if (value.redemption.oncePerUser) {
          const limitSummary = 'One use per customer';
          summaryItems.push(limitSummary);
        }

        if (value.startDate) {
          const activeDate = `Active from ${moment(value.startDate, 'YYY-MM-DD').format('MMM DD')}`;
          summaryItems.push(activeDate);
        }

        if (value.endDate) {
          const endDate = `Active until ${moment(value.endDate, 'YYY-MM-DD').format('MMM DD')}`;
          summaryItems.push(endDate);
        }

        if (value.isExploding && value.explodingTime) {
          summaryItems.push(
            `Voucher will expire ${value.explodingTime} mins from the time of saving`
          );
        }
      }
      return summaryItems;
    }
  }
}
