<input
  type="text"
  class="form-control"
  placeholder="Search locations"
  [ngbTypeahead]="searchLocation"
  [resultTemplate]="selectedLocationTpl"
  [inputFormatter]="locationFormatter"
  [(ngModel)]="value"
  (ngModelChange)="onChange($event)"
  (selectItem)="selectLocation($event)"
/>
<ng-template #selectedLocationTpl let-r="result">
  {{ r.name }} ({{ r.slug }}) - {{ r.venue.name }}
</ng-template>
