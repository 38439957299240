import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'guestFilter',
})
export class GuestFilterPipe implements PipeTransform {
  transform(filter: any): string {
    let value = '';
    switch (filter.property) {
      case 'name':
        value = `Name is ${filter.value}`;
        break;
      case 'phone':
        value = `Phone is ${filter.value}`;
        break;
      case 'email':
        value = `Email is ${filter.value}`;
        break;
      case 'venue':
        value = `Venue is ${filter.selected ? filter.selected.name : filter.value}`;
        break;
      default:
        value = `${filter.property} is ${filter.value}`;
        break;
    }
    return value;
  }
}
