import _ from 'lodash';

export function titlecase(value: string, split = ' '): string {
  if (!_.isString(value)) {
    return value;
  }
  return value
    .toLowerCase()
    .split(split)
    .filter(x => x)
    .map((str: string) => {
      if (str.indexOf('-') > -1) {
        return titlecase(str, '-');
      }
      return str[0].toUpperCase() + str.substring(1);
    })
    .join(' ');
}

export function float(value: number): string {
  if (!_.isNumber(value)) {
    return '';
  }
  return value.toFixed(2);
}

export function int(value: number): string {
  if (!_.isNumber(value)) {
    return '';
  }
  return Math.round(value).toFixed(0);
}

export function currency(value: number): string {
  if (!_.isNumber(value)) {
    return '';
  }
  return (value * 0.01).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function slug(val: string) {
  if (!_.isString(val) || !val) {
    return '';
  }
  const cleaned = val.replace(/[\W_]+/g, ' ');
  const lowercase = cleaned.toLowerCase();
  const split = lowercase.split(' ');
  return split.join('-');
}
