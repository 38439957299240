import {
  Directive,
  Input,
  Output,
  EventEmitter,
  HostListener,
  HostBinding,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import _ from 'lodash';

export interface SortExpression {
  field?: string;
  reverse?: boolean;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'th[sortable]',
})
export class ThSortableDirective implements OnChanges {
  // #region Props

  @Input()
  @HostBinding('class.sortable')
  sortable!: string | string[];

  @Input()
  sort!: SortExpression;

  @Input()
  reverse = false;

  @Output()
  sortChange = new EventEmitter<SortExpression>();

  // #endregion

  // #region Bindings

  @HostBinding('class.asc')
  asc: boolean;

  @HostBinding('class.desc')
  desc: boolean;

  @HostListener('click', ['$event'])
  clickHandler = this.onClick.bind(this);

  // #endregion

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateClasses();
  }

  onClick(event: Event): void {
    this.sort = this.sort || {};
    if (_.isArray(this.sortable)) {
      // toggle reverse or reset to default
      if (this.sort.field === this.sortable[0]) {
        this.sort.reverse = !this.sort.reverse;
      } else {
        this.sort.reverse = this.reverse;
      }
      this.sort.field = this.sortable[0];
    } else {
      // toggle reverse or reset to default
      if (this.sort.field === this.sortable) {
        this.sort.reverse = !this.sort.reverse;
      } else {
        this.sort.reverse = this.reverse;
      }
      this.sort.field = this.sortable;
    }
    this.sortChange.emit({ ...this.sort });
  }

  private updateClasses() {
    if (_.isArray(this.sortable)) {
      this.asc = this.sort?.field === this.sortable[0] ? !this.sort.reverse : false;
      this.desc = this.sort?.field === this.sortable[0] ? this.sort.reverse : false;
    } else {
      this.asc = this.sort?.field === this.sortable ? !this.sort.reverse : false;
      this.desc = this.sort?.field === this.sortable ? this.sort.reverse : false;
    }
  }
}
