import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'guestAge',
})
export class GuestAgePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const now = moment();
      const target = moment(value);
      const result = now.diff(target, 'years');
      return result;
    }
  }
}
