import _ from 'lodash';
import {
  Component,
  Input,
  SimpleChanges,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { ClassificationService } from '@app/providers/event/classification.service';

@Component({
  selector: 'app-classification-selection',
  templateUrl: './classification-selection.component.html',
  styleUrls: ['./classification-selection.component.scss'],
})
export class ClassificationSelectionComponent implements OnInit, OnChanges {
  @Input() classifications: Array<any> = [];
  @Output() classificationsChange = new EventEmitter<any>();

  // Options
  public segments: Array<any> = [];
  public genres: Array<any> = [];
  public subGenres: Array<any> = [];

  // Selected
  public segment: any = null;
  public genre: Array<string> = [];
  public subGenre: Array<string> = [];

  constructor(private _classificationService: ClassificationService) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.classifications?.currentValue && changes.classifications?.firstChange) {
      this.processClassifications();
    }
  }

  private async init() {
    try {
      const data = await this._classificationService.loadTypeLists(100);
      this.segments = data.segments?.data?.data;
      this.genres = data.genres?.data?.data;
      this.subGenres = data.subGenres?.data?.data;
      this.processClassifications();
    } catch (e) {
      console.log(e);
    }
  }

  private filterClassifications(array: Array<any>): Array<any> {
    return array
      .filter((item: any) =>
        this.classifications.find(
          (classification: any) => (classification?._id || classification) === item._id
        )
      )
      .map(x => x._id);
  }

  private processClassifications() {
    // Classifications
    if (!_.size(this.classifications)) {
      return;
    }
    this.segment = _.first(this.filterClassifications(this.segments));
    this.genre = this.filterClassifications(this.genres);
    this.subGenre = this.filterClassifications(this.subGenres);
  }

  public resetGenres() {
    this.genre = [];
    this.subGenre = [];
    this.change();
  }

  public filteredGenres() {
    if (this.segment) {
      return this.genres.filter(x => x.segment._id === this.segment);
    }
    // @ts-ignore
    return [...this.genres];
  }

  public filteredSubGenres() {
    if (this.genre.length) {
      return this.subGenres.filter(x => {
        let isSubGenre = false;
        this.genre.forEach(genre => {
          x.genres.forEach(y => {
            if (y._id === genre) {
              isSubGenre = true;
            }
          });
        });
        return isSubGenre;
      });
    }
    return [...this.subGenres];
  }

  change() {
    const classifications = [this.segment, ...this.genre, ...this.subGenre].filter(x => x);
    this.classificationsChange.emit(classifications);
  }
}
