import { ApiDomain, ApiResources, ApiVersion } from '../../enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type } from '../schema';
import { Competition, CompetitionData } from './competition.model';

export const TournamentCalendarSchema: ISchema = {
  competitionId: {
    type: Type.TYPEAHEAD,
    label: 'Competition',
    domain: ApiDomain.API,
    version: ApiVersion.V2,
    resource: ApiResources.COMPETITION,
    modelProp: 'name',
    displayFields: ['name', 'competitionCode'],
    required: true,
  },
  name: {
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  startDate: {
    type: Type.DATE,
    label: 'Start Date',
    required: true,
  },
  startDateString: {
    type: Type.STRING,
    label: 'Start Date String e.g 2021-01-31',
  },
  endDate: {
    type: Type.DATE,
    label: 'End Date',
    required: true,
  },
  endDateString: {
    type: Type.STRING,
    label: 'End Date String e.g 2021-01-31',
  },
};

export interface TournamentCalendarData extends AbstractModelData {
  competitionId: string | Competition | CompetitionData;
  name: string;
  startDate: Date;
  startDateString?: string;
  endDate: Date;
  endDateString?: string;
}

export class TournamentCalendar extends AbstractModel<TournamentCalendarData> {
  constructor(public data: TournamentCalendarData) {
    super(data);
  }
}
