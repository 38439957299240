import _ from 'lodash';
import { IGameRules } from '@partake/interfaces';
import { ISchema, Type, Widget } from '../schema';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import {
  ApiResources,
  ApiVersion,
  BonusActionType,
  FormFieldType,
  GameStatus,
  GameType,
  HeadsUpCorrectPerson,
  HeadsUpTieBreakerType,
  MoreLessGameType,
  MoreLessResult,
} from '../../enums';
import { CompetitionData, FixtureData, PersonData } from '../sports';
import { FantasyHelper } from '../../fantasy/helpers';

const FormFieldSchema: ISchema = {
  label: {
    type: Type.STRING,
    label: 'Input Label',
    required: true,
  },
  description: {
    type: Type.STRING,
    label: 'Input Description',
  },
  placeholder: {
    type: Type.STRING,
    label: 'Input Placeholder Text',
  },
  fieldType: {
    type: Type.STRING,
    label: 'Input Type',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(FormFieldType),
    },
  },
  isRequired: {
    type: Type.BOOLEAN,
    label: 'Required',
    default: true,
  },
  multiple: {
    type: Type.BOOLEAN,
    label: 'Multiple',
    default: false,
  },
  enum: {
    type: Type.ARRAY,
    label: 'Valid Options',
    items: {
      type: Type.STRING,
      label: 'Option',
    },
    onlyIf: 'multiple',
  },
};

export const GameSchema: ISchema = {
  name: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Name',
  },
  type: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Type',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(GameType),
    },
    immutable: true,
    isHidden: true,
  },
  status: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Game Status',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(GameStatus),
    },
  },
  rules: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Rules',
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        resource: ApiResources.GAME_RULES,
        version: ApiVersion.V2,
        modelProp: 'name',
        dataProp: 'data.data',
        search: { gameType: GameType.BONUS_ACTION },
        displayFields: ['name'],
      },
    },
    onlyIf: [
      {
        field: 'type',
        shouldShow: (gameType: GameType) => [GameType.BONUS_ACTION].includes(gameType),
      },
    ],
  },
  isLive: {
    section: 'Basic Info',
    type: Type.BOOLEAN,
    label: 'Is Live Game',
    description:
      'Live Games occur at a specific time for a set duration, such as Live Trivia or Props.',
    notIf: [
      {
        field: 'isMiniGame',
        shouldShow: (isMiniGame: boolean) => !isMiniGame,
      },
      {
        field: 'type',
        shouldShow: (gameType: GameType) =>
          ![GameType.GAME_GROUP, GameType.BONUS_ACTION].includes(gameType),
      },
    ],
  },
  isHighlighted: {
    section: 'Basic Info',
    type: Type.BOOLEAN,
    label: 'Is Highlighted Game',
    description: 'Highlighted games or groups are promoted ahead of others.',
    onlyIf: [
      {
        field: 'isLive',
        shouldShow: (isLive: boolean) => isLive,
      },
    ],
  },
  isMiniGame: {
    section: 'Basic Info',
    type: Type.BOOLEAN,
    label: 'Is Mini Game',
    description: 'Mini games that are related to a larger game and can be used to unlock bonuses.',
    default: true,
    notIf: [
      {
        field: 'isLive',
        shouldShow: (isLive: boolean) => !isLive,
      },
      {
        field: 'type',
        shouldShow: (gameType: GameType) =>
          ![GameType.GAME_GROUP, GameType.BONUS_ACTION].includes(gameType),
      },
    ],
  },
  action: {
    section: 'Action',
    type: Type.STRING,
    label: 'Bonus Action',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(BonusActionType),
    },
    default: BonusActionType.FREE_FORM,
    onlyIf: [
      {
        field: 'type',
        shouldShow: (gameType: GameType) => [GameType.BONUS_ACTION].includes(gameType),
      },
    ],
  },
  points: {
    section: 'Action',
    type: Type.NUMBER,
    label: 'Bonus Action Points',
    onlyIf: [
      {
        field: 'type',
        shouldShow: (gameType: GameType) => [GameType.BONUS_ACTION].includes(gameType),
      },
    ],
  },
  formFields: {
    section: 'Action',
    type: Type.ARRAY,
    label: 'Bonus Action Fields',
    items: {
      type: Type.OBJECT,
      label: 'Actions',
      properties: FormFieldSchema,
    },
    onlyIf: [
      {
        field: 'type',
        shouldShow: (gameType: GameType) => [GameType.BONUS_ACTION].includes(gameType),
      },
    ],
  },
  openDate: {
    section: 'Dates',
    label: 'Open Date',
    type: Type.DATE_TIME,
    required: true,
    onlyIf: [
      {
        field: 'type',
        shouldShow: (gameType: GameType) => [GameType.BONUS_ACTION].includes(gameType),
      },
    ],
    onChange: (value: Date, data: GameData) => {
      const dateInfo = FantasyHelper.getDateInfoFromDate(value);
      _.extend(data, {
        openTime: dateInfo.time,
        openTimeString: dateInfo.timeString,
        openDate: dateInfo.date,
        openDateString: dateInfo.dateString,
      });
    },
  },
  date: {
    section: 'Dates',
    label: 'Live Date',
    type: Type.DATE_TIME,
    required: true,
    onlyIf: [
      {
        field: 'type',
        shouldShow: (gameType: GameType) => [GameType.BONUS_ACTION].includes(gameType),
      },
    ],
    onChange: (value: Date, data: GameData) => {
      _.extend(data, FantasyHelper.getDateInfoFromDate(value));
    },
  },
  whiteLabelId: {
    section: 'Game Info',
    type: Type.TYPEAHEAD,
    partakeOnly: true,
    label: 'App',
    resource: ApiResources.WHITE_LABELS,
    version: ApiVersion.V2,
    modelProp: 'name',
    search: { isDeleted: false },
    dataProp: 'data.data',
    displayFields: ['name', 'domain', 'account.name', 'account.email'],
  },
  orgId: {
    section: 'Game Info',
    type: Type.TYPEAHEAD,
    label: 'Org',
    partakeOnly: true,
    immutable: true,
    resource: ApiResources.ORGS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
  },
  miniGames: {
    section: 'Mini Games',
    type: Type.ARRAY,
    label: 'Mini Games',
    description: 'Mini games that are related to a larger game and can be used to unlock bonuses.',
    items: {
      type: Type.TYPEAHEAD,
      label: 'Game',
      version: ApiVersion.V2,
      resource: ApiResources.GAMES,
      modelProp: 'name',
      dataProp: 'data.data',
      search: { status: 'draft,pre-open,open,live', isMiniGame: true },
      displayFields: ['name', 'type'],
    },
    onlyIf: 'isLive',
  },
  images: {
    section: 'Game Images',
    type: Type.IMAGES,
    label: 'Images',
    isHidden: true,
  },
  cashPrizes: {
    section: 'Prizes',
    type: Type.ARRAY,
    label: 'Cash Prizes',
    items: {
      type: Type.CURRENCY,
      label: 'Prize Value',
    },
    isHidden: true,
  },
  nonCashPrizes: {
    section: 'Prizes',
    type: Type.ARRAY,
    label: 'Non-Cash Prizes',
    items: {
      type: Type.STRING,
      label: 'Prize',
    },
    isHidden: true,
  },
};

export const GameQuickEditSchema: ISchema = {
  name: GameSchema.name,
  type: {
    ...GameSchema.type,
    isHidden: true,
  },
  openDate: {
    ...GameSchema.openDate,
    onlyIf: null,
    partakeOnly: true,
  },
  date: {
    ...GameSchema.date,
    onlyIf: null,
    partakeOnly: true,
  },
  status: GameSchema.status,
  isLive: GameSchema.isLive,
  isHighlighted: GameSchema.isHighlighted,
  isMiniGame: GameSchema.isMiniGame,
  whiteLabelId: GameSchema.whiteLabelId,
  orgId: GameSchema.orgId,
  miniGames: GameSchema.miniGames,
  images: {
    ...GameSchema.images,
    isHidden: false,
  },
};

export interface MoreLessProps {
  id: string;
  competitionId?: CompetitionData['_id'];
  person?: PersonData['_id'];
  stat: string;
  value: number;
  result?: MoreLessResult;
  points?: number;
}
export interface MoreLessGameData {
  type: GameType.MORE_LESS;
  props: MoreLessProps[];
  multiple: number;
  gameType: MoreLessGameType;
  isFreePlay: boolean;
}
/**
 * HEADS UP
 */
export interface HeadsUpTieBreaker {
  tieBreakerType: HeadsUpTieBreakerType;
  question: string;
  answer: number;
}
export interface HeadsUpOption {
  id: string;
  person1: PersonData['_id'];
  person2: PersonData['_id'];
  value?: string;
  correctPerson: HeadsUpCorrectPerson;
}
export interface HeadsUpGameData {
  type: GameType.HEADS_UP;
  question: string;
  headsUpOptions: HeadsUpOption[];
  tieBreaker: HeadsUpTieBreaker;
}
/**
 * TRIVIA & PROPS
 */
export interface GameAnswer {
  id: string;
  answer: string;
  score?: number;
}
export interface QuestionGameQuestion {
  id: string;
  question: string;
  value?: string;
  answers: GameAnswer[];
  correctAnswerId?: GameAnswer['id'];
  canAnswer: boolean;
  isHidden: boolean;
  countdown?: number;
}
export interface QuestionGameData {
  type: GameType.PROPS | GameType.TRIVIA;
  questions: QuestionGameQuestion[];
  countdown?: number;
}

export interface GroupGameGameData {
  type: GameType.GAME_GROUP;
}

export interface BonusActionFormField {
  fieldType: FormFieldType;
  label: string;
  description: string;
  enum?: string[];
  multiple: boolean;
  isRequired: boolean;
}

export interface BonusActionData {
  type: GameType.BONUS_ACTION;
  action: BonusActionType;
  points: number;
  formFields: BonusActionFormField[];
}

// Date info if a game is a Real-Time game.
export interface LiveGame {
  isLive: true;
  liveStartDelay: number; // This is used to determine the live time
  liveTime: number;
  liveTimeString: string;
  liveDate: Date;
  liveDateString: string;
}
/**
 * CORE GAME
 */
export interface BaseGameData {
  name: string;
  type: GameType;
  status: GameStatus;

  isHighlighted: boolean;
  isLive: boolean;
  isMiniGame: boolean;
  miniGames: GameData['_id'][];

  competitionId?: CompetitionData['_id'];
  fixtures?: FixtureData['_id'][];
  persons?: PersonData['_id'][];
  whiteLabelId?: string;
  orgId?: string;

  images: any[];

  // ObjectId
  classifications: string[];

  minEntries: number;
  maxEntries: number;
  maxPlayerEntries: number;
  entries: number;
  available: number;

  entryFee: number;
  prizePool: number;
  guaranteed: boolean;
  prizes: number[];
  nonCashPrizes: any[];

  private: boolean;
  code: string;

  rules: IGameRules['_id'];

  time: number;
  timeString: string;
  date: Date;
  dateString: string;

  /**
   * Enable auto open timing for games so they can be scheduled
   */
  openTime: number;
  openDate: Date;
  openDateString: string;
  timezone: string;
}
export type CoreData = BaseGameData &
  (LiveGame | { isLive: false }) &
  (GroupGameGameData | HeadsUpGameData | MoreLessGameData | QuestionGameData | BonusActionData);
export type GameData = CoreData & AbstractModelData;

export class Game extends AbstractModel<GameData> {
  constructor(public data: GameData) {
    super(data);
  }
}
