/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';

import { HttpClient, HttpEvent } from '@angular/common/http';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileService {
  private domain = environment.url_v2;
  private baseUrl = 'images';

  constructor(private http: HttpClient) {}

  public upload(file: any, name: string, { bucket }): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, name);
    if (bucket) {
      formData.append('bucket', bucket);
    }
    return this.http.post<any>(`${this.domain}/${this.baseUrl}`, formData);
  }

  public uploadWithProgress<T = any>(
    file: any,
    name: string,
    { bucket }
  ): Observable<HttpEvent<T>> {
    const formData = new FormData();
    formData.append('file', file, name);
    if (bucket) {
      formData.append('bucket', bucket);
    }
    return this.http.post<any>(`${this.domain}/${this.baseUrl}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  public dataURLtoBlob(dataurl: string) {
    console.log(dataurl);
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr], { type: mime });
    return blob;
  }

  public blobToDataURL(blob: Blob) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = err => {
        reject(err);
      };
      reader.readAsDataURL(blob);
    });
  }
}
