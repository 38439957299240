/* eslint-disable class-methods-use-this */
import _ from 'lodash';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CanShow, ListTableHeaderCell, ListTableHeaderOptions } from '../../../models';

@Component({
  selector: 'app-list-table-header',
  templateUrl: './list-table-header.component.html',
  styleUrls: ['./list-table-header.component.scss'],
})
export class ListTableHeaderComponent {
  @Input() options: ListTableHeaderOptions;
  @Input() headers: Array<ListTableHeaderCell> = [];
  @Input() selected: number;
  @Input() selectedAll: boolean;
  @Input() sortProp: { field: string; reverse: boolean };

  @Output() selectAll = new EventEmitter<boolean>();
  @Output() duplicate = new EventEmitter<boolean>();
  @Output() delete = new EventEmitter<boolean>();
  @Output() sort = new EventEmitter<any>();

  sortList(sort: any) {
    this.sort.emit(sort);
  }

  doDuplicate() {
    this.duplicate.emit(true);
  }

  doDelete() {
    this.delete.emit(true);
  }

  doSelectAll() {
    this.selectAll.emit(true);
  }

  canShow(show: CanShow | boolean): boolean {
    if (_.isBoolean(show)) {
      return show;
    }
    return show();
  }
}
