import {
  ApiResources,
  ApiVersion,
  PrinterRole,
  PrinterFulfillmentMode,
  PrinterStatus,
  ApiDomain,
} from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

export const PrinterSchema: ISchema<
  Pick<
    PrinterData,
    | 'name'
    | 'printerRole'
    | 'fulfillmentMode'
    | 'fulfillmentPrintJobs'
    | 'locationIds'
    | 'stationIds'
    | 'locationStationIds'
    | 'cashdrawerLocationId'
    | 'ringBuzzerCount'
  >
> = {
  name: {
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  printerRole: {
    type: Type.STRING,
    label: 'Role',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(PrinterRole),
      multiple: true,
    },
    required: true,
  },
  cashdrawerLocationId: {
    type: Type.STRING,
    label: 'Cashdrawer Location',
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        preserveModel: true,
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.LOCATION,
        modelProp: 'name',
        dataProp: 'data',
        search: { isDeleted: false },
        displayFields: ['name'],
      },
    },
    onlyIf: [
      {
        field: 'printerRole',
        shouldShow: (roles: PrinterRole[]) => roles?.includes(PrinterRole.CASH_DRAWER),
      },
    ],
  },
  fulfillmentMode: {
    type: Type.STRING,
    label: 'Fulfillment Mode',
    description: `Printer fulfillment routing mode for items ordered.
    &bull;&nbsp;<strong>Location</strong> - Items from certain locations are routed to this printer.
    &bull;&nbsp;<strong>Station</strong> - Items with specific stations are routed to this Printer. This IS NOT location specific and could be shared by many locations.
    &bull;&nbsp;<strong>Location Station</strong> - Items are routed to this Printer based on the combination of location/station specified. This IS location specific AND station specific.`,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(PrinterFulfillmentMode),
    },
    default: PrinterFulfillmentMode.LOCATION,
    onlyIf: [
      {
        field: 'printerRole',
        shouldShow: (roles: PrinterRole[]) => roles?.includes(PrinterRole.FULFILLMENT),
      },
    ],
    required: true,
  },
  fulfillmentPrintJobs: {
    type: Type.NUMBER,
    label: 'Fulfillment Print Jobs',
    default: 1,
    min: 1,
    max: 10,
    onlyIf: [
      {
        field: 'printerRole',
        shouldShow: (roles: PrinterRole[]) => roles?.includes(PrinterRole.FULFILLMENT),
      },
    ],
  },
  ringBuzzerCount: {
    type: Type.NUMBER,
    label: 'Printer Buzzer Ring Count',
    description: 'Buzzers will only work with printers that have them attached.',
    default: 0,
    min: 0,
    max: 5,
    onlyIf: [
      {
        field: 'printerRole',
        shouldShow: (roles: PrinterRole[]) => roles?.includes(PrinterRole.FULFILLMENT),
      },
    ],
  },
  locationIds: {
    type: Type.ARRAY,
    label: 'Locations',
    widget: {
      type: Widget.SELECT,
      enum: [],
      multiple: true,
      options: {
        preserveModel: true,
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.LOCATION,
        modelProp: 'name',
        dataProp: 'data',
        search: { isDeleted: false },
        displayFields: ['name'],
      },
    },
    onlyIf: [
      {
        field: 'fulfillmentMode',
        shouldShow: (value: PrinterFulfillmentMode) => value === PrinterFulfillmentMode.LOCATION,
      },
    ],
  },
  stationIds: {
    type: Type.ARRAY,
    label: 'Stations',
    widget: {
      type: Widget.SELECT,
      enum: [],
      multiple: true,
      options: {
        preserveModel: true,
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.MENU_ITEM_STATION,
        modelProp: 'name',
        dataProp: 'data.data',
        search: { isDeleted: false },
        displayFields: ['name'],
      },
    },
    onlyIf: [
      {
        field: 'fulfillmentMode',
        shouldShow: (value: PrinterFulfillmentMode) => value === PrinterFulfillmentMode.STATION,
      },
    ],
  },
  locationStationIds: {
    type: Type.ARRAY,
    label: 'Location Stations',
    items: {
      label: 'Location/Station',
      type: Type.OBJECT,
      properties: {
        locationId: {
          type: Type.STRING,
          label: 'Locations',
          widget: {
            type: Widget.SELECT,
            enum: [],
            options: {
              preserveModel: true,
              domain: ApiDomain.API,
              version: ApiVersion.V2,
              resource: ApiResources.LOCATION,
              modelProp: 'name',
              dataProp: 'data',
              search: { isDeleted: false },
              displayFields: ['name'],
            },
          },
        },
        stationId: {
          type: Type.STRING,
          label: 'Stations',
          widget: {
            type: Widget.SELECT,
            enum: [],
            options: {
              preserveModel: true,
              domain: ApiDomain.API,
              version: ApiVersion.V2,
              resource: ApiResources.MENU_ITEM_STATION,
              modelProp: 'name',
              dataProp: 'data.data',
              search: { isDeleted: false },
              displayFields: ['name'],
            },
          },
        },
      },
    },
    onlyIf: [
      {
        field: 'fulfillmentMode',
        shouldShow: (value: PrinterFulfillmentMode) =>
          value === PrinterFulfillmentMode.LOCATION_STATION,
      },
    ],
  },
};

export interface PrinterData extends AbstractModelData {
  name: string;
  printerId?: number;
  printerRole: PrinterRole[];
  fulfillmentMode: PrinterFulfillmentMode;
  fulfillmentPrintJobs: number;
  status: PrinterStatus;
  locationIds: string[];
  stationIds: string[];
  locationStationIds: {
    locationId: string;
    stationId: string;
  }[];
  cashdrawerLocationId: string;
  ringBuzzerCount: number;
  lastJobId?: string | string;
  lastJobTime?: Date;
  macAddress?: string;
  serialNumber?: string;
  webhookUrl?: string;
}

export class Printer extends AbstractModel<PrinterData> {
  constructor(public data: PrinterData) {
    super(data);
  }
}
