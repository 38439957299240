import moment from 'moment';
import { ApiResources, ApiVersion, ShiftStatus } from '../../enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { LocationData } from '../concession';
import { OrgData } from '../core';
import { ISchema, Type, Widget } from '../schema';

export interface ShiftInventoryItem {
  name: string;
  inventoryItemId: string;
  usesDayCounts: boolean;
  productId: string;
  openQuantity: number;
  closeQuantity: number;
  soldQuantity: number;
  additions: number;
  openVerified: boolean;
  closeVerified: boolean;
  discrepancy?: number;
  comment?: string;
  /** @deprecated */
  menuItem?: string;
}

export interface ShiftInventoryItemWithCurrentCount extends ShiftInventoryItem {
  currentCount: number;
}

export interface ShiftInventoryItemWithAdjustment extends ShiftInventoryItemWithCurrentCount {
  adjustment: number;
}

export interface ShiftData extends AbstractModelData {
  shiftId: number;
  name: string;
  restaurant: string | LocationData;
  orgId: string | OrgData;
  status: ShiftStatus;
  openingCash?: number;
  closingCash?: number;
  inventoryTracked: boolean;
  items: ShiftInventoryItem[];
  openTime: string;
  openingBy?: string | any;
  closeTime: string;
  closeBy?: string | any;
  lastOrder?: string;
  created: string;
  lastUpdated: string;
}

export const ShiftSchema: ISchema<Pick<ShiftData, 'openingBy' | 'closeBy'>> = {
  openingBy: {
    type: Type.STRING,
    label: 'Opened By',
    partakeOnly: true,
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        resource: ApiResources.EMPLOYEE,
        version: ApiVersion.V2,
        modelProp: 'name',
        dataProp: 'data.data',
        search: { limit: 1000, sort: 'name' },
        displayFields: ['name', 'employeeId'],
      },
    },
  },
  closeBy: {
    type: Type.STRING,
    label: 'Closed By',
    partakeOnly: true,
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        resource: ApiResources.EMPLOYEE,
        version: ApiVersion.V2,
        modelProp: 'name',
        dataProp: 'data.data',
        search: { limit: 1000, sort: 'name' },
        displayFields: ['name', 'employeeId'],
      },
    },
  },
};

export class Shift extends AbstractModel<ShiftData> {
  public readonly dayPart: string;

  constructor(public data: ShiftData) {
    super(data);
    this.dayPart = moment(this.get('created')).format('dddd').toLowerCase();
  }
}
