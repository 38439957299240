/* eslint-disable @typescript-eslint/naming-convention */
export enum MenuItemTypeName {
  'food' = 'Food',
  'drink' = 'Drink',
  'snack' = 'Snack',
  'merchandise' = 'Merchandise',
  'other' = 'Other',
}

export enum MenuItemType {
  FOOD = 'food',
  DRINK = 'drink',
  SNACK = 'snack',
  MERCHANDISE = 'merchandise',
  OTHER = 'other',
}

export enum DiningOptionUsageType {
  ALL_DINING_OPTIONS = 'all',
  INCLUDE_DINING_OPTIONS = 'include-dining-options',
  EXCLUDE_DINING_OPTIONS = 'exclude-dining-options',
  NONE = 'none',
}
