import { State, ApiResources, ApiVersion } from '@app/enums';
import { GameStatus, IImageFile } from '@partake/interfaces';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { EnumHelper } from '../../helpers';

export const PrizeSchema: ISchema = {
  id: {
    label: 'ID',
    type: Type.NUMBER,
    isHidden: true,
    immutable: true,
    partakeOnly: true,
  },
  state: {
    section: 'basic',
    type: Type.STRING,
    label: 'State',
    required: true,
    default: State.DRAFT,
    widget: { type: Widget.SELECT },
    enum: EnumHelper.getEnumArray(State),
  },
  name: {
    section: 'basic',
    type: Type.STRING,
    required: true,
    label: 'Name',
  },
  description: {
    section: 'basic',
    type: Type.WYSWYG,
    label: 'Full Prize Description',
    description: 'Provide a full prize description with terms for the user to view.',
  },
  value: {
    section: 'basic',
    type: Type.CURRENCY,
    required: true,
    label: 'Value',
  },
  images: {
    section: 'images',
    type: Type.IMAGES,
    label: 'Prize Images',
    bucket: 'prize',
  },
  sponsor: {
    section: 'basic',
    type: Type.STRING,
    label: 'Sponsor',
    description: 'Sponsor Name',
  },
  sponsorLogo: {
    section: 'images',
    type: Type.IMAGES,
    label: 'Sponsor Logo',
    bucket: 'sponsor-logos',
  },
  gameId: {
    section: 'pointers',
    type: Type.TYPEAHEAD,
    label: 'Game',
    version: ApiVersion.V2,
    resource: ApiResources.GAMES,
    modelProp: 'name',
    dataProp: 'data.data',
    search: { status: `${GameStatus.PRE_OPEN},${GameStatus.OPEN}` },
    displayFields: ['name'],
  },
  whiteLabelId: {
    section: 'pointers',
    type: Type.TYPEAHEAD,
    partakeOnly: true,
    label: 'App',
    resource: ApiResources.WHITE_LABELS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    search: { isDeleted: false },
  },
  orgId: {
    section: 'pointers',
    type: Type.TYPEAHEAD,
    label: 'Org',
    partakeOnly: true,
    immutable: true,
    resource: ApiResources.ORGS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
  },
};

export interface IPrize extends AbstractModelData {
  id: number;
  state: State;
  name: string;
  description: string;
  images: Array<IImageFile>;
  sponsor: string;
  sponsorLogo: Array<IImageFile>;
  value: number;
  gameId: string;
  whiteLabelId: string;
  orgId: string;
  isDeleted?: boolean;
}

export class Prize extends AbstractModel<IPrize> {
  constructor(public data: IPrize) {
    super(data);
  }
}
