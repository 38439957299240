import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeService } from '@app/providers/stripe/stripe.service';

@Component({
  selector: 'app-stripe-bank-account',
  templateUrl: './stripe-bank-account.component.html',
  styleUrls: ['./stripe-bank-account.component.scss'],
})
export class StripeBankAccountComponent implements OnInit {
  private stripe: any;

  public bankAccount = {
    country: 'US',
    currency: 'usd',
    account_holder_type: 'company',
    routing_number: null,
    account_number: null,
    account_holder_name: null,
  };

  public isComplete = false;

  constructor(
    private stripeService: StripeService,
    private modalCtrl: NgbActiveModal
  ) {}

  ngOnInit() {
    this.init();
  }

  private async init() {
    this.stripe = this.stripeService.getStripe();
    if (!this.stripe) {
      const { stripe } = await this.stripeService.init();
      if (stripe) {
        this.stripe = stripe;
      }
    }
  }

  public canSubmit() {
    this.isComplete =
      this.bankAccount.account_holder_name &&
      this.bankAccount.routing_number &&
      this.bankAccount.account_number;
  }

  public close() {
    this.modalCtrl.close();
  }

  public async saveBankAccount() {
    const { token, error } = await this.stripe.createToken('bank_account', this.bankAccount);
    if (token) {
      this.modalCtrl.close(token);
    } else {
      console.log('error', error);
    }
  }
}
