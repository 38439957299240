import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menuItem',
})
export class MenuItemPipe implements PipeTransform {
  transform(categories: Array<any>, filter: any): Array<any> {
    let filtered = [];
    if (!filter?.type && !filter?.category) {
      return categories;
    }

    if (filter.name) {
      // TODO - Need to filter items in each category against a regex of the menu item as a string
    } else if (filter.category) {
      filtered = categories.filter(x => x.name === filter.category);
    } else if (filter.type) {
      filtered = categories.filter(x => x.type === filter.type);
    }

    return filtered;
  }
}
