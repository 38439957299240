/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { ShiftService } from '../shift/shift.service';

@Injectable({
  providedIn: 'root',
})
export class CartUtilService {
  private _SERVICE_FEE = 199; // $1.99
  private _PICKUP_FEE = 99; // $0.99
  private _APPLICATION_FEE = 0.15; // 15%
  private _STRIPE_PERCENTAGE = 0.0285; // 2.85%
  private _AMEX_PERCENTAGE = 0.035; // 3.5%
  private _FLAT_FEE = 30; // $0.30
  private _FREE_TRIAL = false;

  constructor(
    private _userService: UserService,
    private _shiftService: ShiftService
  ) {}

  public init(config: any) {
    if (config) {
      if (config.serviceFee !== undefined) {
        this._SERVICE_FEE = config.serviceFee;
      }
      if (config.pickupFee !== undefined) {
        this._PICKUP_FEE = config.pickupFee;
      }
      if (config.applicationFee !== undefined) {
        this._APPLICATION_FEE = config.applicationFee;
      }
      if (config.stripePercentage !== undefined) {
        this._STRIPE_PERCENTAGE = config.stripePercentage;
      }
      if (config.amexPercentage !== undefined) {
        this._AMEX_PERCENTAGE = config.amexPercentage;
      }
      if (config.flatFee !== undefined) {
        this._FLAT_FEE = config.flatFee;
      }
      if (config.freeTrial !== undefined) {
        this._FREE_TRIAL = config.freeTrial;
      }
    }
  }

  public setDiscount(order: any, discount: any): any {
    order.items.forEach((item: any) => {
      item.discounts = [];
      item.discountTotal = 0;
    });
    for (let i = 0; i < order.items.length; i++) {
      const item = order.items[i];
      const isUsable = this.canUseDiscount(item.item, discount);
      if (isUsable) {
        const value = this.calculateItemDiscount(item, order, discount);
        item.discountTotal = value;
        item.discounts.push({
          id: discount._id,
          total: value,
        });
        item.taxes = this.processTaxes(item, item.item.taxCodes);
        item.taxTotal = this.getItemTaxTotal(item);
      }
    }
    return order;
  }

  public canUseDiscount(item: any, discount: any): boolean {
    if (!item.discounts || !item.discounts.length) {
      return false;
    }
    let canUse = false;
    item.discounts.forEach(disc => {
      if (typeof disc === 'string' && disc === discount._id) {
        canUse = true;
      } else if (disc._id === discount._id) {
        canUse = true;
      }
    });
    return canUse;
  }

  public calculateItemDiscount(item: any, order: any, discount: any): number {
    let value: number;
    switch (discount.type) {
      case 'fixedamountoffretail': {
        const total = order.items.reduce((prev, curr) => {
          let val = 0;
          if (curr.discounts && curr.discounts.length) {
            val = curr.discounts.reduce((p, c) => {
              if (c.total) {
                return p + c.total;
              }
              return 0;
            }, 0);
          }
          return prev + val;
        }, 0);
        value = Math.ceil(discount.value / order.items.length);
        if (value + total > discount.value) {
          value = discount.value - total;
        }
        break;
      }
      case 'percentageoffretail':
        value = Math.ceil(item.price * (discount.value * 0.01));
        break;
      case 'complimentary': {
        // We want to know the value of ONE bc we can comp a variable number.
        value = item.price / item.quantity;
        break;
      }
    }
    return value;
  }

  public setCompDiscount(order: any, discount: any, compItems: Array<any>): any {
    for (let i = 0; i < order.items.length; i++) {
      const item = order.items[i];
      if (compItems[i].compQuantity && this.canUseDiscount(item.item, discount)) {
        item.discounts = [];
        const value = this.calculateItemDiscount(item, order, discount) * compItems[i].compQuantity;
        item.discountTotal = value;
        item.discounts.push({
          id: discount._id,
          total: value,
        });
        item.taxes = this.processTaxes(item, item.item.taxCodes);
        item.taxTotal = this.getItemTaxTotal(item);
      }
    }
    return order;
  }

  public updateGratuityWithPercentage(charge: any, gratuity: any) {
    const gratValue = gratuity.value * 0.01;
    charge.gratuity = Math.ceil((charge.total - charge.gratuity) * gratValue);
    charge.amount = charge.subTotal - charge.discount + charge.taxTotal + charge.serviceFee;
    charge.total = charge.amount + charge.gratuity;
    return charge;
  }

  public updateGratuityWithAmount(charge: any, amount: number) {
    charge.gratuity = amount;
    charge.amount = charge.subTotal - charge.discount + charge.taxTotal + charge.serviceFee;
    charge.total = charge.amount + charge.gratuity;
    return charge;
  }

  private getSubTotal(order: any) {
    return order.items.reduce((total, item) => total + item.price, 0);
  }

  private getServiceFee(order: any) {
    if (order.isConsumerOrder && order.deliveryOption === 'delivery') {
      return this._SERVICE_FEE;
    }
    if (order.isConsumerOrder && order.deliveryOption === 'pickup') {
      return this._PICKUP_FEE;
    }
    return 0;
  }

  public processTaxes(item: any, taxes: Array<any>): Array<any> {
    const array = [];
    taxes.forEach(tax => {
      const rate = tax && tax.taxRate ? tax.taxRate : 0;
      const total = Math.ceil(rate * 0.01 * (item.price - item.discountTotal));
      array.push({
        id: tax,
        total,
      });
    });
    return array;
  }

  public getItemTaxTotal(item: any): number {
    const { taxes } = item;
    let total = 0;
    taxes.forEach((tax: any) => {
      total += tax.total;
    });
    return total;
  }

  private getVenueTax(order: any) {
    return order.items.reduce((total, item) => total + item.taxTotal, 0);
  }

  private getPartakeTax(restaurant: any, order: any) {
    let tax = 0;
    const salesTax =
      restaurant.salesTax && restaurant.salesTax.taxRate ? restaurant.salesTax.taxRate : 0;
    if (order.deliveryOption === 'delivery') {
      tax = Math.ceil(this._SERVICE_FEE * (salesTax * 0.01));
    } else if (order.deliveryOption === 'pickup') {
      tax = Math.ceil(this._PICKUP_FEE * (salesTax * 0.01));
    }
    return tax;
  }

  private getTaxTotal(order: any, restaurant: any) {
    return this.getVenueTax(order) + this.getPartakeTax(restaurant, order);
  }

  private getTotal(order: any) {
    return order.subTotal + order.serviceFee + order.taxTotal + order.gratuity - order.discount;
  }

  private getStripeFee(order: any, charge?: any) {
    if (order.paymentType === 'cash' || order.paymentType === 'member') {
      return 0;
    }
    if (charge && charge.paymentType === 'American Express') {
      return Math.ceil(order.total * this._AMEX_PERCENTAGE + this._FLAT_FEE);
    }
    return Math.ceil(order.total * this._STRIPE_PERCENTAGE + this._FLAT_FEE);
  }

  private getApplicationFee(order: any) {
    if (order.paymentType === 'cash') {
      return 0;
    }
    return Math.ceil((order.subTotal - order.discount) * this._APPLICATION_FEE);
  }

  private getTotalApplicationFee(order: any) {
    return order.applicationFee + order.serviceFee + order.partakeTax;
  }

  private getDiscountTotal(order: any) {
    return order.items.reduce((total, item) => {
      if (item.discountTotal) {
        return total + item.discountTotal;
      }
      return total;
    }, 0);
  }

  public calculateLineItems(order: any, restaurant: any, charge?: any) {
    order.subTotal = this.getSubTotal(order);
    order.discount = this.getDiscountTotal(order);
    order.venueTax = this.getVenueTax(order);
    order.serviceFee = this.getServiceFee(order);
    order.partakeTax = this.getPartakeTax(restaurant, order);
    order.taxTotal = this.getTaxTotal(order, restaurant);
    order.total = this.getTotal(order);
    order.due = order.total - order.amountPaid;
    order.stripeFee = this.getStripeFee(order, charge);
    order.applicationFee = this.getApplicationFee(order);
    order.totalApplicationFee = this.getTotalApplicationFee(order);
    return order;
  }

  public updateCosts(order: any, restaurant: any): any {
    this.calculateLineItems(order, restaurant);
    order.due = order.total;
    return order;
  }

  public defaultCart(venue: string, restaurant: string): any {
    return {
      // device: device,
      venue,
      restaurant,
      shift: this._shiftService.getShift(),
      isConsumerOrder: false,
      items: [],
      deliveryOption: 'fast-close',
      subTotal: 0,
      discount: 0,
      taxTotal: 0,
      partakeTax: 0,
      gratuity: 0,
      serviceFee: 0,
      total: 0,
      amountPaid: 0,
      due: 0,
      status: 'point-of-sale',
      user: null,
      guest: null,
      location: null,
      alcoholCount: 0,
      ageNotified: false,
      customer: null,
      token: null,
      paymentType: null,
      last4: null,
      isFirstOrder: false,
      redeemReward: false,
    };
  }

  public getTaxes(item: any, price: number, discount: number): Array<any> {
    const array = [];
    item.taxCodes.forEach((tax: any) => {
      const total = Math.ceil(tax.taxRate * 0.01 * (price - discount));
      array.push({
        id: tax,
        total,
      });
    });
    return array;
  }

  // eslint-disable-next-line max-len
  public getBaseItem(
    item: any,
    price: number,
    quantity: number,
    location: any,
    modifiers: Array<any>,
    taxes: Array<any>,
    discounts: Array<any>,
    instructions: string
  ) {
    return {
      item,
      restaurant: location._id,
      shift: null, // this._shiftService.getShift(),
      type: item.category.type,
      modifiers,
      modifierTotal: modifiers.reduce((prev, curr) => prev + curr.price, 0),
      taxes,
      taxTotal: taxes.reduce((prev, curr) => prev + curr.total, 0),
      discounts,
      discountTotal: discounts.reduce((prev, curr) => prev + curr.total, 0),
      isAlcoholic: item.isAlcoholic,
      instructions,
      price,
      quantity,
      isSaved: false,
      isPromo: !!item.promoActive,
      promoPrice: item.promoActive ? item.promoPrice : null,
      promoTag: item.promoActive ? item.promoTag : null,
      added: new Date(),
    };
  }
}
