<div class="col user-image">
  <img
    *ngIf="!user?.imgUrl"
    src="assets/images/users/1.jpg"
    alt="user"
    class="rounded-circle"
    width="75"
    height="75"
  />
  <img
    *ngIf="user?.imgUrl"
    [src]="user?.imgUrl"
    alt="user"
    class="rounded-circle"
    width="75"
    height="75"
  />
</div>
<div class="col text-center" *ngIf="user?.type === 'consumer'">
  <h4>{{ user?.name }}</h4>
  <p class="mb-2" *ngIf="user?.email">{{ user?.email }}</p>
</div>
<div class="col text-center" *ngIf="user?.type !== 'consumer'">
  <h4>No Guest Name</h4>
  <p class="mb-2">No Guest Email</p>
</div>
