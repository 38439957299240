import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';

import { environment } from '@env/environment';

import { Signup } from '@app/models/signup.model';
import { Login } from '@app/models/login.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private domain = environment.url;
  private baseUrl = `${this.domain}`;

  // For the Admin Component to listen for auth success to pull in the user profile
  private authStatusListener = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  signup(signup: Signup) {
    return this.http.post<any>(`${this.baseUrl}/signup`, signup);
  }

  login(login: Login) {
    return this.http.post<any>(`${this.baseUrl}/signin`, login);
  }

  // Save the auth token in local storage
  setToken(token?: string) {
    if (token) {
      localStorage.setItem('token', token);
      this.authStatusListener.next(true);
    } else {
      localStorage.removeItem('token');
      this.authStatusListener.next(false);
    }
  }

  // Get token from local storage
  // eslint-disable-next-line class-methods-use-this
  getToken() {
    const token = localStorage.getItem('token');
    return token;
  }

  /*
    Check if we are logged in
    - This is a cheap and dirty way of checking if we are logged in.
    - The backed needs to ensure that we are authorized access certain endpoints.
  */
  isAuthenticated() {
    const token = this.getToken();
    if (token) {
      return true;
    }
    return false;
  }
}
