import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ApiQuery } from '@app/clients';
import { ApiResources, ApiDomain } from '@app/enums';
import { BaseComponent } from '../base.component';
import { Func } from '../../../models';

@Component({
  selector: 'app-stats-resource-typeahead',
  templateUrl: './stats-resource-typeahead.component.html',
  styleUrls: ['./stats-resource-typeahead.component.scss'],
})
export class StatsResourceTypeaheadComponent extends BaseComponent implements OnChanges {
  @Input() value: any;
  @Input() resource: ApiResources;
  @Input() placeholder = 'Search';
  @Input() property = 'name';
  @Input() size = 'form-control';
  @Input() label: string;
  @Input() displayFilter: Func;
  @Input() showCreate = false;
  @Input() query: ApiQuery = {};
  @Input() clearOnSelect: boolean;
  @Input() id = 'stats-resource-typeahead';

  @Output() selected = new EventEmitter<any>();
  @Output() valueChange = new EventEmitter<any>();
  @Output() createProp = new EventEmitter();

  public search: string;

  constructor() {
    super();
    this.apiClient.setDomain(ApiDomain.STATS, null).setCacheTtl(1);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.value?.currentValue && changes?.value?.firstChange) {
      this.search = changes.value.currentValue[this.property];
    }
  }

  public select(selected: any) {
    if (selected?.item) {
      this.value = selected.item;
      this.search = this.value[this.property];
      this.selected.emit(this.value);
      this.valueChange.emit(this.value);
      if (this.clearOnSelect) {
        selected.preventDefault();
        this.value = null;
        this.search = null;
      }
    }
  }

  searchCollection = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        const query: ApiQuery = {
          ...this.query,
        };
        query.prop = this.property;
        query.search = value;
        return this.apiClient.get(query, this.resource).pipe(
          map((x: any) => x[this.resource]),
          catchError(() => of([]))
        );
      })
    );

  formatter = (value: any) => {
    if (value[this.property]) {
      return value[this.property];
    }
    return '';
  };

  create() {
    this.createProp.emit(true);
  }
}
