<input
  type="text"
  class="form-control"
  placeholder="Search modifiers"
  [disabled]="disabled"
  [ngbTypeahead]="searchModifier"
  [resultTemplate]="selectedProductTpl"
  [inputFormatter]="modifierFormatter"
  [(ngModel)]="value"
  (ngModelChange)="onChange($event)"
  (selectItem)="selectModifier($event)"
/>

<ng-template #selectedProductTpl let-r="result">
  {{ r.name }}
</ng-template>
