import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCard',
})
export class CreditCardPipe implements PipeTransform {
  transform(type: string): any {
    switch (type) {
      case 'American Express':
        return 'Amex';
      default:
        return type;
    }
  }
}
