import _ from 'lodash';
import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { ImageMetadata } from '@app/shared/components/image-filepicker';
import { IImageFile } from '@partake/interfaces';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent {
  @Input() label = 'Images';
  @Input() bucket = 'images';
  @Input() object: any;
  @Input() embeded = false;
  @Input() prop = 'images';

  @Output() objectChange = new EventEmitter<any>();

  @ViewChild('carousel') carousel: NgbCarousel;

  public onImageFileSelected(image: ImageMetadata) {
    const type = image.mime.split('/');
    if (!image.name) {
      image.name = `${this.object.name || this.bucket}.${_.last(type)}`;
    }
    // immediately display image preview in base64 format
    if (!this.object[this.prop]) {
      this.object[this.prop] = [image as IImageFile];
    } else {
      this.object[this.prop].push(image as IImageFile);
    }
  }

  public onImageFileUploaded(image: ImageMetadata) {
    const { length } = this.object[this.prop];
    this.object[this.prop].splice(length - 1, 1, image as IImageFile);
    console.log(this.object);
    this.objectChange.emit(this.object);
  }

  public setCurrentImage(ev: any) {
    this.carousel.pause();
  }

  public deleteImage() {
    const idx = Number(this.carousel.activeId);
    this.object[this.prop].splice(idx, 1);
    this.objectChange.emit(this.object);
  }
}
