import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-price-input',
  templateUrl: './price-input.component.html',
  styleUrls: ['./price-input.component.scss'],
})
export class PriceInputComponent implements OnChanges {
  @Input() value: any;
  @Input() disabled: boolean;

  @Output() valueChange = new EventEmitter();

  public tempPrice = '0.00';

  get price() {
    return this.tempPrice;
  }

  set price(val: string) {
    this.tempPrice = val;
    const newValue = Math.round(Number(val) * 100);
    this.value = newValue;
    this.valueChange.emit(this.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value?.currentValue && changes.value?.firstChange) {
      this.tempPrice = (changes.value.currentValue * 0.01).toFixed(2);
    }
  }

  clean() {
    this.tempPrice = (Math.round(+this.tempPrice * 100) * 0.01).toFixed(2);
  }
}
