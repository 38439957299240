<button
  [attr.class]="'btn loading-button btn-' + color + ' btn-' + size"
  [disabled]="loading || disabled"
  (click)="takeAction()"
>
  <ng-container *ngIf="loading">
    <div *ngIf="loading" class="spinner spinner-border text-light" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    {{ buttonText }}
  </ng-container>
</button>
