import { ApiDomain, ApiResources, ApiVersion } from '../../enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type } from '../schema';
import { Contestant, ContestantData } from './contestant.model';
import { Fixture, FixtureData } from './fixture.model';
import { Person, PersonData } from './person.model';

const MatchStatSchema: ISchema = {
  type: {
    type: Type.STRING,
    label: 'Type e.g. fantasyProjections',
    required: true,
  },
  value: {
    label: 'Value',
    type: Type.NUMBER,
  },
};

export const PersonMatchStatsSchema: ISchema = {
  playerId: {
    type: Type.TYPEAHEAD,
    label: 'Player',
    domain: ApiDomain.API,
    version: ApiVersion.V2,
    resource: ApiResources.PERSON,
    modelProp: 'matchName',
    displayFields: ['matchName', 'team.name'],
    dataProp: 'data.data',
    required: true,
  },
  contestantId: {
    type: Type.TYPEAHEAD,
    label: 'Team',
    domain: ApiDomain.API,
    version: ApiVersion.V2,
    resource: ApiResources.CONTESTANT,
    modelProp: 'name',
    displayFields: ['name', 'code'],
    dataProp: 'data.data',
    required: true,
  },
  fixtureId: {
    type: Type.TYPEAHEAD,
    label: 'Fixture',
    domain: ApiDomain.API,
    version: ApiVersion.V2,
    resource: ApiResources.FIXTURE,
    modelProp: 'description',
    displayFields: ['description', 'dateString', 'timeString'],
    dataProp: 'data.data',
    required: true,
  },
  position: {
    label: 'Position e.g. LF',
    type: Type.STRING,
    required: true,
  },
  formationPlace: {
    label: 'Formation Place e.g. 1 for batting position',
    type: Type.STRING,
  },
  projections: {
    label: 'Projections',
    type: Type.ARRAY,
    items: {
      type: Type.OBJECT,
      label: 'Projections',
      properties: MatchStatSchema,
    },
  },
  stats: {
    label: 'Stats',
    type: Type.ARRAY,
    items: {
      type: Type.OBJECT,
      label: 'Stats',
      properties: MatchStatSchema,
    },
  },
};

export interface MatchStat {
  type: string;
  value: number;
}

export interface PersonMatchStatsData extends AbstractModelData {
  playerId: string | Person | PersonData;
  contestantId: string | Contestant | ContestantData;
  fixtureId: string | Fixture | FixtureData;
  shirtNumber: number;
  position: string;
  positionSide?: string;
  formationPlace: string;
  stats: MatchStat[];
  projections: MatchStat[];
}

export class PersonMatchStats extends AbstractModel<PersonMatchStatsData> {
  constructor(public data: PersonMatchStatsData) {
    super(data);
  }
}
