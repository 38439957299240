<div class="table-responsive">
  <table class="table table-sm v-middle">
    <thead>
      <tr>
        <th>Domain</th>
        <th>CName</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of domains">
        <td>{{ item.hostname }}</td>
        <td>{{ item.cname }}</td>
        <td>{{ item.status }}</td>
      </tr>
      <tr *ngIf="!domains.length">
        <td colspan="4" class="text-center">No domains added yet</td>
      </tr>
    </tbody>
  </table>
</div>
