<input
  type="text"
  class="form-control"
  [(ngModel)]="item"
  [ngbTypeahead]="searchItem"
  placeholder="Search items"
  [resultTemplate]="selectedItemTpl"
  [inputFormatter]="itemFormatter"
  (selectItem)="selectItem($event)"
/>
<ng-template #selectedItemTpl let-r="result">
  {{ r.name }}&nbsp;&bull;&nbsp;{{ r.category.name }}
</ng-template>
