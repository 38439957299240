import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { Router } from '@angular/router';

import { UserService } from '@app/providers/user/user.service';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  constructor(
    private _userService: UserService,
    private _router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this._userService.logout();
          this._router.navigate(['/login']);
        }
        return throwError(error);
      })
    );
  }
}
