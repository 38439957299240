export enum OrgRight {
  ORG_POS_USER = 'org-pos-user',
  ORG_MANAGE_GAMES = 'org-manage-games',
  ORG_MANAGE_GUESTS = 'org-manage-guests',
  ORG_MANAGE_EVENTS = 'org-manage-events',
  ORG_MANAGE_VOUCHERS = 'org-manage-vouchers',
  ORG_MANAGE_ALERTS = 'org-manage-alerts',
  ORG_MANAGE_USERS = 'org-manage-users',
  ORG_MANAGE_TAXES = 'org-manage-taxes',
  ORG_MANAGE_TENDERS = 'org-manage-tenders',
  ORG_MANAGE_EMPLOYEES = 'org-manage-employees',
  ORG_MANAGE_POS_SETTINGS = 'org-manage-pos-settings',
  ORG_MANAGE_LOCATIONS = 'org-manage-locations',
  ORG_MANAGE_MENUS = 'org-manage-menus',
  ORG_MANAGE_MODIFIERS = 'org-manage-modifiers',
  ORG_MANAGE_DISCOUNTS = 'org-manage-discounts',
  ORG_MANAGE_GRATUITIES = 'org-manage-gratuities',
  ORG_MANAGE_DINING_OPTIONS = 'org-manage-dining-options',
  ORG_MANAGE_SERVICE_CHARGES = 'org-manage-service-charges',
  ORG_MANAGE_PRODUCTS = 'org-manage-products',
  ORG_MANAGE_INVENTORY = 'org-manage-inventory',
  ORG_MANAGE_INVENTORY_SETTINGS = 'org-manage-inventory-settings',
  ORG_MANAGE_TEE_SHEET = 'org-manage-tee-sheet',
  ORG_MANAGE_TEE_SHEET_SETTINGS = 'org-manage-tee-sheet-settings',
  ORG_MANAGE_INTEGRATIONS = 'org-manage-integrations',
  ORG_REFUNDING = 'org-refunding',
  ORG_REPORTING = 'org-reporting',
  ORG_MARKETING = 'org-marketing',
  ORG_CUSTOMER_SUPPORT = 'org-customer-support',
  ORG_ADMIN = 'org-admin',
  ORG_OWNER = 'org-super-admin',
}

export enum PartakeRight {
  PARTAKE_MANAGE_APPS = 'partake-manage-apps',
  PARTAKE_MANAGE_GAMES = 'partake-manage-games',
  PARTAKE_MANAGE_USERS = 'partake-manage-users',
  PARTAKE_MANAGE_INTEGRATIONS = 'partake-manage-integrations',
  PARTAKE_USERS = 'partake-user',
  PARTAKE_MARKETING = 'partake-marketing',
  PARTAKE_ACCOUNTS = 'partake-accounts',
  PARTAKE_SUPPORT = 'partake-accounts',
  PARTAKE_OPS = 'partake-ops',
  PARTAKE_ENG = 'partake-eng',
  PARTAKE_ADMIN = 'partake-admin',
}

export enum Right {
  ORG_POS_USER = 'org-pos-user',
  ORG_MANAGE_BILLING = 'org-manage-billing',
  ORG_MANAGE_GAMES = 'org-manage-games',
  ORG_MANAGE_GUESTS = 'org-manage-guests',
  ORG_MANAGE_EVENTS = 'org-manage-events',
  ORG_MANAGE_VOUCHERS = 'org-manage-vouchers',
  ORG_MANAGE_ALERTS = 'org-manage-alerts',
  ORG_MANAGE_USERS = 'org-manage-users',
  ORG_MANAGE_TAXES = 'org-manage-taxes',
  ORG_MANAGE_TENDERS = 'org-manage-tenders',
  ORG_MANAGE_EMPLOYEES = 'org-manage-employees',
  ORG_MANAGE_POS_SETTINGS = 'org-manage-pos-settings',
  ORG_MANAGE_LOCATIONS = 'org-manage-locations',
  ORG_MANAGE_MENUS = 'org-manage-menus',
  ORG_MANAGE_MODIFIERS = 'org-manage-modifiers',
  ORG_MANAGE_DISCOUNTS = 'org-manage-discounts',
  ORG_MANAGE_GRATUITIES = 'org-manage-gratuities',
  ORG_MANAGE_DINING_OPTIONS = 'org-manage-dining-options',
  ORG_MANAGE_SERVICE_CHARGES = 'org-manage-service-charges',
  ORG_MANAGE_PRODUCTS = 'org-manage-products',
  ORG_MANAGE_INVENTORY = 'org-manage-inventory',
  ORG_MANAGE_INVENTORY_SETTINGS = 'org-manage-inventory-settings',
  ORG_MANAGE_TEE_SHEET = 'org-manage-tee-sheet',
  ORG_MANAGE_TEE_SHEET_SETTINGS = 'org-manage-tee-sheet-settings',
  ORG_MANAGE_INTEGRATIONS = 'org-manage-integrations',
  ORG_REFUNDING = 'org-refunding',
  ORG_REPORTING = 'org-reporting',
  ORG_MARKETING = 'org-marketing',
  ORG_CUSTOMER_SUPPORT = 'org-customer-support',
  ORG_ADMIN = 'org-admin',
  ORG_OWNER = 'org-super-admin',

  PARTAKE_MANAGE_APPS = 'partake-manage-apps',
  PARTAKE_MANAGE_GAMES = 'partake-manage-games',
  PARTAKE_MANAGE_USERS = 'partake-manage-users',
  PARTAKE_MANAGE_INTEGRATIONS = 'partake-manage-integrations',
  PARTAKE_USERS = 'partake-user',
  PARTAKE_MARKETING = 'partake-marketing',
  PARTAKE_ACCOUNTS = 'partake-accounts',
  PARTAKE_SUPPORT = 'partake-accounts',
  PARTAKE_OPS = 'partake-ops',
  PARTAKE_ENG = 'partake-eng',
  PARTAKE_ADMIN = 'partake-admin',
}
