import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'discountValue',
})
export class DiscountValuePipe implements PipeTransform {
  transform(value: number, type: string): string {
    let val: string;
    switch (type) {
      case 'percentageoffretail':
        val = value ? `${value}%` : '0%';
        break;
      case 'complimentary':
        val = 'Complimentary';
        break;
      case 'fixedamountoffretail':
        val = value ? `${(value * 0.01).toFixed(2)}` : '$0.00';
        break;
      case 'costpluspercentage':
        val = value ? `${value}%` : '0%';
        break;
    }
    return val;
  }
}
