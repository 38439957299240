import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss'],
})
export class EmailTemplateComponent {
  @Input() template: string;
  @Input() title: string;

  @Output() templateChange = new EventEmitter<string>();

  public modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ list: 'ordered' }, { list: 'bullet' }],

      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme

      [{ align: [] }], // remove formatting button

      ['link', 'image'], // link and image, video
    ],
  };

  setContent(val: any) {
    console.log('set content', val);
    this.templateChange.emit(val);
  }
}
