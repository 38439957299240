import { OrderStatus, ApiResources, ApiVersion } from '../../enums';
import { EnumHelper } from '../../helpers';
import { ISchema, Type, Widget } from '../schema';

export const BaseReportProps: ISchema = {
  status: {
    section: 'basic',
    type: Type.STRING,
    label: 'State',
    required: true,
    widget: {
      type: Widget.SELECT,
    },
    default: [OrderStatus.ACCEPTED],
    enum: EnumHelper.getEnumArray(OrderStatus),
    multiple: true,
  },
  restaurant: {
    section: 'basic',
    type: Type.TYPEAHEAD,
    label: 'Location ',
    resource: ApiResources.LOCATION,
    version: ApiVersion.V2,
    modelProp: 'name',
    search: { isDeleted: false },
    displayFields: ['name', 'category.name'],
  },
  isFutureOrder: {
    section: 'basic',
    type: Type.BOOLEAN,
    label: 'Future Orders Only',
  },
  consumerOnly: {
    section: 'basic',
    type: Type.BOOLEAN,
    label: 'Online Only',
  },
  from: {
    section: 'basic',
    description:
      'The date the order was created. Unless Future Orders is selected, then it will be the selected order date.',
    type: Type.DATE,
    default: () => new Date(),
    label: 'Start Date',
    required: true,
  },
  to: {
    section: 'basic',
    type: Type.DATE,
    label: 'End Date',
    notIf: 'isFutureOrder',
  },
  email: {
    section: 'basic',
    type: Type.STRING,
    label: 'Email',
    description: 'If you want to send this to another person that isn\t yourself.',
  },
};
