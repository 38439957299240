import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-timezone-select',
  templateUrl: './timezone-select.component.html',
  styleUrls: ['./timezone-select.component.scss'],
})
export class TimezoneSelectComponent {
  @Input() timezone = moment.tz.guess();
  @Output() timezoneChange = new EventEmitter<string>();

  public timezones = moment.tz.names().filter(tz => _.size(tz.match(/^(US|America)/gi)));

  change() {
    this.timezoneChange.emit(this.timezone);
  }
}
