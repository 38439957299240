import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CartUtilService } from '@app/providers/cart/cart.util.service';
import { ApiClient } from '@app/clients';
import { ApiDomain, ApiVersion, ApiResources } from '@app/enums';

@Component({
  selector: 'app-payment-gratuity',
  templateUrl: './payment-gratuity.component.html',
  styleUrls: ['./payment-gratuity.component.scss'],
})
export class PaymentGratuityComponent implements OnInit {
  @Input() order: any;
  @Input() charge: any;

  private apiClient: ApiClient;

  public customGrat: number;
  public gratuityMap: any;
  public gratOptions = [
    { id: '15', name: '15%' },
    { id: '20', name: '20%' },
    { id: '25', name: '25%' },
    { id: 'other', name: 'Custom' },
  ];
  public selectedGratuity: string;

  constructor(
    http: HttpClient,
    private cartUtil: CartUtilService
  ) {
    this.apiClient = new ApiClient(http).setDomain(
      ApiDomain.API,
      ApiVersion.V1,
      ApiResources.GRATUITY_MAPS
    );
  }

  ngOnInit() {
    this.getGratuityMap();
  }

  private async getGratuityMap() {
    try {
      this.gratuityMap = await this.apiClient.self(null).toPromise();
    } catch (err) {
      console.log(err);
    }
  }

  public async setGratuity(id: string) {
    this.selectedGratuity = id;
    this.charge.gratuityId = this.gratuityMap[id];
    if (this.charge.gratuityId.type === 'percentage') {
      this.charge = this.cartUtil.updateGratuityWithPercentage(this.charge, this.charge.gratuityId);
    } else {
      this.setCustomGratuity();
    }
  }

  public setCustomGratuity() {
    if (this.customGrat && this.selectedGratuity === 'other') {
      this.charge = this.cartUtil.updateGratuityWithAmount(this.charge, this.customGrat);
    }
  }
}
