import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ApiResources, ApiVersion, PointOfSaleStatus, PointOfSaleType } from '../../enums';
import { ISchema, Type, Widget } from '../schema';
import { EnumHelper } from '../../helpers';

export const PointOfSaleSchema: ISchema = {
  name: {
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  status: {
    type: Type.STRING,
    label: 'Status',
    required: true,
    enum: EnumHelper.getEnumArray(PointOfSaleStatus),
    default: PointOfSaleStatus.ACTIVE,
    widget: {
      type: Widget.SELECT,
    },
  },
  deviceId: {
    type: Type.STRING,
    label: 'Device ID',
    required: true,
    immutable: true,
  },
  type: {
    type: Type.STRING,
    label: 'Type',
    required: true,
    immutable: true,
    enum: EnumHelper.getEnumArray(PointOfSaleType),
    default: PointOfSaleType.POS,
    widget: {
      type: Widget.SELECT,
    },
  },
  orgId: {
    type: Type.TYPEAHEAD,
    label: 'Org',
    required: true,
    // isHidden: true,
    immutable: true,
    resource: ApiResources.ORGS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
  },
  venueId: {
    type: Type.TYPEAHEAD,
    label: 'Venue',
    required: true,
    // isHidden: true,
    immutable: true,
    resource: ApiResources.VENUES,
    version: ApiVersion.V2,
    modelProp: 'name',
    search: { isDeleted: false },
  },
  shiftId: {
    type: Type.STRING,
    label: 'Active Shift',
    isHidden: true,
    immutable: true,
  },
  lastActive: {
    type: Type.DATE,
    label: 'Last Active',
    isHidden: true,
    immutable: true,
  },
  appVersion: {
    type: Type.STRING,
    label: 'App Version',
    immutable: true,
  },
  nativeAppVersion: {
    type: Type.STRING,
    label: 'Native App Version',
    immutable: true,
  },
};

interface CardReader {
  serialNumber: string;
  model: string;
  isConnected: boolean;
}

interface CashDrawer {
  port: string;
}

interface Printer {
  name: string;
  model: string;
  port: string;
  paperWidth: number;
  cashdrawerAttached: boolean;
  roles: string[];
  stations: string[];
}

export interface PointOfSaleData extends AbstractModelData {
  name: string;
  deviceId: string;
  type: PointOfSaleType;
  status: PointOfSaleStatus;
  orgId: string | Record<string, any>;
  venueId: string | Record<string, any>;
  shiftId: string | Record<string, any>;
  reader?: CardReader;
  cashDrawer?: CashDrawer;
  printers: Printer[];
  lastActive: Date;
  appVersion: string;
  nativeAppVersion: string;
  browser?: string;
  browserVersion?: string;
  device?: string;
  deviceVendor?: string;
  deviceModel?: string;
  os?: string;
  osVersion?: string;
}

export class PointOfSale extends AbstractModel<PointOfSaleData> {
  constructor(public data: PointOfSaleData) {
    super(data);
  }
}
