import { Component, EventEmitter, Input, Output } from '@angular/core';

import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';

import { MenuItemModifierService } from '@app/providers/restaurant/menu-item-modifier.service';
import { Observable, of } from 'rxjs';
import _ from 'underscore';

@Component({
  selector: 'app-modifier-option-search-typeahead',
  templateUrl: './modifier-option-search-typeahead.component.html',
  styleUrls: ['./modifier-option-search-typeahead.component.scss'],
})
export class ModifierOptionSearchTypeaheadComponent {
  @Input() value: string;
  @Input() clearOnSelect: boolean;
  @Input() set exclude(value: any) {
    if (value) {
      const values = value instanceof Array ? value : [value];
      const ids = _(values)
        .flatten()
        .map(x => x.product || x._id);
      this._exclude = _.compact(ids);
    } else {
      this._exclude = [];
    }
  }

  @Output() selected = new EventEmitter<any>();

  private _exclude = [];

  private noResultError = 'No Results';

  public selectedItem = null;

  constructor(private _menuItemModifierService: MenuItemModifierService) {}

  public selectItem(selected: any) {
    if (selected && selected.item?.item) {
      this.selectedItem = selected.item.item;
      this.selected.emit(selected.item);

      if (this.clearOnSelect) {
        this.value = '';
      }
    } else if (this.selectedItem) {
      this.selectedItem = null;
      this.selected.emit(null);
    }
    if (selected && !selected.item?.item) {
      selected.preventDefault();
      this.value = '';
      this.selectedItem = null;
    }
  }

  public onChange(value: any) {
    if (!value || typeof value === 'string') {
      this.selectItem(null);
    }
  }

  searchOptions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(name => {
        const query = _({
          name,
          limit: 20,
          isDeleted: false,
          exclude: this._exclude,
        }).pick(value => value !== undefined);
        return this._menuItemModifierService.searchOptions(query).pipe(
          map(x => {
            if (x.options?.length) {
              /**
               * @todo Support Nested Mods
               * For now we only support products
               */
              const filtered = x.options.filter(option => option.type === 'product');
              if (filtered.length) {
                return filtered;
              }
            }
            return [this.noResultError];
          }),
          catchError(() => of([this.noResultError]))
        );
      })
    );

  // eslint-disable-next-line class-methods-use-this
  optionFormatter = option => {
    if (typeof option === 'string') {
      return option;
    }
    if (option && option.item && option.item.name) {
      return `${option.item.name}`;
    }
    return '';
  };
}
