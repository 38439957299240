import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { ApiClient } from '../../clients';
import { ApiDomain, ApiHeader, ApiResources, ApiVersion } from '../../enums';
import { LocalStorage } from '../../helpers';
import { Org, OrgData } from '../../models';
import { OrgActions, retrievedOrgs } from './org.actions';

@Injectable()
export class OrgEffects {
  private apiClient: ApiClient;

  selectOrg$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrgActions.ORG_SELECTED),
        tap((payload: any) => {
          if (payload.selectedOrg) {
            LocalStorage.addItem(ApiHeader.ORG_ID, payload.selectedOrg.id);
          }
        })
      ),
    { dispatch: false }
  );

  loadOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.LOAD_ORGS),
      mergeMap(() =>
        this.apiClient.get({ limit: 1000 }).pipe(
          map((orgResult: any) => {
            if (orgResult.data?.total) {
              return retrievedOrgs({
                orgs: orgResult.data.data.map((orgData: OrgData) => new Org(orgData)),
              });
            }
            return retrievedOrgs({
              orgs: [],
            });
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    httpClient: HttpClient,
    private actions$: Actions
  ) {
    this.apiClient = new ApiClient(httpClient).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.ORGS
    );
  }
}
