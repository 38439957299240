/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChargeUtilService {
  constructor() {} // private _shiftService: ShiftService

  public defaultCharge(order: any, amount: number, defaultMember: any) {
    return {
      shift: null, // this._shiftService.getShift(),
      isConsumerOrder: order.isConsumerOrder,
      location: 'pos',
      tender: null,
      customer: null,
      token: null,
      order: order._id,
      subTotal: order.subTotal,
      discount: order.discount,
      serviceFee: order.serviceFee,
      partakeTax: order.partakeTax,
      venueTax: order.venueTax,
      taxTotal: order.taxTotal,
      applicationFee: order.totalApplicationFee,
      amount,
      total: amount,
      user: order.user,
      member: order.member ? order.member : defaultMember,
      transactionId: `${order.orderNumber}-1`,
    };
  }

  public defaultCustomCharge(order: any, amount: number) {
    return {
      shift: null, // this._shiftService.getShift(),
      isConsumerOrder: order.isConsumerOrder,
      location: 'pos',
      order: order._id,
      subTotal: amount,
      discount: 0,
      serviceFee: 0,
      partakeTax: 0,
      venueTax: 0,
      taxTotal: 0,
      applicationFee: 0,
      amount,
      total: amount,
      isFixed: true,
      tender: null,
      user: order.user,
    };
  }

  public reduceFixed(charges: Array<any>) {
    return charges.reduce(
      (prev, curr) => {
        if (curr.isFixed) {
          prev.total += curr.total;
          prev.count++;
          return prev;
        }
        return prev;
      },
      { count: 0, total: 0 }
    );
  }

  public processCharges(charges: Array<any>, order: any, defaultMember: any, option: any) {
    const newCharges = [];
    const fixed = this.reduceFixed(charges);
    const len =
      option.value + fixed.count >= charges.length ? option.value + fixed.count : charges.length;
    const fn = option.value % 2 === 0 ? Math.round : Math.floor;
    charges.sort((a, b) => {
      if (!a.isPaid && b.isPaid) {
        return 1;
      }
      if (a.isPaid && !b.isPaid) {
        return -1;
      }
      return 0;
    });
    for (let i = 0; i < len; i++) {
      const current =
        charges.length - 1 >= i ? charges[i] : this.defaultCharge(order, 0, defaultMember);
      current.transactionId = `${order.orderNumber}-${i + 1}`;
      current.location = 'pos';
      if (i < option.value - 1 + fixed.count && !current.isPaid && !current.isFixed) {
        current.subTotal = fn((order.subTotal - fixed.total) / option.value);
        current.discount = fn(order.discount / option.value);
        current.serviceFee = fn(order.serviceFee / option.value);
        current.partakeTax = fn(order.partakeTax / option.value);
        current.venueTax = fn(order.venueTax / option.value);
      } else if (i === option.value - 1 + fixed.count && !current.isPaid && !current.isFixed) {
        current.subTotal =
          order.subTotal - newCharges.reduce((prev, curr) => prev + curr.subTotal, 0);
        current.discount =
          order.discount - newCharges.reduce((prev, curr) => prev + curr.discount, 0);
        current.serviceFee =
          order.serviceFee - newCharges.reduce((prev, curr) => prev + curr.serviceFee, 0);
        current.partakeTax =
          order.partakeTax - newCharges.reduce((prev, curr) => prev + curr.partakeTax, 0);
        current.venueTax =
          order.venueTax - newCharges.reduce((prev, curr) => prev + curr.venueTax, 0);
      } else if (!current.isPaid && !current.isFixed) {
        current.subTotal = 0;
        current.discount = 0;
        current.serviceFee = 0;
        current.partakeTax = 0;
        current.venueTax = 0;
      }
      current.taxTotal = current.partakeTax + current.venueTax;
      current.amount = current.subTotal + current.serviceFee + current.taxTotal - current.discount;
      current.total = current.amount + (current.gratuity || 0);
      current.isConsumerOrder = order.isConsumerOrder;
      newCharges.push(current);
    }
    return newCharges;
  }
}
