import { IProperties, ISchema, Type } from '../schema';

export const ICustomPageSettings: ISchema = {
  page: {
    type: Type.STRING,
    label: 'Page',
    required: true,
  },
  title: {
    type: Type.STRING,
    label: 'Title',
  },
};

export const ICustomPage: ISchema = {
  name: {
    type: Type.STRING,
    label: 'Page Name',
    required: true,
  },
  settings: {
    type: Type.OBJECT,
    label: 'Settings',
    properties: ICustomPageSettings,
  },
};

export const ICustomPages: IProperties = {
  type: Type.ARRAY,
  label: 'Pages',
  items: {
    type: Type.OBJECT,
    label: null,
    properties: ICustomPage,
  },
};
