import { State, ApiResources, ApiVersion, Trigger } from '@app/enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { EnumHelper } from '../../helpers';

export const EmailMetaSchema: ISchema = {
  sends: {
    type: Type.NUMBER,
    label: 'Sends',
    default: 0,
    immutable: true,
  },
  opens: {
    type: Type.NUMBER,
    label: 'Opens',
    default: 0,
    immutable: true,
  },
  clicks: {
    type: Type.NUMBER,
    label: 'Clicks',
    default: 0,
    immutable: true,
  },
};

export const EmailSchema: ISchema = {
  id: {
    label: 'ID',
    type: Type.NUMBER,
    isHidden: true,
    immutable: true,
    partakeOnly: true,
  },
  name: {
    section: 'basic',
    type: Type.STRING,
    required: true,
    label: 'Name',
  },
  fromEmail: {
    section: 'basic',
    type: Type.STRING,
    required: true,
    label: 'From Email',
  },
  subject: {
    section: 'basic',
    type: Type.STRING,
    required: true,
    label: 'Subject',
  },
  state: {
    section: 'basic',
    type: Type.STRING,
    label: 'State',
    required: true,
    default: State.DRAFT,
    widget: { type: Widget.SELECT },
    enum: EnumHelper.getEnumArray(State),
  },
  trigger: {
    section: 'basic',
    type: Type.STRING,
    label: 'Event Trigger',
    required: true,
    default: State.DRAFT,
    widget: { type: Widget.SELECT },
    enum: EnumHelper.getEnumArray(Trigger),
  },

  whiteLabelId: {
    section: 'Pointers',
    type: Type.TYPEAHEAD,
    partakeOnly: true,
    label: 'White Label',
    resource: ApiResources.WHITE_LABELS,
    version: ApiVersion.V2,
    modelProp: 'name',
    search: { isDeleted: false },
    dataProp: 'data.data',
    displayFields: ['name', 'domain', 'account.name', 'account.email'],
  },
  locationId: {
    section: 'Pointers',
    type: Type.TYPEAHEAD,
    label: 'Location ',
    resource: ApiResources.LOCATION,
    version: ApiVersion.V2,
    modelProp: 'name',
    search: { isDeleted: false },
    displayFields: ['name', 'venue.name', 'category.name'],
  },
  orgId: {
    section: 'Pointers',
    type: Type.TYPEAHEAD,
    label: 'Org',
    partakeOnly: true,
    immutable: true,
    resource: ApiResources.ORGS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    notIf: 'whiteLabelId',
  },

  html: {
    section: 'content',
    type: Type.LONG_STRING,
    widget: {
      type: Widget.LONG_STRING,
      lines: 30,
      showPreview: true,
    },
    label: 'HTML',
    description: 'Provide a full offer description with terms for the user to view.',
  },

  meta: {
    section: 'meta',
    type: Type.OBJECT,
    label: 'Meta',
    properties: EmailMetaSchema,
    isHidden: true,
  },
};

export interface IEmailMeta {
  sends: number;
  opens: number;
  clicks: number;
}

export interface IEmail extends AbstractModelData {
  id: number;
  state: State;
  trigger: Trigger;
  name: string;
  subject: string;
  html: string;
  text: string;
  meta: IEmailMeta;
  whiteLabelId: string;
  locationId: string;
  orgId?: string;
  isDeleted?: boolean;
}

export class Email extends AbstractModel<IEmail> {
  constructor(public data: IEmail) {
    super(data);
  }
}
