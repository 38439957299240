import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.scss'],
})
export class CancelButtonComponent {
  constructor(private _location: Location) {}

  public cancel() {
    this._location.back();
  }
}
