// eslint-disable-next-line @typescript-eslint/no-unused-vars
import $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as Sentry from '@sentry/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { DragulaModule } from 'ng2-dragula';

import { PipesModule } from '@app/pipes/pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthInterceptor } from './authentication/auth-interceptor';
import { AuthErrorInterceptor } from './authentication/auth-error-interceptor';

import { LayoutModule } from './layouts/layout.module';
import { SharedModule } from './shared/shared.module';
import { appEffects, rootReducer } from './state';
import { SearchComponent } from './search/search/search.component';

@NgModule({
  declarations: [AppComponent, SearchComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(Approutes, {
      useHash: false,
      enableTracing: false,
    }),
    DragulaModule.forRoot(),
    StoreModule.forRoot(rootReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot(appEffects),
    LayoutModule,
    SharedModule,
    PipesModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
