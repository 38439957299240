import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-json-modal',
  templateUrl: './view-json-modal.component.html',
  styleUrls: ['./view-json-modal.component.scss'],
})
export class ViewJsonModalComponent {
  public json: any;

  public expanded = true;

  constructor(public modal: NgbActiveModal) {}
}
