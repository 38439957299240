import _ from 'lodash';

export class ImageHelper {
  public static getImage(entity: any, size: string, prop = 'images') {
    const images = _.get(entity, prop) || [];
    if (_.size(images)) {
      const foundImage = images.find(image => image.size === size);
      if (foundImage) {
        return _.get(foundImage, 'url');
      }
    }
    return entity.imageUrl;
  }
}
