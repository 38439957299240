import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ApiQuery, ApiQueryParams } from '@app/clients';

@Injectable({ providedIn: 'root' })
export class DiscountService {
  private domain = environment.url_v2;
  private path = 'discount';
  private baseUrl = `${this.domain}/${this.path}`;

  constructor(private _http: HttpClient) {}

  public create(body: any): Observable<any> {
    return this._http.post(`${this.baseUrl}`, body);
  }

  public find(query?: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this._http.get(`${this.baseUrl}`, { params });
  }

  public self(query?: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this._http.get(`${this.baseUrl}/self`, { params });
  }

  public findOne(id: string): Observable<any> {
    return this._http.get(`${this.baseUrl}/${id}`);
  }

  public update(id: string, body: any): Observable<any> {
    return this._http.put(`${this.baseUrl}/${id}`, body);
  }

  public updateMappings(body: any): Observable<any> {
    return this._http.put(`${this.baseUrl}/update-mappings`, body);
  }
}
