import { ApiDomain, ApiVersion, ApiResources } from '../../enums';
import { ISchema, Type, Widget } from '../schema';

export const DataUploadSchema: ISchema = {
  integration: {
    label: 'Data Source',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
    },
    enum: [
      {
        key: 'fantasy-data',
        name: 'Fantasy Data',
      },
    ],
    default: 'fantasy-data',
    isHidden: true,
  },
  dataType: {
    label: 'Data Type',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
    },
    enum: [
      {
        key: 'fantasy-projections',
        name: 'Fantasy Projections',
      },
      {
        key: 'odds',
        name: 'Betting Odds',
      },
    ],
    required: true,
  },
  tournamentCalendarId: {
    label: 'Season',
    type: Type.TYPEAHEAD,
    domain: ApiDomain.API,
    version: ApiVersion.V2,
    resource: ApiResources.TOURNAMENT_CALENDAR,
    modelProp: 'name',
    displayFields: ['name', 'competition.name'],
    dataProp: 'data.data',
    required: true,
  },
  date: {
    label: 'Date',
    type: Type.DATE,
    default: () => new Date(),
    required: true,
  },
  file: {
    label: 'JSON File',
    type: Type.FILE,
    required: true,
  },
};
