import { ISchema } from '../schema';
import { MenuItemSchema } from './menu-item';

export const IUpdateManyItemProps: ISchema = {
  name: MenuItemSchema.name,
  description: MenuItemSchema.description,
  isPublic: MenuItemSchema.isPublic,
  stations: MenuItemSchema.stations,
  images: MenuItemSchema.images,
};
