/* eslint-disable @typescript-eslint/naming-convention */
export enum VenueType {
  GOLF_CLUB = 'golf-club',
  ARENA = 'arena',
  FOOD_TRUCK = 'food-truck',
  RESTAURANT = 'restaurant',
  HOTEL = 'hotel',
  AIRPORT = 'airport',
}

export enum PromptForMember {
  NONE = 'none',
  START_OF_ORDER = 'start-of-order',
  START_OF_TRANSACTION = 'start-of-transaction',
}
