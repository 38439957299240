import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shiftAccepting',
})
export class ShiftAcceptingPipe implements PipeTransform {
  transform(restaurant: any): string {
    const accepting = [];
    if (!restaurant) {
      return null;
    }

    if (restaurant.deliveryOptions && restaurant.deliveryOptions.delivery) {
      accepting.push('Delivery');
    }
    if (restaurant.deliveryOptions && restaurant.deliveryOptions.pickup) {
      accepting.push('Pickup');
    }
    if (restaurant.alcoholEnabled) {
      accepting.push('Alcohol');
    }

    return accepting.join(', ');
  }
}
