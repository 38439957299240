import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appRate',
})
export class AppRatePipe implements PipeTransform {
  transform(config: any, args?: any): any {
    if (!config) {
      return 'TBC';
    }
    return `${config.applicationFee * 100}%`;
  }
}
