import { Observable } from 'rxjs';

/**
 * @todo Move to Partake Core
 */
export interface ICacheRequest {
  url: string;
  request: Observable<any>;
  ttl: number; // Time to live
  cached: number;
}

export default class CacheClient {
  private static _cacheClient: CacheClient;
  private _cache: Array<ICacheRequest> = [];

  private constructor() {}

  public static getInstance() {
    if (!this._cacheClient) {
      this._cacheClient = new CacheClient();
    }
    return this._cacheClient;
  }

  public getRequest<T>(url: string): Observable<T> {
    const cachedRequestIdx = this._cache.findIndex((request: ICacheRequest) => request.url === url);
    if (cachedRequestIdx > -1) {
      const cachedRequest = this._cache.splice(cachedRequestIdx, 1)[0];
      const duration = Date.now() - cachedRequest.cached;
      if (duration < cachedRequest.ttl * 1000) {
        this._cache.unshift(cachedRequest);
        if (this._cache.length > 100) {
          this._cache = this._cache.slice(0, 100);
        }
        return cachedRequest.request;
      }
    }
    return null;
  }

  public cacheRequest(request: ICacheRequest): boolean {
    this._cache.push(request);
    return true;
  }

  public getCache() {
    return this._cache;
  }

  public invalidateCache() {
    this._cache = [];
  }
}
