import { Pipe, PipeTransform } from '@angular/core';

import { PhoneCountryCodeService } from '@app/providers/phone/phone.country-codes.service';

import 'intl-tel-input';
import 'intl-tel-input/build/js/utils';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  private _intlTelUtils: any;

  constructor(private _countryCodesService: PhoneCountryCodeService) {}

  transform(number: string, userIso?: string): string {
    let phone = number;
    let iso = userIso || 'US';
    if (iso && iso.charAt(0) === '+') {
      const country = this._countryCodesService.getCountry(iso);
      iso = country.iso2;
    }

    if (window.intlTelInputUtils) {
      this._intlTelUtils = window.intlTelInputUtils;
      phone = this._intlTelUtils.formatNumber(
        number,
        iso,
        this._intlTelUtils.numberFormat.NATIONAL
      );
    }

    return phone;
  }
}
