import { ISchema, Type } from '../schema';

export const RequestPasswordResetSchema: ISchema = {
  email: {
    label: 'Email',
    type: Type.EMAIL,
    required: true,
  },
};

export interface RequestPasswordResetData {
  email: string;
}
