import { createAction, props } from '@ngrx/store';

export enum LocationActions {
  LOAD_LOCATIONS = '[Location] Load Locations',
  LOAD_LOCATIONS_SUCCESS = '[Location] Load Location Success',
}

export const retrievedLocations = createAction(
  LocationActions.LOAD_LOCATIONS_SUCCESS,
  props<{ locations: any[] }>()
);
