import { createAction, props } from '@ngrx/store';
import { Venue } from '../../models';

export enum VenueActions {
  VENUE_SELECTED = 'Venue Selected',
}

export const selectedVenue = createAction(
  VenueActions.VENUE_SELECTED,
  props<{ selectedVenue: Venue }>()
);
