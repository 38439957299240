import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card-on-file',
  templateUrl: './card-on-file.component.html',
  styleUrls: ['./card-on-file.component.scss'],
})
export class CardOnFileComponent {
  @Input() charge: any;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() complete = new EventEmitter<any>();

  constructor() {}

  public completePayment() {
    this.complete.emit();
  }
}
