import { IImageFile } from '@partake/interfaces';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { EnumHelper } from '../../helpers';
import {
  ApiDomain,
  ApiResources,
  ApiVersion,
  DiningOptionUsageType,
  PricingStrategy,
  ProductType,
} from '../../enums';
import { PriceOptionData, PriceOptionSchema } from '../products';
import { DiningOptionData } from './dining-option';
import { LocationData } from './location';
import { MenuItemStationData } from './menu-item-station';

export interface MenuItemSchemaData {
  restaurant?: LocationData['_id'] | LocationData;
  product: string | Record<string, any>;
  name: string;
  posName: string;
  description: string;
  isOpen: boolean;
  pricingStrategy: PricingStrategy;
  priceOptions: PriceOptionData[];
  diningOptionUsageType: DiningOptionUsageType;
  diningOptions: DiningOptionData['_id'][] | DiningOptionData[];
  stations: MenuItemStationData['_id'][] | MenuItemStationData[];
  discounts: string[] | any[];
  isPublic: boolean;
  images: IImageFile[];
}

export const MenuItemSchema: ISchema<MenuItemSchemaData> = {
  restaurant: {
    section: 'Basic Info',
    label: 'Location',
    type: Type.STRING,
    description: 'Select the location for the menu item',
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        version: ApiVersion.V2,
        resource: ApiResources.LOCATION,
        modelProp: 'name',
        search: { isDeleted: false },
        displayFields: ['name'],
      },
    },
    reloadOnChange: true,
  },
  product: {
    section: 'Basic Info',
    label: 'Product',
    type: Type.TYPEAHEAD,
    description: 'Select the product for menu item',
    resource: ApiResources.PRODUCT,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    search: {
      isDeleted: false,
      type: [ProductType.MENU_ITEM, ProductType.MODIFIER].join(','),
    },
    displayFields: ['name', 'category.name'],
    onChange: (product: any, data: MenuItemData) => {
      data.name = product?.name;
    },
  },
  name: {
    section: 'Basic Info',
    description: 'If different from the products name',
    type: Type.STRING,
    label: 'Name',
  },
  description: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Description',
  },
  posName: {
    type: Type.STRING,
    label: 'POS Name',
    isHidden: true,
  },
  isOpen: {
    section: 'Pricing',
    type: Type.BOOLEAN,
    label: 'Open Price',
    description: 'Price is set at time of adding to the order. Open items only display in the POS.',
    default: false,
  },
  pricingStrategy: {
    section: 'Pricing',
    type: Type.STRING,
    label: 'Pricing Strategy',
    enum: EnumHelper.getEnumArray(PricingStrategy),
    widget: {
      type: Widget.SELECT,
    },
    default: PricingStrategy.INHERIT,
    notIf: 'openPrice',
    onChange: (pricingStrategy: PricingStrategy, data: MenuItemData) => {
      if (PricingStrategy.INHERIT === pricingStrategy) {
        data.priceOptions = [];
      }
    },
  },
  priceOptions: {
    section: 'Pricing',
    type: Type.ARRAY,
    label: 'Price Options',
    description:
      'Set Price Options if you wish to override the defaults that come from the products',
    items: {
      type: Type.OBJECT,
      label: null,
      properties: PriceOptionSchema,
    },
    onlyIf: [
      {
        field: 'pricingStrategy',
        shouldShow: (strategy: PricingStrategy) => PricingStrategy.INHERIT !== strategy,
      },
    ],
  },
  diningOptionUsageType: {
    section: 'Dining Option Selection',
    type: Type.STRING,
    label: 'Dining Options Types',
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(DiningOptionUsageType),
    required: true,
  },
  diningOptions: {
    section: 'Dining Option Selection',
    type: Type.ARRAY,
    label: 'Dining Options',
    widget: {
      type: Widget.SELECT,
      enum: [],
      multiple: true,
      options: {
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.DINING_OPTIONS,
        modelProp: 'name',
        dataProp: 'data',
        search: { isDeleted: false },
        displayFields: ['name'],
      },
    },
    onlyIf: [
      {
        field: 'diningOptionUsageType',
        shouldShow: (value: any) =>
          [
            DiningOptionUsageType.EXCLUDE_DINING_OPTIONS,
            DiningOptionUsageType.INCLUDE_DINING_OPTIONS,
          ].includes(value),
      },
    ],
  },
  stations: {
    section: 'Station Selection',
    type: Type.ARRAY,
    label: 'Stations',
    widget: {
      type: Widget.SELECT,
      multiple: true,
      enum: [],
      options: {
        resource: ApiResources.MENU_ITEM_STATION,
        version: ApiVersion.V2,
        modelProp: 'name',
        dataProp: 'data.data',
      },
    },
  },
  discounts: {
    section: 'Dining Option Selection',
    type: Type.ARRAY,
    label: 'Discounts',
    isHidden: true,
  },
  isPublic: {
    section: 'Pricing',
    type: Type.BOOLEAN,
    label: 'Available for Purchase',
  },
  images: {
    section: 'images',
    type: Type.IMAGES,
    label: 'Item Images',
    bucket: 'menu-item',
  },
};

export type MenuItemData = MenuItemSchemaData & AbstractModelData;

export class MenuItem extends AbstractModel<MenuItemData> {
  constructor(public data: MenuItemData) {
    super(data);
  }
}
