import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { ConfirmModalComponent } from './confirm-modal.component';

interface ConfirmModalOptions {
  text: string;
  title?: string;
  buttons?: {
    hideConfirm?: boolean;
    confirmText?: string;
    cancelText?: string;
  };
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmModalController {
  constructor(private modalService: NgbModal) {}

  public showModal(options: ConfirmModalOptions): Promise<boolean> {
    const { text, title, buttons, size = 'sm' } = options;
    return new Promise<boolean>(resolve => {
      const modal = this.modalService.open(ConfirmModalComponent, {
        centered: true,
        size,
        backdrop: 'static',
      });
      modal.componentInstance.text = text;
      modal.componentInstance.title = title;
      if (buttons) {
        const { hideConfirm, confirmText, cancelText } = buttons;
        if (hideConfirm) {
          modal.componentInstance.hideConfirm = hideConfirm;
        }
        if (confirmText) {
          modal.componentInstance.confirmText = confirmText;
        }
        if (cancelText) {
          modal.componentInstance.cancelText = cancelText;
        }
      }
      modal.result.then(
        confirm => {
          resolve(confirm);
        },
        reason => {
          resolve(false);
        }
      );
    });
  }
}
