import _ from 'lodash';
import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ImageMetadata } from '@app/shared/components/image-filepicker';
import { IImageFile } from '@partake/interfaces';

@Component({
  selector: 'app-meta-data',
  templateUrl: './meta-data.component.html',
  styleUrls: ['./meta-data.component.scss'],
})
export class MetaDataComponent implements OnChanges {
  @Input() metaData: any;
  @Output() metaDataChange = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.metaData?.currentValue) {
      this.metaData = {};
    }
  }

  change() {
    this.metaDataChange.emit(this.metaData);
  }

  public onImageFileSelected(prop: string, image: ImageMetadata) {
    const type = image.mime.split('/');
    image.name = `${prop}.${_.last(type)}`;
    this.metaData[prop] = image as IImageFile;
  }

  public onImageFileUploaded(prop: string, image: ImageMetadata) {
    this.metaData[prop] = image as IImageFile;
    delete image.preview;
    this.change();
  }
}
