import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { TimeHelper } from '../../helpers';

export interface TimeRange {
  startHour: number;
  startMinute: number;
  openTimeString: string;
  endHour: number;
  endMinute: number;
  closeTimeString: string;
}

export interface DayPartSchemaData {
  name: string;
  monday: TimeRange | null;
  tuesday: TimeRange | null;
  wednesday: TimeRange | null;
  thursday: TimeRange | null;
  friday: TimeRange | null;
  saturday: TimeRange | null;
  sunday: TimeRange | null;
}

const TimeRangeSchema: ISchema<TimeRange> = {
  startHour: {
    type: Type.NUMBER,
    label: 'Start Hour',
    isHidden: true,
  },
  startMinute: {
    type: Type.NUMBER,
    label: 'Start Minute',
    isHidden: true,
  },
  openTimeString: {
    type: Type.STRING,
    label: 'Open',
    side: 'left',
    widget: {
      type: Widget.SELECT,
      enum: [{ name: 'Closed', key: null }, ...TimeHelper.getTimePeriodsWithTimeGap(15)],
    },
    onChange: (openTimeString: string, data: TimeRange) => {
      const [hour, minute] = (openTimeString || '').split(':');
      data.startHour = hour ? parseInt(hour, 10) : null;
      data.startMinute = minute ? parseInt(minute, 10) : null;
      if (!data.closeTimeString) {
        data.closeTimeString = openTimeString;
        data.endHour = data.startHour;
        data.endMinute = data.startMinute;
      }
      if (!openTimeString) {
        data.closeTimeString = null;
        data.endHour = null;
        data.endMinute = null;
      }
    },
  },
  endHour: {
    type: Type.NUMBER,
    label: 'End Hour',
    isHidden: true,
  },
  endMinute: {
    type: Type.NUMBER,
    label: 'End Minute',
    isHidden: true,
  },
  closeTimeString: {
    type: Type.STRING,
    label: 'Close',
    side: 'right',
    widget: {
      type: Widget.SELECT,
      enum: [{ name: 'Closed', key: null }, ...TimeHelper.getTimePeriodsWithTimeGap(15)],
    },
    onlyIf: [
      {
        field: 'openTimeString',
        shouldShow: (openTime: string | null) => !!openTime,
      },
    ],
    onChange: (closeTimeString: string, data: TimeRange) => {
      const [hour, minute] = (closeTimeString || '').split(':');
      data.endHour = hour ? parseInt(hour, 10) : null;
      data.endMinute = minute ? parseInt(minute, 10) : null;
      if (!closeTimeString) {
        data.openTimeString = null;
        data.startHour = null;
        data.startMinute = null;
      }
    },
  },
};

export const DayPartSchema: ISchema<DayPartSchemaData> = {
  name: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Name',
    description: [
      'Set time period from 12am to 12am to always be open.',
      'A close time that is less than the open time will keep the menu open until that time the next day.',
      '\nE.g. 9am to 3am will keep the menu active until 3am the next day',
    ].join(' '),
  },
  monday: {
    type: Type.OBJECT,
    label: 'Monday',
    properties: TimeRangeSchema,
  },
  tuesday: {
    type: Type.OBJECT,
    label: 'Tuesday',
    properties: TimeRangeSchema,
  },
  wednesday: {
    type: Type.OBJECT,
    label: 'Wednesday',
    properties: TimeRangeSchema,
  },
  thursday: {
    type: Type.OBJECT,
    label: 'Thursday',
    properties: TimeRangeSchema,
  },
  friday: {
    type: Type.OBJECT,
    label: 'Friday',
    properties: TimeRangeSchema,
  },
  saturday: {
    type: Type.OBJECT,
    label: 'Saturday',
    properties: TimeRangeSchema,
  },
  sunday: {
    type: Type.OBJECT,
    label: 'Sunday',
    properties: TimeRangeSchema,
  },
};

export type DayPartData = DayPartSchemaData & AbstractModelData;

export class DayPart extends AbstractModel<DayPartData> {
  constructor(public data: DayPartData) {
    super(data);
  }
}
