import { GuestData } from '../crm';
import { TeeTimeBookingStatus } from '../../enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { TeeTimeData } from './tee-time';
import { CourseData } from './course';

export interface TeeTimeBookingData extends AbstractModelData {
  bookingId: number;
  status: TeeTimeBookingStatus;
  guestId: string;
  guest: GuestData;
  teeTimeId: string;
  teeTime: Pick<
    TeeTimeData,
    'dateTime' | 'formattedTime' | 'formattedTimeMeridian' | 'timeHour' | 'timeMinute'
  >;
  orgId: string;
  venueId: string;
  courseId: string;
  course: Pick<CourseData, 'name'>;
  userId?: string;
  orderId?: string;
  order?: Record<string, any>;
}

export class TeeTimeBooking extends AbstractModel<TeeTimeBookingData> {
  constructor(public data: TeeTimeBookingData) {
    super(data);
  }
}
