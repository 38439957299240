import { ExportType, ReportType, UserRoles } from '@app/enums';
import { PdfHelper, StringHelper } from '../../helpers';
import { PdfContent, PdfSectionCreateParams } from '../../helpers/pdf.helper';
import { ISchema, Type, Widget } from '../schema';
import { Report, ReportPeriodParams } from './report';
import { SalesReportResponseData } from './report-export-summaries';
import {
  getReportPeriod,
  getReportSectionOptions,
  getSectionFromName,
  PrintedAt,
} from './report-sections';

export const CustomReportSchema: ISchema = {
  from: {
    section: 'basic',
    description: 'The starting period',
    type: Type.DATE,
    default: () => new Date(),
    label: 'Start Date',
    required: true,
  },
  to: {
    section: 'basic',
    description: 'The end of a the period',
    type: Type.DATE,
    label: 'End Date',
  },
  title: {
    section: 'basic',
    type: Type.STRING,
    label: 'Report Title',
    required: true,
  },
  sections: {
    section: 'sections',
    label: 'Report Sections',
    description: 'Select sections required for the report.',
    type: Type.ARRAY,
    required: true,
    items: {
      label: 'Select Section',
      type: Type.STRING,
      widget: {
        type: Widget.SELECT,
        enum: getReportSectionOptions().map(report => ({
          key: report.label,
          name: report.label,
        })),
      },
    },
  },
};

export interface CustomReportData {
  from: string;
  to: string;
  title: string;
  sections: string[];
}

export class CustomReport extends Report<CustomReportData> {
  ignoreProperties = ['title', 'sections'];
  icon = 'fas fa-drafting-compass';
  title = 'Custom Report';
  description = 'Select all the sections which you want for the report.';
  data: CustomReportData = {} as CustomReportData;
  isImmediate = true;
  schema: ISchema = CustomReportSchema;
  roles: UserRoles[] = [UserRoles.ADMIN, UserRoles.CUSTOMER];
  type: ReportType = ReportType.SALES;
  exportOptions: ExportType[] = [ExportType.PDF];

  pdfSectionCreateParams: PdfSectionCreateParams<SalesReportResponseData>[] = [];

  public generateExport(period: ReportPeriodParams, responseData: SalesReportResponseData): void {
    const { sections, title, ...periodInfo } = period;
    const reportSections = [
      ...this.pdfSectionCreateParams,
      ...sections.map(sectionLabel => getSectionFromName(sectionLabel)),
    ];
    const pdfContent: PdfContent = PdfHelper.generateContent<SalesReportResponseData>(
      title,
      [PrintedAt, getReportPeriod(periodInfo, true)].concat(reportSections),
      responseData
    );
    const periodAsString = [period.from, period.to].filter(val => !!val).join('_');
    PdfHelper.generatePdf(pdfContent, `${StringHelper.slug(title)}_${periodAsString}`);
  }
}
