import { Pipe, PipeTransform } from '@angular/core';
import tinycolor from 'tinycolor2';
import { GreenFeeRateData, TeeTimeRate } from '../models';

@Pipe({
  name: 'teeTimeRateColor',
})
export class TeeTimeRateColorPipe implements PipeTransform {
  transform(rateId: string, rates: TeeTimeRate[], inverse = false): string {
    const rate = rates.find(teeTimeRate => teeTimeRate._id === rateId);
    const greenFeeRate = rate?.greenFeeRateId as GreenFeeRateData;
    if (greenFeeRate?.color) {
      if (inverse) {
        const color = tinycolor(greenFeeRate.color);
        if (color.isDark()) {
          return '#FFFFFF';
        }
        return '#2C2C2C';
      }
      return greenFeeRate.color;
    }
    return '';
  }
}
