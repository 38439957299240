import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

import { Observable, forkJoin } from 'rxjs';
import { ApiQuery, ApiQueryParams } from '@app/clients';

@Injectable({ providedIn: 'root' })
export class ClassificationService {
  // OVERIDE if using a new version for this feature
  private path = 'classifications';
  private domain = environment.url_v2;
  private baseUrl = `${this.domain}/${this.path}`;

  constructor(private http: HttpClient) {}

  find(query: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this.http.get<any>(`${this.baseUrl}`, { params });
  }

  create(body: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}`, body);
  }

  findById(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${id}`);
  }

  update(id: string, body: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}`, body);
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }

  public loadTypeLists(limit = 50): Promise<any> {
    const loadLists = async (resolve, reject) => {
      forkJoin({
        segments: this.find({ type: 'segment', limit }).toPromise(),
        genres: this.find({ type: 'genre', limit }).toPromise(),
        subGenres: this.find({ type: 'sub-genre', limit }).toPromise(),
      }).subscribe(
        result => {
          resolve(result);
        },
        error => {
          reject(error);
        }
      );
    };
    return new Promise(loadLists);
  }
}
