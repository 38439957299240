/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, switchMap, share, map, tap, take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import _ from 'underscore';

import { FileService } from '@app/providers/file/file.service';
import { SafeSubscribable } from '@app/shared/rxjs/extensions';
import { ImageCropperModalComponent } from '@app/shared/image-cropper-modal/image-cropper-modal.component';
import { ImageCropperOptions } from '@app/shared/image-cropper-modal/image-cropper-modal.interface';
import { ImageMetadata } from './image-filepicker.interface';

@Component({
  selector: 'app-image-filepicker',
  templateUrl: './image-filepicker.component.html',
  styleUrls: ['./image-filepicker.component.scss'],
})
export class ImageFilepickerComponent implements OnInit, OnDestroy, SafeSubscribable {
  @Input() bucket: string; // required
  @Input() autoUpload = true;
  @Input() progress = true;
  @Input() disabled = false;
  @Input() crop = true;
  @Input() modalSize = 'lg';
  @Input() cropOptions: ImageCropperOptions;

  @Output() selected = new EventEmitter<ImageMetadata>();
  @Output() uploading = new EventEmitter<ImageMetadata>();
  @Output() uploaded = new EventEmitter<ImageMetadata>();

  public isUploading = false;
  public file: File;

  public uploadingImage: ImageMetadata;
  public uploadingImageOpacity: number;

  public uploadRequest$ = new BehaviorSubject<ImageMetadata>(null);
  public uploadProgress$: Observable<number>;
  public uploadComplete$: Observable<HttpResponse<ImageMetadata>>;

  constructor(
    private _fileService: FileService,
    private _modalService: NgbModal,
    private _toastService: ToastrService
  ) {}

  ngOnInit() {
    this.validateRequiredFields();
    this.subscribeToImageUpload();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() {}

  public get id() {
    return `image_filepicker_${this.bucket}`;
  }

  private validateRequiredFields() {
    if (!this.bucket) {
      throw new Error('ImageFilepicker: bucket is required');
    }
  }

  private subscribeToImageUpload() {
    const uploading = this.uploadRequest$.pipe(
      filter(file => file !== null),
      switchMap(file => this.startUpload(file)),
      share()
    );

    this.uploadProgress$ = uploading.pipe(
      map(event =>
        event.type === HttpEventType.UploadProgress
          ? Math.round((100 * event.loaded) / event.total) || 0
          : 0
      ),
      tap(progress => {
        if (this.isUploading) {
          this.uploadingImageOpacity = this.scale(progress, [0, 100], [30, 90]) / 100;
        }
      }),
      share()
    );

    this.uploadComplete$ = uploading.pipe(
      filter(event => event.type === HttpEventType.Response),
      map(event => event as HttpResponse<any>)
    );

    this.uploadComplete$.safeSubscribe(
      this,
      response => {
        this.onUploadComplete(response.body);
      },
      error => {
        this.onUploadError(error);
      }
    );
  }

  public upload(image: ImageMetadata) {
    this.uploadRequest$.next(image);
  }

  public complete(images?: ImageMetadata[]): Promise<ImageMetadata[]> {
    const cleanup = _images => {
      if (!_images) {
        _images = this.cleanup([this.uploadingImage]);
      } else {
        _images = this.cleanup(_images);
      }
      return Promise.resolve(_images);
    };

    if (this.isUploading) {
      return this.uploadComplete$
        .pipe(take(1))
        .toPromise()
        .then(() => cleanup(images));
    }
    return cleanup(images);
  }

  private cleanup(images: ImageMetadata[]) {
    if (images) {
      // cleanup autogenerated properties
      images = _.compact(images).map(image => {
        delete image.file;
        delete image.preview;
        return image;
      });
    }
    return images;
  }

  public reset() {
    if (this.crop) {
      this.file = null;
    }
    this.isUploading = false;
    this.uploadingImage = null;
    this.uploadingImageOpacity = 1;
  }

  public async onFilePicked(ev: Event) {
    try {
      const files = this.getSelectedFiles(ev.target);
      const file = files[0];

      if (!file) {
        return; // if nothing selected
      }

      if (this.crop) {
        this.showCropper(ev)
          .then(async result => {
            console.log(result);
            const croppedFile = result.blob;
            result.file = croppedFile;
            result.base64 = await this._fileService.blobToDataURL(croppedFile);
            const image = this.getCroppedImageMetadata(result, croppedFile, file.name);
            this.processImage(ev, image);
          })
          .catch(err => {
            this.file = null;
            this.reset();
          });
      } else {
        const image = this.getImageMetadata(file);
        const previewImage = await this._fileService.blobToDataURL(file);
        const dimensions = await this.getDimensionsOf(previewImage);
        image.preview = previewImage;
        image.width = dimensions.width;
        image.height = dimensions.height;
        this.processImage(ev, image);
      }
    } catch (err) {
      console.log(err);
      this.file = null;
      this.reset();
    }
  }

  private showCropper(ev: Event) {
    const modal = this._modalService.open(ImageCropperModalComponent, {
      centered: true,
      size: this.modalSize,
      backdrop: 'static',
    });

    Object.assign(modal.componentInstance, this.cropOptions);
    modal.componentInstance.imageChangedEvent = ev;

    return modal.result;
  }

  private processImage(ev: Event, metadata: ImageMetadata) {
    if (!metadata.name) {
      metadata.name = this.getSelectedFilename(ev.target);
    }
    this.selected.emit(metadata);
    if (this.autoUpload) {
      this.upload(metadata);
    }
  }

  private startUpload(image: ImageMetadata) {
    if (!this.bucket) {
      throw new Error('Image bucket not specified');
    }

    this.isUploading = true;
    this.uploadingImage = image;

    const { bucket } = this;
    const name = image.name || 'noname';

    // start upload with progress tracking
    const uploading = this._fileService.uploadWithProgress(this.uploadingImage.file, name, {
      bucket,
    });

    console.log('File upload started...');
    this.uploading.emit(this.uploadingImage);

    return uploading;
  }

  private onUploadComplete(result: any) {
    this.uploadingImage.url = result.url;
    delete this.uploadingImage.file; // already uploaded
    delete this.uploadingImage.preview; // dont need

    setTimeout(() => this.reset());
    this.uploaded.emit(this.uploadingImage);
    this._toastService.success('Image successfully uploaded.', 'Success');
  }

  private onUploadError(error: any) {
    this.reset();
    console.log(error);
    const message = error.error ? error.error.message : 'An unexpected error occured.';
    this._toastService.error(message, 'Error Uploading Image');
  }

  private getSelectedFilename(target: EventTarget) {
    const files = this.getSelectedFiles(target);
    if (files.length > 0) {
      return files[0].name;
    }
    return null;
  }

  private getSelectedFiles(target: EventTarget) {
    const input = target as HTMLInputElement;
    return input.files || [];
  }

  private getCroppedImageMetadata(cropped: any, blob: Blob, filename: string): ImageMetadata {
    return {
      file: blob,
      name: filename,
      preview: cropped.base64,
      width: cropped.width,
      height: cropped.height,
      length: blob.size,
      mime: blob.type,
      size: cropped.imageSize,
    };
  }

  private getImageMetadata(file: File): ImageMetadata {
    return {
      file,
      name: file.name,
      length: file.size,
      mime: file.type,
      size: 'original',
    };
  }

  private async getDimensionsOf(url: string) {
    const img = await this.getImage(url);
    const result = { width: img.width, height: img.height };
    return result;
  }

  private async getImage(url: string) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = err => {
        reject(err);
      };
      img.src = url;
    });
  }

  private scale(value, fromRange, toRange) {
    const percentage = (value - fromRange[0]) / (fromRange[1] - fromRange[0]);
    const result = toRange[0] + percentage * (toRange[1] - toRange[0]);
    return result;
  }
}
