import { IImageFile } from '@partake/interfaces';
import { ApiResources, ApiVersion, EducationType, IntroModule, State } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

export const CustomerEducationBaseSchema: ISchema = {
  state: {
    section: 'basic',
    type: Type.STRING,
    label: 'State',
    required: true,
    default: State.DRAFT,
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(State),
  },
  type: {
    section: 'basic',
    type: Type.STRING,
    label: 'Type',
    required: true,
    isHidden: true,
  },
  // Pointers
  appId: {
    section: 'pointers',
    type: Type.TYPEAHEAD,
    partakeOnly: true,
    label: 'White Label',
    resource: ApiResources.WHITE_LABELS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    search: { isDeleted: false },
  },
};

export const FaqBaseSchema: ISchema = {
  // FAQ
  question: {
    section: 'FAQ Info',
    type: Type.STRING,
    label: 'Question',
  },
  description: {
    section: 'FAQ Info',
    type: Type.STRING,
    label: 'Answer',
  },
  list: {
    section: 'FAQ Info',
    type: Type.ARRAY,
    label: 'FAQ Bullet Points',
    items: {
      type: Type.STRING,
      label: 'Bullet Point',
    },
  },
};

export const FaqSchema: ISchema = {
  ...CustomerEducationBaseSchema,
  ...FaqBaseSchema,
};

export const VideoBaseSchema: ISchema = {
  title: {
    section: 'Video Details',
    type: Type.STRING,
    label: 'Video Title',
  },
  url: {
    section: 'Video Details',
    type: Type.STRING,
    label: 'Video Youtube Url',
  },
};

export const IVideo: ISchema = {
  ...CustomerEducationBaseSchema,
  ...VideoBaseSchema,
};

export const AppIntroBaseSchema: ISchema = {
  module: {
    section: 'App Intro',
    type: Type.STRING,
    label: 'Intro Module',
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(IntroModule),
  },
  position: {
    section: 'App Intro',
    type: Type.NUMBER,
    label: 'Position in Intro Sequence',
  },
  images: {
    section: 'App Intro',
    type: Type.IMAGES,
    label: 'App Education Images',
    bucket: 'app-education',
  },
  content: {
    section: 'App Intro',
    type: Type.ARRAY,
    label: 'App Intro Content Sections',
    items: {
      type: Type.OBJECT,
      label: 'Section Info',
      properties: {
        sectionTitle: {
          section: 'App Intro',
          type: Type.STRING,
          label: 'Title',
        },
        sectionText: {
          section: 'App Intro',
          type: Type.LONG_STRING,
          label: 'Text',
        },
      },
    },
  },
};

export const AppIntroSchema: ISchema = {
  ...CustomerEducationBaseSchema,
  ...AppIntroBaseSchema,
};

export const TermsPrivacyBaseSchema: ISchema = {
  html: {
    section: 'Content',
    type: Type.HTML,
    label: 'Content',
  },
};
export const TermsPrivacySchema: ISchema = {
  ...TermsPrivacyBaseSchema,
  ...CustomerEducationBaseSchema,
};

export interface CustomerEducationData extends AbstractModelData {
  state: State;
  type: EducationType;

  // VIDEO
  title?: string;
  url?: string;
  // FAQ
  question?: string;
  description?: string;
  list?: string[];
  // APP INTRO
  postion?: number;
  images?: IImageFile[];
  content?: { sectionTitle: string; sectionText: string }[];
  // Terms and Privacy
  html?: string;

  appId: string;
  orgId: string;
  isDeleted?: boolean;
}

export class CustomerEducation extends AbstractModel<CustomerEducationData> {
  constructor(public data: CustomerEducationData) {
    super(data);
  }
}
