import { ApiResources, ApiVersion, CodeType, State } from '@app/enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { EnumHelper } from '../../helpers';

export const CustomCodeSchema: ISchema = {
  state: {
    section: 'basic',
    type: Type.STRING,
    label: 'State',
    required: true,
    default: State.DRAFT,
    widget: { type: Widget.SELECT },
    enum: EnumHelper.getEnumArray(State),
  },
  type: {
    label: 'Type',
    type: Type.STRING,
    widget: { type: Widget.SELECT },
    enum: EnumHelper.getEnumArray(CodeType),
    immutable: true,
    reloadOnChange: true,
  },
  name: {
    section: 'basic',
    type: Type.STRING,
    required: true,
    label: 'Name',
  },
  filename: {
    section: 'basic',
    type: Type.STRING,
    label: 'File Name',
    immutable: true,
    onlyIf: 'compiledFilePath',
  },
  rawCode: {
    section: 'code',
    type: Type.SCSS || Type.HTML || Type.JAVASCRIPT,
    label: 'Code',
    required: true,
    reference: 'type',
    onlyIf: 'type',
  },
  compiledCode: {
    section: 'code',
    label: 'Compiled Code',
    type: Type.LONG_STRING,
    partakeOnly: true,
    immutable: true,
    isHidden: true,
  },
  compiledFilePath: {
    section: 'basic',
    label: 'File Path',
    type: Type.STRING,
    partakeOnly: true,
    immutable: true,
    onlyIf: 'compiledCode',
    description: 'Generated when saved compiled and uploaded to partakecdn.com',
  },
  appId: {
    section: 'pointers',
    type: Type.TYPEAHEAD,
    partakeOnly: true,
    label: 'App',
    resource: ApiResources.WHITE_LABELS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    search: { isDeleted: false },
  },
  orgId: {
    section: 'pointers',
    type: Type.TYPEAHEAD,
    label: 'Org',
    partakeOnly: true,
    immutable: true,
    resource: ApiResources.ORGS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
  },
};

export interface ICustomCode extends AbstractModelData {
  state: State;
  type: CodeType;
  name: string;
  filename: string;
  rawCode: string;
  compiledCode: string;
  compiledFilePath: string;
  orgId: string;
  appId: string;
  isDeleted?: boolean;
}

export class CustomCode extends AbstractModel<ICustomCode> {
  constructor(public data: ICustomCode) {
    super(data);
  }
}
