import { AppName } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

interface ProductUpdateBaseData {
  name: string;
  text: string;
  appName: AppName;
  appVersion: string;
  isPublished: boolean;
}

export const ProductUpdateSchema: ISchema<ProductUpdateBaseData> = {
  name: {
    section: 'Update Info',
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  text: {
    section: 'Update Info',
    type: Type.WYSWYG,
    label: 'Content',
    required: true,
  },
  // Pointers
  appName: {
    section: 'Update Info',
    type: Type.STRING,
    label: 'App',
    required: true,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(AppName),
    },
  },
  appVersion: {
    section: 'Update Info',
    type: Type.STRING,
    label: 'App Version',
    description: 'Semantic version for the app e.g. 2.1.1',
    required: true,
  },
  isPublished: {
    section: 'Update Info',
    type: Type.BOOLEAN,
    label: 'Published',
    required: true,
  },
};

export type ProductUpdateData = ProductUpdateBaseData & AbstractModelData;

export class ProductUpdate extends AbstractModel<ProductUpdateData> {
  constructor(public data: ProductUpdateData) {
    super(data);
  }
}
