import {
  ApiDomain,
  ApiResources,
  ApiVersion,
  EventType,
  ExportType,
  ReportType,
  UserRoles,
} from '@app/enums';
import { PdfHelper } from '../../helpers';
import { PdfContent, PdfSectionCreateParams } from '../../helpers/pdf.helper';
import { EventData } from '../events';
import { ISchema, Type, Widget } from '../schema';
import { Report, EventReportParams } from './report';
import { SalesReportResponseData } from './report-export-summaries';
import {
  BehaviorSalesSummary,
  DiscountsSummary,
  EmployeeSalesSummary,
  EventSalesSummary,
  getEventReportInfo,
  LocationCategorySalesSummary,
  LocationSalesSummary,
  ModifierProductCategorySalesSummary,
  ModifierProductSalesByBehaviorSummary,
  ModifierProductSalesByEventSummary,
  ModifierProductSalesByEmployeeSummary,
  ModifierProductSalesSummary,
  OrdersSummary,
  PrintedAt,
  ProductCategorySalesSummary,
  ProductSalesByBehaviorSummary,
  ProductSalesByEventSummary,
  ProductSalesByEmployeeSummary,
  ProductSalesSummary,
  RefundTransactionsSummaryForOrders,
  SalesSummary,
  ServiceChargesSummary,
  ShiftSalesSummary,
  StatusSalesSummary,
  TaxesSummary,
  TransactionDisclaimer,
  TransactionsSummaryForOrders,
  VoidsSummary,
} from './report-sections';

export const EventSalesReportSchema: ISchema = {
  eventIds: {
    type: Type.ARRAY,
    label: 'Events',
    description: 'Select the Event(s) for the report',
    widget: {
      type: Widget.SELECT,
      enum: [],
      multiple: true,
      options: {
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.EVENTS,
        modelProp: 'name',
        dataProp: 'data.data',
        search: {
          type: EventType.PRIVATE,
          limit: 100,
          sort: 'dates.start.dateTime',
          reverse: true,
          displayFields: ['name'],
        },
      },
    },
  },
};

export interface EventSalesReportData {
  eventIds: EventData[];
}

export class EventSalesReport extends Report<EventSalesReportData> {
  ignoreProperties = [];
  icon = 'fas fa-calendar-day';
  title = 'Event Sales Report';
  description =
    'Export all sales information for particular Event(s). This includes Sales Summary, Sales for Category and Product information for Menu Items and Modifiers.';
  data: EventSalesReportData = {} as EventSalesReportData;
  isImmediate = true;
  schema: ISchema = EventSalesReportSchema;
  roles: UserRoles[] = [UserRoles.ADMIN, UserRoles.CUSTOMER];
  type: ReportType = ReportType.SALES;
  exportOptions: ExportType[] = [ExportType.PDF];

  pdfSectionCreateParams: PdfSectionCreateParams<SalesReportResponseData>[] = [
    OrdersSummary,
    SalesSummary,
    VoidsSummary,
    StatusSalesSummary,
    BehaviorSalesSummary,
    EventSalesSummary,
    TransactionsSummaryForOrders,
    TransactionDisclaimer,
    RefundTransactionsSummaryForOrders,
    TransactionDisclaimer,
    TaxesSummary,
    DiscountsSummary,
    ServiceChargesSummary,
    LocationSalesSummary,
    LocationCategorySalesSummary,
    ShiftSalesSummary,
    EmployeeSalesSummary,
    ProductCategorySalesSummary,
    ModifierProductCategorySalesSummary,
    ProductSalesSummary,
    ModifierProductSalesSummary,
    ProductSalesByBehaviorSummary,
    ModifierProductSalesByBehaviorSummary,
    ProductSalesByEventSummary,
    ModifierProductSalesByEventSummary,
    ProductSalesByEmployeeSummary,
    ModifierProductSalesByEmployeeSummary,
  ];

  public generateExport(params: EventReportParams, responseData: SalesReportResponseData): void {
    const pdfContent: PdfContent = PdfHelper.generateContent<SalesReportResponseData>(
      'Event Sales Report',
      [
        PrintedAt,
        // @ts-ignore
        ...getEventReportInfo(params),
      ].concat(this.pdfSectionCreateParams),
      responseData
    );
    const slugs = params.events.map(event => event.slug).join('_');
    PdfHelper.generatePdf(pdfContent, `event-sales-report-${slugs}`);
  }
}
