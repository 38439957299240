import { AbstractModel, AbstractModelData } from '../abstract.model';
import { GuestRelationship } from '../../enums';
import { Address, AddressSchema, OrgData, VenueData } from '../core';
import { ISchema, Type } from '../schema';
import { MemberData } from './member';

export const GuestSchema: ISchema = {
  name: {
    label: 'Full Name',
    type: Type.STRING,
  },
  email: {
    label: 'Email',
    type: Type.EMAIL,
  },
  phone: {
    label: 'Phone',
    type: Type.PHONE,
  },
  address: {
    label: 'Address',
    type: Type.OBJECT,
    properties: AddressSchema,
  },
};

export interface GuestOrderedItem {
  productId: string | any;
  quantity: number;
  spend: number;
  orders: number;
}

export interface RelatedGuest {
  guestId: string;
  relationship: GuestRelationship[];
}

export interface GuestData extends AbstractModelData {
  name?: string;
  email?: string;
  phone?: string;
  phoneIntl?: string;
  countryCode?: string;

  imageUrl?: string;
  gender?: string;
  dob?: string;

  notificationPermissions?: {
    emailMarketing?: boolean;
    push?: boolean;
    text?: boolean;
  };

  ordersSummary?: {
    updatedAt: Date;
    total: number;
    orders: number;
    firstOrder: Date;
    lastOrder: Date;
    orderCountByStatus: Record<string, any>;
    orderedItems: GuestOrderedItem[];
  };

  address?: Address;

  // the account it needs to be scoped to - derived from concession
  orgId: string | OrgData;
  venueId?: string | VenueData;
  memberId?: string | MemberData;
  /** @deprecated */
  venue?: string | VenueData;
  account?: string;
  user?: string | Record<string, any>;
  otherKnownEmails?: string[];
  otherKnownNames?: string[];
  otherKnownAddresses?: Address[];
  relatedGuests?: RelatedGuest[];
}

export class Guest extends AbstractModel<GuestData> {
  constructor(public data: GuestData) {
    super(data);
  }
}
