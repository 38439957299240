// eslint-disable-next-line import/prefer-default-export
export enum UserType {
  CUSTOMER = 'customer',
  ORG = 'org',
  PARTAKE = 'partake',

  /**
   * @deprecated
   */
  VENUE = 'venue',
  /**
   * @deprecated
   */
  CONSUMER = 'consumer',
}

export enum UserState {
  UNVERIFIED = 'unverified',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  DELETED = 'deleted',
}
