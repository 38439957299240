import { IImageFile } from '@partake/interfaces';
import {
  ApiDomain,
  ApiResources,
  ApiVersion,
  FulfillmentMode,
  InventoryMode,
  LocationType,
  OnlineOrderingShiftTrackingMode,
} from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { LocationCategoryData } from './location-category';

export interface LocationSchemaData {
  name: string;
  slug: string;
  type: LocationType;
  category: string | LocationCategoryData;
  orderQuantityValidation: string;
  isPublic: boolean;
  autoAcceptMobileOrders: boolean;
  mobileOrderEmployeeTrackingMode: OnlineOrderingShiftTrackingMode;
  onlineOrderingShiftEmployee: string;
  diningOptionIds: boolean;
  fulfillmentMode: FulfillmentMode;
  kdsEnabled: boolean;
  inventoryMode: InventoryMode;
  trackCoverCounts: boolean;
  tippingEnabled: boolean;
  collectTipsOnTerminal: boolean;
  trackCash: boolean;
  images: IImageFile[];
  alcoholEnabled: boolean;
  alcoholLimit?: number;
  venue?: string;
  orgId?: string;
}

export const LocationSchema: ISchema<LocationSchemaData> = {
  name: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  slug: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Slug',
    isHidden: true,
  },
  type: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Type',
    required: true,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(LocationType),
    },
  },
  diningOptionIds: {
    section: 'Dining Option Selection',
    side: 'right',
    type: Type.ARRAY,
    label: 'Dining Options',
    widget: {
      type: Widget.SELECT,
      enum: [],
      multiple: true,
      options: {
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.DINING_OPTIONS,
        modelProp: 'name',
        dataProp: 'data',
        search: { isDeleted: false },
        displayFields: ['name'],
      },
    },
    onlyIf: [
      {
        field: 'type',
        shouldShow: (value: any) =>
          [LocationType.RESTAURANT, LocationType.PRO_SHOP, LocationType.STORE].includes(value),
      },
    ],
  },
  category: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Location Category',
    required: true,
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        domain: ApiDomain.API,
        version: ApiVersion.V2,
        resource: ApiResources.LOCATION,
        subCollection: 'categories',
        modelProp: 'name',
        dataProp: 'categories',
        search: { isDeleted: false },
        displayFields: ['name'],
      },
    },
  },
  orderQuantityValidation: {
    section: 'Operating Settings',
    type: Type.STRING,
    label: 'Order Quantity Limitations',
    description: `Limit the quantity of items that can be put into an order as follows:
    &bull;&nbsp;Min/Max: 10-20 for 10 to 20 items
    &bull;&nbsp;Multiples: /6 for multiples of 6 such as 6,12,18,24 etc.
    &bull;&nbsp;Fixed Quantities: 6,12 for 6 or 12 items only
    &bull;&nbsp;Split rule validations as follows: 12-20,/6,6,12
    &bull;&nbsp;The first valid rule the order hits wins`,
    required: true,
  },
  isPublic: {
    section: 'Online Ordering Settings',
    type: Type.BOOLEAN,
    label: 'Online Ordering Enabled',
    default: false,
  },
  autoAcceptMobileOrders: {
    section: 'Online Ordering Settings',
    type: Type.BOOLEAN,
    label: 'Auto Accept Online Orders',
    description:
      'Orders will automatically be accepted when placed online and paid for. Expected time with be based on the asap min value set on the dining option. For future orders the expected time will be set to the selected time.',
    default: false,
  },
  mobileOrderEmployeeTrackingMode: {
    section: 'Online Ordering Settings',
    type: Type.STRING,
    label: 'Online Order Shift Tracking Mode',
    description: 'Select how online orders will be assigned to shifts.',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(OnlineOrderingShiftTrackingMode),
    },
    default: OnlineOrderingShiftTrackingMode.NONE,
    onChange: (value: OnlineOrderingShiftTrackingMode, data: LocationSchemaData) => {
      if (value !== OnlineOrderingShiftTrackingMode.DEFAULT) {
        data.onlineOrderingShiftEmployee = null;
      }
    },
  },
  onlineOrderingShiftEmployee: {
    section: 'Online Ordering Settings',
    type: Type.STRING,
    label: 'Online Order Employee Default Employee',
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        resource: ApiResources.EMPLOYEE,
        version: ApiVersion.V2,
        search: { limit: 1000, sort: 'name' },
        modelProp: 'name',
        dataProp: 'data.data',
        displayFields: ['name', 'employeeId'],
      },
    },
    onlyIf: [
      {
        field: 'mobileOrderEmployeeTrackingMode',
        shouldShow: (mode: OnlineOrderingShiftTrackingMode) =>
          mode === OnlineOrderingShiftTrackingMode.DEFAULT,
      },
    ],
  },
  fulfillmentMode: {
    section: 'Kitchen & Fulfillment Settings',
    type: Type.STRING,
    label: 'Fulfillment Mode',
    description: `Set the KDS & Printer fulfillment routing mode for items ordered at this location.
    &bull;&nbsp;<strong>None</strong> - No Printing or KDS Routing
    &bull;&nbsp;<strong>Location</strong> - Items are routed to the location Kitchen Printer or KDS.
    &bull;&nbsp;<strong>Station</strong> - Items are routed to a station Printer or KDS assigned to the item. This IS NOT location specific and could be shared by many locations.
    &bull;&nbsp;<strong>Location Station</strong> - Items are routed to a station Printer or KDS assigned to the item. This IS location specific.`,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(FulfillmentMode),
    },
    default: FulfillmentMode.NONE,
    onChange: (value: FulfillmentMode, data: LocationSchemaData) => {
      if (value === FulfillmentMode.NONE) {
        data.kdsEnabled = false;
      }
    },
  },
  kdsEnabled: {
    section: 'Kitchen & Fulfillment Settings',
    description: 'Only enable if a KDS is used for items ordered at this location.',
    type: Type.BOOLEAN,
    label: 'KDS Enabled',
    default: false,
    onlyIf: [
      {
        field: 'fulfillmentMode',
        shouldShow: (mode: FulfillmentMode) => mode !== FulfillmentMode.NONE,
      },
    ],
  },
  inventoryMode: {
    section: 'Operating Settings',
    type: Type.STRING,
    label: 'Shift Inventory Mode',
    description: `Set the inventory tracking mode for shifts.
    &bull;&nbsp;<strong>None</strong> - Inventory IS NOT required to be taken at the start of a shift.
    &bull;&nbsp;<strong>Location</strong> - Inventory IS required to be taken at the start of a shift. Only one shift can be open at a time.
    &bull;&nbsp;<strong>Stand Sheet</strong> - Inventory IS required to be taken at the start of a shift. Each shift will have its own inventory with products that have the selected stand sheet.`,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(InventoryMode),
    },
    default: InventoryMode.NONE,
  },
  trackCoverCounts: {
    section: 'Operating Settings',
    type: Type.BOOLEAN,
    label: 'Track Cover Counts',
    description: 'Assign cover counts to orders.',
    default: false,
  },
  tippingEnabled: {
    section: 'Operating Settings',
    type: Type.BOOLEAN,
    label: 'Collect Tips',
    description: 'Disable if you do not want to collect tips for this location.',
    default: true,
  },
  collectTipsOnTerminal: {
    section: 'Operating Settings',
    type: Type.BOOLEAN,
    label: 'Collect Tips on Payment Termimal',
    description: 'This only applies to locations that use a payment static terminal.',
    default: false,
    onlyIf: [
      {
        field: 'tippingEnabled',
        shouldShow: (tippingEnabled: boolean) => !!tippingEnabled,
      },
    ],
  },
  trackCash: {
    section: 'Operating Settings',
    type: Type.BOOLEAN,
    label: 'Track Cash',
    description: 'Require that cash balances are tracked at start and end of shift.',
    default: false,
  },
  alcoholEnabled: {
    section: 'Alcohol Ordering',
    side: 'right',
    type: Type.BOOLEAN,
    label: 'Allow Alcoholic Sales',
    default: false,
  },
  alcoholLimit: {
    section: 'Alcohol Ordering',
    side: 'right',
    type: Type.NUMBER,
    label: 'Alcohol Order Limit',
    description:
      'Limit the number of alcoholic beverages that can be added an order from the Partake Pay app. Leave as <b>0</b> for unlimited.',
    default: false,
    onlyIf: 'alcoholEnabled',
  },
  images: {
    section: 'images',
    side: 'right',
    type: Type.IMAGES,
    label: 'Location Images',
    bucket: 'location',
  },
  orgId: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Org',
    isHidden: true,
  },
  venue: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Venue',
    isHidden: true,
  },
};

export type LocationData = LocationSchemaData & AbstractModelData;

/**
 * @todo - Remove patch once all locations have correct info
 */
export class Location extends AbstractModel<LocationData> {
  constructor(public data: LocationData) {
    super(data);
  }
}
