export enum SummaryDataType {
  USERS = 'users',
  GUESTS = 'guests',
  GAMES = 'games',
  ORDERS = 'orders',
  EMPLOYEE_SALES = 'employee-sales',
  PRODUCT_SALES = 'product-sales',
  MODIFIER_PRODUCT_SALES = 'modifier-product-sales',
  SHIFT_SALES = 'shift-sales',
  LOCATION_ORDERS = 'location-orders',
  TRANSACTIONS = 'transactions',
  SALES = 'sales',
}
export enum SummaryDataDuration {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export enum SummaryDataFieldType {
  STRING = 'string',
  NUMBER = 'number',
  CURRENCY = 'currency',
}

export enum TimePeriod {
  YEAR = 'year',
  LAST_YEAR = 'last year',
  QUARTER = 'quarter',
  LAST_QUARTER = 'last quarter',
  LAST_12_MONTHS = 'last 12 months',
  LAST_6_MOMTHS = 'last 6 months',
  LAST_3_MONTHS = 'last 3 months',
  MONTH = 'month',
  LAST_MONTH = 'last month',
  WEEK = 'week',
  LAST_WEEK = 'last week',
  DAY = 'day',
  YESTERDAY = 'yesterday',
}
