import { State, ApiResources, ApiVersion } from '@app/enums';
import { IImageFile } from '@partake/interfaces';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { EnumHelper } from '../../helpers';

export const MetaSchema: ISchema = {
  unlocks: {
    type: Type.NUMBER,
    label: 'Unlocks',
    default: 0,
    immutable: true,
  },
  redemptions: {
    type: Type.NUMBER,
    label: 'Redemptions',
    default: 0,
    immutable: true,
  },
};

export const PromotionSchema: ISchema = {
  id: {
    label: 'ID',
    type: Type.NUMBER,
    isHidden: true,
    immutable: true,
    partakeOnly: true,
  },
  state: {
    section: 'basic',
    type: Type.STRING,
    label: 'State',
    required: true,
    default: State.DRAFT,
    widget: { type: Widget.SELECT },
    enum: EnumHelper.getEnumArray(State),
  },
  name: {
    section: 'basic',
    type: Type.STRING,
    required: true,
    label: 'Name',
  },
  offer: {
    section: 'basic',
    type: Type.STRING,
    label: 'Offer Summary',
    description: 'Provide a short summary about the offer and/or company.',
  },
  description: {
    section: 'basic',
    type: Type.WYSWYG,
    label: 'Full Offer Description',
    description: 'Provide a full offer description with terms for the user to view.',
  },
  images: {
    section: 'images',
    type: Type.IMAGES,
    label: 'Promotion Images',
    bucket: 'promotions',
  },
  startsAt: {
    section: 'basic',
    type: Type.DATE_TIME,
    label: 'Start Date',
  },
  endsAt: {
    section: 'basic',
    type: Type.DATE_TIME,
    label: 'End Date',
  },

  meta: {
    section: 'meta',
    type: Type.OBJECT,
    label: 'Meta',
    properties: MetaSchema,
    isHidden: true,
  },

  whiteLabelId: {
    section: 'pointers',
    type: Type.TYPEAHEAD,
    partakeOnly: true,
    label: 'White Label',
    resource: ApiResources.WHITE_LABELS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    search: { isDeleted: false },
  },
  orgId: {
    section: 'pointers',
    type: Type.TYPEAHEAD,
    label: 'Org',
    partakeOnly: true,
    immutable: true,
    resource: ApiResources.ORGS,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
  },
};

export interface IPromotionMeta {
  unlocks: number;
  redemptions: number;
}

export interface IPromotion extends AbstractModelData {
  id: number;
  state: State;
  name: string;
  description: string;
  images: Array<IImageFile>;
  meta: IPromotionMeta;
  whiteLabelId: string;
  orgId?: string;
  startsAt?: string;
  endsAt?: string;
  isDeleted?: boolean;
}

export class Promotion extends AbstractModel<IPromotion> {
  constructor(public data: IPromotion) {
    super(data);
  }
}
