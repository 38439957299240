<div class="mb-3">
  <label *ngIf="label">
    <div class="row">
      <div class="col">{{ label }}</div>
      <div class="col text-right" *ngIf="showCreate">
        <button class="btn btn-sm btn-light" (click)="create()">Create</button>
      </div>
    </div>
  </label>
  <input
    type="text"
    [class]="size"
    [(ngModel)]="search"
    [ngbTypeahead]="searchCollection"
    [placeholder]="placeholder"
    [inputFormatter]="formatter"
    [resultTemplate]="selectedTpl"
    (selectItem)="select($event)"
  />
  <ng-template #selectedTpl let-r="result">
    <div *ngIf="!displayFilter">{{ r.name }}</div>
    <div *ngIf="displayFilter">{{ displayFilter(r) }}</div>
  </ng-template>
</div>
