<div class="mb-3">
  <label *ngIf="label">
    <div class="row">
      <div class="col">{{ label }}</div>
    </div>
  </label>
  <div class="input-group">
    <input
      type="text"
      [disabled]="disabled"
      [class]="size"
      [(ngModel)]="search"
      [ngbTypeahead]="searchCollection"
      [placeholder]="placeholder"
      [inputFormatter]="formatter"
      [resultTemplate]="selectedTpl"
      popupClass="typeahead-window"
      (selectItem)="select($event)"
    />
    <div class="input-group-append">
      <button class="btn btn-outline-medium" type="button" (click)="clear()">Clear</button>
    </div>
  </div>
  <ng-template #selectedTpl let-r="result">
    <div *ngIf="!displayFilter">{{ basicDisplay(r) }}</div>
    <div *ngIf="displayFilter">{{ displayFilter(r) }}</div>
  </ng-template>
</div>
