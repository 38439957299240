import _ from 'lodash';
import {
  DateRange,
  TeeTimeRange,
  DaysOfWeekActive,
  TeeTimeRangeSchema,
  DateRangeSchema,
  DaysOfWeekActiveSchema,
} from './tee-sheet-shared';
import { GreenFeeRateData } from './green-fee-rate';
import { ISchema, Type, Widget } from '../schema';
import { ApiResources, ApiVersion, IncrementOperation } from '../../enums';
import { EnumHelper, TimeHelper } from '../../helpers';
import { AbstractModel } from '../abstract.model';
import { CourseData } from './course';

export interface HolesPrice {
  price: number;
  cartPrice: number;
}

export const HolePriceSchema: ISchema<HolesPrice> = {
  price: {
    label: 'Price',
    type: Type.CURRENCY,
    required: true,
  },
  cartPrice: {
    label: 'Cart Price',
    type: Type.CURRENCY,
  },
};

export interface TimeFrame {
  _id?: string;
  name: string;
  daysOfWeek: DaysOfWeekActive;
  teeTimeRange: TeeTimeRange;
  nineHoles: HolesPrice;
  eighteenHoles: HolesPrice;
  isActive: boolean;
  greenFeeRateId: GreenFeeRateData | string;
}

export const TimeFrameSchema: ISchema<Omit<TimeFrame, '_id'>> = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  daysOfWeek: {
    label: 'Active Days of the Week',
    type: Type.OBJECT,
    properties: DaysOfWeekActiveSchema,
  },
  teeTimeRange: {
    label: 'Tee Time Range',
    type: Type.OBJECT,
    properties: TeeTimeRangeSchema,
  },
  nineHoles: {
    label: '9 Hole Price',
    type: Type.OBJECT,
    properties: HolePriceSchema,
  },
  eighteenHoles: {
    label: '18 Hole Price',
    type: Type.OBJECT,
    properties: HolePriceSchema,
  },
  greenFeeRateId: {
    type: Type.TYPEAHEAD,
    partakeOnly: true,
    label: 'Green Fee Rate',
    resource: ApiResources.GREEN_FEE_RATE,
    version: ApiVersion.V2,
    modelProp: 'name',
    dataProp: 'data.data',
    isHidden: true,
  },
  isActive: {
    label: 'Active',
    type: Type.BOOLEAN,
  },
};

export interface Season {
  _id?: string;
  name: string;
  isDefault: boolean;
  dateRange: DateRange;
  isHoliday: boolean;
  timeFrames: TimeFrame[];
}

export const SeasonSchema: ISchema<Omit<Season, '_id'>> = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  dateRange: {
    label: 'Date Range',
    type: Type.OBJECT,
    properties: DateRangeSchema,
  },
  timeFrames: {
    label: 'Season Time Frames',
    type: Type.ARRAY,
    items: {
      label: 'Time Frame',
      type: Type.OBJECT,
      properties: TimeFrameSchema,
    },
    isHidden: true,
  },
  isHoliday: {
    label: 'Is a Holiday',
    type: Type.BOOLEAN,
    default: false,
  },
  isDefault: {
    label: 'Default',
    type: Type.BOOLEAN,
  },
};

export interface MarketingOptions {
  sendReminderText: boolean;
  sendThankYou: boolean;
  thankYouCampaign: string;
}

export const MarketingOptionsSchema: ISchema<MarketingOptions> = {
  sendReminderText: {
    section: 'Marketing Options',
    label: 'Send Reminder Text',
    type: Type.BOOLEAN,
    default: false,
  },
  sendThankYou: {
    section: 'Marketing Options',
    label: 'Send Thank You',
    type: Type.BOOLEAN,
    default: false,
    isHidden: true,
  },
  thankYouCampaign: {
    section: 'Marketing Options',
    label: 'Thank You Campaign',
    type: Type.STRING,
    isHidden: true,
  },
};

export interface OnlineSettings {
  isAvailable: boolean;
  teeTimeRange: TeeTimeRange;
  daysInBookingWindow: number;
  minPlayers: number;
}

export const OnlineSettingsSchema: ISchema<OnlineSettings> = {
  isAvailable: {
    section: 'Online Settings',
    label: 'Allow Online Bookings',
    type: Type.BOOLEAN,
    default: false,
  },
  teeTimeRange: {
    section: 'Online Settings',
    label: 'Online Booking Tee Time Range',
    description: 'Limit the time window for online bookings if applicable.',
    type: Type.OBJECT,
    properties: TeeTimeRangeSchema,
    onlyIf: 'isAvailable',
  },
  daysInBookingWindow: {
    section: 'Online Settings',
    label: 'Days in booking window',
    type: Type.NUMBER,
    default: 5,
    onlyIf: 'isAvailable',
  },
  minPlayers: {
    section: 'Online Settings',
    label: 'Minimum number of players for online bookings',
    type: Type.NUMBER,
    default: 1,
    onlyIf: 'isAvailable',
  },
};

export interface IncrementSetting {
  _id?: string;
  name: string;
  /**
   * Highlight Color
   */
  color: string;
  /**
   * Selected from Defaulted Settings
   */
  operation: IncrementOperation;
  interval: number;
  intervalStep: number;

  /**
   * Open & Close Times for Tee Sheet
   * - Recommended not to change times once created
   * */
  teeTimeRange: TeeTimeRange;
  dateRange: DateRange;
  daysOfWeek: DaysOfWeekActive;
}

export const IncrementSettingsSchema: ISchema<Omit<IncrementSetting, '_id'>> = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  color: {
    label: 'Color',
    type: Type.COLOR,
    required: true,
    position: 'bottom',
  },
  operation: {
    label: 'Increment Operation',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(IncrementOperation),
  },
  interval: {
    label: 'Base Interval',
    type: Type.NUMBER,
  },
  intervalStep: {
    label: 'Alternating Interval',
    type: Type.NUMBER,
    onlyIf: [
      {
        field: 'operation',
        shouldShow: (value: IncrementOperation) =>
          [IncrementOperation.ALTERNATING_INTERVAL].includes(value),
      },
    ],
    default: 0,
  },
  teeTimeRange: {
    label: 'Increments Booking Tee Time Range',
    description: 'Limit the time window for bookings of this custom setting.',
    type: Type.OBJECT,
    properties: TeeTimeRangeSchema,
  },
  dateRange: {
    label: 'Date Range',
    type: Type.OBJECT,
    properties: DateRangeSchema,
  },
  daysOfWeek: {
    label: 'Active Days of the Week',
    type: Type.OBJECT,
    properties: DaysOfWeekActiveSchema,
  },
};

export interface TeeSheetData {
  _id?: string;

  /** BASIC SETTINGS */
  name: string; // Defaults to Course Name
  courseId: string | CourseData;
  isDefault: boolean; // Will show up first

  /**
   * Top Bar Color in Tee Sheet Module
   */
  color: string;
  holes: number;

  /**
   * DEFAULT TEE SHEET SETTINGS
   * - Not recommended to change mid-season as can lead to problems
   * - Overidden by custom increment settings
   * - operation types `same-interval`, `alternating-interval`
   */
  operation: IncrementOperation;
  interval: number;
  intervalStep: number;

  // So re-round time can be blocked off on tee-sheet
  timePerNineHoles: number;

  // Notify all in group not just the one who booked
  notifyAllParticipants: boolean;

  seasons: Season[];

  customIncrements: IncrementSetting[];

  marketingOptions: MarketingOptions;

  onlineSettings: OnlineSettings;

  orgId: string;
  venueId?: string;
}

export const TeeSheetSchema: ISchema<Omit<TeeSheetData, '_id'>> = {
  name: {
    section: 'Basic Details',
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  courseId: {
    section: 'Basic Details',
    type: Type.TYPEAHEAD,
    required: true,
    label: 'Course',
    resource: ApiResources.COURSES,
    version: ApiVersion.V2,
    dataProp: 'data.data',
    modelProp: 'name',
  },
  isDefault: {
    section: 'Basic Details',
    label: 'Default Tee Sheet',
    type: Type.BOOLEAN,
    default: false,
    required: true,
  },
  color: {
    section: 'Basic Details',
    label: 'Color',
    type: Type.COLOR,
    required: true,
  },
  holes: {
    section: 'Basic Details',
    label: 'Holes',
    type: Type.NUMBER,
    default: 18,
    required: true,
    widget: {
      type: Widget.SELECT,
    },
    enum: [
      { name: '9', key: 9 },
      { name: '18', key: 18 },
    ],
  },
  operation: {
    section: 'Basic Details',
    label: 'Increment Operation',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(IncrementOperation),
  },
  interval: {
    section: 'Basic Details',
    label: 'Base Interval',
    type: Type.NUMBER,
  },
  intervalStep: {
    section: 'Basic Details',
    label: 'Alternating Interval',
    type: Type.NUMBER,
    default: 0,
    onlyIf: [
      {
        field: 'operation',
        shouldShow: (value: IncrementOperation) =>
          [IncrementOperation.ALTERNATING_INTERVAL].includes(value),
      },
    ],
  },
  timePerNineHoles: {
    section: 'Basic Details',
    label: 'Time Per Nine Holes',
    type: Type.NUMBER,
    widget: {
      type: Widget.SELECT,
    },
    enum: [
      { key: 90, name: '1 hour 30 mins' },
      { key: 95, name: '1 hour 35 mins' },
      { key: 100, name: '1 hour 40 mins' },
      { key: 105, name: '1 hour 45 mins' },
      { key: 110, name: '1 hour 50 mins' },
      { key: 115, name: '1 hour 55 mins' },
      { key: 120, name: '2 hours' },
      { key: 125, name: '2 hour 5 mins' },
      { key: 130, name: '2 hour 10 mins' },
      { key: 135, name: '2 hour 15 mins' },
      { key: 140, name: '2 hour 20 mins' },
      { key: 145, name: '2 hour 25 mins' },
      { key: 150, name: '2 hour 30 mins' },
      { key: 155, name: '2 hour 35 mins' },
      { key: 160, name: '2 hour 40 mins' },
      { key: 165, name: '2 hour 45 mins' },
      { key: 170, name: '2 hour 50 mins' },
      { key: 175, name: '2 hour 55 mins' },
      { key: 180, name: '3 hours' },
    ],
  },
  notifyAllParticipants: {
    section: 'Basic Details',
    label: 'Notify All Participants',
    type: Type.BOOLEAN,
    default: false,
    isHidden: true,
  },
  seasons: {
    section: 'Basic Details',
    label: 'Seasons',
    type: Type.ARRAY,
    items: {
      label: 'Season',
      type: Type.OBJECT,
      properties: SeasonSchema,
    },
    isHidden: true,
  },
  customIncrements: {
    section: 'Basic Details',
    label: 'Custom Increments',
    type: Type.ARRAY,
    items: {
      label: 'Increment',
      type: Type.OBJECT,
      properties: IncrementSettingsSchema,
    },
    isHidden: true,
  },
  marketingOptions: {
    section: 'Basic Details',
    label: 'Marketing Options',
    type: Type.OBJECT,
    properties: MarketingOptionsSchema,
  },

  onlineSettings: {
    section: 'Basic Details',
    label: 'Online Settings',
    type: Type.OBJECT,
    properties: OnlineSettingsSchema,
  },
  orgId: {
    label: 'Org',
    type: Type.STRING,
    isHidden: true,
  },
  venueId: {
    label: 'Venue',
    type: Type.STRING,
    isHidden: true,
  },
};

export class TeeSheet extends AbstractModel<TeeSheetData> {
  constructor(public data: TeeSheetData) {
    super(data);
  }

  private static getCleanedDateRanges(dateRange: DateRange): DateRange {
    const startDateParts = TimeHelper.getDatePartsFromDate(dateRange.startDate);
    const endDateParts = TimeHelper.getDatePartsFromDate(dateRange.endDate);
    return {
      startDate: dateRange.startDate,
      startDay: startDateParts.day,
      startMonth: startDateParts.month,
      startYear: startDateParts.year,
      endDate: dateRange.endDate,
      endDay: endDateParts.day,
      endMonth: endDateParts.month,
      endYear: endDateParts.year,
      utcOffset: null,
    };
  }

  private static cleanedSeasons(seasons: Season[]): Season[] {
    return seasons.map(season => ({
      ...season,
      dateRange: TeeSheet.getCleanedDateRanges(season.dateRange),
      timeFrames: season.timeFrames.map(timeFrame => ({
        ...timeFrame,
        greenFeeRateId: (timeFrame.greenFeeRateId as GreenFeeRateData)._id,
      })),
    }));
  }

  get json() {
    const cloned = _.cloneDeep(this.data);
    return {
      ...cloned,
      courseId: (cloned.courseId as CourseData)._id,
      customIncrements: cloned.customIncrements.map(increment => ({
        ...increment,
        dateRange: TeeSheet.getCleanedDateRanges(increment.dateRange),
      })),
      seasons: TeeSheet.cleanedSeasons(cloned.seasons),
    };
  }
}
