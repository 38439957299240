import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { ViewJsonModalComponent } from './view-json-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ViewJsonModalController {
  constructor(private modalService: NgbModal) {}

  public show(json: any, expanded = true): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const modal = this.modalService.open(ViewJsonModalComponent, { centered: true, size: 'lg' });
      modal.componentInstance.json = json;
      modal.componentInstance.expanded = expanded;
      modal.result.then(
        () => {
          resolve(true);
        },
        reason => {
          resolve(false);
        }
      );
    });
  }
}
