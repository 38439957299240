export enum TeeTimePlayerStatus {
  PENDING = 'pending',
  BOOKED = 'booked',
  CHECKIN_IN = 'checked-in',
  NO_SHOW = 'no-show',
  RAIN_CHECK = 'rain-check',
  STARTED = 'started',
  COMPLETED = 'completed',
}

export enum RemovedTeeTimePlayerReason {
  NO_SHOW = 'no-show',
  CANCELLED = 'cancelled',
  BLACK_LISTED = 'black-listed',
  RED_LISTED = 'red-listed',
  OTHER = 'other',
}

export enum TeeTimeBookingStatus {
  BOOKED = 'booked',
  PAID = 'paid',
  CANCELLED = 'cancelled',
  RAIN_CHECK = 'rain-check',
}
