import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-option-picker-modal',
  templateUrl: './option-picker-modal.component.html',
  styleUrls: ['./option-picker-modal.component.scss'],
})
export class OptionPickerModalComponent {
  @Input() text = '';

  @Input() title = '';

  @Input() buttons: any[];

  constructor(public modal: NgbActiveModal) {}

  action(value: string) {
    this.modal.close(value);
  }
}
