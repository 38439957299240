<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<div class="row nav-container">
  <div class="col-1">
    <ul class="navbar-nav">
      <li class="nav-item d-none d-md-block">
        <a
          (click)="toggleSidebar.emit()"
          class="nav-link sidebartoggler waves-effect waves-light"
          href="javascript:void(0)"
        >
          <i class="mdi mdi-menu font-24"></i>
        </a>
      </li>
    </ul>
  </div>
  <div class="col" *ngIf="orgModeEnabled">
    <ul class="navbar-nav">
      <li class="nav-item dropdown" ngbDropdown placement="bottom-start" *ngIf="orgs?.length > 1">
        <a
          ngbDropdownToggle
          id="navbarDropdown1"
          class="nav-link dropdown-toggle"
          href="javascript:void(0)"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="d-md-block"
            >{{ selectedOrg?.displayName }} <i class="fa fa-angle-down"></i
          ></span>
        </a>
        <div
          ngbDropdownMenu
          aria-labelledby="navbarDropdown1"
          class="dropdown-menu org-list"
          *ngIf="orgsByState"
        >
          <div class="mb-3" *ngIf="orgs?.length > 2">
            <input
              type="text"
              placeholder="Search orgs"
              [(ngModel)]="orgSearchText"
              (ngModelChange)="filterOrgs()"
              name="header-search"
              class="form-control form-control-line"
            />
          </div>
          <ng-container *ngFor="let state of orgStates">
            <ng-container *ngIf="orgsByState[state.key]?.length">
              <div class="org-section-title">{{ state.name }}</div>
              <a
                href="#"
                class="dropdown-item"
                *ngFor="let org of orgsByState[state.key]"
                (click)="switchOrg(org)"
              >
                {{ org.get('name') }}
              </a>
            </ng-container>
          </ng-container>
        </div>
      </li>
      <li class="nav-item dropdown" ngbDropdown placement="bottom-start" *ngIf="selectedOrg">
        <a
          ngbDropdownToggle
          id="navbarDropdown1"
          class="nav-link dropdown-toggle"
          href="javascript:void(0)"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="d-md-block">{{ selectedVenue?.name }} <i class="fa fa-angle-down"></i></span>
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu venue-list">
          <a
            href="#"
            class="dropdown-item"
            *ngFor="let venue of selectedOrg.get('venueIds')"
            (click)="switchVenue(venue)"
          >
            {{ venue.name }}
          </a>
        </div>
      </li>
    </ul>
  </div>
  <div class="w-100 d-block d-sm-none"></div>
  <div class="col search-input ms-2" *ngIf="orgModeEnabled">
    <ul class="navbar-nav">
      <li class="nav-item">
        <div>
          <input
            type="text"
            placeholder="Search"
            [(ngModel)]="searchText"
            name="header-search"
            (keyup.enter)="p.close(); search()"
            placement="bottom"
            [ngbPopover]="searchTipsContent"
            triggers="manual"
            popoverClass="search-tips-popover"
            #p="ngbPopover"
            (focus)="p.toggle()"
            [autoClose]="false"
            class="form-control form-control-line"
          />
        </div>
        <ng-template #searchTipsContent>
          <div class="filter-popover">
            <h3>Get started</h3>
            <h5>ADVANCED SEARCHES</h5>
            <p>Use different pieces of information as search terms:</p>
            <ul>
              <li>The last four digits of a card (4242).</li>
              <li>The payment method type (VISA).</li>
              <li>The name of a product (Wagyu Burger).</li>
              <li>The order number (18179523).</li>
              <li>The employee who worked on an order (Billy).</li>
            </ul>
            <p>
              For searches that require dates, you can use different formats, like 2023-02 or
              2020-07-12. Use object identifiers (order ID) to take you directly to the object
              you’re looking for. No additional context is necessary for most searches. The
              Dashboard automatically looks for the most relevant information based on your search
              query.
            </p>
            <div class="d-flex justify-content-end">
              <button class="btn btn-sm btn-primary" (click)="p.close()">Okay</button>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
  <div class="w-100 d-block d-sm-none"></div>
  <!-- ============================================================== -->
  <!-- Right side toggle and nav items -->
  <!-- ============================================================== -->
  <div class="col">
    <ul class="navbar-nav right">
      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
      <li class="mode-switch" *ngIf="user?.type === 'partake'">
        <label>Org Mode</label>
        <ui-switch [(ngModel)]="orgModeEnabled" (valueChange)="toggleMode($event)" size="small">
        </ui-switch>
      </li>
      <li class="mode-switch training-mode">
        <label>Training Mode</label>
        <ui-switch
          [(ngModel)]="trainingModeEnabled"
          (valueChange)="toggleTrainingMode($event)"
          size="small"
        >
        </ui-switch>
      </li>
      <li class="nav-item dropdown" ngbDropdown placement="bottom-end">
        <a
          ngbDropdownToggle
          class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
          href="javascript:void(0)"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            *ngIf="!user?.imgUrl"
            src="assets/images/users/1.jpg"
            alt="user"
            class="rounded-circle"
            width="31"
            height="31"
          />
          <img
            *ngIf="user?.imgUrl"
            [src]="user?.imgUrl"
            alt="user"
            class="rounded-circle"
            width="31"
            height="31"
          />
          <h4 class="m-b-0 ml-2 d-inline">{{ user?.name | firstName }}</h4>
        </a>
        <div
          class="dropdown-menu user dropdown-menu-right user-dd animated flipInY"
          ngbDropdownMenu
        >
          <span class="with-arrow">
            <span class="bg-primary"></span>
          </span>
          <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
            <div class="">
              <img
                *ngIf="!user?.imgUrl"
                src="assets/images/users/1.jpg"
                alt="user"
                class="img-circle"
                width="60"
                height="60"
              />
              <img
                *ngIf="user?.imgUrl"
                [src]="user?.imgUrl"
                alt="user"
                class="img-circle"
                width="60"
                height="60"
              />
            </div>
            <div class="m-l-10">
              <h4 class="m-b-0">{{ user?.name }}</h4>
              <p class="m-b-0">{{ user?.email }}</p>
            </div>
          </div>
          <a class="dropdown-item" [routerLink]="['/account/profile']">
            <i class="ti-user m-r-5 m-l-5"></i> My Profile
          </a>
          <a class="dropdown-item" (click)="logout()">
            <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout
          </a>
        </div>
      </li>
      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
    </ul>
  </div>
</div>
