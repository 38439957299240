import { CategoryType } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

export interface CategoryPosButton {
  text: string;
  backgroundColor: string;
  textColor: string;
}

export interface CategorySchemaData {
  name: string;
  posName: string;
  type: CategoryType;
  button: CategoryPosButton;
  isParent?: boolean;
}

export type CategoryData = CategorySchemaData & AbstractModelData;

export type PopulatedCategoryData = CategoryData & {
  items: number;
};

export const CategorySchema: ISchema<CategorySchemaData> = {
  type: {
    section: 'Basic Info',
    side: 'left',
    type: Type.STRING,
    label: 'Type',
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(CategoryType),
    required: true,
  },
  isParent: {
    section: 'Basic Info',
    side: 'left',
    type: Type.BOOLEAN,
    label: 'Is Parent Item',
    description: 'A parent item can only be one which is synced',
    default: false,
    isHidden: true,
  },
  name: {
    section: 'Basic Info',
    side: 'left',
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  posName: {
    section: 'Basic Info',
    side: 'left',
    type: Type.STRING,
    label: 'POS Name',
    isHidden: true,
  },
  button: {
    section: 'POS Settings',
    label: 'Category POS Button',
    side: 'right',
    type: Type.OBJECT,
    notIf: 'isParent',
    properties: {
      text: {
        label: 'Text',
        type: Type.STRING,
      },
      backgroundColor: {
        label: 'Background Color',
        type: Type.COLOR,
      },
      textColor: {
        label: 'Text Color',
        type: Type.COLOR,
      },
    },
  },
};
