import { ApiDomain, ApiResources, ApiVersion, DiningOptionsBehavior } from '../../enums';
import { EnumHelper } from '../../helpers';
import { ISchema, Type, Widget } from '../schema';

export const ServiceChargesToDiningOptions: ISchema = {
  diningOptionUsageType: {
    section: 'Update Options',
    type: Type.STRING,
    label: 'Dining Options Types',
    multiple: true,
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(DiningOptionsBehavior),
    required: true,
  },
  locationIds: {
    section: 'Update Options',
    label: 'Locations',
    type: Type.ARRAY,
    items: {
      type: Type.TYPEAHEAD,
      label: 'Location Name',
      domain: ApiDomain.API,
      version: ApiVersion.V2,
      resource: ApiResources.LOCATION,
      modelProp: 'name',
      search: { isDeleted: false },
      displayFields: ['name'],
    },
  },
};
