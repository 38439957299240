import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type } from '../schema';

export interface CourseHoleData {
  number: number;
  name: string;
  par: number;
  distance: {
    yards: number;
    meters: number;
  };
  handicap: number;
  teeCoordinates: number[];
  fairwayCoordinates: number[];
  greenCoordinates: number[];
  layoutImages: any[];
  images: any[];
}

export interface CourseTeeData {
  name: string;
  color: string;
  distance: {
    yards: number;
    meters: number;
  };
  par: number;
  rating: number;
  slope: number;
  holes: CourseHoleData[];
}

export interface CourseSponsorData {
  images: any[];
  name: string;
  description: string;
  url: string;
  hole: number;
  isPublic: boolean;
}

export const CourseSchema: ISchema = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  venueId: {
    label: 'Venue',
    type: Type.STRING,
    isHidden: true,
  },
  coordinates: {
    label: 'Coordinates',
    type: Type.ARRAY,
    items: {
      label: 'Longitude/Latitude',
      type: Type.NUMBER,
    },
    partakeOnly: true,
  },
  images: {
    type: Type.IMAGES,
    label: 'Images',
    bucket: 'courses',
  },
  tees: {
    label: 'Tees',
    type: Type.ARRAY,
    items: {
      label: 'Tee',
      type: Type.OBJECT,
      properties: {},
    },
    partakeOnly: true,
    isHidden: true,
  },
  sponsors: {
    label: 'Sponsors',
    type: Type.ARRAY,
    items: {
      label: 'Tee',
      type: Type.OBJECT,
      properties: {},
    },
    isHidden: true,
  },
  isPublic: {
    label: 'Is Public',
    type: Type.BOOLEAN,
    partakeOnly: true,
    default: false,
  },
};

export interface CourseData extends AbstractModelData {
  name: string;
  coordinates: number[];
  images: any[]; // Image File
  tees: CourseTeeData[];
  sponsors: CourseSponsorData[];
  venueId: string;
  isDeleted: boolean;
  isPublic: boolean;
}

export class Course extends AbstractModel<CourseData> {
  constructor(public data: CourseData) {
    super(data);
  }
}
