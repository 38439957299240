export enum TenderType {
  CASH = 'cash',
  CREDIT = 'credit',
  MEMBER = 'member',
  GIFT_CARD = 'gift-card',
  EVENT = 'event',
}

export enum TenderProcedure {
  CASH = 'cash',
  CHECK = 'check',
  GIFT_CARD = 'giftcard',
  CREDIT_BOOK = 'creditbook',
  CREDIT = 'credit-debit',
  LOYALTY = 'loyalty',
  EVENT = 'events',
  // We don't want to use these and they need a migration to remove
  CREDIT_CARD_REFERENCE = 'creditcardreference',
  CREDIT_CARD_VERIFICATION = 'creditcardverification',
  DEBIT = 'debitcard',
  DIRECT_CREDIT_BOOK = 'directcreditbook',
  EZ_GOLF = 'ezgolf',
  GIFT_CERTIFICATE = 'giftcertificate',
  HOTEL = 'hotel_ver',
  MEMBER = 'member',
  MEMBER_CARD_ON_FILE = 'membercardonfile',
  NO_PROCEDURE = 'noprocedure',
  OTHER = 'sendtoped',
}
