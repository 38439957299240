import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {
  transform(item: string): string {
    let status;
    switch (item) {
      case 'pending':
        status = 'Pending';
        break;
      case 'intercept':
        status = 'Intercepted';
        break;
      case 'accepted':
        status = 'Accepted';
        break;
      case 'out-for-delivery':
        status = 'Out for Delivery';
        break;
      case 'ready-for-pickup':
        status = 'Ready for Pickup';
        break;
      case 'delivered':
        status = 'Closed';
        break;
      case 'cancelled':
        status = 'Cancelled';
        break;
      case 'point-of-sale':
        status = 'Tab';
        break;
    }
    return status;
  }
}
