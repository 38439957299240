import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderRestaurants',
})
export class OrderRestaurantsPipe implements PipeTransform {
  transform(order: any): any[] {
    if (!order) {
      return [];
    }

    const restaurants = [];

    restaurants.push(order.restaurant);

    order.items.forEach(item => {
      if (item.restaurant) {
        const inArray = restaurants.find(rest => {
          if (
            rest === item.restaurant ||
            rest._id === item.restaurant ||
            rest === item.restaurant._id ||
            rest._id === item.restaurant._id
          ) {
            return true;
          }
          return false;
        });
        if (!inArray) {
          restaurants.push(item.restaurant);
        }
      }
    });

    return restaurants;
  }
}
