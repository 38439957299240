import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';

import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';

import { Observable, of } from 'rxjs';

import { ApiQuery } from '@app/clients';
import { UserService } from '@app/providers/user/user.service';

@Component({
  selector: 'app-user-search-typeahead',
  templateUrl: './user-search-typeahead.component.html',
  styleUrls: ['./user-search-typeahead.component.scss'],
})
export class UserSearchTypeaheadComponent implements OnChanges {
  @Input() user: any;
  @Input() placeholder = 'Search';
  @Input() property = 'name';
  @Input() size = 'form-control';
  @Input() query = { type: 'consumer' };
  @Input() disabled = false;

  @Output() selected = new EventEmitter<any>();
  @Output() userChange = new EventEmitter<any>();

  public search: string;

  constructor(private _userService: UserService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.user?.currentValue) {
      if (typeof changes.user.currentValue === 'string') {
        this.search = changes.user.currentValue;
      } else {
        this.search = changes.user.currentValue[this.property];
      }
    }
  }

  public selectUser(selected: any) {
    selected.preventDefault();
    this.user = selected.item;
    this.search = this.user ? this.user[this.property] : '';
    this.selected.emit(this.user);
    this.userChange.emit(this.user);
  }

  searchUser = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        const query: ApiQuery = {
          ...this.query,
        };
        query[this.property] = value;
        return this._userService.find(query).pipe(
          map(x => x.users),
          catchError(() => of([]))
        );
      })
    );

  userFormatter = (value: any) => {
    if (value[this.property]) {
      return value[this.property];
    }
    return '';
  };

  change() {
    this.userChange.emit(this.user);
  }

  blur() {
    this.userChange.emit({ ...this.user, [this.property]: this.search });
  }
}
