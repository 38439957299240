import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shiftsFilter',
})
export class ShiftsFilterPipe implements PipeTransform {
  transform(filter: any): string {
    let value = '';
    switch (filter.property) {
      case 'employee':
        value = `Employee is ${filter.name}`;
        break;
      case 'concession':
        value = `Concession is ${filter.name}`;
        break;
      default:
        value = `${filter.property} is ${filter.name}`;
        break;
    }
    return value;
  }
}
