import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { ApiClient } from '@app/clients';
import { ApiDomain, ApiVersion } from '@app/enums';
import { AppInjector } from '@app/app-injector.service';
import { UserService } from '@app/providers/user/user.service';
import { ConfirmModalController } from '../confirm-modal/confirm-modal.controller';
import { ErrorService } from '../errors/errors.service';
import { selectOrg } from '../../state';
import { Org } from '../../models';

export class BaseComponent {
  protected subscriptions: Subscription[] = [];

  protected selectedOrg$: Observable<Org>;

  protected store: Store;

  protected apiClient: ApiClient;

  protected toast: ToastrService;

  protected confirmModalCtrl: ConfirmModalController;

  protected router: Router;

  protected location: Location;

  protected errorService: ErrorService;

  protected org: Org;

  public user: any;

  public keys = Object.keys;

  public loading: boolean;

  constructor() {
    const injector = AppInjector.getInjector();
    const http = injector.get(HttpClient);
    const userService = injector.get(UserService);
    this.user = userService.getUser();
    this.apiClient = new ApiClient(http).setDomain(ApiDomain.API, ApiVersion.V2);
    this.toast = injector.get(ToastrService);
    this.confirmModalCtrl = injector.get(ConfirmModalController);
    this.router = injector.get(Router);
    this.location = injector.get(Location);
    this.errorService = injector.get(ErrorService);
    this.store = injector.get(Store);
    this.selectedOrg$ = this.store.pipe(select(selectOrg));
  }

  protected clearSubscriptions(): void {
    while (this.subscriptions.length) {
      const sub = this.subscriptions.pop();
      sub.unsubscribe();
    }
  }

  async makeSafeRequest<T>(observable: Observable<T>, returnError = false): Promise<T> {
    try {
      this.loading = true;
      const result = await observable.toPromise();
      return result;
    } catch (err) {
      if (returnError) {
        return err?.error;
      }
      this.displayError(err);
      return null;
    } finally {
      this.loading = false;
    }
  }

  displayError(error: any) {
    if (error.name === 'HttpErrorResponse') {
      const message = error.error?.message || error.message || 'An unexpected error occured.';
      this.errorService.addError('danger', message);
    }
  }

  showToast(opts?: { message: string; color: 'error' | 'info' | 'success' | 'warning' }) {
    this.toast.show(opts.message, null, null, `toast-${opts.color}`);
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
type Constructor<T = {}> = new (...args: any[]) => T;

export function BaseDecorator<TBase extends Constructor>(Base: TBase) {
  return class extends Base {
    protected apiClient: ApiClient;
    protected toast: ToastrService;
    protected confirmModalCtrl: ConfirmModalController;

    public keys = Object.keys;

    public loading: boolean;

    constructor(...args: any[]) {
      // @ts-ignore
      super(...args);
      const injector = AppInjector.getInjector();
      const http = injector.get(HttpClient);
      this.apiClient = new ApiClient(http).setDomain(ApiDomain.API, ApiVersion.V2);
      this.toast = injector.get(ToastrService);
      this.confirmModalCtrl = injector.get(ConfirmModalController);
    }

    async makeSafeRequest<T>(observable: Observable<T>) {
      try {
        this.loading = true;
        const result = await observable.toPromise();
        return result;
      } catch (err) {
        console.log(err);
        this.displayError(err);
        return null;
      } finally {
        this.loading = false;
      }
    }

    displayError(error: any) {
      const message = error.error?.message || error.message || 'An unexpected error occured.';
      this.toast.error(message);
    }

    showToast(opts?: { message: string; color: string }) {
      this.toast.show(opts.message, null, null, opts.color);
    }
  };
}
