import { DaysOfTheWeek } from '../../enums';
import { EnumHelper, TimeHelper } from '../../helpers';
import { ISchema, Type, Widget } from '../schema';

interface TimeRange {
  startHour: number;
  startMinute: number;
  startTimeString: string;
  endHour: number;
  endMinute: number;
  endTimeString: string;
}

export const PromoPriceSchema: ISchema = {
  name: {
    type: Type.STRING,
    label: 'Name',
  },
  tag: {
    type: Type.STRING,
    label: 'Customer Facing Tag',
    description: 'E.g. Thirsty Thursday',
  },
  price: {
    type: Type.CURRENCY,
    label: 'Price',
    required: true,
  },
  daysOfWeek: {
    type: Type.ARRAY,
    label: 'Days of Week Active',
    description: 'On which days is this price active',
    required: true,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(DaysOfTheWeek),
      multiple: true,
    },
  },
  startHour: {
    type: Type.NUMBER,
    label: 'Start Hour',
    description: 'Hour of the day to start the promotion',
    isHidden: true,
  },
  startMinute: {
    type: Type.NUMBER,
    label: 'Start Minute',
    description: 'Hour of the day to start the promotion',
    isHidden: true,
  },
  startTimeString: {
    type: Type.STRING,
    label: 'Start Time',
    description: 'Hour and minute of the day to start the promotion (optional).',
    side: 'right',
    widget: {
      type: Widget.SELECT,
      enum: [{ name: 'All Day', key: null }, ...TimeHelper.getTimePeriodsWithTimeGap(15)],
    },
    onChange: (startTimeString: string, data: TimeRange) => {
      const [hour, minute] = (startTimeString || '').split(':');
      data.startTimeString = startTimeString;
      data.startHour = hour ? parseInt(hour, 10) : null;
      data.startMinute = minute ? parseInt(minute, 10) : null;
      if (!data.endTimeString) {
        data.endTimeString = startTimeString;
        data.endHour = data.startHour;
        data.endMinute = data.startMinute;
      }
      if (!startTimeString) {
        data.endTimeString = null;
        data.startHour = null;
        data.startMinute = null;
      }
    },
  },
  endHour: {
    type: Type.NUMBER,
    label: 'End Hour',
    description: 'Hour of the day to end the promotion',
    isHidden: true,
  },
  endMinute: {
    type: Type.NUMBER,
    label: 'End Minute',
    description: 'Hour of the day to end the promotion',
    isHidden: true,
  },
  endTimeString: {
    type: Type.STRING,
    label: 'End Time',
    description: 'Hour and minute of the day to end the promotion.',
    side: 'right',
    widget: {
      type: Widget.SELECT,
      enum: [{ name: 'All Day', key: null }, ...TimeHelper.getTimePeriodsWithTimeGap(15)],
    },
    onlyIf: [
      {
        field: 'startTimeString',
        shouldShow: (startTime: string | null) => !!startTime,
      },
    ],
    onChange: (endTimeString: string, data: TimeRange) => {
      const [hour, minute] = (endTimeString || '').split(':');
      data.endTimeString = endTimeString;
      data.endHour = hour ? parseInt(hour, 10) : null;
      data.endMinute = minute ? parseInt(minute, 10) : null;
      if (!endTimeString) {
        data.startTimeString = null;
        data.startHour = null;
        data.startMinute = null;
      }
    },
  },
  isActive: {
    type: Type.BOOLEAN,
    label: 'Active',
  },
};

export interface PromoPriceData {
  name: string;
  price: number;
  daysOfWeek: DaysOfTheWeek[];
  tag?: string;
  startHour?: number;
  startMinute?: number;
  endHour?: number;
  endMinute?: number;
  isActive: boolean;
}
