import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerCharge',
})
export class CustomerChargePipe implements PipeTransform {
  transform(config: any, args?: any): any {
    if (!config) {
      return 'TBC';
    }
    return `$${config.serviceFee * 0.01}`;
  }
}
