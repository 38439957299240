import { ApiResources, ApiVersion, TableName, TableType } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

export const FloorPlanSchema: ISchema<Pick<FloorPlanData, 'name' | 'locationId'>> = {
  name: {
    section: 'Floor Plan',
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  locationId: {
    section: 'Floor Plan',
    type: Type.STRING,
    label: 'Location',
    widget: {
      type: Widget.SELECT,
      enum: [],
      options: {
        version: ApiVersion.V2,
        resource: ApiResources.LOCATION,
        modelProp: 'name',
        search: { isDeleted: false, limit: 100 },
        displayFields: ['name'],
      },
    },
    required: true,
  },
};

export const DiningAreaSchema: ISchema<Pick<DiningArea, 'name' | 'width' | 'length'>> = {
  name: {
    section: 'Dining Area',
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  width: {
    section: 'Dining Area',
    type: Type.NUMBER,
    label: 'Width (feet)',
    required: true,
  },
  length: {
    section: 'Dining Area',
    type: Type.NUMBER,
    label: 'Length (feet)',
    required: true,
  },
};

export const TableSchema: ISchema<Omit<Table, 'id' | 'position'>> = {
  name: {
    section: 'Table',
    side: 'left',
    type: Type.STRING,
    label: 'Name',
    required: true,
  },
  type: {
    section: 'Table',
    side: 'right',
    type: Type.STRING,
    label: 'Type',
    required: true,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(TableType),
    },
  },
  tableName: {
    section: 'Table',
    type: Type.STRING,
    label: 'Table Name',
    required: true,
    isHidden: true,
  },
  minPartySize: {
    section: 'Table',
    side: 'left',
    type: Type.NUMBER,
    label: 'Min Party',
    required: true,
  },
  maxPartySize: {
    section: 'Table',
    side: 'right',
    type: Type.NUMBER,
    label: 'Max Party',
    required: true,
  },
};

export interface Table {
  id: string;
  name: string;
  type: TableType;
  tableName: TableName;
  minPartySize: number;
  maxPartySize: number;
  position: {
    rotation: number;
    x: number;
    y: number;
  };
}

export interface DiningArea {
  name: string;
  slug: string;
  width: number;
  length: number;
  tables: Table[];
}

export interface FloorPlanData extends AbstractModelData {
  name: string;
  locationId: string;
  diningAreas: DiningArea[];
}

export class FloorPlan extends AbstractModel<FloorPlanData> {
  constructor(public data: FloorPlanData) {
    super(data);
  }
}
