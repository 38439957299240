import { ISchema, Type, Widget } from '../schema';

export const ICopyToMenu: ISchema = {
  locationIds: {
    section: 'Location Selection',
    type: Type.STRING,
    label: 'Locations',
    widget: {
      type: Widget.SELECT,
    },
    enum: [],
    multiple: true,
    required: true,
  },
};
