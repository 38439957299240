import { Component, EventEmitter, Input, Output } from '@angular/core';

import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import _ from 'underscore';

import { MenuItemModifierService } from '@app/providers/restaurant/menu-item-modifier.service';

@Component({
  selector: 'app-modifier-search-typeahead',
  templateUrl: './modifier-search-typeahead.component.html',
  styleUrls: ['./modifier-search-typeahead.component.scss'],
})
export class ModifierSearchTypeaheadComponent {
  @Input() type: string | string[] = 'food';
  @Input() disabled: boolean;
  @Input() clearOnSelect: boolean;

  @Output() selected = new EventEmitter<any>();

  public value: string;
  public selectedItem = null;

  constructor(private _modifierService: MenuItemModifierService) {}

  public selectModifier(selected: any) {
    if (selected) {
      this.selectedItem = selected.item;
      this.selected.emit(selected.item);

      if (this.clearOnSelect) {
        this.value = '';
      }
    } else if (this.selectedItem) {
      this.selectedItem = null;
      this.selected.emit(null);
    }
  }

  public onChange(value: any) {
    if (!value || typeof value === 'string') {
      this.selectModifier(null);
    }
  }

  searchModifier = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(name => {
        const query = _({ name, limit: 100, type: this.type }).pick(value => value !== undefined);
        return this._modifierService.self(query).pipe(
          map(x => x.modifiers),
          catchError(() => of([]))
        );
      })
    );

  // eslint-disable-next-line class-methods-use-this
  modifierFormatter = (x: { name: string; type: string }) => {
    if (x.name) {
      return `${x.name}`;
    }
    return '';
  };
}
