/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ApiQuery, ApiQueryParams } from '@app/clients';
import { LocalStorage } from '../../helpers';

@Injectable({ providedIn: 'root' })
export class UserService {
  private domain = environment.url_v2;
  // OVERIDE if using a new version for this feature
  private path = 'user';
  private baseUrl = `${this.domain}/${this.path}`;

  constructor(
    private _router: Router,
    private _http: HttpClient
  ) {}

  public me(): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/self`);
  }

  public find(query: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this._http.get<any>(`${this.baseUrl}`, { params });
  }

  /**
   * @deprecated New guest service for future usage
   * @param user
   */
  public create(user: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}`, user);
  }

  public update(id: string, body: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}`, body);
  }

  public logout() {
    localStorage.clear();
    LocalStorage.clear();
    this._router.navigate(['/']);
  }

  // Save the auth token in local storage
  public setUser(user?: any) {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }

  public getUser(): any {
    const userString = localStorage.getItem('user');
    let user: any;
    if (userString) {
      user = JSON.parse(userString);
    }
    return user;
  }
}
