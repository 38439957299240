import { IntegrationSystem, IntegrationType } from '@app/enums';
import { Integration, IIntegrationBase } from './integration';

export const CloudflareSchema = {
  system: {
    type: 'string',
    isHidden: true,
    default: IntegrationSystem.CLOUDFLARE,
  },
  type: {
    type: 'string',
    isHidden: true,
    default: IntegrationType.DNS,
  },
  apiToken: {
    type: 'string',
    required: true,
    label: 'Api Token',
    description: 'The API token for your own Cloudflare account',
  },
};

export interface ICloudflare extends IIntegrationBase {
  apiToken: string;
}

export class Cloudflare extends Integration<ICloudflare> {
  constructor(public data: ICloudflare) {
    super(data);
  }
}
