import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-payment-items',
  templateUrl: './payment-items.component.html',
  styleUrls: ['./payment-items.component.scss'],
})
export class PaymentItemsComponent {
  @Input() edit = true;
  @Input() items: Array<any> = [];
  @Output() itemsChange = new EventEmitter();
  @Output() removeItem = new EventEmitter();

  public change() {
    this.itemsChange.emit(true);
  }

  public remove(item: any) {
    this.removeItem.emit(item);
  }
}
