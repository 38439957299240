import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderType',
})
export class OrderTypePipe implements PipeTransform {
  transform(isConsumerOrder: boolean): string {
    if (isConsumerOrder) {
      return 'Mobile / Online';
    }
    return 'POS';
  }
}
