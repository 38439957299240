<div class="col">
  <div class="col-md-12">
    <label>Cash Payment</label>
  </div>
  <div class="col text-center">
    <h5>Change Due</h5>
    <h2 [ngClass]="{ 'text-danger': changeDue < 0 }">
      {{ changeDue * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
    </h2>
  </div>
  <div class="form-horizontal form-material">
    <div class="mb-3">
      <div class="col-md-12">
        <app-price-input [value]="amountTendered" (valueChange)="change($event)"></app-price-input>
      </div>
    </div>
  </div>
  <div class="row p-2 mb-2">
    <div class="col">
      <button
        class="btn btn-outline-medium btn-block"
        [ngClass]="{ active: amountTendered === 1000 }"
        (click)="change(1000)"
      >
        $10
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-outline-medium btn-block"
        [ngClass]="{ active: amountTendered === 2000 }"
        (click)="change(2000)"
      >
        $20
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-outline-medium btn-block"
        [ngClass]="{ active: amountTendered === 5000 }"
        (click)="change(5000)"
      >
        $50
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-outline-medium btn-block"
        [ngClass]="{ active: amountTendered === 10000 }"
        (click)="change(10000)"
      >
        $100
      </button>
    </div>
  </div>
  <div class="col mt-3">
    <button class="btn btn-secondary btn-block" (click)="chargeCash()">Complete Payment</button>
  </div>
</div>
