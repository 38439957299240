<input
  type="text"
  [disabled]="disabled"
  [class]="size"
  [(ngModel)]="search"
  [ngbTypeahead]="searchGuest"
  [placeholder]="placeholder"
  [resultTemplate]="selectedGuestTpl"
  (selectItem)="selectGuest($event)"
  (blur)="blur()"
/>
<ng-template #selectedGuestTpl let-r="result">
  <div>{{ r.name }} ({{ r.ordersSummary?.total }} orders)</div>
  <span *ngIf="r.email"> <i class="fas fa-envelope"></i> {{ r.email }} </span>
  <span *ngIf="r.phone"> <i class="fas fa-phone"></i> {{ r | userPhone }} </span>
</ng-template>
