import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pad',
})
export class PadPipe implements PipeTransform {
  constructor() {}

  transform(value: number): string {
    if (value > -1) {
      return value < 10 ? `0${value}` : `${value}`;
    }
    return '';
  }
}
