import { Pipe, PipeTransform } from '@angular/core';

import { TitleCasePipe } from './title-case.pipe';

@Pipe({
  name: 'venueFilter',
})
export class VenueFilterPipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) {}

  transform(filter: any): string {
    let value = '';
    switch (filter.property) {
      case 'name':
        value = `Name contains ${filter.value}`;
        break;
      case 'diningOptionUsageType':
        value = `Dining Option Usage is ${this.titleCasePipe.transform(filter.value)}`;
        break;
      case 'location':
      case 'locationId':
      case 'restaurant':
        value = `Location contains ${filter.value}`;
        break;
      case 'whiteLabelId':
      case 'appId':
        value = `App ID is ${filter.value}`;
        break;
      case 'accountId':
      case 'account':
        value = `Account is ${filter.value}`;
        break;
      case 'type':
        value = `Type is ${filter.value}`;
        break;
      case 'isPublic':
        value = `Visibility is ${filter.value === 'true' ? 'public' : 'hidden'}`;
        break;
      case 'posOnly':
        value = `Is ${filter.value === 'true' ? '' : 'Not '}POS Only `;
        break;
      case 'isMiniGame':
        value = `${filter.value === 'true' ? 'Is Mini Game' : 'Is Not Mini Game'}`;
        break;
      default:
        value = `${this.titleCasePipe.transform(filter.name || filter.property)} is ${
          filter.value
        }`;
        break;
    }
    return value;
  }
}
