import { ISchema, Type } from '../schema';

export const ResetPasswordSchema: ISchema = {
  password: {
    label: 'Password',
    description:
      'Min 8 characters. Only alphanumeric and special characters .!@#$%^&*()_+-= accepted.',
    type: Type.PASSWORD,
    required: true,
  },
  confirmPassword: {
    label: 'Confirm Password',
    type: Type.PASSWORD,
    required: true,
  },
};

export interface ResetPasswordData {
  password: string;
  confirmPassword: string;
}
