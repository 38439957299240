import { ISchema, Type } from '../schema';

export const TabSchema: ISchema = {
  title: {
    type: Type.STRING,
    label: 'Tab Title',
    description: 'Tab Name - label that will be displayed to the user.',
    required: true,
  },
  iconName: {
    type: Type.STRING,
    label: 'Icon Name',
    description: 'View https://ionicons.com for options',
    required: true,
  },
  url: {
    type: Type.STRING,
    label: 'App Url',
    description: 'The view which you want the tab to go to.',
    required: true,
  },
  id: {
    type: Type.STRING,
    label: 'ID',
    isHidden: true,
  },
  venueId: {
    type: Type.STRING,
    label: 'Venue',
    isHidden: true,
  },
};

export const TabsSchema: ISchema = {
  tabs: {
    type: Type.ARRAY,
    label: 'Navigation Tabs',
    items: {
      type: Type.OBJECT,
      label: null,
      properties: TabSchema,
    },
  },
};
