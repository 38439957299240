<div class="col mb-5">
  <div class="col-md-12 text-center">
    <h5>Add Gratuity</h5>
  </div>
  <div class="row">
    <div class="col" *ngFor="let grat of gratOptions">
      <button
        [disabled]="order.isConsumerOrder"
        class="btn btn-secondary btn-block"
        [class.btn-light]="selectedGratuity !== grat.id"
        (click)="setGratuity(grat.id)"
      >
        {{ grat.name }}
      </button>
    </div>
    <div class="col" *ngIf="selectedGratuity === 'other'">
      <app-price-input [(value)]="customGrat" (valueChange)="setCustomGratuity()"></app-price-input>
    </div>
  </div>
</div>
