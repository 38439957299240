import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type } from '../schema';

export const ImageSchema: ISchema = {
  name: {
    section: 'basic',
    type: Type.STRING,
    required: true,
    immutable: true,
    label: 'Name',
  },
  url: {
    section: 'basic',
    type: Type.STRING,
    immutable: true,
    label: 'Url',
    onlyIf: '_id',
  },
  tags: {
    section: 'basic',
    type: Type.ARRAY,
    label: 'Tags',
    immutable: true,
    items: {
      type: Type.STRING,
      label: 'Tag',
    },
  },
  images: {
    section: 'basic',
    type: Type.IMAGES,
    label: 'Image',
    bucket: '',
    immutable: true,
  },
};

export interface IImage extends AbstractModelData {
  url: string;
  name: string;
  tags: Array<string>;
  images: Array<any>;
}

export class Image extends AbstractModel<IImage> {
  constructor(public data: IImage) {
    super(data);
  }
}
