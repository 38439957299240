import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ApiClient } from '../../clients';
import { ApiDomain, ApiHeader, ApiResources, ApiVersion } from '../../enums';
import { LocalStorage } from '../../helpers';
import { VenueActions } from './venue.actions';

@Injectable()
export class VenueEffects {
  private apiClient: ApiClient;

  selectVenue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VenueActions.VENUE_SELECTED),
        tap((payload: any) => {
          if (payload.selectedVenue) {
            LocalStorage.addItem(ApiHeader.VENUE_ID, payload.selectedVenue.id);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    httpClient: HttpClient,
    private actions$: Actions
  ) {
    this.apiClient = new ApiClient(httpClient).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.VENUES
    );
  }
}
