<div class="col">
  <div class="row item-row titles">
    <div class="col description">Description</div>
    <div class="col text-right">Price</div>
    <div class="col text-center">Qty</div>
    <div class="col text-right">Subtotal</div>
    <div class="col delete"></div>
  </div>
  <div class="row item-row" *ngFor="let item of items">
    <div class="col description">
      {{ item.item.name }}
    </div>
    <div class="col text-right">
      {{ (item.price / item.quantity) * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
    </div>
    <div class="col text-center">
      {{ item.quantity }}
    </div>
    <div class="col text-right">
      {{ item.price * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
    </div>
    <div class="col delete">
      <button class="btn btn-outline-danger btn-sm" [disabled]="!edit" (click)="remove(item)">
        <i class="fas fa-trash-alt"></i>
      </button>
    </div>
  </div>
</div>
