<ng-container *ngIf="address">
  <div class="mb-3">
    <label>Search Address</label>
    <input
      type="text"
      placeholder="Search address"
      ngx-gp-autocomplete
      [options]="gMapsOptions"
      #placesRef="ngx-places"
      name="addressSearch"
      [(ngModel)]="address.formatted"
      (onAddressChange)="addressSearchChange($event)"
      class="form-control form-control-line"
    />
  </div>
  <div class="mb-3">
    <label>Address Line 1</label>
    <input
      type="text"
      [(ngModel)]="address.line1"
      name="Line 1"
      class="form-control form-control-line"
    />
  </div>
  <div class="mb-3">
    <label>Address Line 2</label>
    <input
      type="text"
      [(ngModel)]="address.line2"
      name="Line 1"
      class="form-control form-control-line"
    />
  </div>
  <div class="mb-3">
    <label>City</label>
    <input
      type="text"
      [(ngModel)]="address.city"
      name="City"
      class="form-control form-control-line"
    />
  </div>
  <div class="mb-3">
    <label>State</label>
    <input
      type="text"
      [(ngModel)]="address.state"
      name="State"
      class="form-control form-control-line"
    />
  </div>
  <div class="mb-3">
    <label>ZIP</label>
    <input
      type="text"
      [(ngModel)]="address.zip"
      name="ZIP"
      class="form-control form-control-line"
    />
  </div>
</ng-container>
