import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutofocusDirective } from './autofocus.directive';
import { AutoselectDirective } from './autoselect.directive';
import { PassiveLinkDirective } from './passive-link.directive';
import { ThSortableDirective } from './th-sortable.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AutofocusDirective,
    AutoselectDirective,
    PassiveLinkDirective,
    ThSortableDirective,
  ],
  exports: [AutofocusDirective, AutoselectDirective, PassiveLinkDirective, ThSortableDirective],
})
export class DirectivesModule {}
