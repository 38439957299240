import _ from 'lodash';
import {
  DiningOptionsBehavior,
  DiningOptionsOrderType,
  FulfillmentState,
  OrderSource,
  OrderStatus,
  SentNotificationStatus,
  SentNotificationType,
} from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel } from '../abstract.model';
import { DiningOptionData } from '../concession';
import { ISchema, Type, Widget } from '../schema';

export interface OrderDestination {
  diningOptionId: string;
  behaviour: DiningOptionsBehavior;
  orderType: DiningOptionsOrderType;
  location?: {
    option1?: string;
    option2?: string;
    option3?: string;
    option4?: string;
  };
  selectedDate?: string;
  selectedTime?: string;
  selectedTimeString?: string;
  teeTimeId?: string;
  teeTimeBookingId?: string;
  teeTimePlayerIds?: string[];
}

export interface OrderedItemModifier {
  _id?: string;
  modifierSet: string;
  modifierSetOption: string;
  priceOptionId: string;
  name: string;
  price: number;
  unitPrice: number;
  quantity: number;
  taxIncluded: boolean;
  taxes: OrderedItemTax[];
  taxTotal: number;
  discounts: OrderedItemDiscountVoucher[];
  discountTotal: number; // Vouchers and Discounts
  stations: string[];
  isReady?: boolean;
  /**
   * @deprecated
   */
  item: string; // Modifier
}

export interface OrderedItemTax {
  id: string;
  total: number;
  ptTotal: number;
}

export interface OrderedItemDiscountVoucher {
  id: string;
  total: number;
}

export interface OrderedItem {
  _id?: string;
  name: string;
  item: string;
  priceOptionId: string;
  restaurant: string;
  shift: string;

  price: number; // This is the total price after modifiers and quantity
  quantity: number;
  unitPrice: number;

  /** Modifier Info */
  modifiers: OrderedItemModifier[];
  modifierTaxTotal: number;
  modifierTaxIncludedTaxTotal: number;
  modifierTotal: number;

  /** Taxes For Item */
  taxIncluded: boolean;
  taxes: OrderedItemTax[];
  itemTaxTotal: number;
  taxIncludedTaxTotal: number;
  taxTotal: number;

  vouchers: OrderedItemDiscountVoucher[];
  discounts: OrderedItemDiscountVoucher[];
  itemDiscountTotal: number;
  modifierDiscountTotal: number;
  discountTotal: number; // Vouchers and Discounts

  stations: string[];

  isAlcoholic: boolean;
  instructions: string;

  isReady: boolean;
  isDelivered: boolean;
  isSaved: boolean;

  sendEventId?: number;

  employee: string;
  added?: Date;
  holdAt?: Date;
  savedAt?: Date;
  readyAt?: Date;
  removedAt?: Date;
  removedShift?: any;
  voidedAt?: Date;
  voidShift?: any;
  editedAt?: Date;
  editShift?: any;

  loc?: number[];
}

export interface OrderServiceCharge {
  name: string;
  amount: number;
  serviceChargeId: string;
  isPartake: boolean;
}

export interface EmailContent {
  htmlContent: string;
  htmlUrl: string;
}

export type SmsContent = {
  smsContent: string;
  shortHtmlUrl: string;
  clicks: number;
} & EmailContent;

export type Content = SmsContent & EmailContent;

export interface Context {
  orderId?: string;
  campaignId?: string;
  dateString?: string;
}

export interface Recipient {
  guestId?: string;
  email?: string;
  bcc?: string[];
  phone?: string;
}
export interface SentNotificationData {
  notificationType: SentNotificationType;
  status: SentNotificationStatus;
  content: Content;
  recipient: Recipient;
  context: Context;
  error: any;
  sentAt?: Date;
  updatedAt: Date;
  orgId: string;
}

export interface OrderData {
  _id?: string;
  orderNumber?: number;
  venue: string;
  restaurant: string;

  tags: string[];

  createdBy: string;
  user: string;
  guest: string;

  shift?: string;
  employee?: string;

  coverCount?: number;

  fulfillmentState: FulfillmentState;

  latestSendEventId?: number;

  // We will move all of the other items into this when ready.
  items: OrderedItem[];

  // This is when the item is removed before its been sent to the kitchen during a session if a guest changes their mind
  removed: OrderedItem[];

  // This is when the item is removed after its been sent to the kitchen
  void: OrderedItem[];

  instructions: string;

  isConsumerOrder: boolean;
  isFirstOrder: boolean;

  // If shared between concessions - set by the POS app
  isShared: boolean;
  orderDestination: OrderDestination;

  voucher?: string;

  /* ACCOUNTING */
  subTotal: number;
  discount: number;
  venueTax: number;
  partakeTax: number;
  taxTotal: number;
  taxIncludedTaxTotal: number;
  gratuity: number;
  roundUpTotal: number;
  total: number;
  due: number;
  amountPaid: number;
  refundTotal: number;

  gratuityId?: string;

  serviceCharges: OrderServiceCharge[];
  serviceFee: number;
  partakeServiceFee: number;

  shouldRoundUp: boolean;
  paid: boolean;
  ageVerified?: boolean;
  alcoholCount?: number;
  status: OrderStatus;
  source: OrderSource;

  updated?: Date;
  created?: Date;

  expectedPickup?: Date;
  utcOffset?: number;

  acceptedTime?: Date;
  readyTime?: Date;

  pickupTime?: Date;
  delivered?: Date;
  closed?: Date;

  transactions: any[];
  cardOnFile?: any;
  guestCardsOnFile?: any[];
  activeDiningOption: DiningOptionData;

  locationIds: string[];
  searchableFields: string[];

  notificationSendEvents: SentNotificationData[];
}

export const UpdateOrderSchema: ISchema<Pick<OrderData, 'status'>> = {
  status: {
    type: Type.STRING,
    label: 'Status',
    required: true,
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(OrderStatus),
    },
  },
};

export class Order extends AbstractModel<OrderData> {
  static readonly keysToClean = [
    'item',
    'restaurant',
    'shift',
    'employee',
    'modifiers',
    'taxes',
    'id',
  ];

  static readonly openOrderStatuses: OrderStatus[] = [
    OrderStatus.PENDING,
    OrderStatus.ACCEPTED,
    OrderStatus.READY_FOR_PICKUP,
    OrderStatus.OUT_FOR_DELIVERY,
    OrderStatus.POINT_OF_SALE,
  ];

  private orderedItemsById: Record<string, any>;

  private transactions: any[];

  public itemsToFulfillCount = 0;

  constructor(public data: OrderData) {
    super(data);
    if (_.size(this.data?.items)) {
      this.orderedItemsById = _.keyBy(
        this.data.items.map(item => item.item),
        '_id'
      );
      this.setItemNames();
      this.data.items = Order.stripDownToIds(this.data.items);
      this.setFulfillCount();
    }
    this.transactions = data.transactions || [];
  }

  get itemCount() {
    return _.size(this.data.items);
  }

  get taxesAndFees() {
    const taxes = this.data.taxTotal || 0;
    const fees = this.data.serviceFee || 0;
    const roundUp = this.data.roundUpTotal || 0;
    return taxes + fees + roundUp;
  }

  get due() {
    return this.data.due;
  }

  get total() {
    return this.getTotal();
  }

  get canSave() {
    const orderBehavior = this.get('orderDestination.behaviour');
    return [
      DiningOptionsBehavior.TAB,
      DiningOptionsBehavior.TABLE,
      DiningOptionsBehavior.EVENT,
    ].includes(orderBehavior);
  }

  get isTab() {
    const orderBehavior = this.get('orderDestination.behaviour');
    return orderBehavior === DiningOptionsBehavior.TAB;
  }

  get isEvent() {
    const orderBehavior = this.get('orderDestination.behaviour');
    return orderBehavior === DiningOptionsBehavior.EVENT;
  }

  get canSend() {
    const orderBehavior = this.get('orderDestination.behaviour');
    return [DiningOptionsBehavior.DELIVERY, DiningOptionsBehavior.PICKUP].includes(orderBehavior);
  }

  private setItemNames() {
    this.data.items.forEach((item: OrderedItem) => {
      if (!item.name) {
        item.name = _.get(item, 'item.name');
      }
    });
  }

  private setFulfillCount() {
    this.itemsToFulfillCount = this.getItemsToFulfillIds().length;
  }

  public getItemsToFulfillIds(): string[] {
    const ids = [];
    this.data.items.forEach((item: OrderedItem) => {
      if (!item.isReady) {
        ids.push(item._id);
      }
    });
    return ids;
  }

  public setAllItemsReady(): void {
    this.data.items.forEach((item: OrderedItem) => {
      item.isReady = true;
    });
    this.setFulfillCount();
  }

  public static stripDownToIds<T>(items: Array<any>): Array<T> {
    return items.map((item: any) => {
      const copy = _.cloneDeep(item);
      _.each(Order.keysToClean, (key: string) => {
        const propertyValue = _.get(copy, key);
        if (_.isObject(propertyValue)) {
          copy[key] = _.get(propertyValue, '_id');
        }
        if (_.isArray(propertyValue)) {
          copy[key] = Order.stripDownToIds(propertyValue);
        }
      });
      return {
        ...copy,
      } as T;
    });
  }

  public getOrderedItemsById() {
    return this.orderedItemsById;
  }

  private setSubTotal() {
    const subTotal = this.data.items.reduce(
      (total: number, item: OrderedItem) => total + item.price,
      0
    );
    this.set('subTotal', subTotal);
  }

  private setDiscountTotal() {
    const discountTotal = this.data.items.reduce((total: number, item: OrderedItem) => {
      if (item.discountTotal) {
        return total + item.discountTotal;
      }
      return total;
    }, 0);
    this.set('discount', discountTotal);
  }

  private setTaxTotal() {
    const taxTotal = this.data.items.reduce(
      (total: number, item: OrderedItem) => total + item.taxTotal,
      0
    );
    this.data.taxTotal = taxTotal;
    this.data.venueTax = taxTotal; // TODO - REMOVE
  }

  private setServiceFee() {
    if (this.data.isConsumerOrder) {
      this.data.serviceFee = 99;
    } else {
      this.data.serviceFee = 0;
    }
  }

  private setRoundUpTotal() {
    if (!this.data.shouldRoundUp) {
      this.data.roundUpTotal = 0;
      return;
    }
    const preRoundTotal =
      this.data.subTotal +
      this.data.serviceFee +
      this.data.taxTotal +
      this.data.gratuity -
      this.data.discount;
    const roundedUpTotal = Math.ceil(preRoundTotal * 0.01) * 100;
    this.data.roundUpTotal = roundedUpTotal - preRoundTotal;
  }

  private getTotal() {
    return (
      this.data.subTotal +
      this.data.serviceFee +
      this.data.taxTotal +
      this.data.roundUpTotal +
      this.data.gratuity -
      this.data.discount
    );
  }

  private setTotal() {
    const total = this.getTotal();
    this.set('total', total);
  }

  private setAmountPaid() {
    if (_.size(this.transactions)) {
      const amountPaid = this.transactions.reduce((paidTotal: number, transaction: any) => {
        if (transaction.state === 'captured') {
          if (transaction.type === 'sale') {
            return paidTotal + transaction.amount;
          }
          if (transaction.type === 'refund') {
            return paidTotal - transaction.amount;
          }
        }
        return paidTotal;
      }, 0);
      this.set('amountPaid', amountPaid);
    } else {
      this.set('amountPaid', 0);
    }
  }

  private setDue() {
    const amountDue = this.get('total') - this.get('amountPaid');
    this.set('due', amountDue);
  }

  public addToOrder(item: OrderedItem): void {
    this.data.items.push(item);
    if (item.isAlcoholic) {
      this.set('alcoholCount', this.get('alcoholCount') + item.quantity);
    }
    this.updateCosts();
    this.setFulfillCount();
  }

  public removeFromOrder(idx: number): OrderedItem {
    const [removedOrderedItem]: OrderedItem[] = this.data.items.splice(idx, 1);
    if (removedOrderedItem.isAlcoholic) {
      this.set('alcoholCount', this.get('alcoholCount') - removedOrderedItem.quantity);
    }
    this.updateCosts();
    this.setFulfillCount();
    return removedOrderedItem;
  }

  public setItemsToSaved() {
    this.data.items.forEach((item: OrderedItem) => {
      item.isSaved = true;
    });
  }

  public updateCosts(): void {
    this.setSubTotal();
    this.setDiscountTotal();
    this.setTaxTotal();
    this.setServiceFee();
    this.setRoundUpTotal();
    this.setTotal();
    this.setAmountPaid();
    this.setDue();
  }

  get json() {
    return {
      ...this.data,
      deliveryOption: _.toLower(this.get('orderDestination.behaviour')),
    };
  }
}
