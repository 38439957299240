import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SharedModule } from '@app/shared/shared.module';
import { FullComponent } from './full/full.component';
import { BlankComponent } from './blank/blank.component';

@NgModule({
  declarations: [FullComponent, BlankComponent],
  imports: [CommonModule, RouterModule, NgbModule, NgScrollbarModule, SharedModule],
  exports: [FullComponent, BlankComponent],
  providers: [],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutModule {}
