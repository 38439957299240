import { ExportType, ReportType, UserRoles } from '@app/enums';
import { PdfHelper } from '../../helpers';
import { PdfContent, PdfSectionCreateParams } from '../../helpers/pdf.helper';
import { ISchema, Type } from '../schema';
import { Report, ReportPeriodParams } from './report';
import { SalesReportResponseData } from './report-export-summaries';
import {
  getReportPeriod,
  ModifierProductCategorySalesSummary,
  ModifierProductSalesByBehaviorSummary,
  ModifierProductSalesByEventSummary,
  ModifierProductSalesByEmployeeSummary,
  ModifierProductSalesSummary,
  OrdersSummary,
  PrintedAt,
  ProductCategorySalesSummary,
  ProductSalesByBehaviorSummary,
  ProductSalesByEventSummary,
  ProductSalesByEmployeeSummary,
  ProductSalesSummary,
  SalesSummary,
} from './report-sections';

export const ProductSalesReportSchema: ISchema = {
  from: {
    section: 'basic',
    description: 'The starting period',
    type: Type.DATE,
    default: () => new Date(),
    label: 'Start Date',
    required: true,
  },
  to: {
    section: 'basic',
    description: 'The end of a the period',
    type: Type.DATE,
    label: 'End Date',
  },
};

export interface ProductSalesReportData {
  from: string;
  to: string;
}

export class ProductSalesReport extends Report<ProductSalesReportData> {
  ignoreProperties = [];
  icon = 'fas fa-tags';
  title = 'Product Sales Report';
  description =
    'Export sales for all products for orders placed during a particular period. This includes Category and Product information for sales of Menu Items and Modifiers.';
  data: ProductSalesReportData = {} as ProductSalesReportData;
  isImmediate = true;
  schema: ISchema = ProductSalesReportSchema;
  roles: UserRoles[] = [UserRoles.ADMIN, UserRoles.CUSTOMER];
  type: ReportType = ReportType.SALES;
  exportOptions: ExportType[] = [ExportType.PDF];

  pdfSectionCreateParams: PdfSectionCreateParams<SalesReportResponseData>[] = [
    OrdersSummary,
    SalesSummary,
    ProductCategorySalesSummary,
    ModifierProductCategorySalesSummary,
    ProductSalesSummary,
    ModifierProductSalesSummary,
    ProductSalesByBehaviorSummary,
    ModifierProductSalesByBehaviorSummary,
    ProductSalesByEventSummary,
    ModifierProductSalesByEventSummary,
    ProductSalesByEmployeeSummary,
    ModifierProductSalesByEmployeeSummary,
  ];

  public generateExport(period: ReportPeriodParams, responseData: SalesReportResponseData): void {
    const pdfContent: PdfContent = PdfHelper.generateContent<SalesReportResponseData>(
      'Product Sales Report',
      [PrintedAt, getReportPeriod(period, true)].concat(this.pdfSectionCreateParams),
      responseData
    );
    PdfHelper.generatePdf(pdfContent, 'product-sales-report');
  }
}
