import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderData, ProductData, TransactionData } from '../../models';
import { BaseComponent } from '../../shared/components/base.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent extends BaseComponent implements OnInit {
  private searchText: string;

  public orders: OrderData[] = [];

  public transactions: TransactionData[] = [];

  public products: ProductData[] = [];

  public searching = true;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.searchText = params.query;
      if (this.searchText) {
        this.search();
      }
    });
  }

  public goToResource(resource: string, id: string) {
    this.router.navigate([resource, id]);
  }

  public async search() {
    this.searching = true;
    const query = `{
      search(query: "${this.searchText}") {
        getAll {
          orders {
            _id
            items {
              name
              price
              quantity
            }
            orderNumber
            subTotal
            taxTotal
            total
            created
          }
          transactions {
            _id
            id
            state
            result
            type
            amount
            gratuity
            serviceFee
            last4
            cardScheme
            cardEntryMethod
            nameOnCard
            orderId
            createdAt
          }
          products {
            _id
            name
            description
            type
            priceOptions {
              name
              price
              taxIncluded
            }
            isAlcoholic
          }
        }
      }
    }`;
    const searchResult = await this.makeSafeRequest<{
      data: {
        search: {
          getAll: {
            orders: OrderData[];
            transactions: TransactionData[];
            products: ProductData[];
          };
        };
      };
    }>(this.apiClient.makeGraphQlRequest(query));
    if (searchResult) {
      const { data } = searchResult;
      this.orders = data.search.getAll.orders;
      this.transactions = data.search.getAll.transactions;
      this.products = data.search.getAll.products;
    }
    this.searching = false;
  }
}
