<ng-container *ngIf="!searching && !orders.length && !transactions.length && !products.length">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body text-center">
          <h3>No Results</h3>
          <p>
            Oops! It looks like nothing matches your search. Trying reducing the number of keywords
            or try another query.
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="orders.length">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h3>Orders</h3>
          <div class="table-responsive table-sm">
            <table class="table v-middle table-hover">
              <thead>
                <tr>
                  <th width="15%">Total</th>
                  <th width="20%">ID</th>
                  <th>Items</th>
                  <th width="20%">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="clickable"
                  *ngFor="let order of orders"
                  (click)="goToResource('orders', order._id)"
                >
                  <td>{{ order.total * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}</td>
                  <td>{{ order.orderNumber }}</td>
                  <td>
                    <span class="comma-list" *ngFor="let item of order.items"
                      >{{ item.name }} ({{ item.quantity }})</span
                    >
                  </td>
                  <td>{{ order.created | date : 'MMM dd, YYYY, hh:mm a' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="transactions.length">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h3>Transactions</h3>
          <div class="table-responsive table-sm">
            <table class="table v-middle table-hover">
              <thead>
                <tr>
                  <th width="15%">Amount</th>
                  <th width="30%">Transaction</th>
                  <th>Payment Method</th>
                  <th width="20%">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="clickable"
                  *ngFor="let transaction of transactions"
                  (click)="goToResource('orders', transaction.orderId)"
                >
                  <td>{{ transaction.amount * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}</td>
                  <td>{{ transaction.id }}</td>
                  <td>
                    {{ transaction.cardScheme | uppercase }} &bull;&bull;&bull;&bull;
                    {{ transaction.last4 }}
                  </td>
                  <td>{{ transaction.createdAt | date : 'MMM dd, YYYY, hh:mm a' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="products.length">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h3>Products</h3>
          <div class="table-responsive table-sm">
            <table class="table v-middle table-hover">
              <thead>
                <tr>
                  <th width="30%">Name</th>
                  <th width="30%">Description</th>
                  <th>Price Options</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="clickable"
                  *ngFor="let product of products"
                  (click)="goToResource('products', product._id)"
                >
                  <td>{{ product.name }}</td>
                  <td>{{ product.description }}</td>
                  <td>
                    <span *ngFor="let priceOption of product.priceOptions">
                      {{ priceOption.name }} -
                      {{ priceOption.price * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
                      <span *ngIf="priceOption.taxIncluded">(Tax Included)</span>
                      <br />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
