import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { ApiQuery, ApiQueryParams } from '@app/clients';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private path = 'product';
  private domain = environment.url_v2;
  private baseUrl = `${this.domain}/${this.path}`;
  // OVERIDE if using a new version for this feature

  constructor(private _http: HttpClient) {}

  self(query?: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this._http.get<any>(`${this.baseUrl}/self`, { params });
  }

  find(query: ApiQuery): Observable<any> {
    const params = new ApiQueryParams(query);
    return this._http.get<any>(`${this.baseUrl}`, { params });
  }

  findById(id: string): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/${id}`);
  }

  create(body: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}`, body);
  }

  update(id: string, update: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}`, update);
  }

  delete(id: string): Observable<any> {
    return this._http.delete<any>(`${this.baseUrl}/${id}`);
  }
}
