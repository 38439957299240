import { OrgFeature, Right } from '../../enums';
import { RouteInfo } from './sidebar.metadata';
import { environment } from '../../../environments/environment';

export const ROUTES: RouteInfo[] = [
  // ADMIN ROUTES
  {
    path: '/dashboard/overview',
    mode: ['admin'],
    title: 'Dashboard',
    icon: 'fas fa-home',
    class: '',
    extralink: false,
    submenu: [],
    rights: [Right.PARTAKE_ADMIN],
  },
  {
    path: '/dashboard',
    mode: ['org'],
    title: 'Dashboard',
    icon: 'fas fa-home',
    class: '',
    extralink: false,
    submenu: [],
    rights: [Right.PARTAKE_ADMIN],
  },
  {
    path: '/admin/orgs',
    mode: ['admin'],
    title: 'Orgs',
    icon: 'fas fa-building',
    class: '',
    extralink: false,
    rights: [Right.PARTAKE_ADMIN],
    submenu: [],
  },
  {
    path: '/admin/orgs/users',
    mode: ['admin'],
    title: 'Org Users',
    icon: 'fas fa-users',
    class: '',
    extralink: false,
    rights: [Right.PARTAKE_ADMIN],
    submenu: [],
  },
  {
    path: '/admin/venues',
    mode: ['admin'],
    title: 'Venues',
    icon: 'fas fa-map-marker-alt',
    class: '',
    extralink: false,
    rights: [Right.PARTAKE_ADMIN],
    submenu: [],
  },
  {
    path: '/admin/reporting',
    mode: ['admin'],
    title: 'Reporting',
    icon: 'fas fa-chart-line',
    class: '',
    extralink: false,
    rights: [Right.PARTAKE_ADMIN],
    submenu: [],
  },
  {
    path: '/admin/apps',
    mode: ['admin'],
    title: 'Apps',
    icon: 'fas fa-mobile-alt',
    class: '',
    extralink: false,
    rights: [Right.PARTAKE_ADMIN],
    submenu: [],
  },
  {
    path: '/admin/logs',
    mode: ['admin'],
    title: 'Logs',
    icon: 'fas fa-list',
    class: '',
    extralink: false,
    rights: [Right.PARTAKE_ADMIN],
    submenu: [],
  },
  {
    path: '',
    mode: ['admin'],
    title: 'Support',
    icon: 'fas fa-life-ring',
    class: 'has-arrow',
    extralink: false,
    rights: [Right.PARTAKE_ADMIN],
    submenu: [
      {
        path: '/support/product-updates',
        mode: ['admin'],
        title: 'Product Updates',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN],
      },
      {
        path: '/support/education',
        mode: ['admin'],
        title: 'Customer Education',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN],
      },
    ],
  },
  {
    path: '',
    mode: ['org'],
    title: 'Games',
    icon: 'fas fa-trophy',
    class: 'has-arrow',
    extralink: false,
    features: [OrgFeature.GAMES],
    rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_GAMES],
    submenu: [
      {
        path: '/fantasy',
        mode: ['org'],
        title: 'Games',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_GAMES],
        submenu: [],
      },
      {
        path: '/fantasy/prizes',
        mode: ['org'],
        title: 'Prizes',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MARKETING],
      },
      {
        path: '/fantasy/rules',
        mode: ['org'],
        title: 'Rules',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_GAMES],
        submenu: [],
      },
      {
        path: '/fantasy/questions',
        mode: ['org'],
        title: 'Question Bank',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_GAMES],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    mode: ['admin'],
    title: 'Sports Data',
    icon: 'mdi mdi-stadium',
    class: 'has-arrow',
    extralink: false,
    rights: [Right.PARTAKE_ADMIN],
    submenu: [
      {
        path: '/admin/sports-data/competition',
        mode: ['admin'],
        title: 'Competitions',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN],
        submenu: [],
      },
      {
        path: '/admin/sports-data/teams',
        mode: ['admin'],
        title: 'Teams / Contestants',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN],
        submenu: [],
      },
      {
        path: '/admin/sports-data/fixtures',
        mode: ['admin'],
        title: 'Fixtures',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN],
        submenu: [],
      },
      {
        path: '/admin/sports-data/players',
        mode: ['admin'],
        title: 'Players',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN],
        submenu: [],
      },
      {
        path: '/admin/sports-data/person-match-stats',
        mode: ['admin'],
        title: 'Player Game Stats',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN],
        submenu: [],
      },
    ],
  },
  // NON ADMIN
  {
    path: '/dashboard',
    mode: ['org'],
    title: 'Dashboard',
    icon: 'fas fa-home',
    class: '',
    extralink: false,
    rights: [
      Right.ORG_POS_USER,
      Right.ORG_MANAGE_GUESTS,
      Right.ORG_MANAGE_VOUCHERS,
      Right.ORG_MANAGE_ALERTS,
      Right.ORG_MANAGE_USERS,
      Right.ORG_MANAGE_TAXES,
      Right.ORG_MANAGE_TENDERS,
      Right.ORG_MANAGE_EMPLOYEES,
      Right.ORG_MANAGE_POS_SETTINGS,
      Right.ORG_MANAGE_LOCATIONS,
      Right.ORG_MANAGE_MENUS,
      Right.ORG_MANAGE_MODIFIERS,
      Right.ORG_MANAGE_DISCOUNTS,
      Right.ORG_MANAGE_GRATUITIES,
      Right.ORG_MANAGE_DINING_OPTIONS,
      Right.ORG_MANAGE_SERVICE_CHARGES,
      Right.ORG_MANAGE_PRODUCTS,
      Right.ORG_MANAGE_INVENTORY,
      Right.ORG_MANAGE_INVENTORY_SETTINGS,
      Right.ORG_MANAGE_INTEGRATIONS,
      Right.ORG_REFUNDING,
      Right.ORG_REPORTING,
      Right.ORG_MARKETING,
      Right.ORG_CUSTOMER_SUPPORT,
      Right.ORG_ADMIN,
      Right.ORG_OWNER,
    ],
    submenu: [],
  },
  {
    path: '',
    mode: ['org'],
    title: 'Reports',
    features: [OrgFeature.POS, OrgFeature.TEE_SHEET, OrgFeature.ONLINE_ORDERING],
    icon: 'fas fa-poll',
    class: 'has-arrow',
    extralink: false,
    rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_REPORTING],
    submenu: [
      {
        path: '/reports',
        mode: ['org'],
        title: 'Templates',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_REPORTING],
        submenu: [],
      },
      {
        path: '/reports/recently-run',
        mode: ['org'],
        title: 'Recently Run',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_REPORTING],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    mode: ['org'],
    title: 'Tee Sheets',
    features: [OrgFeature.TEE_SHEET],
    icon: 'fas fa-golf-ball',
    class: 'has-arrow',
    extralink: false,
    rights: [
      Right.PARTAKE_ADMIN,
      Right.ORG_OWNER,
      Right.ORG_ADMIN,
      Right.ORG_MANAGE_TEE_SHEET,
      Right.ORG_MANAGE_TEE_SHEET_SETTINGS,
    ],
    submenu: [
      {
        path: '/tee-sheet',
        mode: ['org'],
        title: 'Tee Sheet',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_TEE_SHEET],
      },
      {
        path: '/tee-sheet/settings',
        mode: ['org'],
        title: 'Settings',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_TEE_SHEET_SETTINGS,
        ],
      },
    ],
  },
  {
    path: '',
    mode: ['org'],
    title: 'Billing',
    features: [OrgFeature.BILLING],
    icon: 'fas fa-file-invoice-dollar',
    class: 'has-arrow',
    extralink: false,
    rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_EVENTS],
    submenu: [
      {
        path: '/billing/subscriptions',
        mode: ['org'],
        title: 'Subscriptions',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_BILLING,
          Right.ORG_MANAGE_EVENTS,
        ],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    mode: ['org'],
    title: 'Events',
    features: [OrgFeature.EVENTS],
    icon: 'far fa-calendar',
    class: 'has-arrow',
    extralink: false,
    rights: [
      Right.PARTAKE_ADMIN,
      Right.ORG_OWNER,
      Right.ORG_ADMIN,
      Right.ORG_MANAGE_EVENTS,
      Right.ORG_MANAGE_GUESTS,
    ],
    submenu: [
      // {
      //   path: '/events/attractions',
      //   title: 'Attractions',
      //   icon: 'mdi mdi-adjust',
      //   class: '',
      //   extralink: false,
      //   rights: [
      //     Right.PARTAKE_ADMIN
      //   ],
      //   submenu: []
      // },
      {
        path: '/events/all',
        mode: ['org'],
        title: 'All Events',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_EVENTS,
          Right.ORG_MANAGE_GUESTS,
        ],
        submenu: [],
      },
      {
        path: '/events/classifications',
        mode: ['org'],
        title: 'Classifications',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_EVENTS,
          Right.ORG_MANAGE_GUESTS,
        ],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    mode: ['org'],
    title: 'CRM & Marketing',
    features: [OrgFeature.POS, OrgFeature.TEE_SHEET, OrgFeature.MARKETING],
    icon: 'fas fa-mail-bulk',
    class: 'has-arrow',
    extralink: false,
    rights: [
      Right.PARTAKE_ADMIN,
      Right.ORG_OWNER,
      Right.ORG_ADMIN,
      Right.ORG_REFUNDING,
      Right.ORG_MARKETING,
      Right.ORG_MANAGE_GUESTS,
      Right.ORG_MANAGE_VOUCHERS,
    ],
    submenu: [
      {
        path: '/guests',
        mode: ['org'],
        title: 'Guests',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_REFUNDING,
          Right.ORG_MARKETING,
          Right.ORG_MANAGE_GUESTS,
        ],
      },
      {
        path: '/vouchers',
        mode: ['org'],
        title: 'Vouchers',
        features: [OrgFeature.POS, OrgFeature.TEE_SHEET],
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MARKETING,
          Right.ORG_MANAGE_VOUCHERS,
        ],
      },
      {
        path: '/marketing/assets',
        mode: ['org'],
        title: 'Assets',
        features: [OrgFeature.MARKETING],
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MARKETING],
      },
      {
        path: '/marketing/emails',
        mode: ['org'],
        title: 'Emails',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MARKETING],
      },
      {
        path: '/marketing/notifications',
        mode: ['org'],
        title: 'Sent Notifications',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MARKETING],
      },
      // {
      //   path: '/marketing/promotions',
      //   title: 'Promotions',
      //   features: [OrgFeature.MARKETING],
      //   icon: 'mdi mdi-adjust',
      //   class: '',
      //   extralink: false,
      //   submenu: [],
      //   rights: [
      //     Right.PARTAKE_ADMIN,
      //     Right.ORG_OWNER,
      //     Right.ORG_ADMIN,
      //     Right.ORG_MARKETING
      //   ]
      // }
    ],
  },
  {
    path: '',
    mode: ['org'],
    title: 'Orders',
    features: [OrgFeature.POS, OrgFeature.TEE_SHEET],
    icon: 'fas fa-cart-arrow-down',
    class: 'has-arrow',
    extralink: false,
    rights: [
      Right.PARTAKE_ADMIN,
      Right.ORG_OWNER,
      Right.ORG_ADMIN,
      Right.ORG_REFUNDING,
      Right.ORG_CUSTOMER_SUPPORT,
    ],
    submenu: [
      {
        path: '/orders/online',
        mode: ['org'],
        title: 'Mobile / Online',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_REFUNDING,
          Right.ORG_CUSTOMER_SUPPORT,
        ],
      },
      {
        path: '/orders/pos',
        mode: ['org'],
        title: 'In-Person',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_REFUNDING,
          Right.ORG_CUSTOMER_SUPPORT,
        ],
      },
      {
        path: environment.posUrl,
        mode: ['org'],
        external: true,
        title: 'POS',
        icon: 'fas fa-external-link-alt',
        features: [OrgFeature.POS],
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_POS_USER],
      },
    ],
  },
  {
    path: '',
    mode: ['org'],
    title: 'Inventory & Products',
    features: [OrgFeature.POS, OrgFeature.TEE_SHEET],
    icon: 'mdi mdi-food-variant',
    class: 'has-arrow',
    extralink: false,
    rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_PRODUCTS],
    submenu: [
      {
        path: '/categories',
        mode: ['org'],
        title: 'Categories',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_PRODUCTS],
      },
      {
        path: '/products',
        mode: ['org'],
        title: 'Products',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_PRODUCTS],
      },
      {
        path: '/modifier-groups',
        mode: ['org'],
        title: 'Modifier Groups',
        features: [OrgFeature.POS],
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_MODIFIERS],
        submenu: [],
      },
      {
        path: '/inventory',
        mode: ['org'],
        title: 'Inventory',
        features: [OrgFeature.POS],
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_INVENTORY],
      },
      {
        path: '/inventory/logs',
        mode: ['org'],
        title: 'Inventory Logs',
        features: [OrgFeature.POS],
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_INVENTORY],
      },
    ],
  },
  {
    path: '',
    mode: ['org'],
    title: 'Menus',
    features: [OrgFeature.POS, OrgFeature.TEE_SHEET],
    icon: 'mdi mdi-food',
    class: 'has-arrow',
    extralink: false,
    rights: [
      Right.PARTAKE_ADMIN,
      Right.ORG_OWNER,
      Right.ORG_ADMIN,
      Right.ORG_MANAGE_LOCATIONS,
      Right.ORG_MANAGE_MENUS,
      Right.ORG_MANAGE_MODIFIERS,
      Right.ORG_MANAGE_DISCOUNTS,
      Right.ORG_MANAGE_GRATUITIES,
      Right.ORG_MANAGE_DINING_OPTIONS,
      Right.ORG_MANAGE_SERVICE_CHARGES,
    ],
    submenu: [
      {
        path: '/locations',
        mode: ['org'],
        title: 'Locations',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_LOCATIONS],
        submenu: [],
      },
      {
        path: '/locations/menus',
        mode: ['org'],
        features: [OrgFeature.MENU_SECTIONS],
        title: 'Menus',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_MENUS],
        submenu: [],
      },
      {
        path: '/locations/menus-sections',
        mode: ['org'],
        title: 'Menu Sections',
        features: [OrgFeature.MENU_SECTIONS],
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_MENUS],
        submenu: [],
      },
      {
        path: '/locations/menu-items',
        mode: ['org'],
        title: 'Menu Items',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_MENUS],
        submenu: [],
      },
      {
        path: '/locations/discounts',
        mode: ['org'],
        title: 'Discounts',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_DISCOUNTS],
        submenu: [],
      },
      {
        path: '/locations/gratuities',
        mode: ['org'],
        title: 'Gratuities',
        features: [OrgFeature.POS],
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_GRATUITIES,
        ],
        submenu: [],
      },
      {
        path: '/locations/dining-options',
        mode: ['org'],
        title: 'Dining Options',
        features: [OrgFeature.POS],
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_DINING_OPTIONS,
        ],
        submenu: [],
      },
      {
        path: '/locations/service-charges',
        mode: ['org'],
        title: 'Service Charges',
        features: [OrgFeature.POS],
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_SERVICE_CHARGES,
        ],
        submenu: [],
      },
      {
        path: '/locations/stations',
        mode: ['org'],
        title: 'Stations',
        features: [OrgFeature.POS],
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_MENUS],
        submenu: [],
      },
      {
        path: '/locations/day-parts',
        mode: ['org'],
        title: 'Day Parts & Open Hours',
        features: [OrgFeature.MENU_SECTIONS],
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_MENUS],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    mode: ['org'],
    title: 'Operations',
    features: [OrgFeature.POS, OrgFeature.TEE_SHEET],
    icon: 'fas fa-link',
    class: 'has-arrow',
    extralink: false,
    rights: [
      Right.PARTAKE_ADMIN,
      Right.PARTAKE_ADMIN,
      Right.ORG_OWNER,
      Right.ORG_ADMIN,
      Right.ORG_MANAGE_POS_SETTINGS,
      Right.ORG_MANAGE_INTEGRATIONS,
    ],
    submenu: [
      {
        path: '/point-of-sale',
        mode: ['org'],
        title: 'Point of Sales',
        features: [OrgFeature.POS],
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_POS_SETTINGS,
        ],
      },
      {
        path: '/shifts',
        mode: ['org'],
        title: 'Shifts',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_POS_SETTINGS,
        ],
      },
      {
        path: '/integrations',
        mode: ['org'],
        title: 'Integrations',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_INTEGRATIONS,
        ],
      },
      {
        path: '/activity-log',
        mode: ['org'],
        title: 'Activity Log',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_INTEGRATIONS,
        ],
        submenu: [],
      },
      {
        path: '/integrations/sync-errors',
        mode: ['org'],
        title: 'Sync Errors',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_REPORTING,
          Right.ORG_MANAGE_INTEGRATIONS,
        ],
      },
    ],
  },
  {
    path: '',
    mode: ['org'],
    title: 'Support',
    features: [OrgFeature.ONLINE_ORDERING, OrgFeature.GAMES, OrgFeature.FANTASY_SPORTS],
    icon: 'fas fa-life-ring',
    class: 'has-arrow',
    extralink: false,
    rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN],
    submenu: [
      {
        path: '/support/education',
        mode: ['org'],
        title: 'Customer Education',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN],
      },
      {
        path: '/support/reviews',
        mode: ['org'],
        title: 'Reviews',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        rights: [Right.PARTAKE_ADMIN],
      },
    ],
  },
  {
    path: '',
    mode: ['org'],
    title: 'Settings',
    features: [OrgFeature.POS, OrgFeature.TEE_SHEET, OrgFeature.ONLINE_ORDERING, OrgFeature.GAMES],
    icon: 'fas fa-cog',
    class: 'has-arrow',
    extralink: false,
    rights: [
      Right.PARTAKE_ADMIN,
      Right.ORG_OWNER,
      Right.ORG_ADMIN,
      Right.ORG_MANAGE_ALERTS,
      Right.ORG_MANAGE_USERS,
      Right.ORG_MANAGE_TAXES,
      Right.ORG_MANAGE_TENDERS,
      Right.ORG_MANAGE_EMPLOYEES,
    ],
    submenu: [
      {
        path: '/venues',
        mode: ['org'],
        title: 'Site Settings',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN],
        features: [OrgFeature.POS, OrgFeature.TEE_SHEET, OrgFeature.ONLINE_ORDERING],
        submenu: [],
      },
      {
        path: '/settings/alerts',
        mode: ['org'],
        title: 'Alerts',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_ALERTS],
        features: [OrgFeature.POS, OrgFeature.TEE_SHEET, OrgFeature.ONLINE_ORDERING],
        submenu: [],
      },
      {
        path: '/settings/users',
        mode: ['org'],
        title: 'Users',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_USERS],
        submenu: [],
      },
      {
        path: '/settings/accounting-codes',
        mode: ['org'],
        title: 'Accounting Codes',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_REPORTING],
        features: [OrgFeature.POS, OrgFeature.TEE_SHEET, OrgFeature.ONLINE_ORDERING],
        submenu: [],
      },
      {
        path: '/settings/tax-codes',
        mode: ['org'],
        title: 'Taxes',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_TAXES],
        features: [OrgFeature.POS, OrgFeature.TEE_SHEET, OrgFeature.ONLINE_ORDERING],
        submenu: [],
      },
      {
        path: '/settings/tenders',
        mode: ['org'],
        title: 'Tenders',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_TENDERS],
        features: [OrgFeature.POS, OrgFeature.TEE_SHEET, OrgFeature.ONLINE_ORDERING],
        submenu: [],
      },
      {
        path: '/settings/stand-sheets',
        mode: ['org'],
        title: 'Stand Sheets',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_INVENTORY_SETTINGS,
        ],
        features: [OrgFeature.POS],
        submenu: [],
      },
      {
        path: '/settings/printers',
        mode: ['org'],
        title: 'Printers',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_LOCATIONS],
        features: [OrgFeature.POS],
        submenu: [],
      },
      {
        path: '/settings/tags',
        mode: ['org'],
        title: 'Tags',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN],
        features: [OrgFeature.POS, OrgFeature.TEE_SHEET, OrgFeature.ONLINE_ORDERING],
        submenu: [],
      },
      {
        path: '/settings/floor-plans',
        mode: ['org'],
        title: 'Floor Plans',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [
          Right.PARTAKE_ADMIN,
          Right.ORG_OWNER,
          Right.ORG_ADMIN,
          Right.ORG_MANAGE_DINING_OPTIONS,
        ],
        features: [OrgFeature.POS, OrgFeature.ONLINE_ORDERING],
        submenu: [],
      },
      {
        path: '/settings/employees',
        mode: ['org'],
        title: 'Employees',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN, Right.ORG_MANAGE_EMPLOYEES],
        features: [OrgFeature.POS, OrgFeature.TEE_SHEET, OrgFeature.ONLINE_ORDERING],
        submenu: [],
      },
      {
        path: '/settings/custom-code',
        mode: ['org'],
        title: 'App Customization',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN],
        features: [OrgFeature.ONLINE_ORDERING],
        submenu: [],
      },
      {
        path: '/settings/terms-and-conditions',
        mode: ['org'],
        title: 'Terms & Conditions',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        rights: [Right.PARTAKE_ADMIN, Right.ORG_OWNER, Right.ORG_ADMIN],
        features: [OrgFeature.POS, OrgFeature.ONLINE_ORDERING],
        submenu: [],
      },
    ],
  },
  {
    path: '/logs',
    mode: ['org'],
    title: 'Logs',
    icon: 'fas fa-list',
    class: '',
    extralink: false,
    submenu: [],
    rights: [Right.PARTAKE_ADMIN],
  },
  {
    path: 'https://partaketechnologies.zendesk.com/',
    mode: ['org'],
    external: true,
    title: 'Help Center',
    icon: 'fas fa-info-circle',
    class: '',
    extralink: false,
    submenu: [],
    rights: [
      Right.PARTAKE_ADMIN,
      Right.PARTAKE_SUPPORT,
      Right.ORG_POS_USER,
      Right.ORG_MANAGE_GUESTS,
      Right.ORG_MANAGE_VOUCHERS,
      Right.ORG_MANAGE_ALERTS,
      Right.ORG_MANAGE_USERS,
      Right.ORG_MANAGE_TAXES,
      Right.ORG_MANAGE_TENDERS,
      Right.ORG_MANAGE_EMPLOYEES,
      Right.ORG_MANAGE_POS_SETTINGS,
      Right.ORG_MANAGE_LOCATIONS,
      Right.ORG_MANAGE_MENUS,
      Right.ORG_MANAGE_MODIFIERS,
      Right.ORG_MANAGE_DISCOUNTS,
      Right.ORG_MANAGE_GRATUITIES,
      Right.ORG_MANAGE_DINING_OPTIONS,
      Right.ORG_MANAGE_SERVICE_CHARGES,
      Right.ORG_MANAGE_PRODUCTS,
      Right.ORG_MANAGE_INVENTORY,
      Right.ORG_MANAGE_INVENTORY_SETTINGS,
      Right.ORG_MANAGE_INTEGRATIONS,
      Right.ORG_REFUNDING,
      Right.ORG_REPORTING,
      Right.ORG_MARKETING,
      Right.ORG_CUSTOMER_SUPPORT,
      Right.ORG_ADMIN,
      Right.ORG_OWNER,
    ],
  },
];
