import { GuestData } from '../crm';
import { RemovedTeeTimePlayerReason, TeeTimePlayerStatus } from '../../enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { GreenFeeRateData } from './green-fee-rate';
import { CourseData } from './course';
import { TeeTimeBookingData } from './tee-time-booking';
import { EnumHelper } from '../../helpers';
import { ISchema, Type, Widget } from '../schema';

export interface TeeTimePlayer {
  _id?: string;
  status: TeeTimePlayerStatus;
  userId?: string;
  guestId?: string | GuestData; // At min one player needs a guest ID
  teeTimeRateId: string | TeeTimeRate;
  bookingId: string | TeeTimeBookingData;
  isPaid: boolean;
}

export interface RemovedTeeTimePlayer {
  _id?: string;
  reason: RemovedTeeTimePlayerReason;
  comment?: string;
  userId?: string;
  guestId?: string | GuestData; // At min one player needs a guest ID
  teeTimeRateId: string | TeeTimeRate;
  bookingId: string | TeeTimeBookingData;
  isRefunded: boolean;
}

export const RemoveTeeTimePlayerSchema: ISchema = {
  reason: {
    label: 'Reason',
    type: Type.STRING,
    enum: EnumHelper.getEnumArray(RemovedTeeTimePlayerReason),
    widget: {
      type: Widget.SELECT,
    },
    required: true,
  },
  comment: {
    label: 'Comment',
    type: Type.STRING,
    enum: EnumHelper.getEnumArray(RemovedTeeTimePlayerReason),
    onlyIf: [
      {
        field: 'reason',
        shouldShow: (value: RemovedTeeTimePlayerReason) =>
          value === RemovedTeeTimePlayerReason.OTHER,
      },
    ],
    required: true,
  },
};

export interface SinglePlayerPrice {
  bookingFee: number;
  greenFee: number;
  cartFee: number;
  total: number;
}

export interface TeeTimeRate {
  _id?: string;
  holeCount: number; // 18
  isCartIncluded: boolean; // true
  isEighteen: boolean; // true
  isHotDeal: boolean; // false
  isNine: boolean; // false
  rateName: string; // "Online Rate",
  greenFeeRateId: string | GreenFeeRateData;
  rewardsEligible: boolean; // true
  singlePlayerPrice: SinglePlayerPrice;
  transportation: string; // "Cart Included"
}

export interface TeeTimeData extends AbstractModelData {
  currencyCode: string; // USD
  displayRate: number; // 7714
  isPriceRangeZero: boolean; // true
  maxTeeTimeRate: number; // 7714
  minTeeTimeRate: number; // 7714
  teeTimeRates: TeeTimeRate[];
  defaultTeeTimeRateId?: string; // Within TeeTimeRates[]
  dateTime: Date; // "2019-09-22T10:52:00Z-04:00.000"
  formattedTime: string; // "10:52"
  formattedTimeMeridian: string; // "AM"
  timeHour: number; // 10
  timeMinute: number; // 52
  formattedDateTime: string; // "2019-09-22T10:52"
  minPlayers: number; // 1
  availableSpots: number; // Max 4
  players: TeeTimePlayer[];
  removedPlayers: RemovedTeeTimePlayer[];
  increment: {
    name: string;
    color: string;
  };
  season: string;
  teeSheetId: string;
  orgId: string;
  courseId: string;
  venueId: string;

  // Only returned from the API
  course?: CourseData;
}

export interface BookTeeTimePlayerBody {
  teeTimeRateId: string;
  guestId?: string;
  guest?: {
    name: string;
    email: string;
    phone?: string;
  };
}

export interface BookTeeTimeBody {
  players: BookTeeTimePlayerBody[];
}

export type UpdateTeeTimePlayerBody = Partial<
  Pick<TeeTimePlayer, 'status' | 'guestId' | 'teeTimeRateId'>
>;

export type RemoveTeeTimePlayerBody = Pick<RemovedTeeTimePlayer, 'reason' | 'comment'>;

export class TeeTime extends AbstractModel<TeeTimeData> {
  constructor(public data: TeeTimeData) {
    super(data);
  }
}
