import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'venueRestaurant',
})
export class VenueRestaurantPipe implements PipeTransform {
  transform(venueType: string): string {
    let name = 'Restaurants';
    switch (venueType) {
      case 'arena':
        name = 'Concessions';
        break;
      case 'food-truck':
        name = 'Food Trucks';
        break;
    }
    return name;
  }
}
