export enum GameType {
  BINGO = 'bingo',
  HEADS_UP = 'heads-up',
  PICK_EM = 'pick-em',
  PROPS = 'props',
  STREAK = 'streak',
  MORE_LESS = 'more-less',
  TRIVIA = 'trivia',
  GAME_GROUP = 'group',
  BONUS_ACTION = 'bonus-action',
}

export enum BonusActionType {
  FREE_FORM = 'freeform',
  // PROFILE_FIELD = 'profile-field',
  // VIDEO = 'video',
  // TWITTER_SHARE = 'twitter-share',
  // APP_INSTALL = 'app-install',
  // SOCIAL_FOLLOW = 'social-follow'
}

export enum FormFieldType {
  BOOLEAN = 'boolean',
  EMAIL = 'email',
  NUMBER = 'number',
  TEL = 'tel',
  STRING = 'string',
  LONG_STRING = 'longString',
}

export enum GameStatus {
  DRAFT = 'draft',
  PRE_OPEN = 'pre-open',
  OPEN = 'open',
  LIVE = 'live',
  COMPLETED = 'closed',
  SETTLED = 'settled',
}

export enum MoreLessGameType {
  STAT = 'stats',
  FANTASY = 'fantasy',
}

export enum MoreLessSelection {
  MORE = 'more',
  LESS = 'less',
}

export enum MoreLessResult {
  NO_RESULT = 'no-result',
  WIN = 'win',
  LOSS = 'loss',
  CANCELLED = 'cancelled',
}

export enum HeadsUpCorrectPerson {
  PERSON_1 = 'person1',
  PERSON_2 = 'person2',
  TIE = 'tie',
  UNKNOWN = 'unknown',
}

export enum HeadsUpTieBreakerType {
  SCORE = 'score',
}

export enum GameQueue {
  GAME_ENTRY = 'game-entry',
  SETTLE_GAME_ENTRY = 'settle-game-entry',
}
