import { JobResultFileType, JobResultObject, JobStatus, JobType } from '../../enums';

export interface JobResult {
  object: JobResultObject;
  createdAt: Date;
  expiresAt: Date;
  filename: string;
  size: number;
  title: string;
  type: JobResultFileType;
  url: string;
}

export interface JobData {
  jobType: JobType;
  status: JobStatus;
  parameters: {
    [key: string]: any;
    type: any;
  };
  checksum: any;
  orgId: string;
  userId: string;
  result?: JobResult;
  succeededAt?: Date;
  error?: string;
}
