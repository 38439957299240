export enum CategoryType {
  DRINK = 'drink',
  FOOD = 'food',
  GOLF = 'golf',
  INGREDIENT = 'ingredient',
  MERCHANDISE = 'merchandise',
  RECIPE = 'recipe',
  RETAIL = 'retail',
  SNACK = 'snack',
  OTHER = 'other',
}
