import { IntegrationSystem, IntegrationType } from '../../enums';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type } from '../schema';

export const IntegrationBaseSchema: ISchema = {
  canSync: {
    type: Type.BOOLEAN,
    label: 'Can Sync',
    default: false,
    isHidden: true,
  },
  autoSync: {
    type: Type.BOOLEAN,
    default: false,
    label: 'Auto Sync',
    description: 'Only enable once you have verified that the integration is working correctly.',
    onlyIf: 'canSync',
  },
  isActive: {
    type: Type.BOOLEAN,
    default: true,
    label: 'Is Active',
    partakeOnly: true,
  },
  note: {
    type: Type.STRING,
    label: 'Note',
  },
};

export interface IIntegrationBase extends AbstractModelData {
  type: IntegrationType;
  system: IntegrationSystem;
  orgId: string;
  isActive: boolean;
  canSync?: boolean;
  autoSync?: boolean;
  note?: string;
  isDeleted?: boolean;
}

export class Integration<T extends IIntegrationBase> extends AbstractModel<T> {
  constructor(public data: T) {
    super(data);
  }

  public static getNameFromSystem(system: IntegrationSystem): string {
    switch (system) {
      case IntegrationSystem.CLOUDFLARE:
        return 'Cloudflare';
      case IntegrationSystem.EZ_LINKS:
        return 'EzLinks';
      case IntegrationSystem.STRIPE:
        return 'Stripe';
      case IntegrationSystem.PAYLOAD:
        return 'Payload';
    }
  }

  get canSync() {
    return this.get('canSync');
  }
}
